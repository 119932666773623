import { FormCallbackArgs } from '@data-session/forms';
import { FormCallback } from '@data-session/forms';
import { ModalControls } from '@data-session/mui';
import { useCallback } from 'react';

export interface UseFormModalCallbackArgs<TStorage, TCallbackArgs extends FormCallbackArgs> {
    controls: ModalControls<TStorage>;
    callback?: FormCallback<TCallbackArgs>;
}

export default function useFormModalCallback<TStorage, TCallbackArgs extends FormCallbackArgs>({
    controls,
    callback
}: UseFormModalCallbackArgs<TStorage, TCallbackArgs>) {
    return useCallback((args: TCallbackArgs)=>{
        controls.closeModal();
        if (callback) callback(args);
    }, [controls, callback]);
}