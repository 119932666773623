import {FC, ReactNode, useCallback, useMemo} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {RowRenderer} from "../props";
import DnDRenderer from "./DnDRenderer";

export const DnDRendererProvider: FC<{
    children: ReactNode;
}> = ({children}) => {
    return (
        <DndProvider backend={HTML5Backend}>
            {children}
        </DndProvider>
    )
};