import {FC, MutableRefObject, useMemo, useRef} from "react";
import {ConfirmationDialogBase, ConfirmationDialogReturnProps, useConfirmationDialog} from "./props";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props extends ConfirmationDialogBase {
    entityName?: string;
}
const DeleteConfirmationDialog: FC<Props> = (props) => {
    return (
        <ConfirmationDialog
            {...props}
            title="Doopravdy si to přejete smazat?"
            message={`Tato akce již nepůjde vrátit zpátky. ${props.entityName ? "Entita: "+props.entityName : ""}`}
            primary="no"
        />
    );
};
export default DeleteConfirmationDialog;

interface DeleteConfirmationDialogReturnProps<T> extends ConfirmationDialogReturnProps {
    props: Props;
    entityRef: MutableRefObject<T | undefined>;
    handleOpen: (item?: T)=>void;
}
export function useDeleteConfirmationDialog<T>(handleDelete: ()=>void): DeleteConfirmationDialogReturnProps<T> {
    const entityRef = useRef<T>();
    const props = useConfirmationDialog(handleDelete);

    return useMemo(()=>({
        ...props,
        entityRef,
        handleOpen: (item)=>{
            entityRef.current = item;
            props.handleOpen();
        }
    }), [props]);
}