import {FC} from "react";
import Button from "../button/Button";
import {DialogActions} from "@mui/material";

type Props = {
    loading?: boolean;
    handleClose: ()=>void;
};
const CloseActions: FC<Props> = (
    {
        handleClose,
        loading
    }
) => {
    return (
        <DialogActions>
            <Button
                onClick={handleClose}
                variant="outlined"
                loading={loading}
            >
                Zavřít
            </Button>
        </DialogActions>
    );
};
export default CloseActions;