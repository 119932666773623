import {headerRenderer, HeaderRendererProps} from "react-data-grid";
import TextField, {TextFieldInput} from "../../../form/fields/TextField";
import {Box, css, styled} from "@mui/material";
import {
    createElement,
    FC,
    MutableRefObject,
    PropsWithChildren,
    ReactElement, RefObject,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef
} from "react";
import {DecodedValueMap, QueryParamConfig, QueryParamConfigMap, UrlUpdateType, useQueryParams} from "use-query-params";
import Select, {SelectOption, SelectProps} from "../../../form/fields/select/Select";
import {ChangeQuery, QueryBase} from "../props";
import {RowMasterDetail} from "../RowMasterDetail";
import DateRangeField from "../../../form/fields/DateRangeField";
import {FormikProps} from "formik";

export function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
    const ref = useRef<T>(null);

    useLayoutEffect(() => {
        if (!isSelected) return;
        ref.current?.focus({preventScroll: true});
    }, [isSelected]);

    return {
        ref,
        tabIndex: isSelected ? 0 : -1
    };
}

interface FilterInput {
    name: string;
    ref: MutableRefObject<HTMLInputElement | null>;
    tabIndex: number;
}

type FilterInputElement<I = FilterInput> = (props: I)=>ReactElement;

type Props<T> = {
    headerProps: HeaderRendererProps<T>,
    element: any,
    name: any
}

export const Filter = <T, >(
    {
        headerProps, element, name
    }: PropsWithChildren<Props<T>>
) => {
    const {ref, tabIndex} = useFocusRef<HTMLInputElement>(headerProps.isCellSelected);

    return (
        <FilterWrapper>
            <div>{headerRenderer({...headerProps, isCellSelected: false})}</div>
            <div>
                {element({name, ref, tabIndex})}
            </div>
        </FilterWrapper>
    );
}

/**
 * INPUTS
 */

export function TextFieldFilter(): FilterInputElement {
    return ({name, ref, tabIndex}: FilterInput)=>
        <TextField name={name} InputProps={{
            inputProps: {ref, tabIndex}
        }}/>;
}


interface DateRangeFilterInput extends Omit<FilterInput, "name"> {
    name: {from: string, to: string}
}
export function DateRangeFilter(withoutTime?: boolean): FilterInputElement<DateRangeFilterInput> {
    return ({name}: DateRangeFilterInput)=>
        <DateRangeField name={name} withoutTime={withoutTime} />;
}

export function SpecificSelectFilter(component: FC<SelectProps>, multiple?: boolean, options?: SelectOption[]): FilterInputElement {
    return ({name, ref, tabIndex})=>createElement(component, {
        name,
        SelectProps: {ref, tabIndex, multiple},
        clearable: false,
        ...(!!options ? {options} : {})
    });
}

export function SelectFilter(options: SelectOption[], multiple?: boolean): FilterInputElement {
    return SpecificSelectFilter(Select, multiple, options);
}


export interface FilterValues {
    [key: string]: any
}

export type FilterProps<T> = {
    onSubmit: (values: T)=>void;
    initValues: T;
    filterRef: MutableRefObject<T>;
    formikRef: RefObject<FormikProps<T>>;
    reset: ()=>void;
};

export function useFilter<T extends FilterValues>(
    request: (paginationOnly?: boolean)=>Promise<void>,
    query: DecodedValueMap<QueryBase>,
    handleChangeQuery: ChangeQuery,
    schema: QueryParamConfigMap,
    openMasterDetailsIf?: (values: T)=>boolean,
    masterDetail?: RowMasterDetail
): FilterProps<T> {
    const filterRef = useRef<T>(query as T);
    const formikRef = useRef<FormikProps<T>>(null);

    const onSubmit = useCallback(async (values: T)=>{
        filterRef.current = values;
        handleChangeQuery({...values});
        if (!!openMasterDetailsIf && openMasterDetailsIf(values)) masterDetail?.expandAll();
        await request();
    }, [openMasterDetailsIf, handleChangeQuery, request, masterDetail]);

    const initValues = useMemo((): T=>{
        let result: FilterValues = {};
        Object.entries(schema).forEach(([key, value])=>{
            result[key] = !!query[key] ? query[key] : value.default;
        });
        const values = result as T;
        if (!!openMasterDetailsIf && openMasterDetailsIf(values)) masterDetail?.expandAll();
        return values;
    }, [schema, openMasterDetailsIf]);

    const reset = useCallback(async ()=>{
        if (!formikRef.current) return;
        formikRef.current.resetForm(initValues);
    }, [initValues]);

    return useMemo(()=>({
        onSubmit, initValues,
        filterRef, reset, formikRef
    }), [onSubmit, initValues, reset]);
}

const FilterWrapper = styled(Box)(({theme})=>css`
  display: flex;
  flex-direction: column;
  line-height: normal;
  justify-content: center;
  height: 100%;
`);