import React, {FC, useCallback, useEffect, useState} from "react";
import {FileFieldAccept, FileFieldActions, FileFieldInput} from "../form/fields/FileField";
import DeleteConfirmationDialog, {useDeleteConfirmationDialog} from "./dialog/DeleteConfirmationDialog";
import {DocumentRequest, DocumentType} from "../props/apiRequests";
import {DocumentResponse} from "../props/apiResponses";
import {Messages} from "../props/messages";
import {useMessages} from "../props/messagesHandler";
import {makeDownloadBlob} from "../props/helpers";

export type ExternalOneFileProps = {
    label?: string;
    accept?: FileFieldAccept;
    documents: DocumentResponse[];
    handleRemove: (documentType: DocumentType)=>Promise<boolean>;
    handleUpload: (file: File, metadata: DocumentRequest)=>Promise<DocumentResponse | null>;
    getDocumentBlob: (document: DocumentResponse)=>Promise<Blob | null>;
    startLoading: ()=>void;
    stopLoading: ()=>void;
    loading?: boolean;
    setDocuments: (data: DocumentResponse[])=>void;
    documentType: DocumentType;
    match: (document: DocumentResponse)=>boolean;
    disabled?: boolean;
};
const ExternalOneFile: FC<ExternalOneFileProps> = (
    {
        label,
        accept,
        documents,
        handleRemove,
        stopLoading,
        startLoading,
        setDocuments,
        documentType,
        loading,
        handleUpload,
        getDocumentBlob,
        match,
        disabled
    }
) => {
    const [file, setFile] = useState<File[]>();
    const messageHandler = useMessages();

    const deleteDialog = useDeleteConfirmationDialog(async ()=>{
        startLoading();
        if (await handleRemove(documentType))
            setDocuments(documents.filter((document) => !match(document)));
        stopLoading();
        deleteDialog.handleClose();
    });

    useEffect(()=>{
        if (!documents) return;

        setFile(documents
            .filter(match)
            .map((document)=>new File([], document.title)));
    }, [documents, match]);

    const handleUploadFile = useCallback(async (_: any, files: File[]) => {
        const file = files[0];

        startLoading();

        if (!(await handleRemove(documentType))) {
            stopLoading();
            return;
        }

        const result = await handleUpload(file, {
            title: file.name,
            type: documentType,
            mimeType: file.type
        });

        if (result!==null) {
            messageHandler.success(Messages.SAVED);
            setDocuments(documents.filter((document)=>!match(document)).concat(result));
        }

        stopLoading();
    }, [documents, documentType, handleRemove, match]);

    const handleDownloadFile = useCallback(async ()=>{
        const document = documents.find(match);
        if (!document) return;

        const result = await getDocumentBlob(document);
        if (result) makeDownloadBlob(result, document.title);
    }, [documents, match, getDocumentBlob]);

    return (
        <>
            <FileFieldInput
                label={label}
                accept={accept}
                onChange={handleUploadFile}
                value={file}
                actions={[
                    [FileFieldActions.DELETE, deleteDialog.handleOpen],
                    [FileFieldActions.DOWNLOAD, handleDownloadFile]
                ]}
                loading={loading}
                disabled={disabled}
            />
            <DeleteConfirmationDialog
                {...deleteDialog.props}
                entityName="Soubor"
                loading={loading}
            />
        </>
    );
};
export default ExternalOneFile;
