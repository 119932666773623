import React, {Component, createElement, FC, useCallback, useMemo} from "react";
import {DialogBase, DialogReturnProps, useDialog} from "./props";
import {OfferResponse, OpportunityResponse} from "../../props/apiResponses";
import Dialog from "./Dialog";
import Help from "../Help";
import {ArrowDownward} from "@mui/icons-material";
import CloseActions from "./CloseActions";
import OpportunitiesGrid, {OpportunitiesGridRow} from "../../pages/opportunity/OpportunitiesGrid";
import {ActionsColumn, ActivityColumn} from "../dataGrid/Columns";
import {PredefinedAction} from "../dataGrid/formatter/ActionsFormatter";
import {TopAction} from "../dataGrid/TopActions";
import {CalculatedColumn, Column} from "react-data-grid";
import {ItemRow} from "../dataGrid/useDataGrid";
import {Response} from "../../props/apiResponses";
import {Filter, TextFieldFilter} from "../dataGrid/filter/useFilter";
import {businessCasesOptions} from "../../form/fields/select/options";
import {formatDate} from "../../props/helpers";
import {BusinessCasesGridRow} from "../../pages/businessCase/BusinessCasesGrid";

interface Row<E extends Response> {
    item: E;
}

export interface GridChooserProps<R> {
    topActions?: TopAction[];
    actionsColumn?: Column<R>;
    loading?: boolean;
    onRowDoubleClick?: (row: R, column: CalculatedColumn<R>)=>void;
    disableQueryParams?: boolean;
}

export interface ChooserDialogBase<E extends Response> extends DialogBase {
    handleSelection: (entity: E)=>void;
    loading?: boolean;
}

interface Props<E extends Response, R, C extends FC<GridChooserProps<R>>> extends DialogBase {
    handleSelection: (entity: E)=>void;
    entityName: string;
    component: C;
    loading?: boolean;
}
const GridChooserDialog = <E extends Response, R extends Row<E>, C extends FC<GridChooserProps<R>>>(
    {
        DialogProps,
        handleClose,
        handleSelection,
        entityName,
        loading,
        component
    }: Props<E, R, C>
) => {

    const select = useCallback((row: R)=>handleSelection(row.item), []);

    const actionsColumn = useMemo(()=>ActionsColumn<R>(({row})=>([
        [PredefinedAction.SELECT, ()=>select(row)]
    ])), []);

    return (
        <Dialog
            DialogProps={{
                ...DialogProps,
                maxWidth: "md"
            }}
            title={<>Výběr {entityName} <Help
                title={<>
                    Výběr {entityName} realizujete kliknutím na ikonku <ArrowDownward fontSize="inherit" /> ve sloupci akce,
                    nebo dvojkliknutím na řádek.
                </>}
            /></>}
            actions={
                <CloseActions
                    handleClose={handleClose}
                    loading={loading}
                />
            }
        >
            {createElement(component, {
                loading,
                disableQueryParams: true,
                actionsColumn,
                onRowDoubleClick: select
            })}
        </Dialog>
    );
};
export default GridChooserDialog;

export interface GridChooserReturnProps<E extends Response> extends DialogReturnProps {
    props: Omit<Props<E, any, any>, "component" | "entityName">;
}

export function useGridChooserDialog<E extends Response>(handleSelection: (entity: E)=>void): GridChooserReturnProps<E> {
    const props = useDialog();

    return useMemo(()=>({
        ...props,
        props: {
            ...props.props,
            handleSelection
        }
    }), [props, handleSelection]);
}

export function useGridChooserColumns<R>(columns: Column<R>[], actionsColumn?: Column<R>) {
    return useMemo(()=>{
        const result: Column<R>[] = columns;
        if (actionsColumn) result.push({width: "5%", minWidth: 50, ...actionsColumn});
        return result;
    }, [actionsColumn, columns]);
}