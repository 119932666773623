import {NotificationType} from "../../props/apiRequests";
import {createElement, ReactElement} from "react";
import {SvgIconProps} from "@mui/material";
import {AccessTime, Business, Person, SvgIconComponent} from "@mui/icons-material";
import {PAGE_KEY, PAGES} from "../../mainUI/pages";

export function getRelationIcon(type: NotificationType, props?: SvgIconProps): ReactElement<SvgIconProps> {
    const commonProps: SvgIconProps = {fontSize: "large", ...props};

    let icon: SvgIconComponent | undefined = undefined;

    switch (type) {
        case NotificationType.BUSINESS_CASE:
            icon = PAGES[PAGE_KEY.BUSINESS_CASES].icon;
            break;
        case NotificationType.CONTACT_PERSON:
            return <Person {...commonProps} />;
        case NotificationType.COMPANY:
            return <Business {...commonProps} />;
        case NotificationType.OPPORTUNITY:
            icon = PAGES[PAGE_KEY.OPPORTUNITIES].icon;
            break;
        case NotificationType.OFFER:
            icon = PAGES[PAGE_KEY.OFFERS].icon;
            break;
        case NotificationType.OFFER_VERSION:
            icon = PAGES[PAGE_KEY.OFFERS].icon;
            break;
        case NotificationType.MEETING:
            icon = PAGES[PAGE_KEY.MEETINGS].icon;
            break;
    }

    return !!icon ? createElement(icon, commonProps) : <AccessTime {...commonProps} />;
}