import {createElement, FC, useContext, useMemo} from "react";
import {Stack} from "@mui/material";
import {BusinessCaseContext} from "../props";
import {BusinessCaseItemResponse} from "../../../props/apiResponses";
import {SupplierBlockProps} from "./SupplierBlock";
import {DeliveryNoteResponse} from "../../../containers/trade/businessCase/schema/deliveryNote";

type BlockType = {[key: number]: SupplierBlockProps};

type Props = {
    component: FC<SupplierBlockProps>;
    disabled?: boolean;
    deliveryNote?: DeliveryNoteResponse;
};
const Suppliers: FC<Props> = (
    {
        component,
        disabled,
        deliveryNote
    }
) => {
    const context = useContext(BusinessCaseContext);

    const suppliers: BlockType = useMemo(()=>{
        if (!context?.data) return [];

        let result: BlockType = {};

        const addToResult = (item: BusinessCaseItemResponse)=>{
            if (!item.product) return;

            const {id} = item.product.supplier;
            if (!!result[id]) {
                result[id].items.push(item);
            } else {
                result[id] = {
                    items: [item],
                    supplier: item.product.supplier,
                } as SupplierBlockProps;
            }
        };

        context.data.items?.forEach(addToResult);

        for (let [key, {supplier}] of Object.entries(result)) {
            const order = context.data.supplierOrders.find((order)=>order.supplierId===supplier?.id);
            if (order) {
                result[Number(key)].supplierOrder = order;
            }
        }

        return result;
    }, [context?.data]);

    return (
        <Stack spacing={1}>
            {
                Object.entries(suppliers).map(([key, data])=>
                    createElement(component, {key, disabled, deliveryNote, ...data})
                )
            }
        </Stack>
    );
};
export default Suppliers;
