import React, {PropsWithChildren} from "react";
import {Row as GridRow} from "react-data-grid";
import {RowRendererProps} from "./props";

const BaseRenderer = <R, >(
    {
        rowKey,
        props,
        contextMenuControls,
        innerRef,
        className
    }: PropsWithChildren<RowRendererProps<R>>
) => {
    return (
        <GridRow key={rowKey} ref={innerRef} className={className} {...props} onContextMenu={
            !!contextMenuControls ? (e)=>contextMenuControls.handleContextMenu(
                e,
                {row: props.row}
            ) : undefined
        } />
    );
};
export default BaseRenderer;