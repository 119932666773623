import {GetRP, paramsSerializer, QueryRP} from "../props/apiRequests";
import axios, {AxiosResponse} from "axios";
import {GridResponse} from "../props/apiResponses";
import {Messages} from "../props/messages";
import useRequest, {RequestService} from "./RequestService";
import {CRUDRequestServiceImpl} from "./CRUDRequestService";

export interface GridRP extends GetRP, QueryRP {}
export async function getGridRequest<R, T extends GetRP & QueryRP>({params, controller}: T, api_url: string): Promise<AxiosResponse<GridResponse<R>>> {
    return axios.get<GridResponse<R>>(api_url, {
        params, signal: controller?.signal,
        paramsSerializer: paramsSerializer
    });
}

export const GRID_ERRORS = {
    400: {
        "default": Messages.INVALID_FILTER
    }
};

export interface GridService<Res> {
    grid(rp: GridRP, urlAppendix?: string): Promise<AxiosResponse<GridResponse<Res>>>;
}

export class GridServiceImpl<Req, Res> extends RequestService<Req, Res> implements GridService<Res> {
    public grid = <Res>(rp: GridRP, urlAppendix?: string) => this.getGrid<Res>(this.addressWithId() + (urlAppendix ?? ""), rp);
}

export class GridCRUDServiceImpl<Req, Res, CreateData = Req, UpdateData = Req> extends CRUDRequestServiceImpl<Req, Res, CreateData, UpdateData> implements GridService<Res> {
    protected gridService: GridService<Res>;
    constructor(apiAddress: string) {
        super(apiAddress);
        this.gridService = new GridServiceImpl(apiAddress);
    }

    public grid = (rp: GridRP) => this.gridService.grid(rp);
}

export function useGridRequest<R>(service: GridService<R>, globalLoading: boolean) {
    return useRequest(service.grid, GRID_ERRORS, {globalLoading});
}