import {FC} from "react";
import {
    css,
    FormControl,
    FormControlLabel, FormControlLabelProps,
    FormHelperText,
    styled,
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps
} from "@mui/material";
import {useField} from "formik";
import {FieldProps} from "../props";

interface CommonStylingProps {
    smallFont?: boolean;
}

interface Props extends FieldProps, CommonStylingProps {
    SwitchProps?: MuiSwitchProps;
}
const Switch: FC<Props> = (
    {
        label,
        name,
        SwitchProps,
        smallFont,
        className
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");

    return (
        <FormControl
            error={meta.touched && !!meta.error}
            className={className}
        >
            <SwitchInput
                {...field}

                checked={field.value}
                onChange={(event, checked)=>{
                    helpers.setValue(checked, true);
                }}

                label={label}
                smallFont={smallFont}
                {...SwitchProps}
            />
            {
                meta.touched && meta.error &&
                <FormHelperText>{meta.error}</FormHelperText>
            }
        </FormControl>
    );
};
export default Switch;

interface InputProps extends CommonStylingProps {
    label?: string;
}

export const SwitchInput: FC<MuiSwitchProps & InputProps> = ({label, smallFont, ...props}) => {
    return (
        <StyledLabel smallFont={smallFont} control={
            <MuiSwitch
                color="primary"
                size="small"

                {...props}
            />
        } label={label} />
    );
};

interface StylingProps extends CommonStylingProps {

}
const StyledLabel = styled(
    ({smallFont, ...rest}:StylingProps & FormControlLabelProps) => <FormControlLabel {...rest} />
)<StylingProps>(({theme, smallFont})=>css`
  & .MuiTypography-root {
    ${!!smallFont && css`
      font-size: ${theme.typography.caption.fontSize};
    `}
  }
`);