import { Box, css, styled, Typography } from "@mui/material";
import { memo, PropsWithChildren, ReactNode, useMemo, useRef } from "react";
import InLineLoading from "../../common/InLineLoading";
import { convertFromPercentage } from "../../form/fields/NumberTextField";
import { ItemType, PricingRequest, VatSummary, VatSummaryMap } from "../../props/apiRequests";
import { CurrencyResponse, PricingResponse, TradeItemResponse } from "../../props/apiResponses";
import { notEmpty, round } from "../../props/helpers";
import { useTimerEffect } from "../../props/useTimerEffect";
import {
    computeDiscount,
    computeDiscountValue,
    discountWithCurrency,
    localeNumber,
    numberWithPercentages
} from "./props";
import { isDeepEqual } from '@data-session/utils';

function convertPricingForRequest(
    beforeDiscount: number,
    afterDiscount: number,
    discount: number,
    withVat: number,
    vatSummary: VatSummaryMap,
    currency: CurrencyResponse,
    discountPercentage?: number | null,
    ignoreVat?: boolean
): PricingRequest {
    return {
        priceRaw: beforeDiscount,
        price: afterDiscount,
        discountValue: discount,
        priceVat: withVat,
        vatSummary: vatSummary,
        currency,
        discountPercentage,
        ignoreVat
    };
}

export type SummaryProps<T extends TradeItemResponse> = {
    pricing?: PricingResponse;
    disabled?: boolean;
    formatPrice: (price: number)=>ReactNode;
    loading: boolean;
    exchangeRateCzk?: number;

    items: T[];

    savePricing?: (pricingData: Partial<PricingRequest>)=>Promise<boolean>;
}
const Summary = <T extends TradeItemResponse, >(
    {
        pricing,
        disabled,
        formatPrice,
        items,
        savePricing,
        loading,
        exchangeRateCzk
    }: PropsWithChildren<SummaryProps<T>>
) => {

    const beforeDiscount: number = useMemo(()=>{
        if (!items) return 0;

        let result: number = 0;

        const addItem = (item: T) => {
            result +=item.type===ItemType.TEXTUAL ? 0 : item.priceTotal ?? 0;
        }

        items.forEach((item)=>{
            addItem(item);
            item.options?.forEach(addItem);
        });

        return round(result);
    }, [items]);

    const discount: number = useMemo(()=>{
        const percentage = pricing?.discountPercentage;
        if (percentage!==undefined && percentage!==null) {
            return computeDiscountValue(percentage, beforeDiscount);
        }
        return pricing?.discountValue ?? 0;
    }, [beforeDiscount, pricing]);

    const afterDiscount: number = useMemo(()=>{
        return round(beforeDiscount - (discount ?? 0));
    }, [beforeDiscount, discount]);

    const vatSummary: VatSummaryMap = useMemo(()=>{
        const additionalDiscountPercentage = pricing?.discountPercentage ?? (discount/beforeDiscount);
        const result: VatSummaryMap = {};

        const addItem = (item: T) => {
            const vat = item.vat ?? 0;
            const key = (vat.toFixed(2)).toString();
            if (!notEmpty(vat) || item.type===ItemType.TEXTUAL) return;
            if (!(vat in result))
                result[key] = {
                    base: 0,
                    rate: vat,
                    totalPrice: 0,
                    vatValue: 0
                };

            const summary: VatSummary = result[key];
            const base = round(item.priceTotal ?? 0);
            const additionalDiscount = round(base * additionalDiscountPercentage);
            const vatBase = base - additionalDiscount;
            const totalPriceWithVat = round(vatBase * (1 + vat));

            summary.base += vatBase;
            summary.totalPrice += totalPriceWithVat;
            summary.vatValue += totalPriceWithVat - vatBase;
        };

        items.forEach((item)=>{
            addItem(item);
            item.options?.forEach(addItem);
        });

        return result;
    }, [items]);

    const withVat: number = useMemo(()=>{
        let result: number = afterDiscount;
        for (let vatItem of Object.values(vatSummary)) {
            result += vatItem.vatValue;
        }
        return round(result);
    }, [afterDiscount, vatSummary]);

    const pending = useRef<boolean>(false);
    useTimerEffect(async ()=>{
        if (!pricing?.currency || !savePricing || disabled || pending.current) return;
        const transformed = convertPricingForRequest(beforeDiscount, afterDiscount, discount, withVat, vatSummary, pricing.currency, pricing.discountPercentage, pricing.ignoreVat);

        if (!isDeepEqual(pricing, transformed)) {
            pending.current = true;
            await savePricing({...transformed, discountPercentage: pricing.discountPercentage});
            pending.current = false;
        }
    }, [pricing, savePricing, beforeDiscount, afterDiscount, discount, vatSummary, withVat]);

    const exchangeRate = useMemo((): number => {
        if (notEmpty(exchangeRateCzk)) return exchangeRateCzk as number;
        return 1;
    }, [exchangeRateCzk]);

    return (
        <>
            <PricingWrapper>
                {
                    exchangeRateCzk!==undefined &&
                    <Typography variant="caption">Rekapitulace podle kurzu ČNB ({localeNumber(exchangeRateCzk, {maximumFractionDigits: 3, minimumFractionDigits: 3})})</Typography>
                }
                <div>
                    <ImportantPrice>
                        <div>Celkem bez DPH</div>
                        <div>{formatPrice(beforeDiscount*exchangeRate)}</div>
                    </ImportantPrice>
                    <ImportantPrice>
                        <div>Sleva na celek</div>
                        <div>{discountWithCurrency(
                            beforeDiscount,
                            discount,
                            exchangeRateCzk!==undefined ? "CZK" : pricing?.currency.code,
                            convertFromPercentage(pricing?.discountPercentage ?? 0),
                            exchangeRate
                        )}</div>
                    </ImportantPrice>
                    <MainPrice>
                        <div>Celkem po slevě bez DPH</div>
                        <div>{formatPrice(afterDiscount*exchangeRate)}</div>
                    </MainPrice>
                    {
                        !pricing?.ignoreVat &&
                        <>
                            {
                                Object.values(vatSummary).map((item)=>
                                    <UnimportantPrice key={item.rate}>
                                        <div>DPH {numberWithPercentages(convertFromPercentage(item.rate))}</div>
                                        <div>{formatPrice(item.vatValue*exchangeRate)}</div>
                                    </UnimportantPrice>
                                )
                            }
                            <UnimportantPrice>
                                <div>Celkem včetně DPH</div>
                                <div>{formatPrice(withVat*exchangeRate)}</div>
                            </UnimportantPrice>
                        </>
                    }
                </div>
                <InLineLoading loading={loading} caption="Ukládám souhrn..." />
            </PricingWrapper>
        </>
    );
};
export default memo(Summary);

const SPACING: number = 0.5;

const PricingWrapper = styled(Box)(({theme})=>css`
  margin: 0 ${theme.spacing(SPACING)};

  & > div {
    width: 100%;

    & > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: ${theme.spacing(SPACING)} 0;

      & > div:first-of-type {
        color: ${theme.palette.primary.main};
        margin-right: ${theme.spacing(SPACING * 2)};
      }
    }
  }
`);

const ImportantPrice = styled(Box)(({theme})=>css`
`);

const MainPrice = styled(Box)(({theme})=>css`
  font-weight: ${theme.typography.fontWeightBold};
`);

const UnimportantPrice = styled(Box)(({theme})=>css`
  font-size: ${theme.typography.overline.fontSize};
`);
