import {DeleteRP, GetRP, ID, IdRP, OfferItemRequest, PostRP, PutRP} from "../../props/apiRequests";
import {OfferItemResponse} from "../../props/apiResponses";
import {RequestService} from "../RequestService";
import {CRUDRequestService, useCRUDRequest} from "../CRUDRequestService";
import offerService, {OfferRP} from "./OfferService";
import offerVersionService from "./OfferVersionService";

const ITEMS_API = "/items";

export class OfferItemService extends RequestService<OfferItemRequest, OfferItemResponse> implements CRUDRequestService {
    public address = ({offerId, versionId, itemId}: {offerId?: ID, versionId?: ID, itemId?: ID}) =>
        offerService.addressWithId(offerId) + offerVersionService.addressWithId(versionId) + this.addressWithId(itemId);

    public retrieve = ({id, controller, offerId, versionId}: GetRP & IdRP & OfferRP) =>
        this.get(this.address({offerId, versionId, itemId: id}), controller);
    public create = ({controller, data, offerId, versionId}: PostRP<OfferItemRequest> & OfferRP) =>
        this.post(this.address({offerId, versionId}), data, controller);
    public update = ({id, controller, data, offerId, versionId}: PutRP<OfferItemRequest> & OfferRP & IdRP) =>
        this.put(this.address({offerId, versionId, itemId: id}), data, controller);
    public remove = ({id, controller, offerId, versionId}: DeleteRP & IdRP & OfferRP) =>
        this.delete(this.address({offerId, versionId, itemId: id}), controller);

}

const offerItemService = new OfferItemService(ITEMS_API);

export function useOfferItemManipulator() {
    return useCRUDRequest<OfferItemResponse, OfferItemService>(offerItemService);
}