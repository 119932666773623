import {
    CompanyEmbeddedResponse,
    CompanyResponse,
    ContactPersonResponse,
    EntityResponse,
    OfficeResponse,
    PhoneResponse,
    UserResponse
} from "../../props/apiResponses";
import {mergeAddress} from "../../props/helpers";
import {PAGE_KEY, passURL} from "../../mainUI/pages";


export function storeEntity<T extends EntityResponse>(entity: T, place: T[]): boolean {
    for (let i=0; i<place.length; i++) {
        if (place[i].id===entity.id) {
            place[i] = entity;
            return true;
        }
    }

    return false;
}

type PersonType = (ContactPersonResponse & {office: OfficeResponse});
export function getContactPersonList(company?: CompanyResponse): PersonType[] {
    if (!company) return [];

    let result: PersonType[] = [];

    company.offices?.forEach((office)=>{
        office.contactPersons?.forEach((person)=>result.push({
            ...person,
            office
        }));
    });

    return result;
}

export function getOfficeName(office: OfficeResponse): string {
    return office.name && office.name!=="" ? office.name : mergeAddress(office.address);
}

export function phoneString(phone: PhoneResponse, withoutType?: boolean): string {
    return ((!withoutType && !!phone.type) ? `${phone.type} ` : "") + `${phone.countryCode} ${phone.number}`;
}

export function personNameString(person: {firstname: string; lastname: string} | UserResponse): string {
    return `${person.firstname} ${person.lastname}`;
}

export function companyLink(company: CompanyEmbeddedResponse) {
    return passURL(company.supplier ? PAGE_KEY.SUPPLIERS_DETAIL : PAGE_KEY.CUSTOMERS_DETAIL, {id: company.id});
}
