import {PropsWithChildren, ReactNode, useMemo} from "react";
import {SubmitOnChange} from "../../../form/props";
import DataGrid, {DataGridProps} from "../DataGrid";
import {Form, Formik} from "formik";
import {FilterProps, FilterValues} from "./useFilter";
import {css, styled} from "@mui/material";
import {ItemRow, UseDataGridRP} from "../useDataGrid";
import {PredefinedTopAction} from "../TopActions";

type Props<T extends FilterValues, Row extends ItemRow> = {
    filter: FilterProps<T>;
    dataGrid: UseDataGridRP<Row>;
    DataGridProps?: Partial<DataGridProps<Row>>;
    children?: ReactNode;
};
const FilterDataGrid = <T extends FilterValues, Row extends ItemRow>(
    {
        filter,
        DataGridProps,
        dataGrid,
        children
    }: PropsWithChildren<Props<T, Row>>
) => {
    return (
        <Formik initialValues={filter.initValues} onSubmit={filter.onSubmit} innerRef={filter.formikRef}>
            <StyledForm>
                {children}
                <SubmitOnChange />
                <DataGrid
                    {...dataGrid.props}
                    rowHeaderHeight={55}
                    {...DataGridProps}
                    topActions={useMemo(()=>([
                        ...(!!dataGrid.props.topActions ? dataGrid.props.topActions : []),
                        ...(!!DataGridProps?.topActions ? DataGridProps.topActions : []),
                        [PredefinedTopAction.CLEAR, dataGrid.filter.reset, {rightSide: false}]
                    ]), [dataGrid.props.topActions, dataGrid.filter.reset, DataGridProps?.topActions])}
                />
            </StyledForm>
        </Formik>
    );
};
export default FilterDataGrid;

const StyledForm = styled(Form)(()=>css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`);