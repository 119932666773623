import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import {useDrag, useDrop} from "react-dnd";
import {RowRenderer, RowRendererProps} from "../props";
import BaseRenderer from "../BaseRenderer";
import {alpha, css, styled} from "@mui/material";

interface DraggableRowRenderProps<R> extends RowRendererProps<R> {
    onRowReorder: (sourceIndex: number, targetIndex: number) => void;
}

const DnDRenderer = <R,>(
    {
        contextMenuControls,
        props,
        rowKey,
        onRowReorder,
        className
    }: PropsWithChildren<DraggableRowRenderProps<R>>
) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'ROW_DRAG',
        item: { index: props.rowIdx },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'ROW_DRAG',
        drop({ index }: { index: number }) {
            onRowReorder(index, props.rowIdx);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    return (
        <StyledRow
            innerRef={(ref) => {
                if (ref) {
                    drag(ref.firstElementChild);
                }
                drop(ref);
            }}

            isDragging={isDragging}
            isOver={isOver}

            className={className}
            rowKey={rowKey}
            props={props}
            contextMenuControls={contextMenuControls}
        />
    );
};
export default DnDRenderer;

export function useGridDnD<R>(
    onRowReorder: (sourceIndex: number, targetIndex: number) => void
) {
    const rowRenderer: RowRenderer<R> = useCallback((key, props, contextMenuControls)=>{
        return <DnDRenderer key={key} props={props} contextMenuControls={contextMenuControls} onRowReorder={onRowReorder} />;
    }, [onRowReorder]);

    return useMemo(()=>({
        rowRenderer
    }), [rowRenderer]);
}

type StyledRowProps = {
    isDragging?: boolean;
    isOver?: boolean;
}
const StyledRow = styled(BaseRenderer)<StyledRowProps>(({theme, isOver, isDragging})=>css`
    transition: color 200ms ease;
  
    ${isOver && css`
      color: ${alpha(theme.palette.text.primary, 0.3)};
    `}
    
    ${isDragging && css`
      color: ${theme.palette.secondary.main};
    `}
`) as FC<RowRendererProps<any> & StyledRowProps>;