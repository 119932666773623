import {FC} from "react";
import {ConfirmationDialogBase} from "./props";
import ConfirmationDialog from "./ConfirmationDialog";


const LeavingConfirmationDialog: FC<ConfirmationDialogBase> = (props) => {
    return (
        <ConfirmationDialog
            {...props}
            title="Máte neuložené změny"
            message={`Jsou zde neuložené změny, o které přijdete, pokud odejdete. Přejete si přesto odejít?`}
            primary="no"
        />
    );
};
export default LeavingConfirmationDialog;