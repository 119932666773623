import {FC, useCallback, useMemo, useState} from "react";
import {
    EntityFormDialogBase,
    EntityFormDialogReturnProps,
    useEntityFormDialog,
    useFormDialogMiddleware
} from "../dialog/props";
import {
    BusinessCaseItemResponse,
    CurrencyExchangeRateResponse,
    CurrencyResponse,
    TradeItemResponse
} from "../../props/apiResponses";
import SaveAndCloseActions from "../../common/dialog/SaveAndCloseActions";
import Dialog from "../../common/dialog/Dialog";
import TradeItemForm, {useTradeItemForm} from "./TradeItemForm";
import {DeliveryNoteResponse} from "../../containers/trade/businessCase/schema/deliveryNote";

interface Props extends EntityFormDialogBase<TradeItemResponse> {
    isOffer: boolean;
    currenciesRates: CurrencyExchangeRateResponse[];
    currentCurrency: CurrencyResponse | undefined;
    businessCaseId?: number;
    order: number;
    orderToSupplier?: boolean;
    purchaseCurrency?: CurrencyResponse;
    invoice?: boolean;
    offerId?: number;
    versionId?: number;
    disabled?: boolean;
    ignoreVat?: boolean;
    deliveryNotes?: DeliveryNoteResponse[];
}
const TradeItemFormDialog: FC<Props> = (dialog) => {
    const {
        DialogProps,
        entity,
        afterSave,
        handleClose,
        isOffer,
        currenciesRates,
        currentCurrency,
        businessCaseId,
        order,
        orderToSupplier,
        purchaseCurrency,
        invoice,
        offerId,
        versionId,
        disabled,
        ignoreVat,
        deliveryNotes
    } = useFormDialogMiddleware(dialog);

    const {props, loading} = useTradeItemForm({
        afterSave,
        isOffer,
        currentCurrency,
        currenciesRates,
        id: entity?.id,
        businessCaseId,
        order,
        orderToSupplier,
        purchaseCurrency,
        invoice,
        offerId,
        versionId,
        ignoreVat
    });
    const handleSave = useCallback(()=>props.formikRef.current?.submitForm(), []);

    return (
        <Dialog
            DialogProps={{...DialogProps, maxWidth: "lg"}}
            title={(entity ? "Upravit" : "Přidat")+" položku"}
            actions={
                <SaveAndCloseActions
                    handleSave={handleSave}
                    handleClose={handleClose}
                    loading={loading}
                    disabled={disabled}
                />
            }
        >
            <TradeItemForm
                {...props}
                entity={entity}
                disabled={disabled}
                ignoreVat={ignoreVat}
                deliveryNotes={deliveryNotes}
            />
        </Dialog>
    );
};
export default TradeItemFormDialog;

export interface TradeItemReturnProps extends Omit<EntityFormDialogReturnProps<BusinessCaseItemResponse>, "handleOpen"> {
    props: Omit<Props, "currentCurrency" | "currenciesRates" | "hidePurchasePrice" | "purchaseCurrency">;
    handleOpen: (order: number, entity?: BusinessCaseItemResponse)=>void;
}
interface Opt {
    businessCaseId?: number;
    isOffer: boolean;
    orderToSupplier?: boolean;
    purchaseCurrency?: CurrencyResponse;
    invoice?: boolean;
    offerId?: number;
    versionId?: number;
}
export function useTradeItemFormDialog(
    {
        businessCaseId,
        isOffer,
        orderToSupplier,
        invoice,
        offerId,
        versionId
    }: Opt
): TradeItemReturnProps {
    const [order, setOrder] = useState<number>(0);
    const props = useEntityFormDialog<TradeItemResponse>();
    return useMemo(()=>({
        ...props,
        props: {
            ...props.props,
            businessCaseId,
            order, isOffer, orderToSupplier, invoice, offerId, versionId,
            purchaseCurrency: props.props.entity?.product?.purchaseCurrency
        },
        handleOpen: (tmpOrder, entity)=>{
            setOrder(tmpOrder);
            props.handleOpen(entity);
        }
    }), [businessCaseId, isOffer, order, props]);
}
