import {FC} from "react";
import TermsForm, {TermsFormReturnProps} from "../../../form/trade/TermsForm";
import {SxProps, Theme} from "@mui/material";
import {Frame} from "../../../common/Frame";
import {RichTextFieldProps} from "../../../form/fields/RichTextField";

type Props = {
    termsFormProps: TermsFormReturnProps;
    sx?: SxProps<Theme>;
    RichTextFieldProps?: RichTextFieldProps;
};
const TermsFrameForm: FC<Props> = (
    {
        termsFormProps,
        sx,
        RichTextFieldProps
    }
) => {
    return (
        <Frame defaultColor sx={{maxWidth: "sm", mt: 1, ...sx}}>
            <TermsForm {...termsFormProps.props} RichTextFieldProps={RichTextFieldProps} />
        </Frame>
    );
};
export default TermsFrameForm;
