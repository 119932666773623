import {alpha, css, Paper, PaperProps, styled, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

export const GreyColor = grey[300];

export const FrameHeading = styled(Typography)(({theme}) => css`
  font-size: ${theme.typography.caption.fontSize};
  margin-bottom: ${theme.spacing(1)};
  text-transform: uppercase;
`);
export type FrameProps = {
    clickable?: boolean;
    defaultColor?: boolean;
}
export const Frame = styled(
    ({clickable, defaultColor, ...rest}: PaperProps<any> & FrameProps) => <Paper {...rest} />
)<FrameProps>(({theme, clickable, defaultColor}) => css`
  border: 1px solid ${defaultColor ? GreyColor : theme.palette.primary.main};
  padding: ${theme.spacing(1)};
  color: ${defaultColor ? "inherit" : theme.palette.primary.main};
  text-decoration: none;
  & > .MuiTypography-root {
    color: ${defaultColor ? alpha(theme.palette.text.primary, 0.6) : theme.palette.primary.main};
  }
  
  transition: color 300ms ease, border-color 300ms ease;
  
  ${!!clickable && css`
    cursor: pointer;

    &:hover {
      color: ${theme.palette.secondary.main};
      border-color: ${theme.palette.secondary.main};
    }
  `}
`);