import {FC} from "react";
import {ConfirmationDialogBase} from "./props";
import Dialog from "./Dialog";
import YesAndNoActions from "./YesAndNoActions";

const ConfirmationDialog: FC<ConfirmationDialogBase> = (
    {
        DialogProps,
        title,
        message,
        handleClose,
        handleConfirm,
        loading,
        primary,
        buttons,
        hideIcons,
    }
) => {
    return (
        <Dialog
            DialogProps={{
                ...DialogProps,
                onClose: !!loading ? undefined : DialogProps.onClose
            }}
            actions={
                <YesAndNoActions
                    handleYes={handleConfirm}
                    handleNo={handleClose}
                    loading={loading}
                    primary={primary}
                    buttons={buttons}
                    hideIcons={hideIcons}
                />
            }
            title={title}
        >
            {message}
        </Dialog>
    );
};
export default ConfirmationDialog;