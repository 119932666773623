import {UrlUpdateType} from "use-query-params";

export const ROW_HEIGHT = 25;
export const ROW_HEADER_HEIGHT = 30;

export const DEFAULT_BLOCK_SIZE = 350;

export function computeDynamicHeight(rowSize: number, minHeight: number = ROW_HEADER_HEIGHT+2): number {
    const computed = ROW_HEADER_HEIGHT+(rowSize*ROW_HEIGHT)+2;
    if (computed<minHeight) return minHeight;
    return computed;
}

export type RowKeyGetter<Row> = (row: Row) => number;

export interface QueryBase {
    page: number | undefined | null,
    scroll: number | undefined | null,
    columnKey: string | undefined | null,
    direction: string | undefined | null,
    expanded: string[] | null,
    [key: string]: any
}

export type ChangeQuery = (value: Partial<QueryBase>, updateType?: UrlUpdateType)=>void;