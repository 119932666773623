import React, {FC, useCallback, useMemo} from "react";
import {GridResponse, OpportunityGridResponse} from "../../props/apiResponses";
import {GridRP} from "../../services/GridService";
import useGridContextMenu from "../../common/dataGrid/contextMenu/GridContextMenu";
import BaseMenu, {getPlainText} from "../../common/dataGrid/contextMenu/menu/BaseMenu";
import useDataGrid from "../../common/dataGrid/useDataGrid";
import FilterDataGrid from "../../common/dataGrid/filter/FilterDataGrid";
import {useOpportunitiesManipulator} from "../../services/opportunity/OpportunityService";
import {ArrayParam, DateParam, StringParam, withDefault} from "use-query-params";
import {DateRangeFilter, Filter, TextFieldFilter} from "../../common/dataGrid/filter/useFilter";
import {formatDate} from "../../props/helpers";
import {GridChooserProps, useGridChooserColumns} from "../../common/dialog/GridChooserDialog";
import {ActivityColumn} from "../../common/dataGrid/Columns";
import {opportunityStatusOptions} from "../../form/fields/select/options";
import {OpportunityStatus} from "../../props/apiRequests";

export interface OpportunitiesGridRow {
    name: string;
    lastModificationTo: string;
    customer: string;
    contactPerson: string;
    id: number;
    status: string;
    item: OpportunityGridResponse;
}

interface Props extends GridChooserProps<OpportunitiesGridRow> {}
const OpportunitiesGrid: FC<Props> = (
    {
        topActions,
        actionsColumn,
        loading,
        onRowDoubleClick,
        disableQueryParams
    }
) => {
    const {run} = useOpportunitiesManipulator(true);

    const columns = useGridChooserColumns<OpportunitiesGridRow>([
        {
            key: "id",
            name: "ID",
            width: 50,
            minWidth: 50,
            headerRenderer: props => <Filter headerProps={props} name="id" element={TextFieldFilter()} />
        },
        {
            key: "name",
            name: "Název",
            headerRenderer: props => <Filter headerProps={props} name="name" element={TextFieldFilter()} />
        },
        {
            key: "customer",
            name: "Zákazník",
            headerRenderer: props => <Filter headerProps={props} name="customer" element={TextFieldFilter()} />
        },
        {
            key: "contactPerson",
            name: "Kontaktní osoba",
            headerRenderer: props => <Filter headerProps={props} name="contactPerson" element={TextFieldFilter()} />
        },
        ActivityColumn({options: opportunityStatusOptions}, {width: "10%", minWidth: 100}),
        {
            key: "lastModificationTo",
            name: "Datum změny",
            headerRenderer: props => <Filter
                headerProps={props}
                name={{from: "lastModificationFrom", to: "lastModificationTo"}}
                element={DateRangeFilter(true)}
            />,
            minWidth: 125,
            width: "10%"
        }
    ], actionsColumn);

    const request = useCallback((requestParams: GridRP)=>run(requestParams), []);

    const mapper = useCallback((response: GridResponse<OpportunityGridResponse>): OpportunitiesGridRow[] =>
        response.data.map((item)=>({
            id: item.id,
            name: item.name,
            customer: item.company,
            contactPerson: item.contactPerson,
            status: item.status,
            lastModificationTo: formatDate(item.lastModificationAt, "without time"),
            item
        })
        ), []);

    const gridContextMenu = useGridContextMenu<OpportunitiesGridRow>(useMemo(()=>({
        main: {
            key: "main",
            component: props => <BaseMenu key="main" {...props} getPlainText={data => {
                if (data.column?.key==="lastModificationTo") return formatDate(data.row.lastModificationTo);
                return getPlainText(data);
            }} />
        }
    }), []));

    const dataGrid = useDataGrid(columns, request, mapper, useMemo(()=>({
        sorting: {init:{columnKey: "lastModificationTo", direction: "DESC"}},
        filter: {
            schema: {
                name: withDefault(StringParam, ""),
                customer: withDefault(StringParam, ""),
                contactPerson: withDefault(StringParam, ""),
                id: withDefault(StringParam, ""),
                status: withDefault(ArrayParam, Object.values(OpportunityStatus)),
                lastModificationFrom: withDefault(DateParam, null),
                lastModificationTo: withDefault(DateParam, null)
            }
        },
        disableQueryParams
    }),[disableQueryParams]));

    return (
        <FilterDataGrid
            dataGrid={dataGrid}
            filter={dataGrid.filter}
            DataGridProps={{
                topActions,
                contextMenu: gridContextMenu.contextMenu,
                loading,
                onRowDoubleClick
            }}
        />
    );
};
export default OpportunitiesGrid;