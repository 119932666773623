import {alpha, Box, css, styled, TooltipProps} from "@mui/material";
import {ROW_HEIGHT} from "../props";
import {ReactElement, ReactNode, useCallback} from "react";
import Tooltip from "../../Tooltip";

export function showMoreFormatter(content: ReactNode, number: number, context: TooltipProps["title"]): ReactElement {
    if (number<=1) return <>{content}</>;

    return (
        <Wrapper>
            <Content>{content}</Content>
            <Icon>
                <Tooltip title={context}>
                    <ShowMore>{number===-1 || number>99 ? "+" : number}</ShowMore>
                </Tooltip>
            </Icon>
        </Wrapper>
    );
}

export function getContextAsList(values: ReactNode[]): TooltipProps["title"] {
    return (
        <>
            {
                values.map((value, i)=><div key={i}>{value}</div>)
            }
        </>
    );
}

export const getMassPropertyAsElement = <T, >(
    property: T[] | null,
    getElement: (property: T, index: number)=>ReactNode,
    getCellContentElement?: (property: T, index: number)=>ReactNode
): [ReactNode, ReactNode[]] => {
    let result: ReactNode = "";
    let results: ReactNode[] = [];

    if (property && property.length>0) {
        result = property.map(getCellContentElement ? getCellContentElement : getElement).reduce(
            (prev, curr)=>
                [prev, ", ", curr]
        );
        results = property.map(getElement);
    }

    return [result, results];
};


const Wrapper = styled(Box)(({theme})=>css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`);

const Content = styled(Box)(({theme})=>css`
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`);

const Icon = styled(Box)(({theme})=>css`
  position: relative;
`);

const size = `${ROW_HEIGHT-4}px`;
const spacing = "4px";
const fontSize = "0.8em";
const ShowMore = styled(Box)(({theme})=>css`
  height: ${size}; width: ${size};
  color: ${theme.palette.text.primary};
  border: 1px solid ${alpha(theme.palette.text.primary, 0.6)};
  margin-right: -7px;
  padding-left: ${spacing}; padding-right: ${spacing};
  border-radius: 5px;
  display: grid;
  place-items: center;
  font-size: ${fontSize};
  line-height: ${fontSize};
  user-select: none;
`);