import useRequest, {idAddress, RequestOpt} from "../RequestService";
import {DeleteRP, GetRP, ID, IdRP, MeetingRequest, OpportunityRequest, PostRP, PutRP} from "../../props/apiRequests";
import axios, {AxiosResponse} from "axios";
import {BusinessCaseResponse, GridResponse, MeetingResponse, OpportunityResponse} from "../../props/apiResponses";
import {getGridRequest, GRID_ERRORS, GridCRUDServiceImpl, GridRP, useGridRequest} from "../GridService";
import {useSelectRequest} from "../../form/fields/select/Select";
import {useCallback} from "react";
import {useCurrenciesManipulator} from "../currency/CurrencyService";
import {formatDate} from "../../props/helpers";
import {personNameString} from "../../pages/company/props";
import {useCRUDRequest} from "../CRUDRequestService";

const MEETINGS_API = "/meetings";

interface GetMeetingsRP extends GetRP {
    tradeId: number;
}
class MeetingService extends GridCRUDServiceImpl<MeetingRequest, MeetingResponse> {
    // TODO remove and replace with grid request
    public getByTrade = ({tradeId, controller}: GetMeetingsRP) =>
        this.get<MeetingResponse[]>(`/trades/${tradeId}${MEETINGS_API}`, controller);
}
const meetingService = new MeetingService(MEETINGS_API);

export function useMeetingManipulator(opt?: RequestOpt) {
    return useCRUDRequest<MeetingResponse, MeetingService>(meetingService, undefined, {all: opt});
}

export function useMeetingsManipulator(globalLoading: boolean) {
    return useGridRequest<MeetingResponse>(meetingService, globalLoading);
}