import React, {FC, useCallback, useContext, useMemo} from "react";
import {BusinessCaseContext, BusinessCaseTabProps, useGeneratedDocument, useUpdateTerms} from "../props";
import {FileOpen, Lock, LockOpen, NoteAdd} from "@mui/icons-material";
import {
    useBusinessCaseDocumentManipulator,
    useBusinessCaseManipulator
} from "../../../services/businessCase/BusinessCaseService";
import {downloadDocument} from "../../../services/DocumentService";
import TermsFrameForm from "./TermsFrameForm";
import {BusinessCaseStatus, ConfigurationDocumentTextType, DocumentType, Language} from "../../../props/apiRequests";
import {Messages} from "../../../props/messages";
import {DocumentResponse} from "../../../props/apiResponses";
import DocumentTextTypeChooser from "../../../form/fields/DocumentTextTypeChooser";
import CustomerItemsDataGrid, {BusinessCaseRow, useCustomerItemsGrid} from "../CustomerItemsDataGrid";
import {ActionsFormatter, PredefinedAction} from "../../../common/dataGrid/formatter/ActionsFormatter";
import {TopAction} from "../../../common/dataGrid/TopActions";
import TradeItemFormDialog, {useTradeItemFormDialog} from "../../../form/trade/TradeItemFormDialog";
import {Grid} from "@mui/material";

interface Props extends BusinessCaseTabProps {

}
const ConfirmationToCustomer: FC<Props> = () => {
    const context = useContext(BusinessCaseContext);
    const documentManipulator = useBusinessCaseDocumentManipulator();
    const caseManipulator = useBusinessCaseManipulator();
    const [downloadGeneratedDocument, wasGeneratedDocument] = useGeneratedDocument(DocumentType.CUSTOMER_ORDER_CONFIRMATION, context?.data?.documents, documentManipulator.get.run);

    const termsOrderConf = useUpdateTerms("Platební a dodací podmínky", "paymentTermsOrderConf");
    const introductionOrderConf = useUpdateTerms("Úvod", "introductionOrderConf");

    const generateConfirmation = useCallback(async ()=>{
        const businessCaseId = context?.data?.id;
        if (businessCaseId===undefined || !context?.setData || !context?.data) return;
        const result = await documentManipulator.getConfirmation.run({businessCaseId});
        if (result !== null) {
            const documents: DocumentResponse[] = context.data.documents.filter((document)=>document.type!==DocumentType.CUSTOMER_ORDER_CONFIRMATION);
            context?.setData({...context.data, documents: [...documents, result]});
            downloadDocument(result);
        }
    }, [documentManipulator.getConfirmation, context]);

    const toggleLockOffer = useCallback(async ()=>{
        if (!context?.data || !context?.setData) return;

        const status = context.lockedOffer ? BusinessCaseStatus.INITIAL_PHASE : BusinessCaseStatus.LOCKED;
        let result = await caseManipulator.patch.run({id: context.data.id, status});

        if (result!==null) {
            caseManipulator.patch.messageHandler.success(Messages.SAVED);
            context.setData({...context.data, status});
        }
    }, [context, caseManipulator]);

    const itemDialog = useTradeItemFormDialog({
        businessCaseId: context?.data?.id,
        isOffer: false
    });

    const handleDetail = useCallback((row: BusinessCaseRow)=>{
        itemDialog.handleOpen(row.order, row.item);
    }, [itemDialog]);

    const itemsGrid = useCustomerItemsGrid(useCallback((row)=>ActionsFormatter([
        [PredefinedAction.READONLY, ()=>handleDetail(row)]
    ]), [handleDetail]), "customerDescription");

    const topActions: TopAction[] = useMemo(()=>{
        const result: TopAction[] = [];

        if (wasGeneratedDocument)
            result.push({
                key: "fileOpen",
                icon: <FileOpen />,
                label: "Otevřít potvrzení",
                loading: documentManipulator.loading,
                rightSide: false,
                onClick: downloadGeneratedDocument
            });

        if (!context?.finished) {
            result.push({
                key: "fileGeneration",
                icon: <NoteAdd />,
                label: "Vytvořit potvrzení",
                loading: documentManipulator.loading,
                rightSide: false,
                onClick: generateConfirmation
            });

            result.push({
                key: "caseState",
                icon: context?.lockedOffer ? <LockOpen /> : <Lock />,
                label: context?.lockedOffer ? "Odemknout" : "Zamknout",
                loading: caseManipulator.loading,
                onClick: toggleLockOffer
            });
        }

        return result;
    }, [context?.finished, caseManipulator.loading, context?.lockedOffer, documentManipulator.loading, downloadGeneratedDocument, generateConfirmation, toggleLockOffer, wasGeneratedDocument]);

    termsOrderConf.props.disabled = !!context?.finished;
    introductionOrderConf.props.disabled = !!context?.finished;

    if (!context?.data) return null;

    return (
        <>
            <CustomerItemsDataGrid
                {...itemsGrid.props}
                topActions={topActions}
                onRowDoubleClick={handleDetail}
                DataGridProps={{
                    topActionsAlignment: "flex-end"
                }}
            />
            <TradeItemFormDialog
                {...itemDialog.props}
                currenciesRates={context.exchangeRates ?? []}
                currentCurrency={context.data.pricing?.currency}
                disabled
            />
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <TermsFrameForm
                        sx={{maxWidth: "100%"}}
                        termsFormProps={introductionOrderConf}
                        RichTextFieldProps={{
                            controls: (
                                <DocumentTextTypeChooser
                                    documentType={DocumentType.CUSTOMER_ORDER_CONFIRMATION}
                                    documentTextType={ConfigurationDocumentTextType.PRE_TABLE}
                                    language={context?.data.language ?? Language.CZECH}
                                    textFieldName="terms"
                                    disabled={introductionOrderConf.props.disabled}
                                />
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TermsFrameForm
                        sx={{maxWidth: "100%"}}
                        termsFormProps={termsOrderConf}
                        RichTextFieldProps={{
                            controls: (
                                <DocumentTextTypeChooser
                                    documentType={DocumentType.CUSTOMER_ORDER_CONFIRMATION}
                                    documentTextType={ConfigurationDocumentTextType.APPENDIX}
                                    language={context?.data.language ?? Language.CZECH}
                                    textFieldName="terms"
                                    disabled={termsOrderConf.props.disabled}
                                />
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default ConfirmationToCustomer;
