import {ActivityFormatter} from "./formatter/ActivityFormatter";
import {Filter, SelectFilter} from "./filter/useFilter";
import {Column, FormatterProps} from "react-data-grid";
import {SelectOption} from "../../form/fields/select/Select";
import {Action, ActionsFormatter} from "./formatter/ActionsFormatter";
import {ActivityStatus} from "../ActivityChip";

type StatusRow = {
    item?: {
        status: ActivityStatus
    }
};

export function ActivityColumn<R extends StatusRow = StatusRow>(
    filter?: {options: SelectOption[]},
    opt?: Partial<Column<R>>
): Column<R> {
    return {
        key: "status",
        name: "Status",
        formatter: ({row})=>ActivityFormatter(row.item?.status ?? "ACTIVE"),
        headerRenderer: !filter
            ? undefined
            : (props)=><Filter headerProps={props} element={SelectFilter(filter.options, true)} name="status" />,
        ...opt
    };
}


export function ActionsColumn<R>(
    actions: (props: FormatterProps<R>) => Action[],
    opt?: Partial<Column<R>>
): Column<R> {
    return {
        key: "actions",
        name: "Akce",
        sortable: false,
        formatter: (props)=>ActionsFormatter(actions(props)),
        width: "5%", 
        minWidth: 50,
        ...opt
    };
}