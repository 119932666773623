import {TradeItemRequest} from "../../props/apiRequests";

export function setOrderToItems<T extends {items?: TradeItemRequest[]}>(data: T): T {
    const setOrder = (item: TradeItemRequest, i: number) => ({
        ...item,
        order: i+1
    });

    return {
        ...data,
        items: data.items?.map((item, i)=>({
            ...setOrder(item, i),
            options: item.options?.map(setOrder)
        }))
    };
}