import {FC} from "react";
import {Grid} from "@mui/material";
import TextField from "./TextField";

type Props = {
    name?: string;
    disabled?: boolean;
};
const AddressLine: FC<Props> = ({name, disabled}) => {

    const getName = (field: string)=>`${!!name ? name+"." : ""}${field}`;

    return (
        <>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Ulice"
                    name={getName("street")}
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Město"
                    name={getName("city")}
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    label="PSČ"
                    name={getName("postcode")}
                    disabled={disabled}
                />
            </Grid>
        </>
    );
};
export default AddressLine;