import {RequestOpt} from "../RequestService";
import {
    OfferCreationRequest,
    OfferRequest,
    OfferStatus,
    OfferVersionCreationRequest,
    PostRP
} from "../../props/apiRequests";
import {OfferGridResponse, OfferResponse} from "../../props/apiResponses";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {useEffect, useMemo, useState} from "react";
import {useCRUDRequest} from "../CRUDRequestService";

export interface OfferRP {
    offerId: number;
    versionId: number;
}

const OFFERS_API = "/offers";


class OfferService extends GridCRUDServiceImpl<OfferRequest, OfferGridResponse, OfferVersionCreationRequest | OfferCreationRequest> {
    public create = ({controller, data}: PostRP<OfferVersionCreationRequest | OfferCreationRequest>) =>
        this.post(this.addressWithId(), data, controller);
}
const offerService = new OfferService(OFFERS_API);
export default offerService;

export function useOfferManipulator(opt?: RequestOpt) {
    return useCRUDRequest<OfferResponse, OfferService>(offerService, undefined, {all: opt});
}

export function useOffersManipulator(globalLoading: boolean) {
    return useGridRequest<OfferGridResponse>(offerService, globalLoading);
}

export function useLastOffers(): [
    OfferGridResponse[] | undefined,
    boolean
    ] {
    const [offers, setOffers] = useState<OfferGridResponse[]>();
    const request = useOffersManipulator(false);

    useEffect(()=>{
        (async ()=>{
            const result = await request.run({params: {
                status: OfferStatus.IN_PROGRESS,
                sort: ["-lastModificationTo"],
                pageNum: 1,
                pageSize: 10
            }});

            if (result) setOffers(result.data);
        })();
    }, []);

    return useMemo(()=>([offers, request.loading]), [offers, request.loading]);
}