import {createContext, Dispatch, MutableRefObject, SetStateAction, useCallback, useMemo, useRef, useState} from "react";
import {DecodedValueMap} from "use-query-params";
import {ChangeQuery, QueryBase} from "./props";
import {EntityResponse} from "../../props/apiResponses";
import {ItemRow} from "./useDataGrid";

export interface MasterDetailRow<I extends EntityResponse> {
    type: "MASTER" | "DETAIL";
    expanded?: boolean;
    item: I;
}

export type MasterDetailContextType = {
    expandAll: boolean;
    toggle: (row: MasterDetailRow<any>)=>void;
}

export type RowMasterDetail = {
    handleChangeSet: (expandedSet: Set<string>)=>void;
    handleChange: (expandedId: string, expanded: boolean)=>void;
    resetMasterDetail: ()=>void;
    expandedRef: MutableRefObject<string[]>;
    expandAll: ()=>void;
    expandAllRef: MutableRefObject<boolean>;
    context: MasterDetailContextType;
}

export function useMasterDetail<Row extends ItemRow>(
    query: DecodedValueMap<QueryBase>,
    handleChangeQuery: ChangeQuery,
    rows: Row[],
    setRows: Dispatch<SetStateAction<Row[]>>
): RowMasterDetail {
    const expandedRef = useRef<string[]>(query.expanded ?? []);
    const expandAllRef = useRef<boolean>(false);
    const [expandAllState, setExpandAllState] = useState<boolean>(false);

    const handleChangeSet = useCallback((expandedSet: Set<string>)=>{
        const expanded = Array.from(expandedSet);
        handleChangeQuery({expanded}, "replaceIn");
        expandedRef.current = expanded.map(e=>e.toString());
    }, [handleChangeQuery]);

    const handleChange = useCallback((expandedId: string, expanded: boolean)=>{
        if (expanded)
            expandedRef.current.push(expandedId);
        else
            expandedRef.current = expandedRef.current.filter((id)=>id!==expandedId);
        handleChangeQuery({expanded: expandedRef.current}, "replaceIn");
    }, [handleChangeQuery]);

    const expandAll = useCallback(()=>{
        expandAllRef.current = true;
        setExpandAllState(true);
    }, []);

    const resetMasterDetail = useCallback(()=>{
        setExpandAllState(false);
        expandedRef.current = [];
        expandAllRef.current = false;
    }, []);

    const toggle = useCallback((row: MasterDetailRow<any>)=>{
        row.expanded = !row.expanded;
        const index = rows.findIndex((r)=>r.item.id===row.item.id && row.type==="MASTER");

        handleChange(row.item.id.toString(), row.expanded);

        if (row.expanded) {
            rows.splice(index + 1, 0, {
                type: "DETAIL",
                item: row.item
            } as any);
        } else {
            rows.splice(index + 1, 1);
        }

        rows[index] = {...row} as any;
        setRows([...rows]);
    }, [handleChange, rows]);

    return useMemo(()=>({
        handleChangeSet, expandedRef, handleChange, resetMasterDetail, expandAll, expandAllRef,
        context: {expandAll: expandAllState, toggle}
    }), [handleChangeSet, handleChange, resetMasterDetail, expandAll, expandAllState, toggle]);
}

export const MasterDetailContext = createContext<MasterDetailContextType>({
    expandAll: false,
    toggle: ()=>{}
});
export const MasterDetailProvider = MasterDetailContext.Provider;