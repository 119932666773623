import {LocaleObject} from "yup/lib/locale";

export enum Messages {
    OFF="off",
    SOMETHING_WENT_WRONG = "Něco se nepovedlo",
    OFFLINE = "Aplikace není připojena k internetu",
    UNAUTHORIZED = "Špatné přihlašovací údaje",
    WRONG_OLD_PASS = "Špatné staré heslo",
    DUPLICATED_ICO = "Takové IČO už v databázi je",
    DUPLICATED_EMAIL = "Takový e-mail už nějaký uživatel používá",
    DUPLICATED_USERNAME = "Takové uživatelské jméno už nějaký uživatel používá",
    DUPLICATED_SEGMENT = "Takový segment již existuje.",
    DUPLICATED_CURRENCY = "Taková měna již existuje.",
    DUPLICATED_COUNTRY = "Taková země již existuje.",
    DUPLICATED_PRODUCT = "Takový produkt již existuje, nebo existoval a je někde ještě zanesen v datech.",
    BLOCKED_USER = "Váš účet je momentálně zablokovaný, zkuste to zítra, nebo kontaktujte podporu",
    NEW_NOTIFICATION = "Máte nové upozornění.",
    NEW_OLDIES_NOTIFICATION = "Za vaší nepřítomnosti vám přišla nová upozornění. (:number)",
    CANNOT_MOVE_INVALID_DATA = "Položky se nedají přesunout, jelikož je mezi nimi položka, jejíž data nejsou validní.",
    CANNOT_REMOVE_COUNTRY = "Tuto zemi nelze odstranit, protože je používána v adresách.",
    CANNOT_REMOVE_PRODUCT = "Tento produkt nelze odstranit.",
    CANNOT_REMOVE_SEGMENT = "Tento segment nelze odstranit, protože je používán.",
    CANNOT_REMOVE_CURRENCY = "Tato měna nelze odstranit, protože je používána.",

    NOTIFICATION_REQUIRED_ITEM = "Při tomto typu události je položka :item povinná.",

    SAVED = "Uloženo",
    SAVED_GROUP = "Uloženo :number položek",
    MOVED = "Bylo přesunuto :number položek.",
    CREATED = "Vytvořeno",
    REMOVED = "Odstraněno",
    REMOVED_SELECTION = "Úspěšně bylo smazáno :number položek.",

    CONFIGURATION_DOES_NOT_EXISTS = "Požadovaná konfigurace neexistuje, kontaktujte prosím podporu",

    OFFER_COMMON_INFO_SAVED = "Společná data byla uložena.",
    OFFER_VERSION_SAVED = "Data aktuální verze byla uložena.",
    OFFER_SEALED = "Nabídka byla potvrzena. Nyní nelze upravovat.",
    IMPORT_UNKNOWN_COLUMN = "Import obsahuje neznámý sloupec.",

    SAVED_TO_CLIPBOARD = "Zkopírováno do schránky",

    SUCCESS_UPLOAD = "Úspěšně bylo nahráno :number souborů.",

    BANK_ACCOUNT_NOT_SET = "Není nastavený bankovní účet.",

    IDENTICAL_PASSWORDS = "Hesla nejsou stejná",
    INVALID_PASSWORD = "Heslo musí mít minimálně 8 znaků a obsahovat velká, malá písmena a číslice",

    INVALID_FILTER = "Neplatný formát filtru",

    FINISHED_BUSINESS_CASE = "Gratuluju! Úspěšně jste dokončil obchodní případ.",

    CANNOT_BE_COPIED = "Tuto položku nelze zkopírovat"
}

export const yupLocalization: LocaleObject = {
    mixed: {
        defined: 'Položka je povinná',
        required: 'Položka je povinná',
        oneOf: 'Položka musí obsahovat jednu z následujících hodnot: ${values}',
        notOneOf: 'Položka nesmí obsahovat žádnou z následujících hodnot: ${values}',
        notType: function notType(_ref) {
            switch (_ref.type) {
                case 'number':
                    return 'Položka musí být číselná';
                case 'string':
                    return 'Položka musí být textový řetězec';
                default:
                    return 'Špatný typ položky';
            }
        }
    },
    string: {
        length: 'Položka musí obsahovat přesně ${length} znaků',
        min: 'Položka musí obsahovat minimálně ${min} znaků',
        max: 'Položka musí obsahovat maximálně ${max} znaků',
        matches: 'Položka musí splňovat pravidlo: "${regex}"',
        email: 'Položka musí být platná emailová adresa',
        url: 'Položka musí být platná URL adresa',
        trim: 'Položka nesmí obsahovat mezery',
        lowercase: 'Položka musí obsahovat jen malá písmena',
        uppercase: 'Položka musí obsahovat jen velká písmena'
    },
    number: {
        min: 'Položka musí být větší nebo rovno ${min}',
        max: 'Položka musí být menší nebo rovno ${max}',
        lessThan: 'Položka musí být menší než ${less}',
        moreThan: 'Položka musí být větší než ${more}',
        positive: 'Položka musí být kladné číslo',
        negative: 'Položka musí být záporné číslo',
        integer: 'Položka musí být celé číslo'
    },
    date: {
        min: 'Položka musí být po ${min}',
        max: 'Položka musí být před ${max}'
    },
    object: {
        noUnknown: 'Položka nesmí obsahovat nespecifikované klíče'
    },
    array: {
        min: 'Položka musí obsahovat alespoň ${min} položek',
        max: 'Položka musí obsahova maximálně ${max} položek'
    }
};