import React, {FC} from "react";
import {OfferVersionGridResponse} from "../../props/apiResponses";
import GridChooserDialog, {
    ChooserDialogBase,
    GridChooserProps,
    GridChooserReturnProps,
    useGridChooserDialog
} from "../../common/dialog/GridChooserDialog";
import OfferVersionsGrid, {OfferVersionsGridRow} from "./OfferVersionsGrid";


const OfferVersionChooserDialog: FC<ChooserDialogBase<OfferVersionGridResponse>> = (props) => {

    return (
        <GridChooserDialog<OfferVersionGridResponse, OfferVersionsGridRow, FC<GridChooserProps<OfferVersionsGridRow>>>

            {...props}

            entityName="verzi nabídky"
            component={OfferVersionsGrid}
        />
    );
};
export default OfferVersionChooserDialog;

export function useOfferVersionChooserDialog(handleSelection: (version: OfferVersionGridResponse)=>void): GridChooserReturnProps<OfferVersionGridResponse> {
    return useGridChooserDialog(handleSelection);
}