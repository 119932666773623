import React, {Key, PropsWithChildren, ReactNode, useCallback} from "react";
import {alpha, Box, css, styled} from "@mui/material";
import LibDataGrid, {
    CalculatedColumn,
    Column,
    DataGridProps as LibDataGridProps,
    RowHeightArgs,
    RowRendererProps
} from "react-data-grid";
import {DEFAULT_BLOCK_SIZE, ROW_HEADER_HEIGHT, ROW_HEIGHT} from "./props";
import TopActions, {TopAction, TopActionsWrapperAlign} from "./TopActions";
import {grey} from "@mui/material/colors";
import {GridSelection} from "./RowSelection";
import {GridSorting} from "./RowSorting";
import InLineLoading from "../InLineLoading";
import {GridContextMenu, useGridContextMenuInner} from "./contextMenu/GridContextMenu";
import BaseRenderer from "./row/BaseRenderer";
import {RowRenderer} from "./row/props";

export type DataGridProps<Row> = {
    rows: readonly Row[];
    columns: readonly Column<Row>[];
    topActions?: TopAction[];
    topActionsAlignment?: TopActionsWrapperAlign;
    height?: number;
    error?: boolean;
    rowClass?: (row: Row)=>string;
    rowKeyGetter?: (row: any) => Key;
    selection?: GridSelection;
    disableMt?: boolean;
    sorting?: GridSorting;
    rowHeaderHeight?: number;
    onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    loadingMoreRows?: boolean;
    className?: string;
    hideHeader?: boolean;
    contextMenu?: GridContextMenu<Row>;
    rowRenderer?: RowRenderer<Row>;
    loading?: boolean;
    onRowDoubleClick?: (row: Row, column: CalculatedColumn<Row>)=>void;
    rowHeight?: number | ((args: RowHeightArgs<any>) => number);
};
const DataGrid = <Row,>(
    {
        rows,
        columns,
        topActions,
        height,
        error,
        rowClass,
        rowKeyGetter,
        selection,
        disableMt,
        sorting,
        rowHeaderHeight,
        onScroll,
        loadingMoreRows,
        className,
        hideHeader,
        contextMenu,
        rowRenderer,
        loading,
        onRowDoubleClick,
        rowHeight,
        topActionsAlignment
    }: PropsWithChildren<DataGridProps<Row>>
) => {
    const contextMenuParams = useGridContextMenuInner(columns, contextMenu);

    const rowRendererInner = useCallback((key: Key, props: RowRendererProps<Row>): ReactNode => {
        const contextMenuControls = !!contextMenu ? contextMenuParams.controls : undefined;

        if (!rowRenderer) return (
            <BaseRenderer
                key={key}
                props={props}
                contextMenuControls={contextMenuControls}
            />
        );

        return rowRenderer(key, props, contextMenuControls);
    }, [contextMenu, contextMenuParams.controls, rowRenderer]);

    return (
        <Wrapper>
            {
                !selection?.showSelection && !!topActions && <TopActions alignItems={topActionsAlignment}>{topActions}</TopActions>
            }
            {
                !!selection?.showSelection && !!selection.topActions && <TopActions alignItems={topActionsAlignment}>{selection.topActions}</TopActions>
            }
            <StyledDataGrid
                rows={rows}
                columns={columns}

                rowHeight={rowHeight ?? ROW_HEIGHT}
                headerRowHeight={hideHeader ? 0 : (rowHeaderHeight ?? ROW_HEADER_HEIGHT)}
                hideHeader={hideHeader}

                defaultColumnOptions={{
                    resizable: true,
                    sortable: !!sorting
                }}

                sortColumns={sorting?.sortColumns}
                onSortColumnsChange={sorting?.onSortColumnsChange}

                rowKeyGetter={rowKeyGetter}
                selectedRows={selection?.selectedRows}
                onSelectedRowsChange={selection?.onSelectedRowsChange}

                onRowDoubleClick={onRowDoubleClick}

                onScroll={onScroll}

                rowClass={rowClass}
                height={height}
                error={error}
                disableMt={disableMt}
                loading={loading}
                enableVirtualization

                renderers={{
                    rowRenderer: rowRendererInner
                }}

                className={"rdg-light fill-grid"+(className ? " "+className : "")}
            />
            <InLineLoading loading={!!loadingMoreRows} caption={"Načítám další řádky..."} />
            {contextMenuParams.menusElements}
        </Wrapper>
    );
};
export default DataGrid;

const Wrapper = styled(Box)(({theme})=>css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`);

type StyledProps = {
    height?: number;
    error?: boolean;
    disableMt?: boolean;
    hideHeader?: boolean;
    loading?: boolean;
}
const StyledDataGrid = styled(
    ({height, error, disableMt, hideHeader, loading, ...rest}: StyledProps & LibDataGridProps<any>)=><LibDataGrid {...rest} />
)<StyledProps>(({theme, height, error, disableMt, hideHeader, loading})=>css`
  border-radius: 5px;
  --rdg-selection-color: ${theme.palette.primary.main};
  --rdg-header-background-color: ${grey[100]};
  --rdg-border-color: ${grey[300]};
  flex-grow: 1;
  block-size: ${height ?? DEFAULT_BLOCK_SIZE}px;
  
  transition: opacity 300ms ease;
  ${loading && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  ${!disableMt && css`
    margin-top: ${theme.spacing(1)};
  `}
  
  ${!!error && css`
    border-color: ${theme.palette.error.main};
  `}
  
  & .rdg-cell[aria-selected="true"] {
    outline: none;
  }
  
  & .rdg-header-row {
    ${hideHeader && css`
      display: none;
    `}
    
    & .rdg-cell {
      color: ${theme.palette.primary.main}
    }
  }

  & .rdg-row {

    & .rdg-cell {
      user-select: text;  
    }
    
    &.error {
      background-color: ${alpha(theme.palette.error.main, .2)};
    }
    
    &.inactive {
      background-color: ${grey[200]};
      color: ${grey[700]};
    }
    
    &.subItem {
      background-color: ${grey[200]};
    }
    
    &.light {
      font-style: italic;
      color: ${theme.palette.grey[600]};
    }
  }
`);