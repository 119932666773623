import {FC, SyntheticEvent, useCallback, useState} from "react";
import ComboBox from "./ComboBox";
import {useCompaniesManipulator} from "../../services/company/CompanyService";
import {CompanyResponse} from "../../props/apiResponses";
import {useFastChangingRequest, useInitialRequest} from "../../services/RequestService";
import {useField} from "formik";
import {SearchFieldProps} from "../props";

interface Props extends SearchFieldProps {
    supplier?: boolean;
}
const CompanySearch: FC<Props> = (
    {
        label,
        name,
        valueName,
        required,
        supplier
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");
    const [options, setOptions] = useState<CompanyResponse[]>([]);
    const {run, loading} = useCompaniesManipulator(false);
    const execution = useFastChangingRequest();

    const request = useCallback(async (value: string = "")=>{
        await execution(async (controller) => {
            const data = await run({controller, params: {
                pageSize: 50,
                pageNum: 1,
                name: value,
                sort: "name",
                supplier
            }});

            if (data) {
                const customers: CompanyResponse[] = [];
                const suppliers: CompanyResponse[] = [];
                data.data.forEach((company) => {
                    if (company.supplier)
                        suppliers.push(company);
                    else
                        customers.push(company);
                });
                setOptions(customers.concat(suppliers));
            }
        });
    }, []);
    useInitialRequest(useCallback(()=>request(), []));

    const handleChange = useCallback(async (event: SyntheticEvent, value: string, reason: string)=>{
        if (reason!=="input") return;

        await request(value);

    }, []);

    const handleSelect = useCallback((company: CompanyResponse)=>{
        helpers.setValue(company);
    }, []);

    return (
        <ComboBox
            label={label}
            name={valueName}

            options={options}
            getOptionLabel={option => option.name}
            getOptionKey={option => option.id}
            isOptionEqualToValue={(option, value) => option.id===value.id}
            value={field.value as CompanyResponse}

            groupBy={(option)=>option.supplier ? "Dodavatel" : "Zákazník"}

            onInputChange={handleChange}
            handleSelect={handleSelect}

            disableFreeSolo
            loading={loading}
            required={required}

            TextFieldProps={{onBlur: ()=>helpers.setTouched(true, true)}}
            error={(meta.touched && !!meta.error) ? meta.error : undefined}
        />
    );
};
export default CompanySearch;