import {
    ModalProps,
    UseModalArgs,
    UseModalReturn,
    useModal,
    Modal,
    ModalTitle,
    ModalContent,
    ModalActions
} from "@data-session/mui";
import { FormCallback } from "@data-session/forms";
import DeliveryNoteItemForm, {DeliveryNoteItemFormCallbackArgs, useDeliveryNoteItemForm} from "../forms/DeliveryNoteItemForm";
import {BusinessCaseItemResponse} from "../schema/businessCaseItem";
import useTranslation from "../../../../utils/translations/useTranslation";
import SaveAndCloseAction from "../../../../components/modals/actions/SaveAndCloseAction";
import useFormModalCallback from "../../../../components/modals/utils/useFormModalCallback";

// -> TYPES
export type DeliveryNoteItemFormModalStorage = {
    response: BusinessCaseItemResponse;
    isSubItem: boolean;
};
export interface DeliveryNoteItemFormModalProps extends ModalProps<DeliveryNoteItemFormModalStorage> {
    callback?: FormCallback<DeliveryNoteItemFormCallbackArgs>;
    disabled?: boolean;
}
export interface UseDeliveryNoteItemFormModalArgs extends UseModalArgs {}
export interface UseDeliveryNoteItemFormModalReturn extends UseModalReturn<DeliveryNoteItemFormModalStorage> {}
// TYPES <-

export function useDeliveryNoteItemFormModal(
    args?: UseDeliveryNoteItemFormModalArgs
): UseDeliveryNoteItemFormModalReturn {
    return useModal(args);
}

const InnerContent = (
    props: DeliveryNoteItemFormModalProps
) => {
    const {controls, disabled} = props;
    const {t} = useTranslation();
    const formCallback = useFormModalCallback(props);
    const form = useDeliveryNoteItemForm({item: controls.storage?.response, callback: formCallback, disabled});

    return (
        <>
            <ModalTitle>{t("Edit")} {controls.storage?.response?.name}</ModalTitle>
            <ModalContent>
                <DeliveryNoteItemForm form={form} isSubItem={controls.storage?.isSubItem ?? false} />
            </ModalContent>
            <ModalActions>
                <SaveAndCloseAction
                    handleClose={controls.closeModal}
                    handleSave={form.onSubmit}
                    disabled={form.disabled}
                    loading={form.isLoading}
                />
            </ModalActions>
        </>
    );
};

const DeliveryNoteItemFormModal = (props: DeliveryNoteItemFormModalProps) => (
    <Modal {...props} extension={{maxWidth: "sm", fullWidth: true}}>
        <InnerContent {...props} />
    </Modal>
);

export default DeliveryNoteItemFormModal;
