import {RequestOpt} from "../RequestService";
import {OpportunityRequest, OpportunityStatus} from "../../props/apiRequests";
import {OpportunityGridResponse, OpportunityResponse} from "../../props/apiResponses";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {useEffect, useMemo, useState} from "react";
import {useCRUDRequest} from "../CRUDRequestService";

const OPPORTUNITIES_API = "/opportunities";

class OpportunityService extends GridCRUDServiceImpl<OpportunityRequest, OpportunityGridResponse> {}
const opportunityService = new OpportunityService(OPPORTUNITIES_API);

export function useOpportunityManipulator(opt?: RequestOpt) {
    return useCRUDRequest<OpportunityResponse, OpportunityService>(opportunityService, undefined, {all: opt});
}

export function useOpportunitiesManipulator(globalLoading: boolean) {
    return useGridRequest<OpportunityGridResponse>(opportunityService, globalLoading);
}

export function useLastOpportunities(): [
    OpportunityGridResponse[] | undefined,
    boolean
    ] {
    const [opportunities, setOpportunities] = useState<OpportunityGridResponse[]>();
    const request = useOpportunitiesManipulator(false);

    useEffect(()=>{
        (async ()=>{
            const result = await request.run({params: {
                status: OpportunityStatus.IN_PROGRESS,
                sort: ["-lastModificationTo"],
                pageNum: 1,
                pageSize: 10
            }});

            if (result) setOpportunities(result.data);
        })();
    }, []);

    return useMemo(()=>([opportunities, request.loading]), [opportunities, request.loading]);
}