import {FC} from "react";
import Button, {ButtonProps} from "./Button";
import {Close, Save} from "@mui/icons-material";

const ClearButton: FC<ButtonProps> = (props) => {
    return (
        <Button
            {...props}
            startIcon={props.startIcon ?? <Close />}
        >
            {props.children ?? "Vymazat"}
        </Button>
    );
};
export default ClearButton;