import React, {useCallback, useMemo, useState} from "react";
import {Menu as MuiMenu, MenuItem, MenuItemProps as MuiMenuItemProps} from "@mui/material";

export type MenuItemProps = (MuiMenuItemProps<any> & {key: React.Key}) | null;

interface Props {
    anchorEl: HTMLElement | null,
    handleCloseMenu: () => void,
    children: MenuItemProps[]
}

const Menu = (
    {
        anchorEl,
        handleCloseMenu,
        children
    }: Props
) => {

    return (
        <MuiMenu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
            {
                children.map((child)=>
                    child===null ? null :
                        <MenuItem
                            {...child}
                            key={child.key}
                        >
                            {child.children}
                        </MenuItem>
                )
            }
        </MuiMenu>
    );
};

export default Menu;

export function useMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return useMemo(()=>({
        props: {
            anchorEl,
            handleCloseMenu
        },
        handleMenu
    }), [anchorEl, handleMenu, handleCloseMenu]);
}