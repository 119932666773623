import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Messages} from "../props/messages";
import {ErrorResponse, GridResponse} from "../props/apiResponses";
import keycloakService from "./auth/KeycloakService";
import {MessagesHandler, useMessages} from "../props/messagesHandler";
import useGlobalLoading from "../GlobalLoading";
import {ID, paramsSerializer} from "../props/apiRequests";
import {GridRP} from "./GridService";

axios.defaults.baseURL = "/api";

export enum HttpMethod {
    POST = "POST",
    PUT = "PUT",
    GET = "GET",
    DELETE = "DELETE",
    PATCH = "PATCH"
}

export class RequestService<Req, Res> {
    protected apiAddress: string = "";

    constructor(apiAddress: string) {
        this.apiAddress = apiAddress;
    }

    public addressWithId = (id?: ID): string => {
        return idAddress(this.apiAddress, id);
    }

    private request = (url: string, method: HttpMethod, data?: any, controller?: AbortController, config?: AxiosRequestConfig) => {
        return axios.request({
            url,
            method,
            ...config,
            headers: {...config?.headers, "Accept-Language": "cs"},
            signal: controller?.signal,
            data,
            paramsSerializer: paramsSerializer
        });
    }

    protected get = <MRes = Res,>(url: string, controller?: AbortController, config?: AxiosRequestConfig): Promise<AxiosResponse<MRes>> =>
        this.request(url, HttpMethod.GET, undefined, controller, config);
    protected post = <MReq = Req, MRes = Res>(url: string, data: MReq, controller?: AbortController): Promise<AxiosResponse<MRes>> =>
        this.request(url, HttpMethod.POST, data, controller);
    protected put = <MReq = Req, MRes = Res>(url: string, data: MReq, controller?: AbortController): Promise<AxiosResponse<MRes>> =>
        this.request(url, HttpMethod.PUT, data, controller);
    protected delete = <MRes = "">(url: string, controller?: AbortController): Promise<AxiosResponse<MRes>> =>
        this.request(url, HttpMethod.DELETE, undefined, controller);
    protected patch = <MReq = Req, MRes = "">(url: string, data: MReq, controller?: AbortController): Promise<AxiosResponse<MRes>> =>
        this.request(url, HttpMethod.PATCH, data, controller);
    protected upload = (url: string, formData: FormData, controller?: AbortController) =>
        this.request(url, HttpMethod.POST, formData, controller, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    protected getFile = (url: string, controller?: AbortController, config?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> =>
        this.get(url, controller, {
            responseType: "blob",
            ...config
        });
    protected getGrid = <MRes = Res,>(url: string, {params, controller}: GridRP): Promise<AxiosResponse<GridResponse<MRes>>> =>
        this.get(url, controller, {params, paramsSerializer});
}

export type EnqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;

const isJsonBlob = (data: any) => data instanceof Blob && data.type === "application/json";

export interface RequestOpt {
    globalLoading?: boolean;
    nonApiUrl?: boolean;
    disableAutoLoading?: boolean; // for re-render prevention
}

export type ErrorsDefinition = {
    [code: string]: {
        [code: string]: Messages
    }
};
export default function useRequest<T, P>(
    process: (params: P)=>Promise<AxiosResponse<T>>,
    errors?: ErrorsDefinition,
    opt?: RequestOpt
): {
    run: (params: P)=>Promise<null | T>;
    loading: boolean;
    startLoading: ()=>void;
    stopLoading: ()=>void;
    messageHandler: MessagesHandler;
} {
    const [loading, setLoading] = useState<boolean>(false);
    const [startGlobalLoading, stopGlobalLoading] = useGlobalLoading();
    const messages = useMessages();

    const changeLoading = useCallback((value: boolean): void => {
        if (opt?.disableAutoLoading) return;

        if (opt?.globalLoading) {
            if (value) startGlobalLoading();
            else stopGlobalLoading();
        }

        setLoading(value);
    }, [opt, setLoading]);

    const startLoading = useCallback(() => setLoading(true), [setLoading]);
    const stopLoading = useCallback(() => setLoading(false), [setLoading]);

    const checkLogin = useCallback(async ()=>{
        if (!opt?.nonApiUrl) {
            await keycloakService.updateTokenIfExpired();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + keycloakService.keycloak?.token;
        }
    }, [opt]);

    const handleError = useCallback(async (e: AxiosError<ErrorResponse>)=>{
        let message: string | Messages = Messages.SOMETHING_WENT_WRONG;

        const responseData: string | ErrorResponse | undefined = !!e.response && isJsonBlob(e.response?.data) ? await (e.response.data as unknown as Blob)?.text() : e.response?.data;
        const body: ErrorResponse | undefined = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

        if(body?.message) message = body?.message;

        if (errors && e.response) {
            if (errors[e.response.status]) {
                let code: string = "default";
                if (!!body?.fields) {
                    const fieldsKeys = Object.keys(body?.fields);
                    if (fieldsKeys.length>0) code = fieldsKeys[0];
                }

                if (errors[e.response.status][code]===undefined) code = "default";
                if (errors[e.response.status][code]!==undefined)
                    message = errors[e.response.status][code];
            }
        }

        if (message!==Messages.OFF) messages.error(message);
    }, [messages, errors]);


    const run = useCallback((params: P): Promise<null | T> => {
        changeLoading(true);

        return new Promise<null | T>(async (resolve)=>{

            await checkLogin();

            process(params)
                .then((result)=>resolve(result.data))
                .catch((e: AxiosError<ErrorResponse>)=>{
                    console.error(e);
                    if (axios.isCancel(e)) return;
                    if (!navigator.onLine) {
                        const retry = async () => {
                            resolve(await run(params));
                        };
                        window.addEventListener("online", retry);
                        return;
                    }

                    handleError(e);
                    resolve(null);
                })
                .finally(()=>{
                    changeLoading(false);
                });
        });
    }, [checkLogin, changeLoading, handleError, process]);

    return useMemo(()=>(
        {
            startLoading,
            stopLoading,
            loading,
            messageHandler: messages,
            run
        }
    ), [startLoading, stopLoading, loading, messages, run]);
}

export function useFastChangingRequest(): (callback: (controller: AbortController)=>Promise<void>)=>Promise<void> {
    const abortController = useRef<AbortController>();

    return async (callback: (controller: AbortController)=>Promise<void>): Promise<void> => {
        if (abortController.current && !abortController.current.signal.aborted)
            abortController.current.abort();
        abortController.current = new AbortController();

        await callback(abortController.current);
    };
}

export function useInitialRequest(runnable: (controller?: AbortController)=>void, clear?: ()=>void) {
    useEffect(() => {
        const controller = new AbortController();
        runnable(controller);

        return () => { // before component unmounting
            controller.abort();
            if (clear) clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runnable, clear]);
}

export const idAddress = (apiAddress: string, id?: ID) => apiAddress + (id!==undefined ? `/${id}` : ``);

export const fileRequest = (url: string, formData: FormData): Promise<AxiosResponse> => {
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};
