import {FC} from "react";
import Select, {SelectProps} from "./Select";
import {useUserSelect} from "../../../services/user/UserService";

const UserSelect: FC<SelectProps> = (props) => {
    const {users, loading} = useUserSelect();

    return (
        <Select
            options={users}
            loading={loading}

            {...props}
        />
    );
};
export default UserSelect;