import {FC, ReactNode} from "react";
import Tooltip from "./Tooltip";
import {Help as HelpIcon} from "@mui/icons-material";
import {TooltipProps} from "@mui/material";

type Props = {
    title: TooltipProps["title"];
    className?: string;
};
const Help: FC<Props> = (
    {
        title,
        className
    }
) => {
    return (
        <Tooltip title={title} className={className}>
            <HelpIcon color="primary" />
        </Tooltip>
    );
};
export default Help;