import {DeliveryNoteResponse} from "../../../../containers/trade/businessCase/schema/deliveryNote";
import {Box, css, styled, Typography} from "@mui/material";
import {Frame} from "../../../../common/Frame";
import React, {useCallback, useContext} from "react";
import DeliveryNoteBlock from "./DeliveryNoteBlock";
import Suppliers from "../Suppliers";
import {BusinessCaseContext, useGeneratedDocument, useUpdateTerms} from "../../props";
import {ConfigurationDocumentTextType, DocumentType, Language} from "../../../../props/apiRequests";
import {useBusinessCaseDocumentManipulator} from "../../../../services/businessCase/BusinessCaseService";
import {DocumentResponse} from "../../../../props/apiResponses";
import {downloadDocument} from "../../../../services/DocumentService";
import {Items} from "../../../../components/item/Items";
import DocumentTextTypeChooser from "../../../../form/fields/DocumentTextTypeChooser";
import TermsForm from "../../../../form/trade/TermsForm";
import BlockName from "../BlockName";
import CreateOrOpenPDF from "../CreateOrOpenPDF";
import {Delete} from "@mui/icons-material";
import Button from "../../../../common/button/Button";
import useDeleteDeliveryNote from "../../../../containers/trade/businessCase/services/useDeleteDeliveryNote";
import {Messages} from "../../../../props/messages";
import SupplierBlock from "../SupplierBlock";
import DeliveryNoteBlockWithoutSupplier from "./DeliveryNoteBlockWithoutSupplier";
import DeliveryNoteForm, {useDeliveryNoteForm} from "../../../../form/businessCase/DeliveryNoteForm";

export interface DeliveryNoteItemProps {
    data: DeliveryNoteResponse;
}

const DeliveryNoteItem = (
    {
        data
    }: DeliveryNoteItemProps
) => {
    const context = useContext(BusinessCaseContext);
    const termsForm = useUpdateTerms("Podmínky (dodatek)", "paymentTermsDeliveryNote", data);
    const deleteDeliveryNote = useDeleteDeliveryNote();
    const documentManipulator = useBusinessCaseDocumentManipulator();
    const [downloadGeneratedDocument, wasGeneratedDocument] = useGeneratedDocument(DocumentType.CUSTOMER_DELIVERY_NOTE, context?.data?.documents, documentManipulator.get.run, data);
    const deliveryNoteForm = useDeliveryNoteForm({deliveryNote: data});

    const generateDeliveryNote = useCallback(async ()=>{
        const businessCaseId = context?.data?.id;
        if (businessCaseId===undefined || !context?.data || !context?.setData) return;
        const result = await documentManipulator.getDeliveryNote.run({businessCaseId, deliveryNoteId: Number(data.id)});
        if (result !== null) {
            const documents: DocumentResponse[] = context.data.documents.filter((document)=>document.type!==DocumentType.CUSTOMER_DELIVERY_NOTE || document.deliveryNoteId!==data.id);
            context?.setData({...context.data, documents: [...documents, result]});
            downloadDocument(result);
        }
    }, [context, documentManipulator]);

    const handleDeleteDeliveryNote = useCallback(async ()=>{
        if (!context?.data || !context.setData) return;

        try {
            await deleteDeliveryNote.mutateAsync({id: data.id});
            context.setData({
                ...context.data,
                deliveryNotes: context.data.deliveryNotes.filter(d => d.id!==data.id),
                invoices: context.data.invoices.filter(i => i.deliveryNoteId!==data.id)
            });
            documentManipulator.upload.messageHandler.success(Messages.REMOVED);
        } catch (e) {
            //
        }
    }, [context, deleteDeliveryNote, documentManipulator.upload]);

    termsForm.props.disabled = !!context?.finished;

    return (
        <Wrapper>
            <BlockName>
                <Typography variant="h5" color="primary">Dodací list {data.deliveryNoteNumber}</Typography>
                <Items>
                    <CreateOrOpenPDF
                        generate={generateDeliveryNote}
                        loading={documentManipulator.loading}
                        downloadGenerated={downloadGeneratedDocument}
                        wasGenerated={wasGeneratedDocument}
                    />
                    {
                        !context?.finished && (
                            <Button
                                startIcon={<Delete />}
                                color="error"
                                onClick={handleDeleteDeliveryNote}
                                loading={deleteDeliveryNote.isLoading}
                            >
                                Smazat
                            </Button>
                        )
                    }
                </Items>
            </BlockName>
            <Suppliers component={DeliveryNoteBlock} disabled={context?.finished} deliveryNote={data} />
            <DeliveryNoteBlockWithoutSupplier
                data={data}
            />
            <Frame defaultColor>
                <DeliveryNoteForm {...deliveryNoteForm.props} disabled={context?.finished} />
            </Frame>
        </Wrapper>
    );
};

export default DeliveryNoteItem;

const Wrapper = styled(Frame)(()=>css`
  
`);
