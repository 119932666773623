import {FC, useMemo} from "react";
import Select, {SelectOption, SelectProps} from "./Select";
import {DeliveryNoteResponse} from "../../../containers/trade/businessCase/schema/deliveryNote";

interface DeliveryNoteSelectProps extends SelectProps {
    deliveryNotes: DeliveryNoteResponse[];
}

const DeliveryNoteSelect: FC<DeliveryNoteSelectProps> = (props) => {
    const options = useMemo<SelectOption[]>(() => props.deliveryNotes.map(d=>({
        key: d.id,
        label: `Dodací list ${d.deliveryNoteNumber}`
    })), [props.options]);

    return (
        <Select
            options={options}

            {...props}
        />
    );
};
export default DeliveryNoteSelect;
