import {CompanyRequest, GetRP, PostRP, PutRP, CreatePhysicalPersonCompanyRequest} from '../../props/apiRequests';
import {CompanyResponse} from "../../props/apiResponses";
import useRequest from "../RequestService";
import {Messages} from "../../props/messages";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {makeDownloadBlob, mergeAddress} from "../../props/helpers";
import {useCallback} from "react";
import {useCRUDRequest} from "../CRUDRequestService";

export const COMPANIES_API = "/companies";

export interface GetCompaniesExportRP extends GetRP {
    params: any;
}
class CompanyService extends GridCRUDServiceImpl<CompanyRequest, CompanyResponse> {
    public create = ({data, controller}: PostRP<CompanyRequest>) =>
        this.post(this.addressWithId(), {...data, officeName: mergeAddress(data.address)}, controller);

    public export = ({controller, params}: GetCompaniesExportRP) =>
        this.getFile(this.addressWithId()+"/export", controller, {params});

    public createPhysicalPerson = ({controller, data}: PostRP<CreatePhysicalPersonCompanyRequest>) => this.post(
        this.addressWithId()+"/physical-persons", 
        {...data, company: {...data.company, officeName: mergeAddress(data.company.address)}}, 
        controller
    );
}

const companyService = new CompanyService(COMPANIES_API);

const errors = {
    409: {
        "default": Messages.DUPLICATED_ICO
    }
};
export function useCompanyManipulator() {
    return useCRUDRequest<CompanyResponse, CompanyService>(companyService, {update: errors, create: errors});
}

export function usePhysicalPersonCompanyManipulator() {
    return useRequest(companyService.createPhysicalPerson, errors);
}

export function useCompaniesExport(isSupplier: boolean) {
    const get = useRequest(companyService.export);

    const handleDownload = useCallback(async (params: any, include: Set<string>, fetchAll: boolean)=>{
        params.include = Array.from(include);
        params.fetchAll = fetchAll;
        params.supplier = isSupplier;
        const result = await get.run({params});
        if (result) makeDownloadBlob(result, "Export.xlsx");
    }, [isSupplier]);

    return {handleDownload, loading: get.loading};
}

export function useCompaniesManipulator(globalLoading: boolean) {
    return useGridRequest<CompanyResponse>(companyService, globalLoading);
}

