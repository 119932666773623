import {
    Form,
    FormCallbackArgs,
    FormProps,
    FormValues,
    Input,
    useForm,
    UseFormArgs,
    UseFormReturn,
    useFieldArray
} from "@data-session/forms";
import FormGrid from "../../../../components/forms/FormGrid";
import Grid from "../../../../components/Grid";
import DeliveryNoteSelect from "./fields/DeliveryNoteSelect";
import {useContext, useMemo} from "react";
import {BusinessCaseContext} from "../../../../pages/businessCase/props";
import useTranslation from "../../../../utils/translations/useTranslation";
import {BusinessCaseItemRequest, BusinessCaseItemResponse} from "../schema/businessCaseItem";
import {DeliveryNoteItemSchema} from "../schema/deliveryNoteItem";
import {TextField} from "@data-session/mui";
import {Typography} from "@mui/material";
import usePutBusinessCaseItem from "../services/usePutBusinessCaseItem";
import {useMessages} from "../../../../props/messagesHandler";
import {Messages} from "../../../../props/messages";

// -> TYPES
interface DeliveryNoteItemFormValues extends FormValues, DeliveryNoteItemSchema {}
interface DeliveryNoteItemFormProps extends FormProps<DeliveryNoteItemFormValues> {
    isSubItem: boolean;
}
export interface DeliveryNoteItemFormCallbackArgs extends FormCallbackArgs {
    businessCaseItem: BusinessCaseItemResponse;
}
interface UseDeliveryNoteItemFormArgs extends UseFormArgs<DeliveryNoteItemFormCallbackArgs> {
    item?: BusinessCaseItemResponse;
    disabled?: boolean;
}
interface UseDeliveryNoteItemFormReturn extends UseFormReturn<DeliveryNoteItemFormValues> {}
// TYPES <-

export function useDeliveryNoteItemForm(
    { item, callback, disabled }: UseDeliveryNoteItemFormArgs
): UseDeliveryNoteItemFormReturn {
    const context = useContext(BusinessCaseContext);
    const put = usePutBusinessCaseItem();
    const { success } = useMessages();

    const serialNumbers = useMemo<string[]>(()=>{
        if (!item) return [];

        let result: string[] = item.serialNumbers ?? [];
        if (result.length<item.amount) {
            const diff: number = item.amount - result.length;
            result = result.concat(Array.from({length: diff}, ()=>""));
        }

        return result;
    }, [item]);

    return useForm<DeliveryNoteItemFormValues>({
        onSubmit: async (values) => {
            if (!item || !context?.data) return;

            const data: BusinessCaseItemRequest = {
                ...item,
                ...values
            };

            try {
                const businessCaseItem = await put.mutateAsync({id: item.id, businessCaseId: context.data.id.toString(), data});
                success(Messages.SAVED);
                if (callback) callback({businessCaseItem});
            } catch (e) {
                //
            }
        },
        defaultValues: {
            deliveryNoteId: undefined,
            serialNumbers: []
        },
        values: {
            deliveryNoteId: item?.deliveryNoteId,
            serialNumbers
        },
        isLoading: put.isLoading,
        disabled
    });
}

const SerialNumbers = () => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ name: "serialNumbers" });
    return (
        <>
            {
                fields.map((value, i)=>
                    <Grid key={value.id} xs={12} sm={6}>
                        <Input
                            name={`serialNumbers.${i}`}
                            component={<TextField
                                label={`${t("Product")} ${i + 1}`}
                            />}
                        />
                    </Grid>
                )
            }
        </>
    )
};

const DeliveryNoteItemForm = (
    {
        form,
        isSubItem
    }: DeliveryNoteItemFormProps
) => {
    const {t} = useTranslation();
    const context = useContext(BusinessCaseContext);

    return (
        <Form {...form}>
            <FormGrid>
                {
                    !isSubItem && (
                        <Grid xs={12}>
                            <Input
                                name="deliveryNoteId"
                                component={<DeliveryNoteSelect
                                    label={t("Delivery_note")}
                                    options={context?.data?.deliveryNotes ?? []}
                                />}
                            />
                        </Grid>
                    )
                }
                <Grid xs={12} sx={{mt: !isSubItem ? 2 : 0}}>
                    <Typography variant="h6">{t("Serial_numbers")}</Typography>
                </Grid>
                <SerialNumbers />
            </FormGrid>
        </Form>
    );
};

export default DeliveryNoteItemForm;
