import {FC, MouseEventHandler, ReactNode} from "react";
import {Box, Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress, css, styled} from "@mui/material";
import { grey } from "@mui/material/colors";

export type ButtonProps = {
    children?: ReactNode;
    loading?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    ButtonProps?: MuiButtonProps;
    className?: string;
    variant?: "outlined" | "contained";
    startIcon?: ReactNode;
    color?: "secondary" | "success" | "inherit" | "warning" | "error" | "primary" | "info";
    hidden?: boolean;
    fullWidth?: boolean;
};
const Button: FC<ButtonProps> = (
    {
        children,
        loading,
        onClick,
        ButtonProps,
        className,
        variant,
        startIcon,
        color,
        hidden,
        fullWidth
    }
) => {

    return (
        <Box sx={{
            position: "relative",
            display: hidden ? "none" : "inline-flex",
            width: fullWidth ? "100%" : undefined
        }} className={className}>
            <MuiButton
                size="small"
                variant={variant ?? "contained"}
                type={!!onClick ? undefined : "submit"}
                onClick={onClick}
                startIcon={startIcon}
                color={color}
                fullWidth={fullWidth}

                {...ButtonProps}

                disabled={!!loading || !!ButtonProps?.disabled}
            >
                <TextWrapper component="span" sx={{opacity: loading ? 0 : 1}}>{children}</TextWrapper>
            </MuiButton>
            {loading && (
                <CircularProgress
                    size={18}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-9px',
                        marginLeft: '-9px',
                        color: grey[400]
                    }}
                />
            )}
        </Box>
    );
};
export default Button;

const TextWrapper = styled(Box)(({theme})=>css`
    transition: opacity 300ms ease;
`);