import React, {FC} from "react";
import GridChooserDialog, {
    ChooserDialogBase,
    GridChooserProps,
    GridChooserReturnProps,
    useGridChooserDialog
} from "../../common/dialog/GridChooserDialog";
import {MeetingResponse} from "../../props/apiResponses";
import MeetingsGrid, {MeetingsGridRow} from "./MeetingsGrid";

const MeetingChooserDialog: FC<ChooserDialogBase<MeetingResponse>> = (props) => {

    return (
        <GridChooserDialog<MeetingResponse, MeetingsGridRow, FC<GridChooserProps<MeetingsGridRow>>>

            {...props}

            entityName="schůzek"
            component={MeetingsGrid}
        />
    );
};
export default MeetingChooserDialog;

export function useMeetingChooserDialog(handleSelection: (meeting: MeetingResponse)=>void): GridChooserReturnProps<MeetingResponse> {
    return useGridChooserDialog(handleSelection);
}