import {
    Box,
    CircularProgress,
    css,
    FormControl,
    FormControlProps,
    FormHelperText,
    InputLabel,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuItemProps,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    styled
} from '@mui/material';
import {FC, MouseEventHandler, ReactElement, ReactNode, useCallback, useState} from "react";
import {useField} from 'formik';
import {grey} from '@mui/material/colors';
import {Close} from "@mui/icons-material";
import {FieldProps} from "../../props";
import {useInitialRequest} from "../../../services/RequestService";

export type SelectOption = {
    MenuItemProps?: MenuItemProps;
    key: number | string;
    label: ReactNode;
};

export interface SelectProps extends FieldProps {
    options?: SelectOption[];
    disabled?: boolean;
    loading?: boolean;
    clearable?: boolean;
    listButtonAppendix?: {
        onClick: MouseEventHandler;
        label: string;
        icon: ReactElement;
    };
    SelectProps?: MuiSelectProps;
}
const Select: FC<SelectProps> = (
    {
        label,
        name,
        options,
        disabled,
        loading,
        required,
        listButtonAppendix,
        clearable,
        SelectProps
    }
) => {
    const [field, meta] = useField(name ?? "");

    return (
        <Wrapper
            fullWidth
            size="small"
            variant="standard"
            error={meta.touched && !!meta.error}
            disabled={!!loading || disabled}
            required={required}
            isLabel={!!label}
        >
            <InputLabel shrink>{label}</InputLabel>
            <MuiSelect
                size="small"
                fullWidth
                {...field}
                value={!!options && options.length>0 ? field.value : (SelectProps?.multiple ? [] : "")}

                {...SelectProps}
                onChange={(e, child)=>{
                    field.onChange(e);
                    if (SelectProps?.onChange) SelectProps.onChange(e, child);
                }}
            >
                {
                    clearable &&
                        <ClearableItem value="">
                            <ListItemIcon><Close /></ListItemIcon>
                            <ListItemText><i>Nevyplněno</i></ListItemText>
                        </ClearableItem>
                }
                {
                    options?.map((item)=>
                        <MenuItem key={item.key} value={item.key} {...item.MenuItemProps}>
                            {item.label}
                        </MenuItem>
                    )
                }
                {
                    !!listButtonAppendix &&
                        <ItemButton onClick={listButtonAppendix.onClick}>
                            <ListItemIcon>{listButtonAppendix.icon}</ListItemIcon>
                            <ListItemText>{listButtonAppendix.label}</ListItemText>
                        </ItemButton>
                }
            </MuiSelect>
            {
                loading &&
                <Loading>
                    <CircularProgress size={20} sx={{color: grey[400]}} />
                </Loading>
            }
            {
                meta.touched && meta.error &&
                <FormHelperText>{meta.error}</FormHelperText>
            }
        </Wrapper>
    );
};
export default Select;

export function fromStringArrToSelectOption(arr: string[], labelFunction?: (value: any)=>string): SelectOption[] {
    return arr.map((item)=>({label: labelFunction ? labelFunction(item) : item, key: item}));
}

export function useSelectRequest<T>(
    run: (controller?: AbortController)=>Promise<T | null>,
    mapper: (data: T)=>SelectOption[]
) {
    const [items, setItems] = useState<SelectOption[]>([]);

    useInitialRequest(useCallback(async (controller?: AbortController)=>{
        const data = await run(controller);
        if (data) setItems(mapper(data));
    }, [run, mapper]));

    return items;
}

type WrapperProps = {
    isLabel: boolean;
}
const Wrapper = styled(
    ({isLabel, ...rest}: FormControlProps & WrapperProps) => <FormControl {...rest} />
)<WrapperProps>(({theme, isLabel})=>css`
    ${!isLabel && css`
      & .MuiInput-root {
        margin-top: 0;
      }
    `}
`);

const Loading = styled(Box)(({theme})=>(css`
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    display: grid;
    place-items: center;
`));

const ItemButton = styled(ListItemButton)(({theme})=>css`
  & .MuiListItemIcon-root {
    color: ${theme.palette.text.primary};
  }
`);

const ClearableItem = styled(MenuItem)(({theme})=>css`
  & .MuiTypography-root {
    color: ${theme.palette.text.primary};
  }
  
  & .MuiListItemIcon-root {
    color: ${theme.palette.text.primary};
  }
`);