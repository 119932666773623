import {passParams} from "../props/helpers";
import {
    AccessibilityNew,
    AccountBalance, Archive,
    Badge,
    BusinessCenter,
    Dashboard,
    Description,
    EditNotifications,
    Euro,
    Handshake,
    LocalOffer,
    LocalShipping, LocationCity,
    Notifications,
    NotificationsActive,
    Person,
    Public,
    Receipt,
    Settings,
    Shop,
    ShoppingCart,
    Store,
    SvgIconComponent
} from "@mui/icons-material";
import {UserRole} from "../props/apiRequests";
import {matchPath, useLocation} from "react-router-dom";
import {useMemo} from "react";
import {Auth} from "../services/auth/AuthService";

export enum PAGE_KEY {
    DASHBOARD = "DashboardPage",

    USERS = "UsersPage",
    USERS_DETAIL = "UserDetailPage",
    PROFILE = "ProfilePage",

    CUSTOMERS = "CustomersPage",
    CUSTOMERS_DETAIL = "CustomersDetailPage",

    OPPORTUNITIES = "OpportunitiesPage",
    OPPORTUNITIES_DETAIL = "OpportunitiesDetailPage",

    OFFERS = "OffersPage",
    OFFERS_DETAIL = "OffersDetailPage",

    BUSINESS_CASES = "BusinessCasesPage",
    BUSINESS_CASES_DETAIL = "BusinessCasesDetailPage",

    DELIVERED_GOODS = "DeliveredGoodsPage",

    NOTIFICATIONS = "NotificationPage",
    NOTIFICATIONS_FOR_ME = "NotificationPageForMe",
    NOTIFICATIONS_ASSIGNED = "NotificationPageEntered",

    SUPPLIERS = "SuppliersPage",
    SUPPLIERS_DETAIL = "SuppliersDetailPage",

    PRODUCTS = "ProductsPage",
    PRODUCTS_SETTINGS = "ProductsSettingsPage",

    SETTINGS = "SettingsPage",

    BASIC_SETTINGS = "BasicSettingsPage",
    VAT_SETTINGS = "VatSettingsPage",
    DOCUMENTS_SETTINGS = "DocumentSettings",

    COUNTRIES = "CountriesPage",

    SEGMENTS = "SegmentsPage",

    CURRENCIES = "CurrenciesPage",
    CURRENCIES_DETAIL = "CurrenciesDetailPage",

    BANK_ACCOUNTS = "BankAccountsPage",

    MEETINGS = "MeetingsPage",

    INVOICES = "InvoicesPage"
}

/**
 * PAGE TYPE
 */

export interface PageDefinition {
    title: string;
    url: string;
    key: PAGE_KEY;
    icon: SvgIconComponent;
    permission?: UserRole[];
    parent?: PAGE_KEY;
}

const ProfilePageType: PageDefinition = {
    title: "Profil",
    url: "/profil",
    icon: Badge,
    key: PAGE_KEY.PROFILE
}


const DashboardPageType: PageDefinition = {
    title: "Dashboard",
    url: "",
    icon: Dashboard,
    key: PAGE_KEY.DASHBOARD
};

// CUSTOMERS

const CustomersPageType: PageDefinition = {
    key: PAGE_KEY.CUSTOMERS,
    title: "Zákazníci",
    icon: Store,
    url: "/zakaznici"
};

const CustomersDetailPageType: PageDefinition = {
    key: PAGE_KEY.CUSTOMERS_DETAIL,
    title: "Detail",
    icon: Store,
    url: `${CustomersPageType.url}/:id`,
    parent: PAGE_KEY.CUSTOMERS
};

// OPPORTUNITIES

const OpportunitiesPageType: PageDefinition = {
    key: PAGE_KEY.OPPORTUNITIES,
    title: "Příležitosti",
    url: "/prilezitosti",
    icon: AccessibilityNew
};

const OpportunitiesDetailPageType: PageDefinition = {
    key: PAGE_KEY.OPPORTUNITIES_DETAIL,
    title: "Detail",
    url: `${OpportunitiesPageType.url}/:id`,
    icon: AccessibilityNew,
    parent: PAGE_KEY.OPPORTUNITIES
};

// MEETINGS

const MeetingsDetailPageType: PageDefinition = {
    key: PAGE_KEY.MEETINGS,
    title: "Schůzky",
    url: "/schuzky",
    icon: Handshake
}

// OFFERS

const OffersPageType: PageDefinition = {
    key: PAGE_KEY.OFFERS,
    title: "Nabídky",
    url: "/nabidky",
    icon: LocalOffer
};

const OffersDetailPageType: PageDefinition = {
    key: PAGE_KEY.OFFERS_DETAIL,
    title: "Detail",
    url: `${OffersPageType.url}/:offerId/:versionId`,
    parent: PAGE_KEY.OFFERS,
    icon: LocalOffer
}

// BUSINESS CASES

const BusinessCasesPageType: PageDefinition = {
    key: PAGE_KEY.BUSINESS_CASES,
    title: "Obchodní případy",
    url: "/obchodni-pripady",
    icon: BusinessCenter
};

const BusinessCasesDetailPageType: PageDefinition = {
    key: PAGE_KEY.BUSINESS_CASES_DETAIL,
    title: "Detail",
    url: `${BusinessCasesPageType.url}/:id/:tab`,
    parent: PAGE_KEY.BUSINESS_CASES,
    icon: BusinessCenter
}

// DELIVERED GOODS

const DeliveredGoodsPageType: PageDefinition = {
    key: PAGE_KEY.DELIVERED_GOODS,
    title: "Dodané zboží",
    url: "/dodane-zbozi",
    icon: Archive
}

// NOTIFICATIONS

const NotificationsPageType: PageDefinition = {
    key: PAGE_KEY.NOTIFICATIONS,
    title: "Události",
    url: "/udalosti",
    icon: Notifications
};

const NotificationsForMePageType: PageDefinition = {
    key: PAGE_KEY.NOTIFICATIONS_FOR_ME,
    title: "Určené mně",
    url: `${NotificationsPageType.url}/urcene-mne`,
    icon: NotificationsActive,
    parent: PAGE_KEY.NOTIFICATIONS
};

const NotificationsAssignedPageType: PageDefinition = {
    key: PAGE_KEY.NOTIFICATIONS_ASSIGNED,
    title: "Mnou zadané",
    url: `${NotificationsPageType.url}/mnou-zadane`,
    icon: EditNotifications,
    parent: PAGE_KEY.NOTIFICATIONS
};

// SUPPLIERS

const SuppliersPageType: PageDefinition = {
    title: "Dodavatelé",
    key: PAGE_KEY.SUPPLIERS,
    url: "/dodavatele",
    icon: LocalShipping
};

const SuppliersDetailPageType: PageDefinition = {
    key: PAGE_KEY.SUPPLIERS_DETAIL,
    title: "Detail",
    url: `${SuppliersPageType.url}/:id`,
    parent: PAGE_KEY.SUPPLIERS,
    icon: LocalShipping
};

// PRODUCTS

const ProductsPageType: PageDefinition = {
    title: "Produkty",
    key: PAGE_KEY.PRODUCTS,
    url: "/produkty",
    icon: ShoppingCart
};

// SETTINGS

const SettingsPageType: PageDefinition = {
    title: "Nastavení",
    key: PAGE_KEY.SETTINGS,
    url: "/nastaveni",
    icon: Settings
};

const BasicSettingsPageType: PageDefinition = {
    title: "Obecné",
    key: PAGE_KEY.BASIC_SETTINGS,
    url: `${SettingsPageType.url}/obecne`,
    icon: SettingsPageType.icon,
    parent: PAGE_KEY.SETTINGS
};

const VatSettingsPageType: PageDefinition = {
    title: "DPH sazby",
    key: PAGE_KEY.VAT_SETTINGS,
    url: `${SettingsPageType.url}/dph`,
    icon: LocationCity,
    parent: PAGE_KEY.SETTINGS
};

const ProductsSettingsPageType: PageDefinition = {
    title: "Produkty",
    key: PAGE_KEY.PRODUCTS_SETTINGS,
    url: `${SettingsPageType.url}/produkty`,
    icon: ProductsPageType.icon,
    parent: PAGE_KEY.SETTINGS
};

const DocumentsSettingsPageType: PageDefinition = {
    title: "Dokumenty",
    key: PAGE_KEY.DOCUMENTS_SETTINGS,
    url: `${SettingsPageType.url}/dokumenty`,
    icon: Description,
    parent: PAGE_KEY.SETTINGS
};

const CountriesPageType: PageDefinition = {
    title: "Země",
    key: PAGE_KEY.COUNTRIES,
    url: `${SettingsPageType.url}/zeme`,
    icon: Public,
    parent: PAGE_KEY.SETTINGS
};

const SegmentsPageType: PageDefinition = {
    title: "Segmenty",
    key: PAGE_KEY.SEGMENTS,
    url: `${SettingsPageType.url}/segmenty`,
    icon: Shop,
    parent: PAGE_KEY.SETTINGS
};

const UsersPageType: PageDefinition = {
    title: "Uživatelé",
    permission: [UserRole.ADMIN],
    key: PAGE_KEY.USERS,
    icon: Person,
    url: `${SettingsPageType.url}/uzivatele`,
    parent: PAGE_KEY.SETTINGS
};

const UsersDetailPageType: PageDefinition = {
    title: "Detail",
    permission: [UserRole.ADMIN],
    key: PAGE_KEY.USERS_DETAIL,
    icon: Person,
    url: `${UsersPageType.url}/:id`,
    parent: PAGE_KEY.USERS
};

const CurrenciesPageType: PageDefinition = {
    title: "Měny",
    key: PAGE_KEY.CURRENCIES,
    url: `${SettingsPageType.url}/meny`,
    icon: Euro,
    parent: PAGE_KEY.SETTINGS
};

const CurrenciesDetailPageType: PageDefinition = {
    title: "Detail",
    key: PAGE_KEY.CURRENCIES_DETAIL,
    url: `${SettingsPageType.url}/meny/:id`,
    icon: Euro,
    parent: PAGE_KEY.CURRENCIES
};

const BankAccountsPageType: PageDefinition = {
    title: "Bankovní účty",
    key: PAGE_KEY.BANK_ACCOUNTS,
    url: `${SettingsPageType.url}/bankovni-ucty`,
    icon: AccountBalance,
    parent: PAGE_KEY.SETTINGS
};

// INVOICES

const InvoicesPageType: PageDefinition = {
    title: "Faktury",
    key: PAGE_KEY.INVOICES,
    url: "/faktury",
    icon: Receipt
};

/**
 * GLOBAL
 */

export const PAGES: {
    [key in PAGE_KEY]: PageDefinition;
} = {
    [PAGE_KEY.DASHBOARD]: DashboardPageType,

    [PAGE_KEY.USERS]: UsersPageType,
    [PAGE_KEY.USERS_DETAIL]: UsersDetailPageType,
    [PAGE_KEY.PROFILE]: ProfilePageType,

    [PAGE_KEY.CUSTOMERS]: CustomersPageType,
    [PAGE_KEY.CUSTOMERS_DETAIL]: CustomersDetailPageType,

    [PAGE_KEY.OPPORTUNITIES]: OpportunitiesPageType,
    [PAGE_KEY.OPPORTUNITIES_DETAIL]: OpportunitiesDetailPageType,

    [PAGE_KEY.OFFERS]: OffersPageType,
    [PAGE_KEY.OFFERS_DETAIL]: OffersDetailPageType,

    [PAGE_KEY.BUSINESS_CASES]: BusinessCasesPageType,
    [PAGE_KEY.BUSINESS_CASES_DETAIL]: BusinessCasesDetailPageType,

    [PAGE_KEY.DELIVERED_GOODS]: DeliveredGoodsPageType,

    [PAGE_KEY.NOTIFICATIONS]: NotificationsPageType,
    [PAGE_KEY.NOTIFICATIONS_FOR_ME]: NotificationsForMePageType,
    [PAGE_KEY.NOTIFICATIONS_ASSIGNED]: NotificationsAssignedPageType,

    [PAGE_KEY.SUPPLIERS]: SuppliersPageType,
    [PAGE_KEY.SUPPLIERS_DETAIL]: SuppliersDetailPageType,

    [PAGE_KEY.PRODUCTS]: ProductsPageType,
    [PAGE_KEY.PRODUCTS_SETTINGS]: ProductsSettingsPageType,

    [PAGE_KEY.SETTINGS]: SettingsPageType,
    [PAGE_KEY.BASIC_SETTINGS]: BasicSettingsPageType,
    [PAGE_KEY.VAT_SETTINGS]: VatSettingsPageType,
    [PAGE_KEY.DOCUMENTS_SETTINGS]: DocumentsSettingsPageType,
    [PAGE_KEY.COUNTRIES]: CountriesPageType,
    [PAGE_KEY.SEGMENTS]: SegmentsPageType,

    [PAGE_KEY.CURRENCIES]: CurrenciesPageType,
    [PAGE_KEY.CURRENCIES_DETAIL]: CurrenciesDetailPageType,

    [PAGE_KEY.BANK_ACCOUNTS]: BankAccountsPageType,

    [PAGE_KEY.MEETINGS]: MeetingsDetailPageType,

    [PAGE_KEY.INVOICES]: InvoicesPageType
};

export function passURL(key: PAGE_KEY, params?:{[key in string | number]: string | number}): string {
    const {url} = PAGES[key];
    if (!params) return url;
    return passParams(url, params);
}

export function usePage(): {
    pagesList: PageDefinition[];
    currentPage: PageDefinition;
    } {
    const location = useLocation();

    const result = useMemo(()=>{
        let page = PAGES[PAGE_KEY.DASHBOARD];
        for (let p of Object.values(PAGES)) {
            if (matchPath(p.url, location.pathname)) {
                page=p;
                break;
            }
        }
        const pagesList: PageDefinition[] = [page];
        let tmpPage: PageDefinition = page;
        while (!!tmpPage.parent) {
            tmpPage = PAGES[tmpPage.parent];
            pagesList.push(tmpPage);
        }

        return {pagesList: pagesList.reverse(), page};
    }, [location]);

    return useMemo(()=>({pagesList: result.pagesList, currentPage: result.page}), [result]);
}

export function checkPagePermission(pageKey: PAGE_KEY, auth: Auth): boolean {
    const page = PAGES[pageKey];
    return (!page.permission || auth.isInRole(page.permission));
}
