import {FC} from "react";
import Select, {SelectProps} from "./Select";
import {useCountrySelect} from "../../../services/country/CountryService";

const CountrySelect: FC<SelectProps> = (props) => {
    const {countries, loading} = useCountrySelect();

    return (
        <Select
            options={countries}
            loading={loading}

            {...props}
        />
    );
};
export default CountrySelect;