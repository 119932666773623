import {Box, css, Divider, styled, Typography} from "@mui/material";
import {Frame} from "../../../../common/Frame";
import React, {useCallback, useContext, useMemo} from "react";
import {InvoiceRequest, InvoiceResponse} from "../../../../containers/trade/businessCase/schema/invoice";
import CustomerItemsDataGrid, {BusinessCaseRow, useCustomerItemsGrid} from "../../CustomerItemsDataGrid";
import {ActionsFormatter, PredefinedAction} from "../../../../common/dataGrid/formatter/ActionsFormatter";
import {BusinessCaseContext, useGeneratedDocument, useUpdateTerms} from "../../props";
import TradeItemFormDialog, {useTradeItemFormDialog} from "../../../../form/trade/TradeItemFormDialog";
import BlockName from "../BlockName";
import {ConfigurationDocumentTextType, DocumentType, Language} from "../../../../props/apiRequests";
import {BusinessCaseItemResponse, DocumentResponse, PricingResponse} from "../../../../props/apiResponses";
import {downloadDocument} from "../../../../services/DocumentService";
import {useBusinessCaseDocumentManipulator} from "../../../../services/businessCase/BusinessCaseService";
import CreateOrOpenPDF from "../CreateOrOpenPDF";
import {Items} from "../../../../components/item/Items";
import Grid from "../../../../components/Grid";
import Summary, {SummaryProps} from "../../../trade/Summary";
import {priceFormatter} from "../../../../common/dataGrid/formatter/PriceFormatter";
import DocumentTextTypeChooser from "../../../../form/fields/DocumentTextTypeChooser";
import TermsFrameForm from "../TermsFrameForm";
import InvoicePropertiesForm, {useInvoicePropertiesForm} from "../../../../form/businessCase/InvoicePropertiesForm";
import usePutInvoice from "../../../../containers/trade/businessCase/services/usePutInvoice";
import InvoiceDiscountForm, {
    useInvoiceDiscountForm
} from "../../../../containers/trade/businessCase/forms/InvoiceDiscountForm";

export interface InvoiceItemProps {
    data: InvoiceResponse;
    index: number;
    remainingDiscount?: number;
}

interface InvoiceDiscountFormWrapperProps {
    remainingDiscount: number;
    data: InvoiceResponse;
    disabled?: boolean;
}

const InvoiceDiscountFormWrapper = ({ remainingDiscount, data, disabled }: InvoiceDiscountFormWrapperProps) => {
    const invoiceDiscountForm = useInvoiceDiscountForm({
        invoice: data,
        disabled
    });
    return (
        <Box sx={{mb: 4}}>
            <InvoiceDiscountForm remainingDiscount={remainingDiscount} form={invoiceDiscountForm} />
        </Box>
    );
};

const InvoiceItem = (
    {
        data,
        index,
        remainingDiscount
    }: InvoiceItemProps
) => {
    const context = useContext(BusinessCaseContext);
    const itemDialog = useTradeItemFormDialog({
        businessCaseId: context?.data?.id,
        isOffer: false,
        orderToSupplier: true,
        invoice: true
    });
    const documentManipulator = useBusinessCaseDocumentManipulator();
    const introForm = useUpdateTerms("Úvod", "introductionInvoice", undefined, data);
    const termsForm = useUpdateTerms("Dodatek", "paymentTermsInvoice", undefined, data);
    const invoicePropertiesForm = useInvoicePropertiesForm({invoice: data});
    const putInvoice = usePutInvoice();

    const handleDetail = useCallback((row: BusinessCaseRow)=>{
        itemDialog.handleOpen(row.order, row.item);
    }, [itemDialog]);

    const items = useMemo<BusinessCaseItemResponse[]>(()=>context?.data?.items?.filter(item => item.deliveryNoteId===data.deliveryNoteId) ?? [], [context?.data?.items]);

    const itemsGrid = useCustomerItemsGrid(useCallback((row)=>ActionsFormatter([
        [context?.finished ? PredefinedAction.READONLY : PredefinedAction.EDIT, ()=>handleDetail(row)]
    ]), [context?.finished]), "invoiceDescription", items);

    const [downloadGeneratedDocument, wasGeneratedDocument] = useGeneratedDocument(DocumentType.CUSTOMER_INVOICE, context?.data?.documents, documentManipulator.get.run, undefined, data);

    const generateInvoice = useCallback(async ()=>{
        const businessCaseId = context?.data?.id;
        if (businessCaseId===undefined || !context?.data || !context?.setData) return;
        const result = await documentManipulator.getInvoice.run({businessCaseId, invoiceId: Number(data.id)});
        if (result !== null) {
            const documents: DocumentResponse[] = context.data.documents.filter((document)=>document.type!==DocumentType.CUSTOMER_INVOICE || document.invoiceId!==data.id);
            context?.setData({...context.data, documents: [...documents, result]});
            downloadDocument(result);
        }
    }, [context, documentManipulator]);

    const savePricing = useCallback<Required<SummaryProps<BusinessCaseItemResponse>>["savePricing"]>(async (pricing)=>{
        if (!context?.setData || !context?.data) return false;

        const request: InvoiceRequest = {
            ...data,
            pricing: {
                ...data.pricing,
                ...pricing
            }
        };

        try {
            const response = await putInvoice.mutateAsync({ data: request, id: data.id });
            context.setData({
                ...context.data,
                invoices: context.data.invoices.map(i => {
                    if (i.id===response.id) return response;
                    return i;
                }) ?? []
            });
            return true;
        } catch (e) {
            //
        }

        return false;
    }, [context]);

    const pricing = useMemo<PricingResponse>(()=>({
        ...data.pricing,
        discountPercentage: context?.data?.pricing.discountPercentage
    }), [data.pricing, context?.data?.pricing.discountPercentage]);

    introForm.props.disabled = !!context?.finished;
    termsForm.props.disabled = !!context?.finished;

    if (!context?.data) return null;

    return (
        <Wrapper>
            <BlockName>
                <Typography variant="h5" color="primary" sx={{display: "flex", alignItems: "center"}}>
                    Faktura {data.invoiceNumber ?? index}
                    {!data.invoiceNumber && <Typography variant="body1" component="span" sx={{ml: 1}}>(bez čísla)</Typography>}
                </Typography>
                <Items>
                    <CreateOrOpenPDF
                        generate={generateInvoice}
                        loading={documentManipulator.loading}
                        downloadGenerated={downloadGeneratedDocument}
                        wasGenerated={wasGeneratedDocument}
                    />
                </Items>
            </BlockName>
            <Box>
                <InvoicePropertiesForm {...invoicePropertiesForm.props} disabled={context?.finished} />
                <CustomerItemsDataGrid
                    {...itemsGrid.props}
                    onRowDoubleClick={handleDetail}
                    DataGridProps={{
                        topActionsAlignment: "flex-end"
                    }}
                />
            </Box>
            <Grid container spacing={1}>
                <Grid xs={12} md={7}>
                    <TermsFrameForm
                        termsFormProps={introForm}
                        sx={{maxWidth: "unset"}}
                        RichTextFieldProps={{
                            controls: (
                                <DocumentTextTypeChooser
                                    documentType={DocumentType.CUSTOMER_INVOICE}
                                    documentTextType={ConfigurationDocumentTextType.PRE_TABLE}
                                    language={context?.data?.language ?? Language.CZECH}
                                    textFieldName="terms"
                                    disabled={context?.finished}
                                />
                            )
                        }}
                    />
                    <TermsFrameForm
                        termsFormProps={termsForm}
                        sx={{maxWidth: "unset"}}
                        RichTextFieldProps={{
                            controls: (
                                <DocumentTextTypeChooser
                                    documentType={DocumentType.CUSTOMER_INVOICE}
                                    documentTextType={ConfigurationDocumentTextType.APPENDIX}
                                    language={context?.data?.language ?? Language.CZECH}
                                    textFieldName="terms"
                                    disabled={context?.finished}
                                />
                            )
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5}>
                    {
                        remainingDiscount!==undefined && <InvoiceDiscountFormWrapper
                            key={remainingDiscount}
                            remainingDiscount={remainingDiscount}
                            data={data}
                            disabled={context?.finished}
                        />
                    }
                    <Summary
                        formatPrice={(price)=>priceFormatter(price, data.pricing.currency.code)}
                        loading={false}
                        items={items}
                        pricing={pricing}
                        savePricing={savePricing}
                    />
                    {
                        data.pricing.currency.code!=="CZK" &&
                        <>
                            <Divider sx={{mt:1}} />
                            <Summary
                                formatPrice={(price)=>priceFormatter(price,"CZK")}
                                loading={false}
                                items={items}
                                pricing={pricing}
                                exchangeRateCzk={data.exchangeRateCzk}
                            />
                        </>
                    }
                </Grid>
            </Grid>
            <TradeItemFormDialog
                {...itemDialog.props}
                currenciesRates={context.exchangeRates ?? []}
                currentCurrency={data.pricing?.currency}
                afterSave={context.afterSaveItem}
                disabled={context?.finished}
            />
        </Wrapper>
    );
};

export default InvoiceItem;

const Wrapper = styled(Frame)(({theme})=>css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`);
