import React, {
    MutableRefObject,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState
} from "react";
import {Column} from "react-data-grid";
import BaseMenu from "./menu/BaseMenu";

export type ContextMenuRef<Row> = {row: Row, column?: Column<Row>};

export type HandleContextMenu<Row> = (event: React.MouseEvent<HTMLElement>, contextMenuRef: ContextMenuRef<Row>) => void;

export type GridContextMenuControls<Row> = {
    handleContextMenu: HandleContextMenu<Row>;
    contextMenu: {mouseX: number, mouseY: number} | null;
    handleClose: ()=>void;
};

export interface GridContextMenuElementProps<Row> {
    data: MutableRefObject<ContextMenuRef<Row> | undefined>;
    controls: GridContextMenuControls<Row>;
    visible: boolean;
}

export type GridContextMenuDefinition<Row> = {
    when: (ref: ContextMenuRef<Row>)=>boolean;
    component: (props: GridContextMenuElementProps<Row>)=>ReactElement<GridContextMenuElementProps<Row>>;
    key: string;
    prioritize?: boolean;
};

type GridContextMenuDefinitions<Row> = {
    main?: Omit<GridContextMenuDefinition<Row>, "when"> & {disabled?: (data: ContextMenuRef<Row>)=>boolean};
    definitions?: GridContextMenuDefinition<Row>[];
};

export type GridContextMenu<Row> = {
    ref: MutableRefObject<ContextMenuRef<Row> | undefined>;
    definitions?: GridContextMenuDefinitions<Row>;
}

export function useGridContextMenuInner<Row>(
    columns: readonly Column<Row>[],
    props?: GridContextMenu<Row>
): {
    controls: GridContextMenuControls<Row>;
    menusElements: ReactNode;
} {
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const visibleMenu = useRef<string>();

    const handleClose = useCallback(() => {
        setContextMenu(null);
    }, []);

    const determineWhichMenu = useCallback((): string => {
        const main: string = "main";
        if (!props?.ref.current) return main;

        if (!!props.definitions?.definitions) {
            let deposit: string | undefined = undefined;
            for (let definition of props.definitions.definitions) {
                if (definition.when(props.ref.current)) {
                    if (definition.prioritize) return definition.key;
                    deposit = definition.key;
                }
            }
            if (deposit!==undefined) return deposit;
        }

        return main;
    }, [props]);

    const handleContextMenu = useCallback((event: React.MouseEvent<HTMLElement>, ref: ContextMenuRef<Row>) => {
        event.preventDefault();
        let cellEl: HTMLElement = event.target as HTMLElement;
        if (!cellEl.classList.contains("rdg-cell"))
            cellEl = cellEl.closest(".rdg-cell") as HTMLElement;
        const colIndex: number = Number(cellEl.getAttribute("aria-colindex"));
        let column: Column<Row> | undefined = undefined;
        if (!isNaN(colIndex)) column = columns[colIndex-1];

        if (column && column.key==="actions") return;

        if (!!props) {
            const data = {row: ref.row, column};
            const menuKey = determineWhichMenu();
            if (menuKey==="main" && (!!props?.definitions?.main?.disabled && props.definitions.main.disabled(data)))
                return;

            visibleMenu.current = menuKey;
            props.ref.current = data;
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
        }
    }, [props, contextMenu, determineWhichMenu, columns]);

    const controls: GridContextMenuControls<Row> = useMemo(()=>({
        handleContextMenu,
        contextMenu,
        handleClose
    }), [handleContextMenu, contextMenu, handleClose]);

    const menusElements: ReactNode = useMemo(()=>{
        if (!props) return null;

        const keyMatch = (key: string) => visibleMenu.current===key;

        let allMenus: ReactNode[] = [];
        const definitions = props.definitions;
        const elementProps = {data: props.ref, controls};
        const pushToAll = (def: Omit<GridContextMenuDefinition<Row>, "when">)=>
            allMenus.push(def.component({visible: keyMatch(def.key), ...elementProps}));

        if (definitions?.main) pushToAll(definitions.main);
        if (definitions?.definitions) definitions.definitions.forEach(pushToAll);

        return allMenus;
    }, [controls, props]);

    return useMemo(()=>({
        controls,
        menusElements
    }), [controls, menusElements]);
}


export default function useGridContextMenu<Row>(
    definitions?: GridContextMenuDefinitions<Row>
): {
    contextMenu: GridContextMenu<Row>;
} {
    const ref = useRef<ContextMenuRef<Row>>();

    return useMemo(()=>({
        contextMenu: {
            ref,
            definitions
        },
    }), [definitions]);
}