import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {DecodedValueMap} from "use-query-params";
import {ChangeQuery, QueryBase} from "./props";
import {useTimerEffect, useTimerExecution} from "../../props/useTimerEffect";
import {Timeout} from "../../props/constants";

export const PAGE_SIZE: number = 100;

export type RowPagination = {
    handleScroll: (event: React.UIEvent<HTMLDivElement>)=>void,
    handleDataPage: (responseNumberOfItems: number, dataPage: number)=>void,
    resetPage: ()=>void;
    loading: boolean;
    page: MutableRefObject<number>;
};

export function useRowPagination(
    request: (paginationOnly?: boolean)=>Promise<void>,
    query: DecodedValueMap<QueryBase>,
    handleChangeQuery: ChangeQuery,
    dataGridClassName?: string
): RowPagination {
    const pending = useRef<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const page = useRef<number>(query.page ?? 1);
    const dataPage = useRef<number>(1);
    const allDataLoaded = useRef<boolean>(false);
    const scrollRef = useRef<number>(0);

    const numberChange = useCallback((newPage: number, withoutQuery?: boolean)=>{
        page.current = newPage;
        if (!withoutQuery) handleChangeQuery({page: newPage});
    }, [handleChangeQuery]);

    const changePage = useCallback(async (newPage: number)=>{
        if (pending.current) return;

        setLoading(true);
        pending.current = true;
        numberChange(newPage);
        await request(true);
        pending.current = false;
        setLoading(false);
    }, [request, numberChange]);

    const observerEl = useRef<HTMLElement | null>(null);
    const intersectionObserver = useMemo(()=>new IntersectionObserver(async () => {
        if (page.current<dataPage.current) await changePage(dataPage.current+1);
        else await changePage(page.current+1);
        if (observerEl.current) intersectionObserver.unobserve(observerEl.current);
    }, { threshold: 0 }), [changePage]);

    const timerExecution = useTimerExecution();
    const handleScroll = useCallback( (event: React.UIEvent<HTMLDivElement>) => {
        if (scrollRef.current===event.currentTarget.scrollTop) return;
        const scrollTop = event.currentTarget.scrollTop;
        timerExecution(()=>{
            scrollRef.current = scrollTop;
            handleChangeQuery({scroll: scrollRef.current}, "replaceIn");
        });
    }, [handleChangeQuery, timerExecution]);

    const getDataGrid = useCallback(()=>{
        return document.querySelector(dataGridClassName ?? ".rdg");
    }, [dataGridClassName]);

    const handleDataPage = useCallback((responseNumberOfItems: number, responsePage: number) => {
        dataPage.current = responsePage;
        allDataLoaded.current = responseNumberOfItems<PAGE_SIZE;
    }, []);

    useEffect(()=>{
        const element = getDataGrid();
        if (!element) return;

        const initialObserver = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type==="childList") {
                    if (query.scroll) element.scrollTop = Number(query.scroll);
                    initialObserver.disconnect();
                }
            });
        });

        const pageEndObserver = new MutationObserver(function(mutations) {
            mutations.forEach(async (mutation) => {
                if (mutation.type==="childList") {
                    for (const node of mutation.addedNodes) {
                        const el: HTMLElement = node as HTMLElement;
                        if (Number(el.getAttribute("aria-rowindex"))===page.current*PAGE_SIZE) {
                            if (pending.current || allDataLoaded.current) return;
                            if (page.current<dataPage.current) await changePage(dataPage.current+1);
                            else await changePage(page.current+1);
                        }
                    }
                }
            });
        });

        initialObserver.observe(element, {childList: true});
        pageEndObserver.observe(element, {childList: true});
        return ()=>{
            initialObserver.disconnect();
            pageEndObserver.disconnect();
        };
    }, []);

    const resetPage = useCallback( ()=>{
        const element = getDataGrid();
        if (element) {
            scrollRef.current = 0;
            element.scrollTop = 0;
        }
        numberChange(1, true);
    }, [getDataGrid, numberChange]);

    return useMemo(()=>({
        handleScroll,
        handleDataPage,
        resetPage,
        loading,
        page
    }), [handleScroll, handleDataPage, resetPage, loading]);
}