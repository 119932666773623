import {FC, ReactElement, useMemo, useRef, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {Box, css, FormControl, FormHelperText, InputLabel, styled, useTheme} from "@mui/material";
import {FieldProps} from "../props";
import {useField} from "formik";
import RectangularSkeleton from "../../common/RectangularSkeleton";

export interface RichTextFieldProps extends FieldProps {
    height?: number;
    disabled?: boolean;
    controls?: ReactElement;
}
const RichTextField: FC<RichTextFieldProps> = (
    {
        height,
        name,
        required,
        label,
        className,
        disabled,
        controls
    }
) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [field, meta, helpers] = useField(name ?? "");

    const theme = useTheme();

    const contentStyle = useMemo(()=>css` 
      body {
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.body1.fontSize};
        color: ${theme.palette.text.primary};
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    `, [theme]);

    height = height ?? 100;

    const labelComponent = <InputLabel>{label}</InputLabel>;

    return (
        <Wrapper fullWidth className={className} error={meta.touched && !!meta.error}>
            {
                !!controls && <Header>{labelComponent}{controls}</Header>
            }
            {
                !controls && labelComponent
            }
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onEditorChange={(value)=>{
                    helpers.setValue(value, true);
                    helpers.setTouched(true);
                }}
                onInit={() => setIsLoaded(true)}
                value={field.value}
                disabled={disabled}
                init={{
                    height: height,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'preview', 'anchor', 'wordcount', 'table', 'fullscreen', 'link'
                    ],
                    toolbar: disabled ? false : 'undo redo | bold italic forecolor removeformat | link | table | bullist numlist | fullscreen',
                    content_style: contentStyle.styles,
                    language: "cs",
                    indent: false,
                    entity_encoding: "raw",
                    skin: "custom",
                    statusbar: false
                }}
            />
            {
                !isLoaded &&
                    <SkeletonWrapper>
                        <RectangularSkeleton height={height} />
                    </SkeletonWrapper>
            }
            {
                meta.touched && meta.error &&
                <FormHelperText>{meta.error}</FormHelperText>
            }
        </Wrapper>
    );
};
export default RichTextField;

const Wrapper = styled(FormControl)(({theme, error})=>css`
  display: flex;
  flex-direction: column;
  position: relative;
  
  & .MuiInputLabel-root {
    position: relative;
    transform: none;
    font-size: ${theme.typography.overline.fontSize};
    margin-bottom: ${theme.spacing(0.5)};
  }
  
  & > div {
    flex-grow: 1;
    
    ${error && css`
      border-color: ${theme.palette.error.main};
    `}
  }
  
  & .MuiFormHelperText-root {
    margin-left: 0;
  }
`);

const SkeletonWrapper = styled(Box)(({theme})=>css`
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  z-index: 1;
  background: ${theme.palette.background.default};
  display: flex;
  flex-direction: column;
`);

const Header = styled(Box)(({theme})=>css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`);
