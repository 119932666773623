import {createElement, FC} from "react";
import Select, {SelectProps} from "./Select";
import {useBankAccountSelect} from "../../../services/bankAccount/BankAccountService";
import {PersonAdd} from "@mui/icons-material";
import {PAGE_KEY, PAGES} from "../../../mainUI/pages";
import {ContactPersonResponse} from "../../../props/apiResponses";

type AddedProps = {
    handleBankAccountsManagement?: ()=>void;
}

type Props = SelectProps & AddedProps;

const BankAccountSelect: FC<Props> = ({handleBankAccountsManagement, ...props}) => {
    const {accounts, loading} = useBankAccountSelect();

    return (
        <Select
            options={accounts}
            loading={loading}

            listButtonAppendix={!!handleBankAccountsManagement ? {
                icon: createElement(PAGES[PAGE_KEY.BANK_ACCOUNTS].icon),
                label: "Spravovat účty",
                onClick: handleBankAccountsManagement
            } : undefined}

            {...props}
        />
    );
};
export default BankAccountSelect;