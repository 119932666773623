import React, {FC, useCallback, useContext, useMemo} from "react";
import {Asserts, date, object, string} from "yup";
import {Formik, FormikValues} from "formik";
import {FORM_SPACING, FormProps, FormReturnProps, OnSubmit, SubmitOnChange, useForm} from "../props";
import {clearNumberInput, notEmpty, useHistory} from "../../props/helpers";
import BankAccountSelect from "../fields/select/BankAccountSelect";
import {PAGE_KEY, passURL} from "../../mainUI/pages";
import {Grid} from "@mui/material";
import {BusinessCaseContext} from "../../pages/businessCase/props";
import {useMessages} from "../../props/messagesHandler";
import {BusinessCaseRequest, idSchemaObject} from "../../props/apiRequests";
import {Messages} from "../../props/messages";
import DateField from "../fields/DateField";
import Form from "../Form";
import TextField from "../fields/TextField";
import {InvoiceRequest, InvoiceResponse} from "../../containers/trade/businessCase/schema/invoice";
import usePutInvoice from "../../containers/trade/businessCase/services/usePutInvoice";

const schema = {
    billingDate: date().nullable(),
    paymentDueDate: date().nullable(),
    taxableSupplyDate: date().nullable(),
    bankAccount: idSchemaObject.required(),
    invoiceNumber: string()
};
const schemaObject = object(schema);

interface InvoicePropertiesFormValues extends Asserts<typeof schemaObject>, FormikValues {
}

interface Props extends FormProps<InvoicePropertiesFormValues> {
    disabled?: boolean;
    invoice: InvoiceResponse;
}
const InvoicePropertiesForm: FC<Props> = (
    {
        onSubmit,
        loading,
        disabled,
        invoice
    }
) => {
    const history = useHistory();

    const initValues = useCallback((): InvoicePropertiesFormValues=>({
        billingDate: invoice?.billingDate ?? new Date(),
        paymentIdentifier: invoice?.paymentIdentifier,
        paymentDueDate: invoice?.paymentDueDate ?? new Date(),
        taxableSupplyDate: invoice?.taxableSupplyDate ?? new Date(),
        bankAccount: invoice?.bankAccount ?? {id: clearNumberInput()},
        invoiceNumber: invoice?.invoiceNumber ?? ""
    }), []);

    return (
        <Formik
            initialValues={initValues()}
            onSubmit={onSubmit}
            key="bankAccount"
        >
            <Form>
                {
                    !disabled && <SubmitOnChange />
                }
                <Grid container spacing={FORM_SPACING} maxWidth={1000}>
                    <Grid item xs={12} md={2}>
                        <TextField
                            label="Číslo faktury"
                            name="invoiceNumber"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <BankAccountSelect
                            name="bankAccount.id"
                            label="Bankovní účet"
                            handleBankAccountsManagement={()=>history.push(passURL(PAGE_KEY.BANK_ACCOUNTS))}
                            disabled={disabled}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            label="Variabilní symbol"
                            name="paymentIdentifier"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <DateField
                            label="Datum fakturace"
                            name="billingDate"
                            DatePickerProps={{disabled}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <DateField
                            label="Datum splatnosti"
                            name="paymentDueDate"
                            DatePickerProps={{disabled}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <DateField
                            label="Datum zdanitelného plnění"
                            name="taxableSupplyDate"
                            DatePickerProps={{disabled}}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};
export default InvoicePropertiesForm;

export interface InvoicePropertiesFormReturnProps extends FormReturnProps<InvoicePropertiesFormValues> {
    props: Props;
}

export interface UseInvoicePropertiesFormArgs {
    invoice: InvoiceResponse;
}

export function useInvoicePropertiesForm({ invoice }: UseInvoicePropertiesFormArgs): InvoicePropertiesFormReturnProps {
    const context = useContext(BusinessCaseContext);
    const {success} = useMessages();
    const update = usePutInvoice();

    const onSubmit: OnSubmit<InvoicePropertiesFormValues> = useCallback( async (values, formikHelpers) => {
        if (!context?.data || !context?.setData) return;

        const data: InvoiceRequest = {
            ...invoice,
            ...values
        };

        try {
            const response = await update.mutateAsync({id: invoice.id, data});
            formikHelpers.resetForm({values});
            context.setData((oldData)=>!oldData ? undefined : ({
                ...oldData,
                invoices: oldData.invoices.map(i=>{
                    if (i.id===response.id) return response;
                    else return i;
                })
            }));
            success(Messages.SAVED);
        } catch (e) {
            //
        }
    }, [context, success]);

    const {props} = useForm(onSubmit, !!context?.saveCaseLoading);

    return useMemo(()=>({
        props: {
            ...props,
            invoice
        }
    }), [props, invoice]);
}
