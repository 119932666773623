import useRequest, {RequestOpt} from "../RequestService";
import {FileRP, ProductRequest} from "../../props/apiRequests";
import {ProductResponse} from "../../props/apiResponses";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {useMemo} from "react";
import {Messages} from "../../props/messages";
import {useCRUDRequest} from "../CRUDRequestService";


const PRODUCT_API = "/products";

interface PostProductsImportRP extends Omit<FileRP, "metadata"> {
    supplierId: number;
    preview: boolean;
}
class ProductService extends GridCRUDServiceImpl<ProductRequest, ProductResponse> {
    public import = ({file, preview, supplierId}: PostProductsImportRP) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("supplierId", supplierId.toString());
        formData.append("preview", preview ? "true" : "false");
        return this.upload(this.addressWithId()+"/import", formData);
    };
}
const productService = new ProductService(PRODUCT_API);

const errors = {
    409: {
        default: Messages.DUPLICATED_PRODUCT
    }
};
export function useProductManipulator(opt?: RequestOpt) {
    return useCRUDRequest<ProductResponse, ProductService>(productService, {create: errors, update: errors}, {all: opt});
}

export function useProductsManipulator(globalLoading: boolean) {
    return useGridRequest<ProductResponse>(productService, globalLoading);
}

export function useProductsImportManipulator() {
    const upload = useRequest(productService.import);

    return useMemo(()=>({
        upload,
        loading: upload.loading
    }), [upload]);
}