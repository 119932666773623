import {FC, useCallback, useContext, useMemo, useRef} from "react";
import {Formik, FormikProps, FormikValues} from "formik";
import {
    ConfigurationDocumentTextType,
    DocumentType,
    Language,
    ShippingDestination,
    SupplierOrderRequest,
    supplierOrderSchemaObject
} from "../../props/apiRequests";
import {FORM_SPACING, FormOpt, FormProps, FormReturnProps, ListenChange, OnSubmit, useForm} from "../props";
import {clearNumberInput} from "../../props/helpers";
import {SupplierOrderResponse} from "../../props/apiResponses";
import {Grid, SelectChangeEvent} from "@mui/material";
import TextField from "../fields/TextField";
import AddressLine from "../fields/AddressLine";
import {convertFromPercentage, convertToPercentage} from "../fields/NumberTextField";
import CountrySelect from "../fields/select/CountrySelect";
import SaveGridItem from "../SaveGridItem";
import RichTextField from "../fields/RichTextField";
import {useSupplierOrderManipulator} from "../../services/businessCase/BusinessCaseService";
import {Messages} from "../../props/messages";
import Select from "../fields/select/Select";
import {languageOptions, shippingDestinationOptions} from "../fields/select/options";
import Form from "../Form";
import {Frame} from "../../common/Frame";
import DocumentTextTypeChooser from "../fields/DocumentTextTypeChooser";
import DiscountField from "../fields/DiscountField";
import {BusinessCaseContext} from "../../pages/businessCase/props";

export interface SupplierOrderFormValues extends FormikValues, Omit<SupplierOrderRequest, "supplierId" | "businessCaseId"> {
    isDiscountInPercentage: boolean;
}

interface Props extends FormProps<SupplierOrderFormValues> {
    supplierOrder?: SupplierOrderResponse;
    onValuesChange?: (values: SupplierOrderFormValues)=>void;
    disabled?: boolean;
    priceBeforeDiscount: number;
}
const SupplierOrderForm: FC<Props> = (
    {
        onSubmit,
        supplierOrder,
        loading,
        onValuesChange,
        disabled,
        priceBeforeDiscount
    }
) => {
    const context = useContext(BusinessCaseContext);
    const formikRef = useRef<FormikProps<SupplierOrderFormValues>>(null);

    const initValues = useCallback((): SupplierOrderFormValues=>({
        discountPercentage: !!supplierOrder?.discountPercentage ? Number(convertFromPercentage(supplierOrder.discountPercentage)) : 0,
        isDiscountInPercentage: !supplierOrder?.discountValue || !!supplierOrder?.discountPercentage,
        discountValue: supplierOrder?.discountValue ?? 0,
        supplierOrderNumber: supplierOrder?.supplierOrderNumber ?? 0,
        supplierOrderCode: supplierOrder?.supplierOrderCode ?? "",
        paymentTerms: supplierOrder?.paymentTerms ?? "",
        shippingAddress: {
            street: supplierOrder?.shippingAddress?.street ?? "",
            city: supplierOrder?.shippingAddress?.city ?? "",
            postcode: supplierOrder?.shippingAddress?.postcode ?? "",
            type: supplierOrder?.shippingAddress?.type ?? "",
            country: {id: supplierOrder?.shippingAddress?.country.id ?? clearNumberInput()}
        },
        shippingDestination: supplierOrder?.shippingDestination ?? ShippingDestination.CUSTOMER,
        shippingCompanyName: supplierOrder?.shippingCompanyName ?? "",
        shippingContact: supplierOrder?.shippingContact ?? "",
        shippingPhone: supplierOrder?.shippingPhone ?? "",
        shippingEmail: supplierOrder?.shippingEmail ?? "",
        language: supplierOrder?.language ?? Language.CZECH,
        currency: supplierOrder?.currency ?? {id: clearNumberInput()},
        orderReference: supplierOrder?.orderReference ?? context?.data?.customerOrderCode ?? ""
    }), [context?.data]);

    const handleChangeShippingDestination = useCallback((e: SelectChangeEvent<any>)=>{
        if (!context?.data) return;
        let result: string = "";
        if (e.target.value===ShippingDestination.CUSTOMER) {
            result = context.data.customerOrderCode ?? "";
        }

        formikRef.current?.setFieldValue("orderReference", result);
    }, [context?.data]);

    return (
        <Formik
            initialValues={initValues()}
            validationSchema={supplierOrderSchemaObject}
            onSubmit={onSubmit}
            innerRef={formikRef}
        >
            {
                ({values})=>
                    <Form>
                        <ListenChange onValuesChange={onValuesChange} />
                        <Grid container spacing={FORM_SPACING}>
                            <Grid item xs={12} md={8}>
                                <Frame defaultColor>
                                    <Grid container spacing={FORM_SPACING}>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                label="Číslo objednávky"
                                                name="supplierOrderNumber"
                                                type="number"
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                label="Identifikátor objednávky dodavatele"
                                                name="supplierOrderCode"
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Select
                                                label="Jazyk"
                                                name="language"
                                                options={languageOptions}
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <DiscountField
                                                label="Sleva"
                                                disabled={disabled}
                                                checkboxName={"isDiscountInPercentage"}
                                                percentageName={"discountPercentage"}
                                                valueName={"discountValue"}
                                                calculationPrice={priceBeforeDiscount}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RichTextField
                                                label="Platební podmínky (dodatek)"
                                                name="paymentTerms"
                                                height={150}
                                                disabled={disabled}
                                                controls={
                                                    <DocumentTextTypeChooser
                                                        documentType={DocumentType.SUPPLIER_ORDER}
                                                        documentTextType={ConfigurationDocumentTextType.APPENDIX}
                                                        language={(values.language ?? Language.CZECH) as Language}
                                                        textFieldName="paymentTerms"
                                                        disabled={disabled}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Frame>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Frame defaultColor>
                                    <Grid container spacing={FORM_SPACING}>
                                        <Grid item xs={12}>
                                            <Select
                                                label="Místo doručení"
                                                name="shippingDestination"
                                                options={shippingDestinationOptions}
                                                disabled={disabled}
                                                SelectProps={{onChange: handleChangeShippingDestination}}
                                            />
                                        </Grid>
                                        {
                                            ([ShippingDestination.MY_COMPANY, ShippingDestination.CUSTOM]
                                                .includes(values.shippingDestination as ShippingDestination)) &&
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Kontaktní osoba"
                                                        name="shippingContact"
                                                        required
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        label="E-mail"
                                                        name="shippingEmail"
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        label="Telefon"
                                                        name="shippingPhone"
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {
                                            values.shippingDestination===ShippingDestination.CUSTOM &&
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        label="Firma"
                                                        name="shippingCompanyName"
                                                        required
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <CountrySelect
                                                        label="Země"
                                                        name="shippingAddress.country.id"
                                                        required
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <AddressLine name="shippingAddress" disabled={disabled} />
                                            </>
                                        }
                                        {
                                            ShippingDestination.MY_COMPANY!==values.shippingDestination &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Označení objednávky"
                                                        name="orderReference"
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                        }
                                    </Grid>
                                </Frame>
                            </Grid>
                            {
                                !disabled && <SaveGridItem loading={loading} />
                            }
                        </Grid>
                    </Form>
            }
        </Formik>
    );
};
export default SupplierOrderForm;

interface ReturnProps extends FormReturnProps<SupplierOrderFormValues> {
    props: Props;
}
interface Opt extends FormOpt<SupplierOrderFormValues, SupplierOrderResponse> {
    supplierId?: number;
    businessCaseId?: number;
    priceBeforeDiscount: number;
}
export function useSupplierOrderForm({supplierId, businessCaseId, afterSave, priceBeforeDiscount}: Opt): ReturnProps {
    const manipulator = useSupplierOrderManipulator();

    const onSubmit: OnSubmit<SupplierOrderFormValues> = useCallback(async (values, formikHelpers) => {
        if (!supplierId || !businessCaseId) return;

        const data: SupplierOrderRequest = {
            ...values,
            shippingAddress: !values.shipToCustomer ? values.shippingAddress : null,
            supplierId,
            businessCaseId,
            discountPercentage: values.isDiscountInPercentage
                ? convertToPercentage(values.discountPercentage ?? 0)
                : null,
            discountValue: values.discountValue ?? 0
        };

        const result = await manipulator.update.run({id: supplierId, businessCaseId, data});
        if (result!==null) {
            formikHelpers.resetForm({values});
            manipulator.update.messageHandler.success(Messages.SAVED);
            if (afterSave) afterSave(result);
        }
    }, []);

    const {props} = useForm(onSubmit, manipulator.loading);

    return useMemo(()=>({
        props: {
            ...props,
            priceBeforeDiscount
        }
    }), [props, priceBeforeDiscount]);
}