import {FC, ReactNode} from "react";
import {DialogBase} from "./props";
import {DialogContent, DialogTitle, Dialog as MuiDialog, styled, css} from "@mui/material";

interface Props extends Omit<DialogBase, "handleClose"> {
    title?: ReactNode;
    children?: ReactNode;
    actions?: ReactNode;
}
const Dialog: FC<Props> = (
    {
        DialogProps,
        title,
        children,
        actions
    }
) => {
    return (
        <MuiDialog
            fullWidth

            {...DialogProps}
        >
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <DialogContent>{children}</DialogContent>
            {actions}
        </MuiDialog>
    );
};
export default Dialog;

const StyledDialogTitle = styled(DialogTitle)(({theme})=>css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`);