import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {
    EntityFormDialogBase,
    EntityFormDialogReturnProps,
    useEntityFormDialog,
    useFormDialogMiddleware
} from "../dialog/props";
import {
    BusinessCaseEmbeddedResponse,
    CompanyEmbeddedResponse,
    ContactPersonResponse,
    MeetingResponse,
    NotificationResponse,
    OfferListItemResponse,
    OfferVersionResponse,
    OpportunityResponse
} from "../../props/apiResponses";
import Dialog from "../../common/dialog/Dialog";
import IconButton from "../../common/button/IconButton";
import {Delete, Drafts, EditOff, Verified} from "@mui/icons-material";
import NotificationForm, {useNotificationForm} from "./NotificationForm";
import {NotificationStatus, NotificationType} from "../../props/apiRequests";
import {useNotificationManipulator, useNotificationStatus} from "../../services/notification/NotificationService";
import RectangularSkeleton from "../../common/RectangularSkeleton";
import {Box, css, DialogActions, styled, Typography} from "@mui/material";
import {formatDate} from "../../props/helpers";
import {getMassPropertyAsElement} from "../../common/dataGrid/formatter/ShowMoreFormatter";
import {personNameString} from "../../pages/company/props";
import {PAGE_KEY, passURL} from "../../mainUI/pages";
import {TAB_KEY} from "../../pages/businessCase/props";
import {Link} from "react-router-dom";
import {tradeEntityName} from "../../pages/trade/props";
import SaveButton from "../../common/button/SaveButton";
import Button from "../../common/button/Button";
import EditButton from "../../common/button/EditButton";
import {getRelationIcon} from "../../pages/notification/props";
import DeleteConfirmationDialog, {useDeleteConfirmationDialog} from "../../common/dialog/DeleteConfirmationDialog";
import {Messages} from "../../props/messages";
import {getMeetingName} from "../../pages/meeting/props";
import MeetingFormDialog, {useMeetingFormDialog} from "../meeting/MeetingFormDialog";
import {Frame} from "../../common/Frame";
import isStringUnset from "../../utils/format/isStringUnset";

interface Props extends EntityFormDialogBase<Partial<NotificationResponse>> {
    type: NotificationType;
    afterRemoval?: (id: number)=>void;
}
const NotificationFormDialog: FC<Props> = (dialog) => {
    const {
        DialogProps,
        entity,
        handleClose,
        type,
        afterSave,
        afterRemoval
    } = useFormDialogMiddleware(dialog);

    const manipulator = useNotificationManipulator();
    const {props} = useNotificationForm({
        notificationId: entity?.id,
        afterSave
    });
    const [notification, setNotification] = useState<Partial<NotificationResponse>>();
    const [detail, setDetail] = useState<boolean>(false);
    const handleSave = useCallback(()=>props.formikRef.current?.submitForm(), []);

    const deleteDialog = useDeleteConfirmationDialog(async ()=>{
        if (!entity?.id) return;

        const result = await manipulator.remove.run({id: entity.id})
        if (result!==null) {
            manipulator.remove.messageHandler.success(Messages.REMOVED);
            deleteDialog.handleClose();
            if (afterRemoval) afterRemoval(entity.id);
        }
    });

    const [read, unread, statusLoading] = useNotificationStatus(
        useCallback((status: NotificationStatus)=>{
            if (afterSave) afterSave({...notification, status});
            handleClose();
        }, [afterSave]),
        notification?.id
    );

    useEffect(()=>{
        if (entity?.id===undefined) {
            setDetail(false);
            setNotification(entity);
            return;
        }

        (async ()=>{
            setDetail(true);
            const result = await manipulator.get.run({id: entity.id as number});
            if (result) setNotification(result);
        })();
    }, [entity]);

    const data = useMemo(()=>({
        notification,
        type: notification?.notificationType?.entity ?? type
    }), [notification, type]);

    const loading = props.loading || manipulator.loading || statusLoading;

    return (
        <Dialog
            DialogProps={{
                ...DialogProps,
                maxWidth: "xs",
                TransitionProps: {
                    onExited: ()=>{
                        setDetail(false);
                        setNotification(undefined);
                    }
                }
            }}
            title={entity?.id!==undefined ? <>Událost
                {
                    !detail &&
                    <IconButton
                        IconButtonProps={{color: "error", disabled: loading}}
                        onClick={()=>deleteDialog.handleOpen()}
                    >
                        <Delete />
                    </IconButton>
                }
            </> : "Vytvořit událost"}
            actions={
                <DialogActions>
                    {
                        !manipulator.loading && !detail &&
                        <>
                            <SaveButton onClick={handleSave} loading={loading} />
                            {
                                notification?.id!==undefined &&
                                <Button
                                    startIcon={<EditOff />}
                                    variant="outlined"
                                    onClick={()=>setDetail(true)}
                                    loading={loading}
                                >
                                    Detail
                                </Button>
                            }
                        </>
                    }
                    {
                        !manipulator.loading && detail &&
                        (
                            notification?.status===NotificationStatus.UNREAD
                                ?
                                <Button
                                    startIcon={<Verified />}
                                    loading={loading}
                                    onClick={read}
                                >
                                    Dokončit
                                </Button>
                                :
                                <Button
                                    startIcon={<Drafts />}
                                    loading={loading}
                                    onClick={unread}
                                >
                                    Znovu otevřít
                                </Button>
                        )
                    }
                    {
                        !manipulator.loading && detail && <EditButton onClick={()=>setDetail(false)} variant="outlined" />
                    }
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        loading={loading}
                    >
                        Zavřít
                    </Button>
                </DialogActions>
            }
        >
            {
                !manipulator.loading && !detail &&
                <NotificationForm
                    {...props}

                    data={data}
                />
            }
            {
                !manipulator.loading && detail &&
                <NotificationDetail notification={notification as NotificationResponse} />
            }
            {
                manipulator.loading && <RectangularSkeleton height={150} />
            }
            <DeleteConfirmationDialog {...deleteDialog.props} entityName="Událost" loading={loading} />
        </Dialog>
    );
};
export default NotificationFormDialog;

function getRelationLink(notification: NotificationResponse, handleMeeting: (meeting: MeetingResponse)=>void): any {
    let props: any = {};

    const companyLink = (company: CompanyEmbeddedResponse) => passURL(
        company.supplier ? PAGE_KEY.SUPPLIERS_DETAIL : PAGE_KEY.CUSTOMERS_DETAIL,
        {id: company.id}
    );

    let entity: any = notification.entity;
    switch (notification.notificationType.entity) {
        case NotificationType.BUSINESS_CASE:
            props.to = passURL(PAGE_KEY.BUSINESS_CASES_DETAIL, {id: entity.id, tab: TAB_KEY.CUSTOMER_ORDER});
            break;

        case NotificationType.COMPANY:
            props.to = companyLink(entity);
            break;

        case NotificationType.CONTACT_PERSON:
            props.to = companyLink((entity as ContactPersonResponse).company);
            break;

        case NotificationType.OPPORTUNITY:
            props.to = passURL(PAGE_KEY.OPPORTUNITIES_DETAIL, {id: entity.id});
            break;

        case NotificationType.MEETING:
            props.onClick = ()=>handleMeeting(entity as MeetingResponse);
            break;

        case NotificationType.OFFER:
            let offer: OfferListItemResponse = entity;
            props.to = passURL(PAGE_KEY.OFFERS_DETAIL, {offerId: offer.id, versionId: offer.versions.sort((a, b)=>b.version-a.version)[0].id});
            break;

        case NotificationType.OFFER_VERSION:
            let version: OfferVersionResponse = entity;
            props.to = passURL(PAGE_KEY.OFFERS_DETAIL, {offerId: version.offer.id, versionId: version.id});
            break;
    }

    if ("to" in props) {
        props.clickable = true;
        props.component = Link;
    }

    if ("onClick" in props) props.clickable = true;

    return props;
}

function getRelationLabel(notification: NotificationResponse): string {

    switch (notification.notificationType.entity) {
        case NotificationType.BUSINESS_CASE:
            return tradeEntityName(notification.entity as BusinessCaseEmbeddedResponse);
        case NotificationType.CONTACT_PERSON:
            return personNameString(notification.entity as ContactPersonResponse);
        case NotificationType.COMPANY:
            return (notification.entity as CompanyEmbeddedResponse).name;
        case NotificationType.OPPORTUNITY:
            return (notification.entity as OpportunityResponse).name;
        case NotificationType.OFFER:
            return tradeEntityName(notification.entity as OfferListItemResponse);
        case NotificationType.MEETING:
            return getMeetingName(notification.entity as MeetingResponse);
        case NotificationType.OFFER_VERSION:
            return "Nabídka verze "+(notification.entity as OfferVersionResponse).versionCode;
    }

    return "Bez vztahu";
}

interface NotificationDetailProps {
    notification: NotificationResponse;
}
const NotificationDetail: FC<NotificationDetailProps> = (
    {
        notification
    }
) => {
    const meetingFormDialog = useMeetingFormDialog();

    return (
        <>
            <HeaderWrapper>
                <Typography variant="h6">{notification.title}</Typography>
                <Typography variant="body2" color="primary" pb={0.5}>{formatDate(notification.notificationDate)}</Typography>
            </HeaderWrapper>
            <RelationWrapper {...getRelationLink(notification, (meeting)=>meetingFormDialog.handleOpen(meeting))}>
                <IconRelationWrapper>
                    {getRelationIcon(notification.notificationType.entity)}
                </IconRelationWrapper>
                <RelationTextWrapper>
                    <Typography>{getRelationLabel(notification)}</Typography>
                </RelationTextWrapper>
            </RelationWrapper>
            <InfosWrapper>
                <div>
                    <strong>Pro:</strong>{" "}
                    {getMassPropertyAsElement(
                        notification.recipients.map((recipient)=>personNameString(recipient)),
                        property => property
                    )[0]}
                </div>
                <div>
                    <strong>Vytvořil:</strong> {formatDate(notification.createdAt)} {personNameString(notification.creator)}
                </div>
                <div>
                    <strong>Poslední změna:</strong> {formatDate(notification.lastModificationAt)}
                </div>
                {
                    !isStringUnset(notification.note) &&
                        <Box mt={1}>
                            {notification.note}
                        </Box>
                }
            </InfosWrapper>
            <MeetingFormDialog {...meetingFormDialog.props} />
        </>
    );
};

export interface NotificationFormDialogReturnProps extends EntityFormDialogReturnProps<Partial<NotificationResponse>> {}
export function useNotificationFormDialog(): NotificationFormDialogReturnProps {
    return useEntityFormDialog();
}

const HeaderWrapper = styled(Box)(({theme})=>css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: ${theme.spacing(1)};
`);

const RelationWrapper = styled(Frame)(({theme})=>css`
  display: flex;
  gap: ${theme.spacing(0.5)};
  align-items: center;
`);

const IconRelationWrapper = styled(Box)(({theme})=>css`
  display: grid;
  place-items: center;
`);

const RelationTextWrapper = styled(Box)(({theme})=>css`
  flex-grow: 1;
`);

const InfosWrapper = styled(Box)(({theme})=>css`
  font-size: ${theme.typography.caption.fontSize};
  margin-top: ${theme.spacing(0.5)};
`);
