import {SortColumn} from "react-data-grid";
import {MutableRefObject, useCallback, useMemo, useRef, useState} from "react";
import {DecodedValueMap, StringParam, UrlUpdateType, useQueryParams} from "use-query-params";
import {ChangeQuery, QueryBase} from "./props";
import {FilterValues} from "./filter/useFilter";


export type GridSorting = {
    sortColumns: readonly SortColumn[];
    onSortColumnsChange: (sortColumns: SortColumn[]) => void;
    sortingRef: MutableRefObject<readonly (SortColumn | null | undefined)[]>;
};


export type GridSortingQuery = {columnKey?: string, direction?: string};

export function useGridRowSorting(
    request: ()=>Promise<void>,
    query: DecodedValueMap<QueryBase>,
    handleChangeQuery: ChangeQuery,
    initValue?: GridSortingQuery
): {
    sorting: GridSorting;
} {
    const [detectedChange, setDetectedChange] = useState<boolean>(false);

    const determineValue = useCallback((): GridSortingQuery => {
        return {
            columnKey: detectedChange ? (query.columnKey ?? null) : query.columnKey ?? initValue?.columnKey,
            direction: detectedChange ? (query.direction ?? null) : query.direction ?? initValue?.direction
        };
    }, [initValue, query, detectedChange]);

    const sortingRef = useRef<readonly (SortColumn | null | undefined)[]>([determineValue()] as readonly (SortColumn | null | undefined)[]);

    const onSortColumnsChange = useCallback(async (sortColumns: SortColumn[]) => {
        setDetectedChange(true);

        let result: any;
        if (sortColumns.length===0) result = {columnKey: null, direction: null };
        else result = sortColumns[0];

        sortingRef.current = [result];
        handleChangeQuery({...result});
        await request();
    }, [handleChangeQuery]);

    return useMemo(()=>({
        sorting: {sortColumns: [determineValue()] as readonly SortColumn[], onSortColumnsChange, sortingRef}
    }), [determineValue, onSortColumnsChange]);
}
