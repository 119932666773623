import React, {ReactNode, useCallback, useImperativeHandle, useMemo} from "react";
import {ContactPersonResponse, GridResponse, MeetingResponse, UserResponse} from "../../props/apiResponses";
import {GridChooserProps, useGridChooserColumns} from "../../common/dialog/GridChooserDialog";
import {DateRangeFilter, Filter, TextFieldFilter} from "../../common/dataGrid/filter/useFilter";
import {formatDate} from "../../props/helpers";
import {GridRP} from "../../services/GridService";
import useGridContextMenu from "../../common/dataGrid/contextMenu/GridContextMenu";
import BaseMenu, {getPlainText} from "../../common/dataGrid/contextMenu/menu/BaseMenu";
import useDataGrid from "../../common/dataGrid/useDataGrid";
import {DateTimeParam, StringParam, withDefault} from "use-query-params";
import FilterDataGrid from "../../common/dataGrid/filter/FilterDataGrid";
import {useMeetingsManipulator} from "../../services/meeting/MeetingService";
import {personNameString} from "../company/props";
import {
    getContextAsList,
    getMassPropertyAsElement,
    showMoreFormatter
} from "../../common/dataGrid/formatter/ShowMoreFormatter";

export type MeetingsGridRow = {
    company: string;
    contactPerson: ReactNode;
    contactPersons: ReactNode[];
    userName: ReactNode;
    usersNames: ReactNode[];
    meetingDateTo: string;
    item: MeetingResponse;
}

interface Props extends GridChooserProps<MeetingsGridRow> {}

export interface MeetingsGridRef {
    request: ()=>void
}

export default React.forwardRef<MeetingsGridRef, Props>((
    {
        topActions,
        actionsColumn,
        loading,
        onRowDoubleClick,
        disableQueryParams
    }, ref)=>{
    const {run} = useMeetingsManipulator(true);

    const columns = useGridChooserColumns<MeetingsGridRow>([
        {
            key: "company",
            name: "Společnost",
            headerRenderer: (props)=><Filter headerProps={props} name="company" element={TextFieldFilter()} />
        },
        {
            key: "contactPerson",
            name: "Kontaktní osoba",
            formatter: ({row})=>{
                return showMoreFormatter(row.contactPerson, row.contactPersons.length, getContextAsList(row.contactPersons));
            },
            headerRenderer: (props)=><Filter headerProps={props} name="contactPerson" element={TextFieldFilter()} />
        },
        {
            key: "userName",
            name: "Uživatel",
            formatter: ({row})=>{
                return showMoreFormatter(row.userName, row.usersNames.length, getContextAsList(row.usersNames));
            },
            headerRenderer: (props)=><Filter headerProps={props} name="userName" element={TextFieldFilter()} />
        },
        {
            key: "meetingDateTo",
            name: "Datum schůzky",
            width: 130,
            headerRenderer: props => <Filter
                headerProps={props}
                name={{from: "meetingDateFrom", to: "meetingDateTo"}}
                element={DateRangeFilter(true)}
            />,

        }
    ], actionsColumn);

    const request = useCallback((requestParams: GridRP)=>run(requestParams), []);

    const mapper = useCallback((response: GridResponse<MeetingResponse>): MeetingsGridRow[] =>
        response.data.map((item)=> {
            let [contactPerson, contactPersons] = getMassPropertyAsElement<ContactPersonResponse>(
                item.contactPersons,
                e => personNameString(e)
            );
            let [userName, usersNames] = getMassPropertyAsElement<UserResponse>(
                item.users,
                e => personNameString(e)
            );
            return ({
                company: item.companies.map(c => c.name).join(),
                contactPerson, contactPersons,
                userName, usersNames,
                meetingDateTo: formatDate(item.meetingDate, "without time"),
                item
            })
        })
    , []);

    const gridContextMenu = useGridContextMenu<MeetingsGridRow>(useMemo(()=>({
        main: {
            key: "main",
            component: props => <BaseMenu key="main" {...props} getPlainText={data => {

                return getPlainText(data);
            }} />
        }
    }), []));

    const dataGrid = useDataGrid(columns, request, mapper, useMemo(()=>({
        sorting: {init:{}},
        filter: {
            schema: {
                company: withDefault(StringParam, ""),
                contactPerson: withDefault(StringParam, ""),
                userName: withDefault(StringParam, ""),
                meetingDateFrom: withDefault(DateTimeParam, null),
                meetingDateTo: withDefault(DateTimeParam, null)
            }
        },
        disableQueryParams
    }),[disableQueryParams]));

    useImperativeHandle(ref, ()=>({
        request: dataGrid.reset
    }));

    return (
        <FilterDataGrid
            dataGrid={dataGrid}
            filter={dataGrid.filter}
            DataGridProps={{
                topActions,
                contextMenu: gridContextMenu.contextMenu,
                loading,
                onRowDoubleClick
            }}
        />
    );
});