import {useGridDnD} from "../../common/dataGrid/row/dnd/DnDRenderer";
import React, {useCallback, useContext, useMemo} from "react";
import {OfferVersionRequest, TradeItemRequest} from "../../props/apiRequests";
import {Messages} from "../../props/messages";
import {OfferItemResponse, TradeItemResponse} from "../../props/apiResponses";
import ConfirmationToMakeSubItem, {useConfirmationToMakeSubItemDialog} from "./ConfirmationToMakeSubItem";
import {ActivityStatus} from "../../common/ActivityChip";

interface TradeDndRow {
    item: TradeItemResponse
}

export function useTradeItemsDnd<Row extends TradeDndRow>(
    save: (items: TradeItemRequest[])=>void,
    rows: Row[],
    currentItems?: TradeItemResponse[]
) {

    const removeSource = useCallback((items: OfferItemResponse[], source: OfferItemResponse)=>{
        const originParent = items.find((item)=>item.id===source.parentItemId);
        if (originParent) {
            originParent.options = originParent.options?.filter(item=>item.id!==source.id);
        } else {
            const sourceIndex = items.findIndex((item)=>item.id===source.id);
            items.splice(sourceIndex, 1);
        }
    }, []);

    const confirmationToMakeSubItemDialog = useConfirmationToMakeSubItemDialog(
        useCallback(()=> {
            if (!currentItems || !confirmationToMakeSubItemDialog.entityRef.current) return;
            const {source, target} = confirmationToMakeSubItemDialog.entityRef.current;
            let newItems = [...currentItems];
            const targetIndexItems = newItems.findIndex((item) => item.id === target.id);

            removeSource(newItems, source);

            newItems.splice(targetIndexItems, 0, {...source, parentItemId: undefined});
            save(newItems);

        }, [currentItems, removeSource, save]),
        useCallback(()=>{
            if (!currentItems || !confirmationToMakeSubItemDialog.entityRef.current) return;
            const {source, target} = confirmationToMakeSubItemDialog.entityRef.current;
            let newItems = [...currentItems];

            removeSource(newItems, source);

            target.options = !!target.options ? [...target.options] : [];
            target.options.push({...source, parentItemId: target.id, options: []});
            save(newItems);
        }, [currentItems, save, removeSource])
    );

    const gridDnD = useGridDnD<Row>(useCallback((sourceIndex, targetIndex)=>{
        if (!currentItems) return;

        const target = rows[targetIndex].item;
        const source = rows[sourceIndex].item;

        if (target.id===source.id) return;

        if (!!target.parentItemId) {
            let newItems = [...currentItems];
            const targetParent = newItems.find((item)=>item.id===target.parentItemId);
            if (!targetParent || !targetParent.options || targetParent.id===source.id) return;

            const targetIndexItems = targetParent.options.findIndex((item) => item.id === target.id);

            removeSource(newItems, source);

            const newOptions = [...targetParent.options];
            newOptions.splice(targetIndexItems, 0, {...source, options: [], parentItemId: targetParent.id});
            targetParent.options = newOptions;
            save(newItems);
            return;
        }

        confirmationToMakeSubItemDialog.handleOpen({target, source});
    }, [confirmationToMakeSubItemDialog, rows, currentItems, save, removeSource]));

    const dialogElement = useMemo(()=>
        <ConfirmationToMakeSubItem {...confirmationToMakeSubItemDialog.props} />
    , [confirmationToMakeSubItemDialog]);

    return useMemo(()=>({
        gridDnD, dialogElement
    }), [gridDnD, dialogElement]);
}