import {RequestService} from "../RequestService";
import businessCaseService, {BusinessCaseRP} from "./BusinessCaseService";
import {BusinessCaseItemRequest, DeleteRP, GetRP, ID, IdRP, PostRP, PutRP} from "../../props/apiRequests";
import {BusinessCaseItemResponse} from "../../props/apiResponses";
import {CRUDRequestService, useCRUDRequest} from "../CRUDRequestService";

const ITEMS_API = "/items";

export class BusinessCaseItemService extends RequestService<BusinessCaseItemRequest, BusinessCaseItemResponse> implements CRUDRequestService {
    public address = ({businessCaseId, itemId}: {businessCaseId?: ID, itemId?: ID}) =>
        businessCaseService.addressWithId(businessCaseId) + this.addressWithId(itemId);

    public retrieve = ({id, controller, businessCaseId}: GetRP & IdRP & BusinessCaseRP) =>
        this.get(this.address({itemId: id, businessCaseId}), controller);
    public create = ({controller, data, businessCaseId}: PostRP<BusinessCaseItemRequest> & BusinessCaseRP) =>
        this.post(this.address({businessCaseId}), data, controller);
    public update = ({id, controller, data, businessCaseId}: PutRP<BusinessCaseItemRequest> & BusinessCaseRP) =>
        this.put(this.address({itemId: id, businessCaseId}), data, controller);
    public remove = ({id, controller, businessCaseId}: DeleteRP & IdRP & BusinessCaseRP) =>
        this.delete(this.address({businessCaseId, itemId: id}), controller);

}

const businessCaseItemService = new BusinessCaseItemService(ITEMS_API);

export function useBusinessCaseItemManipulator() {
    return useCRUDRequest<BusinessCaseItemResponse, BusinessCaseItemService>(businessCaseItemService);
}