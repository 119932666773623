import React, {createElement, FC, ReactElement, useCallback, useEffect, useMemo} from "react";
import {Formik, FormikValues} from "formik";
import {Box, FormHelperText, Grid} from "@mui/material";
import {
    FORM_SPACING,
    FormOpt,
    OnSubmit,
    OuterSubmitFormProps,
    OuterSubmitFormReturnProps,
    saveIdEntity,
    useOuterSubmitForm
} from "../props";
import {
    NotificationRequest,
    notificationSchemaObject,
    NotificationStatus,
    NotificationType,
} from "../../props/apiRequests";
import {
    BusinessCaseResponse,
    CompanyEmbeddedResponse,
    ContactPersonResponse,
    MeetingResponse,
    NotificationResponse,
    OfferResponse, OfferVersionResponse,
    OpportunityResponse
} from "../../props/apiResponses";
import FormWithoutButton from "../FormWithoutButton";
import {useNotificationManipulator} from "../../services/notification/NotificationService";
import TextField from "../fields/TextField";
import Select from "../fields/select/Select";
import DateTimeField from "../fields/DateTimeField";
import UserSelect from "../fields/select/UserSelect";
import {notificationTypeOptions} from "../fields/select/options";
import CompanySearch from "../fields/CompanySearch";
import {ContactPersonSearch} from "../fields/select/ContactPersonSelect";
import Button from "../../common/button/Button";
import OpportunityChooserDialog, {useOpportunityChooserDialog} from "../../pages/opportunity/OpportunityChooserDialog";
import {PAGE_KEY, PAGES} from "../../mainUI/pages";
import {clearNumberInput} from "../../props/helpers";
import {tradeEntityName} from "../../pages/trade/props";
import OfferChooserDialog, {useOfferChooserDialog} from "../../pages/offer/OfferChooserDialog";
import BusinessCaseChooserDialog, {
    useBusinessCaseChooserDialog
} from "../../pages/businessCase/BusinessCaseChooserDialog";
import MeetingChooserDialog, {useMeetingChooserDialog} from "../../pages/meeting/MeetingChooserDialog";
import {getMeetingName} from "../../pages/meeting/props";
import {Frame, GreyColor} from "../../common/Frame";
import OfferVersionChooserDialog, {useOfferVersionChooserDialog} from "../../pages/offer/OfferVersionChooserDialog";

export interface NotificationFormValues extends FormikValues , Omit<NotificationRequest, "recipients"> {
    recipients: number[];
    companyValue: string;
}

export interface NotificationData {
    notification?: Partial<NotificationResponse>;
    type: NotificationType;
}

interface Props extends OuterSubmitFormProps<NotificationFormValues> {
    data: NotificationData
}

const NotificationForm: FC<Props> = (
    {
        formikRef,
        onSubmit,
        data,
        loading
    }
) => {

    const chooseEntity = useCallback((entity: {id: number})=>{
        formikRef.current?.setFieldValue("entity", entity);
        formikRef.current?.setFieldValue("entityId", entity.id);
    }, []);

    const opportunityChooser = useOpportunityChooserDialog((opportunity)=>{
        chooseEntity(opportunity);
        opportunityChooser.handleClose();
    });
    const offerChooser = useOfferChooserDialog((offer)=>{
        chooseEntity(offer);
        offerChooser.handleClose();
    });
    const offerVersionChooser = useOfferVersionChooserDialog((version)=>{
        chooseEntity(version);
        offerVersionChooser.handleClose();
    });
    const businessCaseChooser = useBusinessCaseChooserDialog((businessCase)=>{
        chooseEntity(businessCase);
        businessCaseChooser.handleClose();
    });
    const meetingChooser = useMeetingChooserDialog((meeting)=>{
        chooseEntity(meeting);
        meetingChooser.handleClose();
    });

    let defaultDate = new Date();
    defaultDate.setHours(defaultDate.getHours() + 1);

    const initValues = useCallback((): NotificationFormValues=>({
        title: data.notification?.title ?? "",
        note: data.notification?.note ?? "",
        notificationDate: data.notification?.notificationDate ?? defaultDate,
        recipients: data.notification?.recipients?.map((recipient)=>recipient.id) ?? [],
        status: NotificationStatus.UNREAD,
        notificationType: {
            entity: data.type
        },

        companyValue: NotificationType.COMPANY===data.notification?.notificationType?.entity
            ? (data.notification.entity as CompanyEmbeddedResponse).name
            : (NotificationType.CONTACT_PERSON===data.notification?.notificationType?.entity
                ? (data.notification.entity as ContactPersonResponse).company.name
                : ""
            ),
        entity: data.notification?.notificationType?.entity===NotificationType.CONTACT_PERSON
            ? (data.notification.entity as ContactPersonResponse).company
            : (data.notification?.entity ?? null),
        entityId: data.notification?.entityId ?? clearNumberInput()
    }), [data]);

    useEffect(()=>{
        formikRef.current?.resetForm({values: initValues()});
    }, [initValues]);

    const getChooser = (name: string | undefined, button: ReactElement, touched?: boolean, error?: string) => (
        <Grid item xs={12}>
            <Frame defaultColor sx={(theme)=>({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: theme.spacing(0.5),
                flexWrap: "wrap",
                borderColor: touched && !!error ? theme.palette.error.main : GreyColor
            })}>
                <Box
                    sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        flexGrow: 1,
                        width: 100
                    }}
                >
                    {name}
                </Box>
                {button}
            </Frame>
            {
                touched && !!error && <FormHelperText error>{error}</FormHelperText>
            }
        </Grid>
    );

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initValues()}
            validationSchema={notificationSchemaObject}
            onSubmit={onSubmit}
        >
            {
                ({values, errors, touched, setFieldValue}) => {
                    //console.log(errors);
                    return (
                        <FormWithoutButton loading={loading}>
                            <Grid container spacing={FORM_SPACING}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Název"
                                        name="title"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateTimeField
                                        label="Čas upozornění"
                                        name="notificationDate"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <UserSelect
                                        label="Pro koho"
                                        name="recipients"
                                        SelectProps={{multiple: true}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Poznámka"
                                        name="note"
                                        multiline
                                        InputProps={{minRows: 3}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        label="Propojit s"
                                        name="notificationType.entity"
                                        options={notificationTypeOptions}
                                        SelectProps={{
                                            onChange: ()=>setFieldValue("entity", null)
                                        }}
                                    />
                                </Grid>
                                {
                                    ([NotificationType.COMPANY, NotificationType.CONTACT_PERSON])
                                        .includes(values.notificationType.entity)
                                    &&
                                    <>
                                        <Grid item xs={12} sm={values.notificationType.entity===NotificationType.CONTACT_PERSON ? 6 : 12}>
                                            <CompanySearch
                                                label="Společnost"
                                                name="entity"
                                                valueName="companyValue"
                                                required
                                            />
                                        </Grid>
                                        {
                                            values.notificationType.entity===NotificationType.CONTACT_PERSON &&
                                            <Grid item xs={12} sm={6}>
                                                <ContactPersonSearch
                                                    label="Kontaktní osoba"
                                                    name="entityId"
                                                    companyEmbedded={values.entity as CompanyEmbeddedResponse}
                                                    dirty={false}
                                                    required
                                                />
                                            </Grid>
                                        }
                                    </>
                                }
                                {
                                    values.notificationType.entity === NotificationType.OPPORTUNITY
                                    &&
                                        <>
                                            {getChooser(
                                                (values.entity as OpportunityResponse | undefined)?.name,
                                                <Button
                                                    color="inherit"
                                                    onClick={opportunityChooser.handleOpen}
                                                    startIcon={createElement(PAGES[PAGE_KEY.OPPORTUNITIES].icon)}
                                                >
                                                    Vybrat příležitost
                                                </Button>,
                                                touched.entity,
                                                errors.entity
                                            )}
                                            <OpportunityChooserDialog {...opportunityChooser.props} />
                                        </>
                                }
                                {
                                    values.notificationType.entity === NotificationType.OFFER
                                    &&
                                    <>
                                        {getChooser(
                                            !values.entity ? "" : tradeEntityName(values.entity as OfferResponse),
                                            <Button
                                                color="inherit"
                                                onClick={offerChooser.handleOpen}
                                                startIcon={createElement(PAGES[PAGE_KEY.OFFERS].icon)}
                                            >
                                                Vybrat nabídku
                                            </Button>,
                                            touched.entity,
                                            errors.entity
                                        )}
                                        <OfferChooserDialog {...offerChooser.props} />
                                    </>
                                }
                                {
                                    values.notificationType.entity === NotificationType.OFFER_VERSION
                                    &&
                                    <>
                                        {getChooser(
                                            !values.entity ? "" : (values.entity as OfferVersionResponse).versionCode,
                                            <Button
                                                color="inherit"
                                                onClick={offerVersionChooser.handleOpen}
                                                startIcon={createElement(PAGES[PAGE_KEY.OFFERS].icon)}
                                            >
                                                Vybrat verzi nabídky
                                            </Button>,
                                            touched.entity,
                                            errors.entity
                                        )}
                                        <OfferVersionChooserDialog {...offerVersionChooser.props} />
                                    </>
                                }
                                {
                                    values.notificationType.entity === NotificationType.BUSINESS_CASE
                                    &&
                                    <>
                                        {getChooser(
                                            !values.entity ? "" : tradeEntityName(values.entity as BusinessCaseResponse),
                                            <Button
                                                color="inherit"
                                                onClick={businessCaseChooser.handleOpen}
                                                startIcon={createElement(PAGES[PAGE_KEY.BUSINESS_CASES].icon)}
                                            >
                                                Vybrat obchodní případ
                                            </Button>,
                                            touched.entity,
                                            errors.entity
                                        )}
                                        <BusinessCaseChooserDialog {...businessCaseChooser.props} />
                                    </>
                                }
                                {
                                    values.notificationType.entity === NotificationType.MEETING
                                    &&
                                    <>
                                        {getChooser(
                                            !values.entity ? "" : getMeetingName(values.entity as MeetingResponse),
                                            <Button
                                                color="inherit"
                                                onClick={meetingChooser.handleOpen}
                                                startIcon={createElement(PAGES[PAGE_KEY.MEETINGS].icon)}
                                            >
                                                Vybrat schůzku
                                            </Button>,
                                            touched.entity,
                                            errors.entity
                                        )}
                                        <MeetingChooserDialog {...meetingChooser.props} />
                                    </>
                                }
                            </Grid>
                        </FormWithoutButton>
                    );
                }
            }
        </Formik>
    );
};
export default NotificationForm;

interface ReturnProps extends OuterSubmitFormReturnProps<NotificationFormValues> {
    props: Omit<Props, "data">;
}
interface Opt extends FormOpt<NotificationFormValues, NotificationResponse> {
    notificationId?: number
}
export function useNotificationForm({notificationId, afterSave}: Opt): ReturnProps {
    const manipulator = useNotificationManipulator();

    const onSubmit: OnSubmit<NotificationFormValues> = useCallback(async (values, formikHelpers) => {
        const requestData: NotificationRequest = {
            ...values,
            recipients: values.recipients.map((id)=>({id})),
            entity: values.notificationType.entity===NotificationType.TIME_ONLY ? null : values.entity,
            entityId: values.notificationType.entity===NotificationType.TIME_ONLY ? null : values.entityId,
        };

        if (values.notificationType.entity===NotificationType.COMPANY) requestData.entityId = values.entity?.id ?? null;

        if (await saveIdEntity(
            (id) => manipulator.update.run({id, data: requestData}),
            () => manipulator.create.run({data: requestData}),
            manipulator.update.messageHandler.success,
            notificationId, afterSave
        )) formikHelpers.resetForm({values});

    }, [afterSave, notificationId]);

    const {props} = useOuterSubmitForm(onSubmit, manipulator.loading);

    return useMemo(()=>({
        props
    }), [props]);
}
