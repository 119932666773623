import React, {FC, ReactNode} from "react";
import {
    css,
    FormControlLabel,
    FormControlLabelProps,
    RadioProps as MuiRadioProps,
    styled,
    Radio as MuiRadio,
    FormControl, FormLabel, RadioGroup, RadioGroupProps
} from "@mui/material";
import {useField} from "formik";
import {FieldProps} from "../props";
import {SelectOption} from "./select/Select";

interface CommonStylingProps {
    smallFont?: boolean;
}

interface Props extends FieldProps, CommonStylingProps {
    options?: SelectOption[];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string)=>void;
    RadioProps?: MuiRadioProps;
}

const Radio: FC<Props> = (
    {
        name,
        options,
        label,
        smallFont,
        onChange,
        RadioProps
    }
) => {
    const [field] = useField(name ?? "");

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                row
                {...field}
                value={field.value.toString()}
                onChange={!!onChange ? onChange : field.onChange}
            >
                {
                    options?.map((option)=>
                        <RadioInput value={option.key} key={option.key} label={option.label} smallFont={smallFont} {...RadioProps} />
                    )
                }
            </RadioGroup>
        </FormControl>
    );
};
export default Radio;

interface InputProps extends CommonStylingProps {
    label?: ReactNode;
}

export const RadioInput: FC<MuiRadioProps & InputProps> = (props) => {
    return (
        <StyledLabel smallFont={props.smallFont} control={
            <MuiRadio
                color="primary"
                sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: 15,
                    },
                }}

                {...props}
            />} label={props.label} />
    );
};

interface StylingProps extends CommonStylingProps {

}
const StyledLabel = styled(
    ({smallFont, ...rest}:StylingProps & FormControlLabelProps) => <FormControlLabel {...rest} />
)<StylingProps>(({theme, smallFont})=>css`
  & .MuiTypography-root {
    ${!!smallFont && css`
      font-size: ${theme.typography.caption.fontSize};
      margin-left: -5px;
    `}
  }
`);