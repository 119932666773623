import {Column, SortColumn} from "react-data-grid";
import {DataGridProps} from "./DataGrid";
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {GridSortingQuery, useGridRowSorting} from "./RowSorting";
import {FilterProps, FilterValues, useFilter} from "./filter/useFilter";
import {useFastChangingRequest} from "../../services/RequestService";
import {GridRP} from "../../services/GridService";
import {EntityResponse, GridResponse} from "../../props/apiResponses";
import {PAGE_SIZE, RowPagination, useRowPagination} from "./RowPagination";
import {
    ArrayParam,
    DecodedValueMap,
    NumberParam,
    QueryParamConfigMap, SetQuery,
    StringParam,
    useQueryParams,
    withDefault
} from "use-query-params";
import {ChangeQuery, QueryBase} from "./props";
import {RowMasterDetail, useMasterDetail} from "./RowMasterDetail";
import {notEmpty} from "../../props/helpers";

type Opt = {
    sorting?: {init: GridSortingQuery};
    filter?: {schema: QueryParamConfigMap, openMasterDetailIf?: (values: FilterValues)=>boolean};
    disableQueryParams?: boolean;
};

export interface ItemRow {item: EntityResponse}

export type UseDataGridRP<Row extends ItemRow> = {
    props: DataGridProps<Row>;
    filter: FilterProps<FilterValues>;
    request: ()=>Promise<void>;
    setRows: Dispatch<SetStateAction<Row[]>>;
    pagination: RowPagination;
    masterDetail: RowMasterDetail;
    reset: ()=>Promise<void>;
};

export default function useDataGrid<Row extends ItemRow, Response>(
    columns: Column<Row>[],
    run: (requestParams: GridRP)=>Promise<GridResponse<Response> | null>,
    rowsMapper: (response: GridResponse<Response>)=>Row[],
    options?: Opt
): UseDataGridRP<Row> {
    const [rows, setRows] = useState<Row[]>([]);

    const defaultFiltersForStateQuery: any = useMemo(()=>{
        if (!options?.filter?.schema) return {};
        let result: any = {};
        Object.entries(options.filter.schema).forEach(([key, value])=>{
            result[key] = value.default;
        });
        return result;
    }, [options?.filter?.schema]);
    const [stateQuery, setStateQuery] = useState<DecodedValueMap<QueryBase>>({
        page: 1,
        scroll: 0,
        columnKey: "",
        direction: "",
        expanded: [],
        ...defaultFiltersForStateQuery
    });
    let [query, setQuery] = useQueryParams({
        page: withDefault(NumberParam, stateQuery.page),
        scroll: withDefault(NumberParam, stateQuery.scroll),
        columnKey: StringParam,
        direction: StringParam,
        expanded: withDefault(ArrayParam, stateQuery.expanded),
        ...options?.filter?.schema
    });
    useEffect(()=>{
        if (!options?.disableQueryParams) {
            let sorting: any = {};
            if (options?.sorting?.init.columnKey && options?.sorting?.init.direction) {
                sorting = {columnKey: options?.sorting?.init.columnKey, direction: options?.sorting?.init.direction};
            }
            setQuery({...query, ...sorting}, "replaceIn");
        }
    }, []);


    // StateQuery is used if we disable query parameters.
    query = options?.disableQueryParams ? stateQuery : query;

    const fastChangingRequest = useFastChangingRequest();
    const request = useCallback(async (paginationOnly?: boolean)=>{
        await fastChangingRequest(async (controller)=>{
            const params: any = paginationOnly ? {
                pageSize: PAGE_SIZE,
                pageNum: pagination.page.current,
                ...filter.filterRef.current
            } : {
                pageSize: PAGE_SIZE*pagination.page.current,
                pageNum: 1,
                ...filter.filterRef.current
            };

            if (gridSorting.sorting.sortingRef.current.length!==0 && !!gridSorting.sorting.sortingRef.current[0]) {
                const sortColumn: SortColumn = gridSorting.sorting.sortingRef.current[0];
                if (notEmpty(sortColumn.columnKey))
                    params["sort"] = [(sortColumn.direction==="DESC" ? "-" : "+")+sortColumn.columnKey];
            }

            const result = await run({params, controller});

            if (result) {
                setRows((oldRows)=>{
                    const newRows = rowsMapper(result);
                    if (paginationOnly)
                        return [...oldRows, ...newRows];
                    return newRows;
                });
                pagination.handleDataPage(result.data.length, pagination.page.current);
            }
        });
    }, [run, rowsMapper]);

    const handleChangeQuery: ChangeQuery = useCallback((value, type)=>{
        if (options?.disableQueryParams) setStateQuery(value as any);
        else setQuery(value as any, type);
    }, [options?.disableQueryParams]);

    const handleChangeQueryReset: ChangeQuery = useCallback((value, type)=>{
        pagination.resetPage();
        masterDetail.resetMasterDetail();
        handleChangeQuery({...value, page: 1, scroll: 0, expanded: []}, type);
    }, [handleChangeQuery]);

    const gridSorting = useGridRowSorting(request, query, handleChangeQueryReset, options?.sorting?.init);
    const pagination = useRowPagination(request, query, handleChangeQuery);
    const masterDetail = useMasterDetail(query, handleChangeQuery, rows, setRows);
    const filter = useFilter(
        request,
        query,
        handleChangeQueryReset,
        options?.filter?.schema ?? {},
        options?.filter?.openMasterDetailIf,
        masterDetail
    );

    useEffect(()=>{
        request().then();
    }, [request]);

    const reset = useCallback(async ()=>{
        handleChangeQueryReset(query);
        await request();
    }, [request, query, handleChangeQueryReset]);

    return useMemo(()=>({
        props: {
            rows,
            columns,
            sorting: !!options?.sorting ? gridSorting.sorting : undefined,
            onScroll: pagination.handleScroll,
            loadingMoreRows: pagination.loading
        },
        filter,
        request,
        setRows,
        pagination,
        masterDetail,
        reset
    }), [gridSorting.sorting, rows, columns, options, pagination, filter, request, setRows, masterDetail, reset]);
}