import {
    Form,
    FormCallbackArgs,
    FormProps,
    FormValues,
    Input,
    useForm,
    UseFormArgs,
    useFormContext,
    UseFormReturn
} from "@data-session/forms";
import {NumberField, NumberFieldProps} from "@data-session/mui";
import {z} from "zod";
import {useContext, useMemo} from "react";
import {BusinessCaseContext} from "../../../../pages/businessCase/props";
import {Box, css, styled} from "@mui/material";
import Button from "../../../../components/buttons/Button";
import {InvoiceRequest, InvoiceResponse} from "../schema/invoice";
import usePutInvoice from "../services/usePutInvoice";
import {priceFormatter} from "../../../../common/dataGrid/formatter/PriceFormatter";
import localeNumber from "../../../../utils/format/localeNumber";
import {round} from "../../../../props/helpers";

const validationSchema = z.object({
    discount: z.number()
});

// -> TYPES
interface InvoiceDiscountFormValues extends FormValues, z.infer<typeof validationSchema> {}
interface InvoiceDiscountFormProps extends FormProps<InvoiceDiscountFormValues> {
    remainingDiscount: number;
}
interface FieldProps extends NumberFieldProps<any> {
    remainingDiscount: number;
}
export interface InvoiceDiscountFormCallbackArgs extends FormCallbackArgs {}
interface UseInvoiceDiscountFormArgs extends UseFormArgs<InvoiceDiscountFormCallbackArgs> {
    invoice: InvoiceResponse;
    disabled?: boolean;
}
interface UseInvoiceDiscountFormReturn extends UseFormReturn<InvoiceDiscountFormValues> {}
// TYPES <-

export function useInvoiceDiscountForm(
    {invoice, disabled}: UseInvoiceDiscountFormArgs
): UseInvoiceDiscountFormReturn {
    const putInvoice = usePutInvoice();
    const context = useContext(BusinessCaseContext);

    return useForm<InvoiceDiscountFormValues>({
        onSubmit: async (values) => {
            if (!context?.data || !context?.setData) return;

            const data: InvoiceRequest = {
                ...invoice,
                pricing: {
                    ...invoice.pricing,
                    discountValue: values.discount
                }
            };

            try {
                const result = await putInvoice.mutateAsync({
                    data,
                    id: invoice.id
                });
                context.setData({
                    ...context.data,
                    invoices: context.data.invoices.map(i=>{
                        if (i.id===result.id) return result;
                        return i;
                    })
                });
            } catch (e) {
                //
            }
        },
        defaultValues: {
            discount: invoice.pricing.discountValue
        },
        validationSchema,
        isLoading: putInvoice.isLoading,
        disabled
    });
}

const Field = ({remainingDiscount, ...props}: FieldProps) => {
    const form = useFormContext();
    const max = useMemo(()=>{
        const originValue = !form.controls?.formState.defaultValues ? 0 : (form?.controls?.formState?.defaultValues["discount"] ?? 0);
        return round(remainingDiscount + originValue);
    },[form.controls?.formState.defaultValues, remainingDiscount]);
    return <NumberField {...props} max={max} extension={{InputProps: {endAdornment: `z ${localeNumber(max)}`}}} />;
};

const InvoiceDiscountForm = (
    {
        form,
        remainingDiscount
    }: InvoiceDiscountFormProps
) => {
    return (
        <Form {...form}>
            <Wrapper>
                <Input
                    name="discount"
                    component={<Field label="Sleva v částce" remainingDiscount={remainingDiscount} />}
                />
                {
                    !form.disabled && <Button loading={form.isLoading}>Uložit</Button>
                }
            </Wrapper>
        </Form>
    );
};

export default InvoiceDiscountForm;

const Wrapper = styled(Box)(({theme})=>css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
`);
