import {createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {NotificationType} from "../../props/apiRequests";
import NotificationFormDialog, {
    NotificationFormDialogReturnProps,
    useNotificationFormDialog
} from "./NotificationFormDialog";
import {
    BusinessCaseEmbeddedResponse,
    ContactPersonResponse,
    NotificationResponse, OfferVersionResponse,
    ProfileResponse
} from "../../props/apiResponses";
import {fireNotification, notEmpty, passParams} from "../../props/helpers";
import {useProfileManipulator} from "../../services/user/ProfileService";
import {Timeout} from "../../props/constants";
import {useNotificationHighlight} from "../../services/notification/NotificationService";
import {useMessages} from "../../props/messagesHandler";
import {Messages} from "../../props/messages";


type NotificationContextType = {
    createNotification: (type?: NotificationType, entity?: ContactPersonResponse | BusinessCaseEmbeddedResponse | OfferVersionResponse)=>void;
    openNotification: (entity: NotificationResponse)=>void;
    registerForUpdate: (callback: ()=>void)=>number;
    unregisterForUpdate: (key: number)=>void;
    unreadNotifications: NotificationResponse[];
    checkNotifications: (profileResponse: ProfileResponse)=>void;
}

export const NotificationContext = createContext<NotificationContextType>({
    createNotification: ()=>{},
    openNotification: ()=>{},
    registerForUpdate: ()=>-1,
    unregisterForUpdate: ()=>{},
    unreadNotifications: [],
    checkNotifications: ()=>{}
});

type ProviderProps = {
    children: ReactNode;
};

let KEY_GENERATOR: number = 1;
const UNREAD_NOTIFICATIONS_INTERVAL: number = 20000;

function useNotifications(): {
    value: NotificationContextType,
    dialog: NotificationFormDialogReturnProps,
    notifyObservers: ()=>void
    } {
    const dialog = useNotificationFormDialog();
    const profileManipulator = useProfileManipulator();
    const highlightRequest = useNotificationHighlight();
    const {info} = useMessages();
    const [unreadNotifications, setUnreadNotifications] = useState<NotificationResponse[]>([]);
    const observers = useRef<{[key: number]: ()=>void}>({});
    const createNotification = useCallback((type: NotificationType = NotificationType.TIME_ONLY, entity?: ContactPersonResponse | BusinessCaseEmbeddedResponse | OfferVersionResponse)=>{
        dialog.handleOpen({
            notificationType: {entity: type},
            entity: entity ?? null,
            entityId: entity?.id
        });
    }, [dialog]);

    const checkNotifications = useCallback(({unreadNotifications}: ProfileResponse)=>{
        let minuteAGo: Date = new Date();
        minuteAGo.setMilliseconds( minuteAGo.getMilliseconds() - UNREAD_NOTIFICATIONS_INTERVAL);
        setUnreadNotifications(unreadNotifications);

        let oldToNotify = 0;
        unreadNotifications.forEach((notification)=>{
            if (notification.highlightedCount>0) return;

            highlightRequest(notification.id).then();

            if (new Date(notification.notificationDate) > minuteAGo) {
                info(Messages.NEW_NOTIFICATION);
                const audio = new Audio(window.location.origin + '/deduction-588.mp3');
                audio.autoplay = true;
                audio.loop = false;
                audio.play().then();
                fireNotification(notification.title, notification.note);
            } else
                oldToNotify++;
        });

        if (oldToNotify > 0) info(passParams(Messages.NEW_OLDIES_NOTIFICATION, {number: oldToNotify}));
    }, [highlightRequest]);

    const timer = useRef<Timeout>();
    const performProfileRequest = useCallback(async ()=>{
        if (!!timer.current) clearTimeout(timer.current);
        const result = await profileManipulator.me.run({});
        if (result) checkNotifications(result);
        timer.current = setTimeout(performProfileRequest, UNREAD_NOTIFICATIONS_INTERVAL);
    }, [checkNotifications]);

    useEffect(()=>{
        timer.current = setTimeout(performProfileRequest, UNREAD_NOTIFICATIONS_INTERVAL);
        return ()=>{
            if (timer.current) clearTimeout(timer.current);
        }
    }, [performProfileRequest]);

    const registerForUpdate = useCallback((callback: ()=>void): number=>{
        const key = KEY_GENERATOR++;
        observers.current[key] = callback;
        return key;
    }, []);

    const unregisterForUpdate = useCallback((key: number)=>{
        delete observers.current[key];
    }, []);

    const notifyObservers = useCallback(()=>{
        performProfileRequest().then();
        Object.values(observers.current).forEach((callback)=>callback());
    }, [performProfileRequest]);

    return useMemo(()=>({
        value: {
            createNotification,
            openNotification: dialog.handleOpen,
            registerForUpdate, unregisterForUpdate,
            unreadNotifications, checkNotifications
        },
        dialog,
        notifyObservers
    }), [
        createNotification,
        dialog,
        registerForUpdate,
        unregisterForUpdate,
        notifyObservers,
        unreadNotifications,
        checkNotifications
    ]);
}

export function useNotification() {
    return useContext(NotificationContext);
}

export function useNotificationsListening(callback: ()=>void) {
    const notification = useNotification();

    useEffect(()=>{
        const key: number = notification.registerForUpdate(callback);
        return ()=>notification.unregisterForUpdate(key);
    }, []);
}


const NotificationProvider: FC<ProviderProps> = (
    {
        children
    }
) => {
    const notifications = useNotifications();

    const afterSave = useCallback((notification: Partial<NotificationResponse>)=>{
        if (!notEmpty(notifications.dialog.props.entity?.id)) notifications.dialog.handleClose();
        notifications.notifyObservers();
    }, [notifications]);

    const afterRemoval = useCallback(()=>{
        notifications.notifyObservers();
        notifications.dialog.handleClose();
    }, [notifications]);

    return (
        <NotificationContext.Provider value={notifications.value}>
            {children}
            <NotificationFormDialog
                {...notifications.dialog.props}
                type={NotificationType.TIME_ONLY}
                afterSave={afterSave}
                afterRemoval={afterRemoval}
            />
        </NotificationContext.Provider>
    );
};
export default NotificationProvider;
