import {FC} from "react";
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers";
import {TextFieldInput} from "./TextField";
import {FieldProps} from "../props";
import {useField} from "formik";

interface Props extends FieldProps {
    DatePickerProps?: Partial<DatePickerProps<Date, Date>>;
}

/**
 * The values are of type Date.
 * If you want nothing, set the default value to null.
 *
 * @param label
 * @param name
 * @param required
 * @param DatePickerProps
 * @constructor
 */
const DateField: FC<Props> = (
    {
        label,
        name,
        required,
        DatePickerProps
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");

    return (
        <DatePicker
            label={label}

            {...field}
            onChange={value => {
                helpers.setValue(value, true);
            }}

            renderInput={(params) => <TextFieldInput
                required={required}
                {...params}

                error={(meta.touched && !!meta.error) || params.error}
                helperText={params.error ? "Formát není validní" : (meta.touched && meta.error)}
            />}


            {...DatePickerProps}
        />
    );
};
export default DateField;