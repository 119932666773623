import {DialogBase, DialogReturnProps, useDialog} from "../../common/dialog/props";
import {useMemo, useState} from "react";

export interface FormDialogBase<R> extends DialogBase {
    afterSave?: (entity: R)=>void;
}

export interface FormDialogReturnProps<R> extends DialogReturnProps {
    props: FormDialogBase<R>;
}

export function useFormDialog<R>(): FormDialogReturnProps<R> {
    return useDialog();
}

export interface EntityFormDialogBase<R> extends FormDialogBase<R> {
    entity?: R;
}

export interface EntityFormDialogReturnProps<R> extends FormDialogReturnProps<R> {
    props: EntityFormDialogBase<R>;
    handleOpen: (entity?: R)=>void;
}

export function useEntityFormDialog<R>(): EntityFormDialogReturnProps<R> {
    const props = useFormDialog<R>();
    const [entity, setEntity] = useState<R>();

    return useMemo(()=>({
        ...props,
        props: {
            ...props.props,
            entity
        },
        handleOpen: p => {
            setEntity(p);
            props.handleOpen();
        }
    }), [props, entity]);
}

export function useFormDialogMiddleware<R extends FormDialogBase<any>>(props: R) {
    return useMemo(()=>({
        ...props,
        afterSave: (entity: any)=>{
            if (!props.afterSave) return;
            props.afterSave(entity);
            props.handleClose();
        }
    }), [props]);
}