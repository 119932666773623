import React, {FC, ReactNode} from "react";
import {Box, CircularProgress, Collapse, css, styled} from "@mui/material";

type Props = {
    loading: boolean;
    caption?: ReactNode;
};
const InLineLoading: FC<Props> = (
    {
        loading,
        caption
    }
) => {
    return (
        <Collapse in={loading}>
            <Wrapper>
                <CircularProgress size={20} />
                {caption ?? "Načítám..."}
            </Wrapper>
        </Collapse>
    );
};
export default InLineLoading;

const Wrapper = styled(Box)(({theme})=>css`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${theme.palette.primary.main};
  padding-top: ${theme.spacing(0.5)};
  
  & .MuiCircularProgress-root {
    margin-right: ${theme.spacing(0.5)};
  }
`);
