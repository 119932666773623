import {FC, MutableRefObject, useCallback, useMemo, useRef} from "react";
import {ConfirmationDialogBase, ConfirmationDialogReturnProps, useConfirmationDialog} from "../../common/dialog/props";
import ConfirmationDialog from "../../common/dialog/ConfirmationDialog";
import {TradeItemResponse} from "../../props/apiResponses";

interface Props extends ConfirmationDialogBase {}
const ConfirmationToMakeSubItem: FC<Props> = (
    {
        ...props
    }
) => {
    return (
        <ConfirmationDialog
            title="Přesun položky"
            message="Přejete si položku vložit a přidat jako podpoložku, nebo jen upravit pořadí položek?"
            buttons={{
                yes: {label: "Přidat jako podpoložku"},
                no: {label: "Upravit pořadí"}
            }}
            primary="no"
            hideIcons

            {...props}
        />
    );
};
export default ConfirmationToMakeSubItem;

type Entity = {source: TradeItemResponse, target: TradeItemResponse};
interface ConfirmationToMakeSubItemDialogReturnProps extends Omit<ConfirmationDialogReturnProps, "handleOpen"> {
    props: Props;
    entityRef: MutableRefObject<Entity | undefined>;
    handleOpen: (entity: Entity)=>void
}
export function useConfirmationToMakeSubItemDialog(
    handleReorder: ()=>void,
    handleMakeSubItem: ()=>void
): ConfirmationToMakeSubItemDialogReturnProps {
    const entityRef = useRef<Entity>();

    const props = useConfirmationDialog(useCallback(()=>{
        handleMakeSubItem();
        props.handleClose();
    }, [handleMakeSubItem]));

    const handleClose = useCallback(()=>{
        handleReorder();
        props.handleClose();
    }, [props, handleReorder]);

    const handleOpen = useCallback((entity: Entity)=>{
        entityRef.current = entity;
        props.handleOpen();
    }, [props]);

    return useMemo(()=>({
        ...props,
        props: {
            ...props.props,
            handleClose
        },
        handleClose,
        handleOpen,
        entityRef
    }), [props, handleClose, handleOpen]);
}