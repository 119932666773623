import React from "react";
import {MouseEventHandler, ReactElement, ReactNode} from 'react';
import Button from "../../buttons/Button";
import SaveButton from "../../buttons/SaveButton";

interface SaveAndCloseActionProps {
    handleSave: MouseEventHandler;
    loading?: boolean;
    handleClose: ()=>void;
    saveLabel?: string;
    saveIcon?: ReactElement;
    disabled?: boolean;
    children?: ReactNode;
}

const SaveAndCloseAction = ({
    handleSave,
    loading,
    handleClose,
    saveLabel,
    saveIcon,
    disabled,
    children
}: SaveAndCloseActionProps) => {
    return (
        <>
            {
                !disabled &&
                    <>
                        <SaveButton
                            onClick={handleSave}
                            loading={loading}
                            startIcon={saveIcon}
                            ButtonProps={{disabled}}
                        >
                            {saveLabel}
                        </SaveButton>
                        {children}
                    </>
            }
            <Button
                onClick={handleClose}
                variant="outlined"
                loading={loading}
            >
                Zavřít
            </Button>
        </>
    );
};

export default SaveAndCloseAction;