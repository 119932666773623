import {UserResponse} from "../../props/apiResponses";
import {BaseRP, DataRP, IdRP, PasswordChangeRequest, UserRequest} from "../../props/apiRequests";
import useRequest from "../RequestService";
import {Messages} from "../../props/messages";
import {useCallback} from "react";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {SelectOption, useSelectRequest} from "../../form/fields/select/Select";
import {personNameString} from "../../pages/company/props";
import {useCRUDRequest} from "../CRUDRequestService";

const USERS_API = "/users";

interface UserPassRP extends DataRP<PasswordChangeRequest>, Partial<IdRP>, BaseRP {}

class UserService extends GridCRUDServiceImpl<UserRequest, UserResponse> {
    public changePass = ({data, id, controller}: UserPassRP) =>
        this.patch(this.addressWithId(id)+"/password", data, controller);
}
const userService = new UserService(USERS_API);

const errors = {
    409: {
        username: Messages.DUPLICATED_USERNAME,
        email: Messages.DUPLICATED_EMAIL
    }
};
export function useUserManipulator() {
    return useCRUDRequest<UserResponse, UserService>(userService, {create: errors, update: errors});
}

export function useUserPasswordManipulator() {
    const update = useRequest(userService.changePass);

    return {update, loading: update.loading};
}

export function useUsersManipulator(globalLoading: boolean) {
    return useGridRequest<UserResponse>(userService, globalLoading);
}

export function useUserSelect(): {users: SelectOption[], loading: boolean} {
    const {run, loading} = useUsersManipulator(false);

    const users = useSelectRequest(
        useCallback((controller) => run({controller, params:{fetchAll: true, pageSize: 1, pageNum: 1}}), []),
        useCallback(data => data.data.map((item)=>(
            {key: item.id, label: personNameString(item)}
        )), [])
    );

    return {users, loading};
}