import {FC} from "react";
import {Save} from "@mui/icons-material";
import Button, {ButtonProps} from "./Button";


const SaveButton: FC<ButtonProps> = (props) => {
    return (
        <Button
            {...props}
            startIcon={props.startIcon ?? <Save />}
        >
            {props.children ?? "Uložit"}
        </Button>
    );
};
export default SaveButton;