import {ProfileResponse} from "../../props/apiResponses";
import {UserRole} from "../../props/apiRequests";
import {createContext, useCallback, useEffect, useState} from "react";
import {useMessages} from "../../props/messagesHandler";
import keycloakService from "./KeycloakService";
import {Messages} from "../../props/messages";
import Keycloak from "keycloak-js";
import {useProfileManipulator} from "../user/ProfileService";
import {useNotification} from "../../form/notification/NotificationProvider";

export type Auth = {
    isLoggedIn: boolean;
    logOut: ()=>void;
    user: ProfileResponse | null;
    isInRole: (role?: UserRole[] | UserRole)=>boolean;
};
export const DEFAULT_AUTH: Auth = {
    isLoggedIn: false,
    user: null,
    logOut: ()=>{},
    isInRole: (role?: UserRole[] | UserRole)=>!role
};
export const AuthContext = createContext<Auth>(DEFAULT_AUTH);
export const AuthProvider = AuthContext.Provider;

export function useAuth(showLoading: ()=>void, hideLoading: ()=>void): Auth {
    const manipulator = useProfileManipulator();
    const notification = useNotification();
    const {error} = useMessages();

    const logOut = useCallback(async ()=>{
        showLoading();
        if (await keycloakService.logOut())
            setLogin(DEFAULT_AUTH);
        else
            error(Messages.SOMETHING_WENT_WRONG);
        hideLoading();
    }, []);
    const [login, setLogin] = useState<Auth>(DEFAULT_AUTH);

    const isInRole = useCallback((roles: UserRole[], role?: UserRole[] | UserRole): boolean =>
        !role ? true : roles.some(r => Array.isArray(role) ? role.includes(r) : role===r)
    , []);

    useEffect(()=>{
        (async ()=>{
            const keycloak: Keycloak | null = await keycloakService.auth();
            if (keycloak?.authenticated) {
                const user: ProfileResponse | null = await manipulator.me.run({});
                if (user) {
                    setLogin({isLoggedIn: true, user, logOut, isInRole: (role?: UserRole[] | UserRole) => isInRole(user.role, role)});
                    notification.checkNotifications(user);
                    hideLoading();
                }
            }
        })();
    }, []);

    return login;
}