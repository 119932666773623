import {OptionsObject, SnackbarKey, useSnackbar, VariantType} from "notistack";
import {useCallback, useMemo} from "react";

export type MessageKey = SnackbarKey;

export type MessageHandler = (message: string, options?: OptionsObject)=>MessageKey;

export type MessagesHandler = {
    success: MessageHandler;
    error: MessageHandler;
    info: MessageHandler;

    all: (message: string, variant: VariantType, options?: OptionsObject)=>MessageKey;

    close: (key?: MessageKey)=>void;
};

export function useMessages(): MessagesHandler {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const success = useCallback(
        (message: string, options?: OptionsObject)=>
            enqueueSnackbar(message, {variant: "success", ...options}),
        [enqueueSnackbar]
    );

    const error = useCallback(
        (message: string, options?: OptionsObject)=>
            enqueueSnackbar(message, {variant: "error", ...options}),
        [enqueueSnackbar]
    );

    const info = useCallback(
        (message: string, options?: OptionsObject)=>
            enqueueSnackbar(message, {variant: "info", ...options}),
        [enqueueSnackbar]
    );

    const all = useCallback(
        (message: string, variant: VariantType, options?: OptionsObject)=>
            enqueueSnackbar(message, {variant, ...options}),
        [enqueueSnackbar]
    );

    return useMemo(()=>({success, error, info, all, close: closeSnackbar}),
        [success, error, info, all, closeSnackbar]);
}