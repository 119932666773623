import {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import Select, {SelectOption, SelectProps} from "./Select";
import {PersonAdd} from "@mui/icons-material";
import {CompanyEmbeddedResponse, CompanyResponse, ContactPersonResponse} from "../../../props/apiResponses";
import {getContactPersonList, personNameString} from "../../../pages/company/props";
import {useHistory} from "../../../props/helpers";
import {PAGE_KEY, passURL} from "../../../mainUI/pages";
import {useConfirmationDialog} from "../../../common/dialog/props";
import LeavingConfirmationDialog from "../../../common/dialog/LeavingConfirmationDialog";
import {useCompanyManipulator} from "../../../services/company/CompanyService";
import {useField} from "formik";

type AddedProps = {
    handlePersonsManagement?: ()=>void;
    persons: ContactPersonResponse[];
}

type Props = SelectProps & AddedProps;

const ContactPersonSelect: FC<Props> = (
    {
        handlePersonsManagement,
        persons,
        ...props
    }
) => {

    const options: SelectOption[] = persons.map((person)=>({
        key: person.id,
        label: personNameString(person)
    }));

    return (
        <Select
            options={options}
            listButtonAppendix={!!handlePersonsManagement ? {
                icon: <PersonAdd />,
                label: "Spravovat osoby",
                onClick: handlePersonsManagement
            } : undefined}

            {...props}
        />
    );
};
export default ContactPersonSelect;

type ContactPersonSearchProps = {
    companyEmbedded?: CompanyEmbeddedResponse | CompanyResponse | null;
    dirty: boolean;
    multiple?: boolean;
}
export const ContactPersonSearch: FC<Partial<Props> & ContactPersonSearchProps> = (
    {
        companyEmbedded,
        dirty,
        name,
        multiple,
        ...props
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");
    const {confirmPersonsManagement, navigateToCompany, companyRef} = useContactPersonSelect();
    const manipulator = useCompanyManipulator();
    const [company, setCompany] = useState<CompanyResponse>();

    useEffect(()=>{
        if (companyEmbedded?.id===company?.id || !companyEmbedded) return;

        const setPhysicalPerson = (c: CompanyResponse) => {
            if (c.physicalPerson && c.offices[0].contactPersons) {
                helpers.setValue(c.offices[0].contactPersons[0].id);
            }
        };

        if (!!(companyEmbedded as CompanyResponse).offices) {
            setCompany(companyEmbedded as CompanyResponse);
            setPhysicalPerson(companyEmbedded as CompanyResponse);
            return;
        }

        (async ()=>{
            const result = await manipulator.get.run({id: companyEmbedded.id});
            if (result) {
                setCompany(result);
                setPhysicalPerson(result);
            }
        })();
    }, [companyEmbedded]);

    return <>
        <ContactPersonSelect
            {...props}
            name={name}
            persons={getContactPersonList(company)}
            disabled={!companyEmbedded || company?.physicalPerson}
            loading={manipulator.loading}
            handlePersonsManagement={()=>{
                if (dirty) {
                    companyRef.current = company;
                    confirmPersonsManagement.handleOpen();
                } else navigateToCompany(company);
            }}
            SelectProps={{multiple}}
        />
        <LeavingConfirmationDialog {...confirmPersonsManagement.props} />
    </>;
}

export function useContactPersonSelect() {
    const history = useHistory();
    const companyRef = useRef<CompanyResponse>();

    const navigateToCompany = useCallback((company?: CompanyResponse)=>{
        if (!company) return;

        history.push(passURL(
            company.supplier
                ? PAGE_KEY.SUPPLIERS_DETAIL
                : PAGE_KEY.CUSTOMERS_DETAIL,
            {id: company.id}
        ));
    }, [history]);

    const confirmPersonsManagement = useConfirmationDialog(useCallback(()=>{
        const company = companyRef.current;
        if (!company) return;
        navigateToCompany(company);
    }, [navigateToCompany]));

    return useMemo(()=>({
        companyRef, confirmPersonsManagement, navigateToCompany
    }), [confirmPersonsManagement, navigateToCompany]);
}
