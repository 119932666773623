import React, {FC} from "react";
import GridChooserDialog, {
    ChooserDialogBase,
    GridChooserProps,
    GridChooserReturnProps,
    useGridChooserDialog
} from "../../common/dialog/GridChooserDialog";
import OffersGrid, {OffersGridRow} from "./OffersGrid";
import {OfferGridResponse} from "../../props/apiResponses";


const OfferChooserDialog: FC<ChooserDialogBase<OfferGridResponse>> = (props) => {

    return (
        <GridChooserDialog<OfferGridResponse, OffersGridRow, FC<GridChooserProps<OffersGridRow>>>

            {...props}

            entityName="nabídky"
            component={OffersGrid}
        />
    );
};
export default OfferChooserDialog;

export function useOfferChooserDialog(handleSelection: (offer: OfferGridResponse)=>void): GridChooserReturnProps<OfferGridResponse> {
    return useGridChooserDialog(handleSelection);
}