import Button from "../../../components/buttons/Button";
import {FileOpen, NoteAdd} from "@mui/icons-material";
import React, {useContext} from "react";
import {BusinessCaseContext} from "../props";

export interface CreateOrOpenPDFProps {
    generate: ()=>Promise<void>;
    loading: boolean;
    downloadGenerated: ()=>Promise<void>;
    wasGenerated: boolean;
}

const CreateOrOpenPDF = (
    {
        generate,
        loading,
        downloadGenerated,
        wasGenerated
    }: CreateOrOpenPDFProps
) => {
    const context = useContext(BusinessCaseContext);

    return (
        <>
            {
                wasGenerated && (
                    <Button
                        startIcon={<FileOpen />}
                        onClick={downloadGenerated}
                        loading={loading}
                    >
                        Otevřít PDF
                    </Button>
                )
            }
            {
                !context?.finished && (
                    <Button
                        startIcon={<NoteAdd />}
                        onClick={generate}
                        loading={loading}
                    >
                        Vytvořit PDF
                    </Button>
                )
            }
        </>
    );
};

export default CreateOrOpenPDF;
