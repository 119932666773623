import {TextFieldProps as MuiTextFieldProps, TextField as MuiTextField, InputAdornment, styled, css} from "@mui/material";
import { useField } from "formik";
import { ChangeEvent, FC } from "react";
import {FieldProps} from "../props";

type CommonProps = {
    adjustableHeight?: boolean;
}

export type TextFieldProps = MuiTextFieldProps & CommonProps;

const TextField: FC<TextFieldProps> = (props) => {
    const [field, meta] = useField(props?.name ?? "");

    return (
        <TextFieldInput
            {...field}
            {...props}

            onChange={(e: ChangeEvent<any>)=>{
                field.onChange(e);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}

            error={meta.touched && !!meta.error}
            helperText={(meta.touched && !!meta.error) ? meta.error : props.helperText}
        />
    );
};
export default TextField;

export const TextFieldInput: FC<TextFieldProps> = (props) => {
    return (
        <StyledTextField
            size="small"
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"

            {...props}

            InputProps={{
                ...props.InputProps,
                endAdornment: props.InputProps?.endAdornment
                    ? <InputAdornment position="end">{props.InputProps?.endAdornment}</InputAdornment>
                    : undefined
            }}
        />
    );
};


const StyledTextField = styled(
    ({adjustableHeight, ...rest}: TextFieldProps) => <MuiTextField {...rest} />
)<CommonProps>(({adjustableHeight})=>css`
  ${adjustableHeight && css`
    flex-grow: 1;
    
    & > .MuiInput-root {
      flex-grow: 1;
      align-items: flex-start;
    }
  `}
`);