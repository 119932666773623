import {FC, useCallback} from "react";
import {TextFieldInput} from "./TextField";
import {FieldProps, FORM_SPACING} from "../props";
import {useFormikContext} from "formik";
import {formatDate, notEmpty} from "../../props/helpers";
import {useDialog} from "../../common/dialog/props";
import Dialog from "../../common/dialog/Dialog";
import DateField from "./DateField";
import {Grid} from "@mui/material";
import ClearAndCloseActions from "../../common/dialog/ClearAndCloseActions";
import IconButton from "../../common/button/IconButton";
import {Close} from "@mui/icons-material";
import DateTimeField from "./DateTimeField";

interface Props extends Omit<FieldProps, "name"> {
    name: {from: string, to: string};
    withoutTime?: boolean;
}
const DateRangeField: FC<Props> = (
    {
        label,
        name,
        withoutTime
    }
) => {
    const {values, setFieldValue} = useFormikContext<any>();
    const dialog = useDialog(true);

    const clear = useCallback(()=>{
        dialog.handleClose();
        setFieldValue(name.from, null);
        setFieldValue(name.to, null);
    }, [name]);

    const printDate = (date: any) => formatDate(date, withoutTime ? "without time" : undefined);

    let value: string = "";
    if (notEmpty(values[name.from]) && notEmpty(values[name.to]))
        value = `${printDate(values[name.from])} – ${printDate(values[name.to])}`;
    if (notEmpty(values[name.from]) && !notEmpty(values[name.to]))
        value = `Od ${printDate(values[name.from])}`;
    if (notEmpty(values[name.to]) && !notEmpty(values[name.from]))
        value = `Do ${printDate(values[name.to])}`;

    const renderField = (labelName: string, fieldName: string, handleClear: ()=>void, min?: Date, max?: Date) => (
        <>
            <Grid item xs={11} sm={withoutTime ? 5 : 11}>
                {
                    withoutTime &&
                        <DateField
                            label={labelName}
                            name={fieldName}
                            DatePickerProps={{minDate: min, maxDate: max}}
                        />
                }
                {
                    !withoutTime &&
                        <DateTimeField
                            label={labelName}
                            name={fieldName}
                            DateTimePickerProps={{minDateTime: min, maxDateTime: max}}
                        />
                }
            </Grid>
            <Grid item xs={1} display="flex" alignItems="center">
                <IconButton gridVariant IconButtonProps={{sx: {mt: 2}}} onClick={handleClear}>
                    <Close />
                </IconButton>
            </Grid>
        </>
    );

    return (
        <>
            <TextFieldInput
                label={label}
                value={value}
                InputProps={{
                    onClick: dialog.handleOpen
                }}
            />
            <Dialog
                {...dialog.props}
                DialogProps={{...dialog.props.DialogProps, maxWidth: "xs"}}
                title={`Výběr ${withoutTime ? "data" : "času"} ${label ? "– "+label : ""}`}

                actions={
                    <ClearAndCloseActions handleClear={clear} handleClose={dialog.handleClose} />
                }
            >
                <Grid container spacing={FORM_SPACING}>
                    {renderField(
                        "Od",
                        name.from,
                        ()=>setFieldValue(name.from, null),
                        undefined,
                        notEmpty(values[name.to]) ? values[name.to] : undefined
                    )}
                    {renderField(
                        "Do",
                        name.to,
                        ()=>setFieldValue(name.to, null),
                        notEmpty(values[name.from]) ? values[name.from] : undefined
                    )}
                </Grid>
            </Dialog>
        </>
    );
};
export default DateRangeField;