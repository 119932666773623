import {FC} from "react";
import {TooltipProps, Tooltip as MuiTooltip} from "@mui/material";

const Tooltip: FC<TooltipProps> = (props) => {
    return (
        <MuiTooltip
            arrow

            {...props}
        >
            {props.children}
        </MuiTooltip>
    );
};
export default Tooltip;