import {FC, useCallback} from "react";
import {Delete} from "@mui/icons-material";
import {CompanyEmbeddedResponse, MeetingResponse} from "../../props/apiResponses";
import SaveAndCloseActions from "../../common/dialog/SaveAndCloseActions";
import {
    EntityFormDialogBase,
    EntityFormDialogReturnProps,
    useEntityFormDialog,
    useFormDialogMiddleware
} from "../dialog/props";
import Dialog from "../../common/dialog/Dialog";
import IconButton from "../../common/button/IconButton";
import DeleteConfirmationDialog, {useDeleteConfirmationDialog} from "../../common/dialog/DeleteConfirmationDialog";
import {Messages} from "../../props/messages";
import {formatDate} from "../../props/helpers";
import {useMeetingManipulator} from "../../services/meeting/MeetingService";
import MeetingForm, {useMeetingForm} from "./MeetingForm";

interface Props extends EntityFormDialogBase<MeetingResponse> {
    tradeId?: number;
    afterRemoval?: (id: number)=>void;
    company?: CompanyEmbeddedResponse;
}
const MeetingFormDialog: FC<Props> = (dialog) => {
    const {
        DialogProps,
        handleClose,
        entity,
        afterSave,
        tradeId,
        afterRemoval,
        company
    } = useFormDialogMiddleware(dialog);
    const {props} = useMeetingForm({meeting: entity ?? undefined, afterSave, tradeId});
    const handleSave = useCallback(()=>props.formikRef.current?.submitForm(), []);
    const {remove, loading: deleteLoading} = useMeetingManipulator();
    const deleteDialog = useDeleteConfirmationDialog(async ()=>{
        if (!entity) return;

        const result = await remove.run({id: entity.id})
        if (result!==null) {
            remove.messageHandler.success(Messages.REMOVED);
            deleteDialog.handleClose();
            if (afterRemoval) afterRemoval(entity.id);
        }
    });

    const loading = props.loading || deleteLoading;

    return (
        <Dialog
            DialogProps={{
                ...DialogProps,
                maxWidth: "md"
            }}
            title={entity ? <>Schůzka {formatDate(entity.meetingDate, "without time")}
                <IconButton
                    IconButtonProps={{color: "error", disabled: loading}}
                    onClick={deleteDialog.handleOpen}
                >
                    <Delete />
                </IconButton>
            </> : "Přidat schůzku"}
            actions={
                <SaveAndCloseActions
                    handleSave={handleSave}
                    handleClose={handleClose}
                    loading={loading}
                />
            }
        >
            <MeetingForm {...props} company={company} />
            <DeleteConfirmationDialog {...deleteDialog.props} entityName="Schůzka" loading={loading} />
        </Dialog>
    );
};
export default MeetingFormDialog;

export interface MeetingFormDialogReturnProps extends EntityFormDialogReturnProps<MeetingResponse> {}
export function useMeetingFormDialog(): MeetingFormDialogReturnProps {
    return useEntityFormDialog();
}