import {createContext, useCallback, useContext, useMemo, useState} from "react";

export type GlobalLoadingContextType = {
    startLoading: ()=>void;
    stopLoading: ()=>void;
    loading: boolean;
}
export const GlobalLoadingContext = createContext<GlobalLoadingContextType>({
    startLoading: ()=>{},
    stopLoading: ()=>{},
    loading: false
});
export const GlobalLoadingProvider = GlobalLoadingContext.Provider;

export function useGlobalLoadingSet(init: boolean = false): GlobalLoadingContextType {
    const [loading, setLoading] = useState<boolean>(init);

    const startLoading = useCallback(()=>setLoading(true), []);
    const stopLoading = useCallback(()=>setLoading(false), []);

    return useMemo(()=>({
        loading, stopLoading, startLoading
    }), [loading]);
}

export default function useGlobalLoading(): [()=>void, ()=>void] {
    const loading = useContext(GlobalLoadingContext);

    return [loading.startLoading, loading.stopLoading];
}