import {PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useRef} from "react";
import {FormikHelpers, FormikProps, FormikTouched, FormikValues, useFormikContext} from "formik";
import {Response} from "../props/apiResponses";
import {Messages} from "../props/messages";
import {MessageHandler} from "../props/messagesHandler";
import {useTimerEffect} from "../props/useTimerEffect";
import {Box, css, styled} from "@mui/material";

export const FORM_SPACING: number = 1.5;

export interface FieldProps {
    name?: string;
    label?: string;
    required?: boolean;
    className?: string;
}

export interface SearchFieldProps extends FieldProps {
    valueName?: string;
}

export type OnSubmit<T extends FormikValues> = (values: T, formikHelpers: FormikHelpers<T>) => void;

export interface FormProps<T extends FormikValues> {
    onSubmit: OnSubmit<T>;
    loading: boolean | undefined;
}

export interface FormOpt<T extends FormikValues, R extends Response> {
    afterSave?: (entity: R)=>void;
}

export interface FormReturnProps<T extends FormikValues> {
    props: FormProps<T>;
}

export function useForm<T extends FormikValues>(onSubmit: OnSubmit<T>, loading: boolean | undefined): FormReturnProps<T> {
    return useMemo(()=>({
        props: {onSubmit, loading}
    }), [onSubmit, loading]);
}

export interface LoadingFormReturnProps {
    loading: boolean;
}

export interface OuterSubmitFormProps<T extends FormikValues> extends FormProps<T> {
    formikRef: RefObject<FormikProps<T>>;
}

export interface OuterSubmitFormReturnProps<T extends FormikValues> extends FormReturnProps<T> {
    props: OuterSubmitFormProps<T>;
}

export function useOuterSubmitForm<T extends FormikValues>(onSubmit: OnSubmit<T>, loading: boolean | undefined): OuterSubmitFormReturnProps<T> {
    const props = useForm(onSubmit, loading);
    const formikRef = useRef<FormikProps<T>>(null);

    return {
        props: {...props.props, formikRef}
    };
}


export const saveIdEntity = async <R extends Response>(
    performUpdate: (id: number)=>Promise<R | null>,
    performCreate: ()=>Promise<R | null>,
    successMessage: MessageHandler,
    id?: number,
    afterSave?: (entity: R)=>void,
    resetForm?: ()=>void
): Promise<boolean> => {
    let result: R | null;

    if (id!==undefined)
        result = await performUpdate(id);
    else
        result = await performCreate();

    if (result!==null) {
        if (resetForm) resetForm();
        successMessage(Messages.SAVED);
        if (afterSave) afterSave(result);
        return true;
    }

    return false;
};

export function getPrefixedName(mainName?: string, index?: number, itemName?: string) {
    return `${mainName}.${index}${!!itemName ? "."+itemName : ""}`;
}


export const ListenChange = <T,>({
    onValuesChange
}: PropsWithChildren<
    {
        onValuesChange?: (values: T)=>void;
    }
    >
) => {
    const {values} = useFormikContext<T>();

    useEffect(()=>{
        if (onValuesChange) onValuesChange(values);
    }, [values]);

    return null;
};


export function useDetectFormChanges(onlyDirtyParam?: boolean) {
    const {dirty, touched} = useFormikContext<any>();

    return useMemo(():boolean => {
        if (onlyDirtyParam) return dirty;

        if (!dirty) return false;

        const traverse = (fieldTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined): boolean => {
            if (typeof fieldTouched === "boolean" || !fieldTouched) {
                return !!fieldTouched;
            } else if (Array.isArray(fieldTouched)) {
                for (let subFieldTouched of fieldTouched) {
                    if (traverse(subFieldTouched)) return true;
                }
            } else {
                for (let subFieldTouched of Object.values(fieldTouched)) {
                    if (traverse(subFieldTouched)) return true;
                }
            }

            return false;
        };

        return traverse(touched);
    }, [touched, dirty]);
}


export const SubmitOnChange = <T,>() => {
    const {submitForm, values, dirty} = useFormikContext<T>();
    const wasChanged = useRef<boolean>(false);

    useTimerEffect(useCallback(()=>{
        if (wasChanged.current || dirty) {
            wasChanged.current = true;
            submitForm().then();
        }
    }, [values]));

    return null;
};

export const ErrorsDebugger = <T,>() => {
    const {errors, values} = useFormikContext<T>();

    useEffect(()=>{
        console.log(errors, values);
    }, [errors]);

    return null;
}

export const OneFieldWrapper = styled(Box)(({theme})=>css`
  display: flex;
  align-items: flex-start;
  
  & > *:first-of-type {
    margin-right: ${theme.spacing(FORM_SPACING)};
    flex-grow: 1;
  }
  & > *:last-of-type {
    margin-top: ${theme.spacing(1.5)};
  }
`);
