import {FC} from "react";
import SaveButton from "../common/button/SaveButton";
import {ButtonProps, Grid, GridProps} from "@mui/material";
import {useDetectFormChanges} from "./props";

type Props = {
    loading?: boolean;
    ButtonProps?: ButtonProps;
    GridProps?: GridProps;
    onlyDirtyParam?: boolean;
};
const SaveGridItem: FC<Props> = ({loading, ButtonProps, GridProps, onlyDirtyParam}) => {
    const changes = useDetectFormChanges(onlyDirtyParam);

    return (
        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="flex-end" {...GridProps}>
            <SaveButton loading={loading} ButtonProps={{...ButtonProps, color: changes ? "error" : "primary"}} />
        </Grid>
    );
};
export default SaveGridItem;