import React, {FC, useCallback, useContext, useMemo} from "react";
import {BusinessCaseContext, BusinessCaseTabProps, useUpdateTerms} from "../../props";
import {useBusinessCaseManipulator} from "../../../../services/businessCase/BusinessCaseService";
import {AssignmentTurnedIn} from "@mui/icons-material";
import {BusinessCaseStatus} from "../../../../props/apiRequests";
import {Messages} from "../../../../props/messages";
import {useConfirmationDialog} from "../../../../common/dialog/props";
import {useMessages} from "../../../../props/messagesHandler";
import InvoiceItem from "./InvoiceItem";
import {Alert, Stack} from "@mui/material";
import Button from "../../../../common/button/Button";
import {Items} from "../../../../common/item/Items";
import ConfirmationDialog from "../../../../common/dialog/ConfirmationDialog";
import {priceFormatter} from "../../../../common/dataGrid/formatter/PriceFormatter";
import {round} from "../../../../props/helpers";

interface Props extends BusinessCaseTabProps {

}
const Invoice: FC<Props> = () => {
    const introForm = useUpdateTerms("Úvod", "introductionInvoice");
    const termsForm = useUpdateTerms("Dodatek", "paymentTermsInvoice");

    const context = useContext(BusinessCaseContext);
    const caseManipulator = useBusinessCaseManipulator();
    const {success} = useMessages();

    const confirmFinish = useConfirmationDialog(useCallback(async ()=>{
        if (!context?.data || !context?.setData) return;
        const result = await caseManipulator.patch.run({id: context.data.id, status: BusinessCaseStatus.COMPLETED});
        if (result!==null) {
            context.setData({...context.data, status: BusinessCaseStatus.COMPLETED});
            success(Messages.FINISHED_BUSINESS_CASE);
            confirmFinish.handleClose();
        }
    }, [context]));

    const remainingDiscount = useMemo<number | undefined>(()=>{
        if (!context?.data || (
            context.data.pricing.discountPercentage!==undefined
            && context.data.pricing.discountPercentage!==null
        )) return undefined;

        let exhausted: number = 0;

        for (let invoice of context.data.invoices) {
            exhausted += invoice.pricing.discountValue;
        }

        return round(context.data.pricing.discountValue-exhausted);
    }, [context?.data?.pricing, context?.data?.invoices]);

    termsForm.props.disabled = !!context?.finished;
    introForm.props.disabled = !!context?.finished;

    if (!context?.data) return null;

    return (
        <>
            {
                !!remainingDiscount && remainingDiscount>0 && (
                    <Alert severity="error" sx={{mb: 1}}>
                        Zbývá vám aplikovat {priceFormatter(remainingDiscount, context?.data?.pricing.currency.code)} ze slevy poskytnuté v nabídce.
                    </Alert>
                )
            }
            {
                !!remainingDiscount && remainingDiscount<0 && (
                    <Alert severity="error" sx={{mb: 1}}>
                        Máte poskytnuto více slevy ({priceFormatter(Math.abs(remainingDiscount), context?.data?.pricing.currency.code)}), než bylo původně plánováno.
                    </Alert>
                )
            }
            {
                !context.finished && (
                    <Items>
                        <Button
                            startIcon={<AssignmentTurnedIn />}
                            onClick={()=>confirmFinish.handleOpen()}
                            loading={caseManipulator.loading}
                        >
                            Dokončit obchodní případ
                        </Button>
                    </Items>
                )
            }
            <Stack spacing={4} sx={{mt: 4}}>
                {
                    context.data.invoices.map((invoice, i)=>
                        <InvoiceItem
                            key={invoice.id}
                            data={invoice}
                            index={i+1}
                            remainingDiscount={remainingDiscount}
                        />
                    )
                }
            </Stack>
            <ConfirmationDialog
                {...confirmFinish.props}
                title="Dokončení obchodní případu"
                message="Doopravdy si přejete obchodní případ dokončit? Tato skutečnost již nepůjde vrátit zpátky."
                primary="no"
                loading={caseManipulator.loading}
            />
        </>
    );
};
export default Invoice;
