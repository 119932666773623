import {ProductResponse} from "../../props/apiResponses";


export function getProductNameWithoutProduct(catalogCode?: string, type?: string): string {
    return (catalogCode ?? "") + (!!catalogCode ? " " : "") + (type ?? "");
}

export function getProductName(product: ProductResponse | null): string {
    if (!product) return "";
    return getProductNameWithoutProduct(product.catalogCode, product.catalogName);
}

