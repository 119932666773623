import useRequest, {idAddress} from "../RequestService";
import {BaseRP, ConfigurationKey, ConfigurationRequest} from "../../props/apiRequests";
import axios, {AxiosResponse} from "axios";
import {ConfigurationResponse} from "../../props/apiResponses";
import {useCallback, useMemo} from "react";
import {CRUDRequestServiceImpl, useCRUDRequest} from "../CRUDRequestService";


export const CONFIGURATIONS_API = "/configuration";
const configurationsAddress = (key?: ConfigurationKey) => idAddress(CONFIGURATIONS_API, key);


class ConfigurationService extends CRUDRequestServiceImpl<ConfigurationRequest, ConfigurationResponse> {}
const configurationService = new ConfigurationService(CONFIGURATIONS_API);

export function useConfigurationManipulator() {
    return useCRUDRequest<ConfigurationResponse, ConfigurationService>(configurationService);
}


/**
 * LIST
 */

export interface GetConfigurationsRP extends BaseRP {}
export async function getConfigurationsRequest({controller}: GetConfigurationsRP): Promise<AxiosResponse<ConfigurationResponse[]>> {
    return axios.get(configurationsAddress(),{signal: controller?.signal});
}

export function useConfigurationsManipulator() {
    const get = useRequest(getConfigurationsRequest);

    return {get, loading: get.loading};
}


/**
 * SPECIFIC CONFIGURATION
 */

export function useConfiguration(key: ConfigurationKey) {
    const manipulator = useConfigurationManipulator();

    const getData = useCallback((value: string): ConfigurationRequest=>({key, value}), []);

    const update = useCallback((value: string)=>manipulator.update.run(
        {
            id: key,
            data: getData(value)
        }
    ), []);

    const get = useCallback(()=>manipulator.get.run({id: key}), []);

    return useMemo(()=>(
        {
            update, get,
            loading: manipulator.loading
        }
    ), [update, manipulator.loading]);
}