import {FC} from "react";
import Button, {ButtonProps} from "./Button";
import {Edit} from "@mui/icons-material";

const EditButton: FC<ButtonProps> = (props) => {
    return (
        <Button
            {...props}
            startIcon={props.startIcon ?? <Edit />}
        >
            {props.children ?? "Upravit"}
        </Button>
    );
};
export default EditButton;