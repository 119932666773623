import {BaseRP, GetRP, ID, IdRP, OfferVersionRequest, PostRP, PutRP} from "../../props/apiRequests";
import {
    DocumentResponse,
    OfferResponse,
    OfferVersionGridResponse,
    OfferVersionResponse
} from "../../props/apiResponses";
import useRequest, {RequestOpt, RequestService} from "../RequestService";
import {CRUDRequestService, useCRUDRequest} from "../CRUDRequestService";
import offerService from "./OfferService";
import {UnsupportedMethod} from "../../props/errors";
import {useCallback, useContext, useMemo} from "react";
import {openFileBlob} from "../../props/helpers";
import {setOrderToItems} from "../trade/TradeService";
import {GridRP, GridService, useGridRequest} from "../GridService";
import {downloadDocument} from "../DocumentService";
import {OfferContext} from "../../pages/offer/props";

const OFFER_VERSIONS_API = "/versions";

interface VersionRP {
    offerId: number;
}

interface PatchOfferVersionRP extends VersionRP, IdRP, BaseRP {
    what: string;
}

export interface GetDocumentOffer extends GetRP, IdRP {}
class OfferVersionService extends RequestService<OfferVersionRequest, OfferVersionResponse> implements CRUDRequestService, GridService<OfferVersionResponse> {
    public address = ({id, offerId}: { id?: ID, offerId?: ID }) =>
        offerService.addressWithId(offerId) + this.addressWithId(id);

    public retrieve = ({id, controller, offerId}: GetRP & VersionRP & IdRP) =>
        this.get(this.address({id, offerId}), controller);
    public create = ({controller, data, offerId}: PostRP<OfferVersionRequest> & VersionRP) =>
        this.post(this.address({offerId}), data, controller);
    public update = ({id, data, controller, offerId}: PutRP<OfferVersionRequest> & VersionRP & IdRP) =>
        this.put(this.address({id, offerId}), setOrderToItems(data), controller);
    public remove = () => { throw new UnsupportedMethod(); };
    public status = ({id, offerId, controller, what}: PatchOfferVersionRP) =>
        this.patch<undefined, OfferVersionResponse>(this.address({offerId, id})+`/${what}`, undefined, controller);
    public generateDocument = ({controller, id, offerId}: GetRP & VersionRP & IdRP) =>
        this.get<DocumentResponse>(this.address({id, offerId})+"/document", controller);
    public retrieveDocument = ({controller, id}: GetDocumentOffer) =>
        this.getFile(`/documents/${id}/download`, controller); // TODO document-only service

    public grid = <Res>(rp: GridRP) => this.getGrid<Res>(this.address({}), rp);
}

const offerVersionService = new OfferVersionService(OFFER_VERSIONS_API);
export default offerVersionService;

export function useOfferVersionManipulator(opt?: RequestOpt) {
    return useCRUDRequest<OfferVersionResponse, OfferVersionService>(offerVersionService, undefined, {all: opt});
}

export function useOfferVersionsManipulator(globalLoading: boolean) {
    return useGridRequest<OfferVersionGridResponse>(offerVersionService, globalLoading);
}

export function useSealOffer(
    successUpdate: (offerVersion: OfferVersionResponse)=>void,
    offer?: OfferResponse,
    version?: OfferVersionResponse
) {
    const {run, loading} = useRequest(offerVersionService.status, undefined, {globalLoading: true});

    const seal = useCallback(async ()=>{
        if (!offer || !version) return;

        const result = await run({offerId: offer.id, id: version.id, what: "seal"});
        if (result!==null) successUpdate(result);
    }, [successUpdate, offer, version, run]);

    return useMemo(()=>({seal, loading}), [seal, loading]);
}

export function useOfferDocument() {
    const context = useContext(OfferContext);
    const retrieveRequest = useRequest(offerVersionService.retrieveDocument);
    const generateRequest = useRequest(offerVersionService.generateDocument);

    const generate = useCallback(async ()=>{
        if (!context?.data || !context?.version || !context?.setVersion) return;
        const result = await generateRequest.run({offerId: context.data.id, id: context.version.id});
        if (result!==null) {
            downloadDocument(result);
            context.setVersion({...context.version, document: result});
        }
    }, [context, generateRequest]);

    const downloadGenerated = useCallback(async () => {
        if (!context?.version?.document) return;
        const blob = await retrieveRequest.run({id: context.version.document.id});
        if (blob) openFileBlob(blob, context.version.document.title);
    }, [context?.version?.document, retrieveRequest]);

    const loading = retrieveRequest.loading || generateRequest.loading;

    return useMemo(()=>({generate, loading, downloadGenerated}), [generate, loading, downloadGenerated]);
}
