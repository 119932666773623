import {DocumentResponse} from "../props/apiResponses";
import {createURLFromBase64, makeDownload} from "../props/helpers";
import {RequestService} from "./RequestService";
import {FileRP, IdRP} from "../props/apiRequests";


export function downloadDocument({data, mimeType, title}: DocumentResponse) {
    if (!data) return;
    makeDownload(createURLFromBase64(data, mimeType), title);
}

export class DocumentService extends RequestService<any, DocumentResponse> {
    public uploadDocument = ({file, metadata}: FileRP) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("metadata", new Blob([JSON.stringify(metadata)], {
            type: "application/json"
        }));

        return this.upload("/documents", formData);
    };
}