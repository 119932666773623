import {FC, MouseEventHandler, ReactNode} from "react";
import {css, IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, styled} from "@mui/material";
import {Link} from "react-router-dom";

type CommonProps = {
    gridVariant?: boolean;
}

export type IconButtonProps = {
    IconButtonProps?: MuiIconButtonProps;
    children?: ReactNode;
    onClick?: MouseEventHandler;
    disabled?: boolean;
} & CommonProps;
const IconButton: FC<IconButtonProps> = (
    {
        IconButtonProps,
        children,
        gridVariant,
        onClick,
        disabled
    }
) => {
    return (
        <Button
            color="primary"
            size="small"
            gridVariant={gridVariant}
            onClick={onClick}
            disabled={disabled}

            {...IconButtonProps}
        >
            {children}
        </Button>
    );
};
export default IconButton;


type StylingProps = CommonProps;

const Button = styled(
    ({gridVariant, ...rest}: MuiIconButtonProps & StylingProps)=><MuiIconButton {...rest} />
)<StylingProps>(({theme, gridVariant})=>css`
  
  ${!!gridVariant && css`
    padding: 0;

    & .MuiSvgIcon-root {
      font-size: 1.4rem;
      width: 0.9em;
      height: 0.9em;
    }
  `}
`);