import {createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useMemo} from "react";
import CustomerOrder from "./tabs/CustomerOrder";
import OrderToSupplier from "./tabs/orderToSupplier/OrderToSupplier";
import ConfirmationToCustomer from "./tabs/ConfirmationToCustomer";
import DeliveryNote from "./tabs/deliveryNote/DeliveryNote";
import Invoice from "./tabs/invoice/Invoice";
import {
    BusinessCaseItemResponse,
    BusinessCaseResponse,
    CompanyEmbeddedResponse,
    CurrencyExchangeRateResponse,
    DocumentResponse,
    SupplierOrderDocumentResponse
} from "../../props/apiResponses";
import {StepperReturnProps} from "./Stepper";
import {TermsFormValues, useTermsForm} from "../../form/trade/TermsForm";
import {Messages} from "../../props/messages";
import {
    GetDocumentBusinessCase,
    useBusinessCaseDocumentManipulator,
    useBusinessCaseManipulator
} from "../../services/businessCase/BusinessCaseService";
import {TradeGridRow} from "../trade/props";
import {BusinessCaseRequest, DocumentRequest, DocumentType} from "../../props/apiRequests";
import {openFileBlob} from "../../props/helpers";
import {DeliveryNoteResponse} from "../../containers/trade/businessCase/schema/deliveryNote";
import usePutDeliveryNote from "../../containers/trade/businessCase/services/usePutDeliveryNote";
import {InvoiceRequest, InvoiceResponse} from "../../containers/trade/businessCase/schema/invoice";
import usePutInvoice from "../../containers/trade/businessCase/services/usePutInvoice";

export interface BusinessCaseTabProps {}

export enum TAB_KEY {
    CUSTOMER_ORDER="objednavka-zakaznika",
    ORDER_TO_SUPPLIER="objednavka-dodavateli",
    CONFIRMATION_TO_CUSTOMER="potvrzeni-objednavky-zakaznika",
    DELIVERY_NOTE="dodaci-list",
    INVOICE="faktura"
}

export const TABS: {[key in TAB_KEY]: {
    component: FC<BusinessCaseTabProps>,
    title: string;
    index: number;
}
} = {
    [TAB_KEY.CUSTOMER_ORDER]: {
        component: CustomerOrder,
        title: "Objednávka zákazníka",
        index: 0
    },
    [TAB_KEY.ORDER_TO_SUPPLIER]: {
        component: OrderToSupplier,
        title: "Objednávka dodavateli",
        index: 1
    },
    [TAB_KEY.CONFIRMATION_TO_CUSTOMER]: {
        component: ConfirmationToCustomer,
        title: "Potvrzení objednávky zákazníka",
        index: 2
    },
    [TAB_KEY.DELIVERY_NOTE]: {
        component: DeliveryNote,
        title: "Dodací list",
        index: 3,
    },
    [TAB_KEY.INVOICE]: {
        component: Invoice,
        title: "Faktura",
        index: 4
    }
};

interface BusinessCaseContextType {
    data?: BusinessCaseResponse;
    stepper: StepperReturnProps;
    exchangeRates?: CurrencyExchangeRateResponse[];
    setData?: Dispatch<SetStateAction<BusinessCaseResponse | undefined>>;
    afterSaveItem?: (entity: BusinessCaseItemResponse)=>void;
    lockedOffer: boolean;
    saveCase: (requestData: BusinessCaseRequest)=>Promise<boolean>;
    saveCaseLoading: boolean;
    finished: boolean;
}

export const BusinessCaseContext = createContext<BusinessCaseContextType | undefined>(undefined);
export const BusinessCaseProvider = BusinessCaseContext.Provider;

export interface BusinessCaseGridRow extends TradeGridRow {
    item: BusinessCaseItemResponse;
    isSubItem: boolean;
}

export function useUpdateTerms(
    termsLabel: string,
    termsKey: string,
    deliveryNote?: DeliveryNoteResponse,
    invoice?: InvoiceResponse
) {
    const context = useContext(BusinessCaseContext);
    const businessCaseManipulator = useBusinessCaseManipulator();
    const updateDeliveryNote = usePutDeliveryNote();
    const updateInvoice = usePutInvoice();

    const updateTerms = useCallback(async (values: TermsFormValues): Promise<boolean>=>{
        const businessCase = context?.data;
        if (!businessCase || !context?.setData || !context?.data) return false;

        if (deliveryNote) {
            try {
                await updateDeliveryNote.mutateAsync({
                    id: deliveryNote.id, data: {
                        ...deliveryNote,
                        deliveryNoteTerms: values.terms
                    }
                });

                for (let oldDeliveryNote of businessCase.deliveryNotes) {
                    if (deliveryNote.id === oldDeliveryNote.id) {
                        oldDeliveryNote.deliveryNoteTerms = values.terms;
                        break;
                    }
                }

                businessCaseManipulator.update.messageHandler.success(Messages.SAVED);
                context.setData({...businessCase});
                return true;
            } catch (e) {
                //
            }
        } else if (invoice) {
            const data: InvoiceRequest = invoice;
            if (termsKey==="introductionInvoice") {
                // intro
                data.introText = values.terms;
            } else {
                // appendix
                data.appendixText = values.terms;
            }

            try {
                const response = await updateInvoice.mutateAsync({id: invoice.id, data});
                context.setData({
                    ...context.data,
                    invoices: context.data.invoices.map(i=>{
                        if (i.id===response.id) return response;
                        else return i;
                    })
                });
                businessCaseManipulator.update.messageHandler.success(Messages.SAVED);
                return true;
            } catch (e) {
                //
            }
        } else {
            const result = await businessCaseManipulator.update.run({
                id: businessCase.id,
                data: {
                    ...businessCase,
                    [termsKey]: values.terms
                }
            });

            if (result!==null) {
                businessCaseManipulator.update.messageHandler.success(Messages.SAVED);
                context.setData(result);
                return true;
            }
        }
        return false;
    }, [context?.data, context?.setData, deliveryNote, invoice]);

    const terms = useMemo(()=>{
        const businessCase = context?.data;
        if (!businessCase) return "";

        if (deliveryNote) return deliveryNote.deliveryNoteTerms;

        if (invoice) {
            if (termsKey==="introductionInvoice") return invoice.introText;
            if (termsKey==="paymentTermsInvoice") return invoice.appendixText;
        }

        if (!(termsKey in businessCase)) return "";

        // @ts-ignore
        return businessCase[termsKey];
    }, []);

    return useTermsForm({
        termsLabel, terms,
        loading: businessCaseManipulator.loading || updateDeliveryNote.isLoading || updateInvoice.isLoading,
        update: updateTerms
    });
}


export function useBusinessCaseDocuments(supplier?: CompanyEmbeddedResponse, deliveryNote?: DeliveryNoteResponse) {
    const documentManipulator = useBusinessCaseDocumentManipulator();
    const context = useContext(BusinessCaseContext);

    const removeDocument = useCallback(async (document: DocumentResponse): Promise<boolean> => {
        if (!context?.data) return false;
        const result = await documentManipulator.remove.run({id: document.id});
        return result!==null;
    }, [context]);

    const removeDocumentByType = useCallback(async (documentType: DocumentType): Promise<boolean> => {
        const id = context?.data?.id;
        if (!id) return false;

        let documents: DocumentResponse[];
        if (documentType===DocumentType.SUPPLIER_DELIVERY_NOTE) {
            documents = (context?.data?.documents ?? []).filter((document)=>
                document.type===DocumentType.SUPPLIER_DELIVERY_NOTE
                && (document as SupplierOrderDocumentResponse).supplierId===supplier?.id
                && document.deliveryNoteId===deliveryNote?.id
            );
        } else {
            documents = (context?.data?.documents ?? []).filter((document)=>
                document.type===documentType
                && (!supplier || (document as SupplierOrderDocumentResponse).supplierId === supplier?.id)
            );
        }


        for (let oldDocument of documents) {
            if (!(await removeDocument(oldDocument))) return false;
        }
        return true;
    }, [context, supplier, removeDocument, deliveryNote]);

    const uploadFile = useCallback(async (file: File, metadata: DocumentRequest)=>{
        const id = context?.data?.id;
        if (!id) return null;
        return documentManipulator.upload.run({file,
            metadata: ({
                ...metadata,
                supplierId: supplier?.id,
                deliveryNoteId: deliveryNote?.id,
                businessCase: {id}
            } as SupplierOrderDocumentResponse)
        });
    }, [context?.data?.id, supplier?.id, deliveryNote]);

    const getDocumentBlob = useCallback(async (document: DocumentResponse)=>{
        if (!document.businessCase) return null;
        return documentManipulator.get.run({id: document.id})
    }, []);

    const setDocuments = useCallback((documents: DocumentResponse[])=>{
        if (!!context?.setData) {
            context.setData((oldData)=>(!oldData ? oldData : {
                ...oldData,
                documents
            }));
        }
    }, [context?.setData]);

    return useMemo(()=>({
        removeDocumentByType, uploadFile, getDocumentBlob, setDocuments, manipulator: documentManipulator,
        removeDocument
    }), [removeDocumentByType, uploadFile, getDocumentBlob, setDocuments, documentManipulator, removeDocument]);
}

export function useGeneratedDocument(documentType: DocumentType, documents: DocumentResponse[] | undefined, getBlob: (rp: GetDocumentBusinessCase)=>Promise<Blob | null>, deliveryNote?: DeliveryNoteResponse, invoice?: InvoiceResponse): [() => Promise<void>, boolean] {
    const context = useContext(BusinessCaseContext);

    const open = useCallback(async ()=>{
        if (!context?.data) return;
        const doc = documents?.find((doc)=>doc.type===documentType && (!deliveryNote || deliveryNote.id===doc.deliveryNoteId) && (!invoice || invoice.id===doc.invoiceId));
        if (!doc) return;
        const blob = await getBlob({id: doc.id});
        if (blob) openFileBlob(blob, doc.title);
    }, [context, documentType, getBlob, documents, deliveryNote]);

    const wasGeneratedOrder = useMemo(()=>{
        if (!documents) return false;
        for (let document of documents)
            if (document.type===documentType && (!deliveryNote || deliveryNote.id===document.deliveryNoteId) && (!invoice || invoice.id===document.invoiceId)) return true;
        return false;
    }, [documents, documentType, deliveryNote]);

    return useMemo(()=>([open, wasGeneratedOrder]), [open, wasGeneratedOrder]);
}
