import {DependencyList, useCallback, useEffect, useRef} from "react";
import {Timeout} from "./constants";

export function useTimerEffect(handleWork: ()=>void, deps?: DependencyList) {
    const timerRef = useRef<Timeout>();

    const setTimer = useCallback(()=>{
        timerRef.current = setTimeout(handleWork, 500);
    }, [handleWork]);

    deps = deps === undefined ? [setTimer] : [setTimer, ...deps];

    useEffect(()=>{
        if (!timerRef.current) setTimer();
        else {
            clearTimeout(timerRef.current);
            setTimer();
        }
    }, deps);
}

export function useTimerExecution() {
    const timerRef = useRef<Timeout>();

    const setTimer = useCallback((work: ()=>void)=>{
        timerRef.current = setTimeout(work, 500);
    }, []);

    return useCallback((work: ()=>void)=>{
        if (!timerRef.current) setTimer(work);
        else {
            clearTimeout(timerRef.current);
            setTimer(work);
        }
    }, [setTimer]);
}