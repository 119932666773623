import {FC, ReactNode} from "react";
import {Form as FormikForm, useFormikContext} from "formik";
import Prompt from "../props/Prompt";

type Props = {
    children: ReactNode;
    disablePrompt?: boolean;
};
const Form: FC<Props> = (
    {
        children,
        disablePrompt
    }
) => {
    const context = useFormikContext();

    return (
        <FormikForm>
            {children}
            {!disablePrompt && <Prompt when={context.dirty} />}
        </FormikForm>
    );
};
export default Form;