import React, {FC, useCallback, useMemo} from "react";
import FilterDataGrid from "../../common/dataGrid/filter/FilterDataGrid";
import {GridChooserProps, useGridChooserColumns} from "../../common/dialog/GridChooserDialog";
import {GridResponse, OfferGridResponse} from "../../props/apiResponses";
import {Filter, TextFieldFilter} from "../../common/dataGrid/filter/useFilter";
import {formatDate} from "../../props/helpers";
import {useOffersManipulator} from "../../services/offer/OfferService";
import {GridRP} from "../../services/GridService";
import useGridContextMenu from "../../common/dataGrid/contextMenu/GridContextMenu";
import BaseMenu, {getPlainText} from "../../common/dataGrid/contextMenu/menu/BaseMenu";
import useDataGrid from "../../common/dataGrid/useDataGrid";
import {StringParam, withDefault} from "use-query-params";

export type OffersGridRow = {
    code: number;
    year: number;
    customer: string;
    createdAt: string;
    item: OfferGridResponse;
};

interface Props extends GridChooserProps<OffersGridRow> {}
const OffersGrid: FC<Props> = (
    {
        topActions,
        actionsColumn,
        loading,
        onRowDoubleClick,
        disableQueryParams
    }
) => {
    const {run} = useOffersManipulator(true);

    const columns = useGridChooserColumns<OffersGridRow>([
        {
            key: "code",
            name: "Kód",
            width: "5%",
            minWidth: 50,
            headerRenderer: props => <Filter headerProps={props} name="code" element={TextFieldFilter()} />
        },
        {
            key: "year",
            name: "Rok",
            width: "5%",
            minWidth: 50,
            headerRenderer: props => <Filter headerProps={props} name="year" element={TextFieldFilter()} />
        },
        {
            key: "customer",
            name: "Zákazník",
            headerRenderer: props => <Filter headerProps={props} name="customer" element={TextFieldFilter()} />
        },
        {
            key: "createdAt",
            name: "Vytvořeno",
            width: "10%",
            minWidth: 100
        }
    ], actionsColumn);

    const request = useCallback((requestParams: GridRP)=>{
        return run(requestParams);
    }, []);

    const mapper = useCallback((response: GridResponse<OfferGridResponse>): OffersGridRow[] => {
        return response.data.map((item, i)=>{
            return {
                code: item.code,
                year: item.year,
                customer: item.customer,
                createdAt: formatDate(item.createdAt, "without time"),
                item
            };
        });
    }, []);

    const gridContextMenu = useGridContextMenu<OffersGridRow>(useMemo(()=>({
        main: {
            key: "main",
            component: props => <BaseMenu key="main" {...props} getPlainText={data => {
                if (data.column?.key==="createdAt") return formatDate(data.row.createdAt);
                return getPlainText(data);
            }} />
        }
    }), []));

    const dataGrid = useDataGrid(columns, request, mapper, useMemo(()=>({
        sorting: {init: {columnKey: "createdAt", direction: "DESC"}},
        filter: {
            schema: {
                code: withDefault(StringParam, ""),
                year: withDefault(StringParam, ""),
                customer: withDefault(StringParam, "")
            }
        },
        disableQueryParams
    }),[disableQueryParams]));

    return (
        <FilterDataGrid
            dataGrid={dataGrid}
            filter={dataGrid.filter}
            DataGridProps={{
                topActions,
                contextMenu: gridContextMenu.contextMenu,
                loading,
                onRowDoubleClick
            }}
        />
    );
};
export default OffersGrid;