import {Box, css, styled} from "@mui/material";

const BlockName = styled(Box)(()=>css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`);

export default BlockName;
