import React, {FC, MouseEventHandler, ReactElement, useCallback, useMemo} from "react";
import {Box, css, styled} from "@mui/material";
import Button from "../button/Button";
import Chip from "../Chip";
import {Add, Build, Clear, Delete, Download, Publish} from "@mui/icons-material";
import {SwitchInput} from "../../form/fields/Switch";
import {Items} from "../item/Items";

type BasicTopAction = {
    key: string;
    label: string;
    icon?: ReactElement;
    onClick?: MouseEventHandler;
    mini?: boolean;
    outlined?: boolean;
    color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
    rightSide?: boolean;
    value?: any;
    disabled?: boolean;
    loading?: boolean;
}

type ElementAction = {
    rightSide?: boolean;
    element: ReactElement;
}

export enum PredefinedTopAction {
    ADD,
    SWITCH,
    DOWNLOAD,
    EXPORT,
    CONFIG,
    REMOVE,
    IMPORT,
    CLEAR
}

type Predefined = [PredefinedTopAction, MouseEventHandler] | [PredefinedTopAction, MouseEventHandler, Partial<BasicTopAction>];

export type TopAction = BasicTopAction | Predefined | ElementAction;

type RightSideObj = {isRight: boolean};

export type TopActionsWrapperAlign = "center" | "flex-start" | "flex-end";
type Props = {
    alignItems?: TopActionsWrapperAlign
    children: TopAction[];
};
const TopActions: FC<Props> = ({children, alignItems}) => {

    const getElement = useCallback((action: BasicTopAction)=>{
        const variant = action.outlined ? "outlined" : undefined;
        const buttonColor = !!action.color && action.color !== "default" ? action.color : undefined;

        return (
            action.mini
                ?
                <Chip
                    key={action.key}
                    icon={action.icon}
                    onClick={action.onClick}
                    label={action.label}
                    variant={variant}
                    color={action.color ?? "primary"}
                    disabled={action.disabled || action.loading}
                />
                :
                <Button
                    key={action.key}
                    startIcon={action.icon}
                    onClick={action.onClick}
                    variant={variant}
                    color={buttonColor}
                    ButtonProps={{disabled: action.disabled}}
                    loading={action.loading}
                >
                    {action.label}
                </Button>
        );
    }, []);

    const renderElement = useCallback((action: BasicTopAction | ElementAction, isRightSideObj?: RightSideObj)=>{
        if (!!isRightSideObj) {
            if (isRightSideObj.isRight) {
                if (!action.rightSide) return false;
            } else {
                if (action.rightSide) return false;
            }
        }

        return true;
    }, []);

    const getPredefinedElement = useCallback((action: Predefined)=>{
        const userProps = action.length===3 ? action[2] : {};

        const props = {
            ...userProps,
            mini: userProps.mini ?? false,
            outlined: userProps.outlined ?? false,
            color: userProps.color ?? undefined
        };

        switch (action[0]) {
            case PredefinedTopAction.ADD:
                return getElement({
                    key: "add",
                    icon: <Add />,
                    onClick: action[1],
                    label: userProps.label ?? "Přidat",
                    ...props
                });

            case PredefinedTopAction.DOWNLOAD:
                return getElement({
                    key: "download",
                    icon: <Download />,
                    onClick: action[1],
                    label: userProps.label ?? "Stáhnout",
                    ...props
                });

            case PredefinedTopAction.EXPORT:
                return getElement({
                    key: "export",
                    icon: <Download />,
                    onClick: action[1],
                    label: userProps.label ?? "Export",
                    ...props
                });

            case PredefinedTopAction.CONFIG:
                return getElement({
                    key: "config",
                    icon: <Build />,
                    onClick: action[1],
                    label: userProps.label ?? "Nastavení",
                    ...props
                });

            case PredefinedTopAction.REMOVE:
                return getElement({
                    key: "remove",
                    icon: <Delete />,
                    onClick: action[1],
                    label: userProps.label ?? "Smazat",
                    ...props,
                    color: "error"
                });

            case PredefinedTopAction.IMPORT:
                return getElement({
                    key: "import",
                    icon: <Publish />,
                    onClick: action[1],
                    label: userProps.label ?? "Import",
                    ...props
                });

            case PredefinedTopAction.CLEAR:
                return getElement({
                    key: "clear",
                    icon: <Clear />,
                    onClick: action[1],
                    label: userProps.label ?? "Vymazat filtr",
                    ...props
                });

            case PredefinedTopAction.SWITCH:
                return (
                    <SwitchWrapper key={userProps.key ?? "switch"}>
                        <SwitchInput
                            label={userProps.label}
                            onClick={action[1]}
                            checked={userProps.value ?? undefined}
                            smallFont
                        />
                    </SwitchWrapper>
                );
        }
    }, []);

    const renderPredefined = useCallback((action: Predefined, isRightSideObj?: RightSideObj): boolean =>{
        if (!!isRightSideObj) {
            if (isRightSideObj.isRight) {
                if (action.length === 3 && !action[2].rightSide)
                    return false;
            } else {
                if (action.length === 3 && action[2].rightSide)
                    return false;
            }
        }

        return true;
    }, []);

    const getAction = useCallback((action: TopAction, isRightSideObj?: RightSideObj) => {
        if (Array.isArray(action)) {
            // predefined
            if (!renderPredefined(action, isRightSideObj)) return;
            return getPredefinedElement(action);
        } else if (!!(action as ElementAction).element) {
            // element action
            if (!renderElement(action, isRightSideObj)) return;
            return (action as ElementAction).element;
        } else {
            if (!renderElement(action, isRightSideObj)) return;
            return getElement(action as BasicTopAction);
        }
    }, [getElement]);

    let isRightSide: boolean = useMemo(
        ()=>{
            for (let action of children) {
                if (
                    (Array.isArray(action) && action.length===3 && action[2].rightSide)
                    || (!Array.isArray(action) && action.rightSide)
                )
                    return true;
            }

            return false;
        },
        [children]);

    if (!isRightSide)
        return (
            <Items alignItems={alignItems}>{children.map((action)=>getAction(action))}</Items>
        );



    return (
        <SideWrapper alignItems={alignItems}>
            <Items>
                {children.map((action)=>getAction(action, {isRight: false}))}
            </Items>
            <Items>
                {children.map((action)=>getAction(action, {isRight: true}))}
            </Items>
        </SideWrapper>
    );
};
export default TopActions;

const SideWrapper = styled(Box)(({theme})=>css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${theme.spacing(0.5)};
`);

const SwitchWrapper = styled(Box)(({theme})=>css`
  padding-left: ${theme.spacing(0.6)};
  margin-right: -${theme.spacing(1)};
`);