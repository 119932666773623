import {FC, ReactElement, RefObject, useCallback, useContext, useMemo, useRef} from "react";
import {Asserts, number, object, string} from "yup";
import {Formik, FormikProps, FormikValues} from "formik";
import {ErrorsDebugger, FORM_SPACING, FormProps, FormReturnProps, OnSubmit, useForm} from "../props";
import NumberTextField, {convertFromPercentage, convertToPercentage} from "../fields/NumberTextField";
import {BusinessCaseContext} from "../../pages/businessCase/props";
import {clearNumberInput} from "../../props/helpers";
import {Grid} from "@mui/material";
import DiscountField from "../fields/DiscountField";
import Summary from "../../pages/trade/Summary";
import SaveGridItem from "../SaveGridItem";
import TextField from "../fields/TextField";
import {BusinessCaseRequest, PricingRequest} from "../../props/apiRequests";
import {Messages} from "../../props/messages";
import {priceFormatter} from "../../common/dataGrid/formatter/PriceFormatter";
import {useMessages} from "../../props/messagesHandler";
import Form from "../Form";
import usePutInvoice from "../../containers/trade/businessCase/services/usePutInvoice";
import {InvoiceResponse} from "../../containers/trade/businessCase/schema/invoice";

const schema = {
    discountValue: number().min(0).nullable(),
    discountPercentage: number().when("isDiscountInPercentage", {
        is: true,
        then: number().min(0).nullable()
    }),
    customerOrderCode: string()
};
const schemaObject = object(schema);

interface CustomerOrderFormValues extends Asserts<typeof schemaObject>, FormikValues {
    isDiscountInPercentage: boolean;
}

interface Props extends FormProps<CustomerOrderFormValues> {
    fileUploadComponent: ReactElement;
    disabled?: boolean;
}
const CustomerOrderForm: FC<Props> = (
    {
        onSubmit,
        fileUploadComponent,
        loading,
        disabled
    }
) => {
    const context = useContext(BusinessCaseContext);

    const savePricing = useCallback(async (pricingData: Partial<PricingRequest>)=>{
        if (!context?.data) return false;
        const data: BusinessCaseRequest = {
            ...context.data,
            pricing: {
                ...context.data.pricing,
                ...pricingData
            }
        };

        return context.saveCase(data);
    }, [context]);

    const initValues = useCallback((): CustomerOrderFormValues=>{
        const pricing = context?.data?.pricing;
        const discountPercentage = pricing?.discountPercentage;
        const discountValue = pricing?.discountValue;

        return ({
            discountPercentage: !!discountPercentage ? Number(convertFromPercentage(discountPercentage)) : 0,
            discountValue: discountValue ?? 0,
            isDiscountInPercentage: !!discountPercentage,
            customerOrderCode: context?.data?.customerOrderCode ?? ""
        });
    }, [context]);

    return (
        <Formik
            initialValues={initValues()}
            validationSchema={schemaObject}
            onSubmit={onSubmit}
        >
            {
                ({values})=>
                    <Form disablePrompt> {/* TODO remove disablePrompt */}
                        <Grid container spacing={FORM_SPACING}>
                            <Grid item xs={12} sm={5}>
                                <Grid container spacing={FORM_SPACING}>
                                    <Grid item xs={12}>
                                        {fileUploadComponent}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Identifikátor objednávky u zákazníka"
                                            name="customerOrderCode"
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DiscountField
                                            label="Sleva na nabídku"
                                            percentageName="discountPercentage"
                                            valueName="discountValue"
                                            checkboxName="isDiscountInPercentage"
                                            calculationPrice={context?.data?.pricing.priceRaw ?? 0}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Grid container spacing={FORM_SPACING}>
                                    <Grid item xs={12}>
                                        {
                                            !!context?.data &&
                                                <Summary
                                                    formatPrice={(price)=>priceFormatter(price, context?.data?.pricing.currency.code)}
                                                    loading={!!context?.saveCaseLoading}
                                                    items={context?.data?.items ?? []}
                                                    savePricing={savePricing}
                                                    pricing={{
                                                        ...context?.data?.pricing,
                                                        discountPercentage: values.isDiscountInPercentage
                                                            ? convertToPercentage(values.discountPercentage ?? 0)
                                                            : null,
                                                        discountValue: values.discountValue ?? 0
                                                    }}
                                                    disabled={disabled}
                                                />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                !disabled && <SaveGridItem loading={loading} />
                            }
                        </Grid>
                    </Form>
            }
        </Formik>
    );
};
export default CustomerOrderForm;

export interface CustomerOrderFormReturnProps extends FormReturnProps<CustomerOrderFormValues> {
    props: Omit<Props, "fileUploadComponent">;
}
export function useCustomerOrderForm(): CustomerOrderFormReturnProps {
    const context = useContext(BusinessCaseContext);
    const {success} = useMessages();
    const putInvoice = usePutInvoice();

    const onSubmit: OnSubmit<CustomerOrderFormValues> = useCallback( async (values, formikHelpers) => {
        if (!context?.saveCase || !context?.data) return;

        const requestedData: BusinessCaseRequest = {
            ...context.data,
            ...values,
            pricing: {
                ...context.data.pricing,
                discountPercentage: values.isDiscountInPercentage
                    ? convertToPercentage(values.discountPercentage ?? 0)
                    : null,
                discountValue: values.discountValue ?? 0
            }
        };
        if (await context.saveCase(requestedData)) {

            // TODO clear invoices pricing
            /*if (
                requestedData.pricing?.discountPercentage!==context.data.pricing.discountPercentage
                || requestedData.pricing?.discountValue!==context.data.pricing.discountValue
            ) {
                const promises: Promise<InvoiceResponse>[] = [];
                for (let invoice of context.data.invoices) {
                    promises.push(putInvoice.mutateAsync({
                        id: invoice.id,
                        data: {
                            ...invoice,
                            pricing: {
                                ...invoice.pricing,
                                discountPercentage: null,
                                discountValue: 0
                            }
                        }
                    }));
                }

                await Promise.all(promises);
            }*/

            formikHelpers.resetForm({values});
            success(Messages.SAVED);
        }
    }, [context, success]);

    const {props} = useForm(onSubmit, !!context?.saveCaseLoading);

    return useMemo(()=>({
        props
    }), [props]);
}
