import {FC, useMemo} from "react";
import Chip, {ChipProps} from "./Chip";
import {translateState} from "../props/apiRequests";
import {useTheme} from "@mui/material";

export type ActivityStatus = "ACTIVE" | "INACTIVE" | "COMPLETED" | "INITIAL_PHASE" | "LOCKED" | "INVOICE_GENERATED" | "CANCELED" | "READ" | "UNREAD" | "IN_PROGRESS";

type Props = {
    ChipProps?: ChipProps;
    status?: ActivityStatus;
    className?: string;
}
const ActivityChip: FC<Props> = ({ChipProps, status, className}) => {
    const theme = useTheme();
    const color: ChipProps["color"] = useMemo(()=>{
        if (Array.from(["READ", "COMPLETED"]).includes(status ?? "")) return "success";
        if (Array.from(["INACTIVE", "CANCELED"]).includes(status ?? "")) return "error";
        if (Array.from(["INVOICE_GENERATED"]).includes(status ?? "")) return "warning";
        return "default";
    }, [status]);

    return <Chip
        label={translateState(status)}
        variant={status==="COMPLETED" ? "filled" : "outlined"}
        color={color}
        style={status==="COMPLETED" ? {color: theme.palette.common.white} : undefined}
        className={className}
        {...ChipProps}
    />;
};
export default ActivityChip;