import {FC} from "react";
import {Box, ChipProps, css, styled} from "@mui/material";
import Chip from "./Chip";

type Props = {
    children: ChipProps[];
    className?: string;
};
const ChipGroup: FC<Props> = ({children, className}) => {
    return (
        <Wrapper className={className}>
            {
                children.map((item, i) =>
                    <Chip
                        key={i}
                        variant="outlined"
                        {...item}
                    />
                )
            }
        </Wrapper>
    );
};
export default ChipGroup;

const SPACING = 0.3;
const Wrapper = styled(Box)(({theme})=>css`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${theme.spacing(SPACING)};
  margin-right: -${theme.spacing(SPACING)};
  
  & .MuiChip-root {
    margin: ${theme.spacing(SPACING)};
  }
`);