import {FC} from "react";
import {ChipProps as MuiChipProps, Chip as MuiChip, styled, css} from "@mui/material";

export interface ChipProps extends MuiChipProps {
    gridVariant?: boolean;
}

const Chip: FC<ChipProps> = ({gridVariant, ...props}) => {
    return <StyledChip
        size="small"
        color="primary"
        sx={gridVariant ? (theme)=>({height: "20px", fontSize: theme.typography.overline.fontSize}) : undefined}
        {...props}
    />;
};
export default Chip;

const StyledChip = styled(MuiChip)(()=>css`
  border-radius: 5px;
`);