import {ReactElement} from "react";
import {Box, css, styled} from "@mui/material";
import ActivityChip, {ActivityStatus} from "../../ActivityChip";


export function ActivityFormatter(status: ActivityStatus): ReactElement {

    return (
        <Wrapper>
            <ActivityChip status={status} ChipProps={{gridVariant: true}} />
        </Wrapper>
    );
}

const Wrapper = styled(Box)(()=>css`
  display: flex;
  align-items: center;
  height: 100%;
`);