import {PropsWithChildren, useState} from "react";
import {ContextMenuRef, GridContextMenuElementProps} from "../GridContextMenu";
import GridContextMenuWrapper from "./GridContextMenuWrapper";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import {useMessages} from "../../../../props/messagesHandler";
import {Messages} from "../../../../props/messages";
import {ContentCopy, Visibility} from "@mui/icons-material";
import PropertyDialog, {usePropertyDialog} from "../../../dialog/PropertyDialog";

export interface BaseDataGridContextMenuProps<Row> extends GridContextMenuElementProps<Row> {
    getPlainText?: (data: ContextMenuRef<Row>)=>string;
}
const BaseMenu = <Row,>(
    {
        controls,
        children,
        visible,
        data,
        getPlainText
    }: PropsWithChildren<BaseDataGridContextMenuProps<Row>>
) => {
    const messageHandler = useMessages();
    const propertyDialog = usePropertyDialog();
    const [title, setTitle] = useState<string | undefined>();
    const [message, setMessage] = useState<string | undefined>();

    return (
        <>
            <GridContextMenuWrapper controls={controls} visible={visible}>
                {
                    !!getPlainText &&
                    <MenuItem onClick={()=>{
                        if (!data.current?.column) return;

                        window.navigator.clipboard.writeText(getPlainText(data.current)).then(()=>{
                            messageHandler.info(Messages.SAVED_TO_CLIPBOARD);
                        });

                        controls.handleClose();
                    }}>
                        <ListItemIcon>
                            <ContentCopy fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Kopírovat</ListItemText>
                    </MenuItem>
                }
                {
                    !!getPlainText &&
                    <MenuItem onClick={()=>{
                        if (!data.current) return;
                        setTitle(data.current?.column?.name ? data.current.column.name.toString() : undefined);
                        setMessage(getPlainText(data.current));
                        propertyDialog.handleOpen();
                        controls.handleClose();
                    }}>
                        <ListItemIcon>
                            <Visibility fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Zobrazit</ListItemText>
                    </MenuItem>
                }
                {children}
            </GridContextMenuWrapper>
            <PropertyDialog {...propertyDialog.props} title={title} message={message} />
        </>
    );
};
export default BaseMenu;

export function getPlainText(data: ContextMenuRef<any>): string {
    if (!data.column) return "";
    return data.row[data.column.key].toString();
}