import {CurrencyExchangeRateResponse, OfferResponse, OfferVersionResponse} from "../../props/apiResponses";
import {createContext, Dispatch, ReactNode, SetStateAction} from "react";

interface OfferContextType {
    data?: OfferResponse;
    version?: OfferVersionResponse;
    setData?: Dispatch<SetStateAction<OfferResponse | undefined>>;
    setVersion?: Dispatch<SetStateAction<OfferVersionResponse | undefined>>;
    completed?: boolean;
    formatPrice: (price: number)=>ReactNode;
    exchangeRates?: CurrencyExchangeRateResponse[];
}

export const OfferContext = createContext<OfferContextType | undefined>(undefined);
export const OfferProvider = OfferContext.Provider;