import {FC} from "react";
import {DateTimePicker, DateTimePickerProps} from "@mui/x-date-pickers";
import {TextFieldInput} from "./TextField";
import {FieldProps} from "../props";
import {useField} from "formik";

interface Props extends FieldProps {
    DateTimePickerProps?: Partial<DateTimePickerProps<Date, Date>>;
}

/**
 * The values are of type Date.
 * If you want nothing, set the default value to null.
 *
 * @param label
 * @param name
 * @param required
 * @param DatePickerProps
 * @constructor
 */
const DateTimeField: FC<Props> = (
    {
        label,
        name,
        required,
        DateTimePickerProps
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");

    return (
        <DateTimePicker
            label={label}

            {...field}
            onChange={value => {
                helpers.setValue(value, true);
            }}

            renderInput={(params) => <TextFieldInput
                required={required}
                {...params}

                error={(meta.touched && !!meta.error) || params.error}
                helperText={params.error ? "Formát není validní" : (meta.touched && meta.error)}
            />}


            {...DateTimePickerProps}
        />
    );
};
export default DateTimeField;