import React, {FC, ReactNode, useCallback, useMemo} from "react";
import {BusinessCaseGridResponse, GridResponse, OfficeResponse} from "../../props/apiResponses";
import {useBusinessCasesManipulator} from "../../services/businessCase/BusinessCaseService";
import {GridChooserProps, useGridChooserColumns} from "../../common/dialog/GridChooserDialog";
import {Filter, SpecificSelectFilter, TextFieldFilter} from "../../common/dataGrid/filter/useFilter";
import {ActivityColumn} from "../../common/dataGrid/Columns";
import {businessCasesOptions} from "../../form/fields/select/options";
import {formatDate} from "../../props/helpers";
import {GridRP} from "../../services/GridService";
import useGridContextMenu from "../../common/dataGrid/contextMenu/GridContextMenu";
import BaseMenu, {getPlainText} from "../../common/dataGrid/contextMenu/menu/BaseMenu";
import useDataGrid from "../../common/dataGrid/useDataGrid";
import {ArrayParam, StringParam, withDefault} from "use-query-params";
import {BusinessCaseStatus} from "../../props/apiRequests";
import FilterDataGrid from "../../common/dataGrid/filter/FilterDataGrid";
import {
    getContextAsList,
    getMassPropertyAsElement,
    showMoreFormatter
} from "../../common/dataGrid/formatter/ShowMoreFormatter";

export type BusinessCasesGridRow = {
    code: number;
    year: number;
    customer: string;
    contactPerson: string;
    lastModificationTo: string;
    status: string;
    item: BusinessCaseGridResponse;
    previewItem: ReactNode;
    previewItems: ReactNode[];
}

interface Props extends GridChooserProps<BusinessCasesGridRow> {}


const BusinessCasesGrid: FC<Props> = (
    {
        topActions,
        actionsColumn,
        loading,
        onRowDoubleClick,
        disableQueryParams
    }
) => {
    const {run} = useBusinessCasesManipulator(true);

    const columns = useGridChooserColumns<BusinessCasesGridRow>([
        {
            key: "code",
            name: "Kód",
            headerRenderer: (props)=><Filter headerProps={props} name="code" element={TextFieldFilter()} />,
            width: "5%",
            minWidth: 50
        },
        {
            key: "year",
            name: "Rok",
            headerRenderer: (props)=><Filter headerProps={props} name="year" element={TextFieldFilter()} />,
            width: "5%",
            minWidth: 50
        },
        {
            key: "customer",
            name: "Zákazník",
            headerRenderer: (props)=><Filter headerProps={props} name="customer" element={TextFieldFilter()} />
        },
        {
            key: "contactPerson",
            name: "Kontaktní osoba",
            headerRenderer: props => <Filter headerProps={props} name="contactPerson" element={TextFieldFilter()} />
        },
        {
            key: "previewItem",
            name: "Dodávané položky",
            formatter: ({row})=>showMoreFormatter(row.previewItem, row.previewItems.length, getContextAsList(row.previewItems)),
            sortable: false
        },
        ActivityColumn({options: businessCasesOptions}, {width: "10%", minWidth: 100}),
        {
            key: "lastModificationTo",
            name: "Datum změny",
            width: "10%",
            minWidth: 100
        }
    ], actionsColumn);

    const request = useCallback((requestParams: GridRP)=>run(requestParams), []);

    const mapper = useCallback((response: GridResponse<BusinessCaseGridResponse>): BusinessCasesGridRow[] =>
        response.data.map((item)=>{
            let [previewItem, previewItems] = getMassPropertyAsElement<string>(
                item.previewItems,
                e=>e
            );

            return ({
                code: item.code,
                year: item.year,
                customer: item.company,
                contactPerson: item.contactPerson,
                lastModificationTo: formatDate(item.lastModificationAt, "without time"),
                status: item.status,
                previewItem,
                previewItems,
                item
            });
        })
    , []);

    const gridContextMenu = useGridContextMenu<BusinessCasesGridRow>(useMemo(()=>({
        main: {
            key: "main",
            component: props => <BaseMenu key="main" {...props} getPlainText={data => {
                if (data.column?.key==="lastModificationTo") return formatDate(data.row.lastModificationTo);
                if (data.column?.key==="previewItem") return data.row.item.previewItems.join(", ");
                return getPlainText(data);
            }} />
        }
    }), []));

    const dataGrid = useDataGrid(columns, request, mapper, useMemo(()=>({
        sorting: {init:{}},
        filter: {
            schema: {
                code: withDefault(StringParam, ""),
                year: withDefault(StringParam, ""),
                contactPerson: withDefault(StringParam, ""),
                customer: withDefault(StringParam, ""),
                status: withDefault(ArrayParam, Object.values(BusinessCaseStatus)),
            }
        },
        disableQueryParams
    }),[disableQueryParams]));

    return (
        <FilterDataGrid
            dataGrid={dataGrid}
            filter={dataGrid.filter}
            DataGridProps={{
                topActions,
                contextMenu: gridContextMenu.contextMenu,
                loading,
                onRowDoubleClick
            }}
        />
    );
};
export default BusinessCasesGrid;
