import useRequest, {RequestOpt} from "../RequestService";
import {BaseRP, IdRP, NotificationRequest, NotificationStatus} from "../../props/apiRequests";
import {NotificationResponse} from "../../props/apiResponses";
import {GridCRUDServiceImpl, GridServiceImpl, useGridRequest} from "../GridService";
import {useCallback, useMemo, useState} from "react";
import {useCRUDRequest} from "../CRUDRequestService";

const NOTIFICATIONS_API = "/notifications";

interface PatchNotificationRP extends IdRP, BaseRP {
    status: string;
}
class NotificationService extends GridCRUDServiceImpl<NotificationRequest, NotificationResponse> {
    public status = ({id, status, controller}: PatchNotificationRP) =>
        this.patch(this.addressWithId(id)+"/"+status.toLowerCase(), controller);

    public generateGridService = (owned?: boolean) => {
        if (owned) return new GridServiceImpl<any, NotificationResponse>(this.addressWithId()+(owned ? "/owned" : ""));
        return new GridServiceImpl<any, NotificationResponse>(this.addressWithId());
    }
}
const notificationService = new NotificationService(NOTIFICATIONS_API);


export function useNotificationManipulator(opt?: RequestOpt) {
    return useCRUDRequest<NotificationResponse, NotificationService>(notificationService, undefined, {all: opt});
}

export function useNotificationsManipulator(globalLoading: boolean, owned?: boolean) {
    return useGridRequest<NotificationResponse>(notificationService.generateGridService(owned), globalLoading);
}


export function useFutureUnreadNotifications(pageSize: number = 4) {
    const manipulator = useNotificationsManipulator(false);
    const [notifications, setNotifications] = useState<NotificationResponse[]>();

    const request = useCallback(async (controller?: AbortController)=>{
        const result = await manipulator.run({params: {
            pageNum: 1,
            pageSize,
            notificationDateFrom: new Date(),
            status: NotificationStatus.UNREAD,
            sort: ["-notificationDateFrom"]
        }, controller});
        if (result) setNotifications(result.data);
    }, []);

    return useMemo(()=>({
        notifications, request
    }), [notifications, request]);
}

export function useNotificationStatus(callback: (status: NotificationStatus)=>void, id?: number): [
    ()=>void,
    ()=>void,
    boolean
] {
    const patch = useRequest(notificationService.status);

    const request = useCallback(async (status: NotificationStatus)=>{
        if (id===undefined) return;
        const result = await patch.run({id, status});
        if (result!==null) callback(status);
    }, [id, callback]);

    const readNotification = useCallback(()=>request(NotificationStatus.READ), [request]);
    const unreadNotification = useCallback(()=>request(NotificationStatus.UNREAD), [request]);

    return useMemo(()=>([readNotification, unreadNotification, patch.loading]),
        [readNotification, unreadNotification, patch.loading]);
}

export function useNotificationHighlight() {
    const patch = useRequest(notificationService.status);
    return useCallback(async (id: number)=>await patch.run({id, status: "highlighted"}), [patch]);
}