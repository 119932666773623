import {ChangeEvent, FC} from "react";
import {TextFieldInput, TextFieldProps} from "./TextField";
import {useField} from "formik";

type Props = {
    precision?: number;
    percentage?: boolean;
} & TextFieldProps;
const NumberTextField: FC<Props> = (
    {
        precision,
        percentage,
        ...props
    }
) => {
    const [field, meta, helpers] = useField(props?.name ?? "");

    return (
        <TextFieldInput
            {...field}
            {...props}

            onChange={(e: ChangeEvent<any>)=>{
                const value = convertToNumber(e.target.value, precision ?? 2);
                helpers.setValue(
                    value,
                    true
                );
                if (props.onChange) props.onChange({...e, target: {...e.target, value}});
            }}
            value={convertFromNumber(field.value)}

            InputProps={{
                ...props.InputProps,
                endAdornment: percentage ? "%" : props.InputProps?.endAdornment
            }}

            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
        />
    );
};
export default NumberTextField;

export function convertToNumber(num: string, precision: number): string {
    let numericString = num
        .replaceAll(".","")
        .replaceAll(",", ".");

    let pointPosition: number;
    if ((pointPosition = numericString.indexOf("."))!==-1) {
        const diff = numericString.length - pointPosition - 1;
        if (diff>precision) {
            numericString = numericString.substr(0, pointPosition+precision+1);
        }
    }

    return numericString;
}

export function convertFromNumber(num?: string | number | null): string {
    if (!num) return "";
    return num.toString()
        .replaceAll(".", ",");
}

export function convertFromPercentage(percentage: number | string): string {
    return (Number(percentage)*100).toString();
}

export function convertToPercentage(numString: string | number): number {
    return Number(numString)/100;
}
