import React, {ReactNode, useCallback, useMemo} from "react";
import {GridResponse, OfferVersionGridResponse} from "../../props/apiResponses";
import {GridChooserProps, useGridChooserColumns} from "../../common/dialog/GridChooserDialog";
import {useOfferVersionsManipulator} from "../../services/offer/OfferVersionService";
import {Filter, TextFieldFilter} from "../../common/dataGrid/filter/useFilter";
import {GridRP} from "../../services/GridService";
import {formatDate} from "../../props/helpers";
import useGridContextMenu from "../../common/dataGrid/contextMenu/GridContextMenu";
import BaseMenu, {getPlainText} from "../../common/dataGrid/contextMenu/menu/BaseMenu";
import useDataGrid from "../../common/dataGrid/useDataGrid";
import {ArrayParam, StringParam, withDefault} from "use-query-params";
import FilterDataGrid from "../../common/dataGrid/filter/FilterDataGrid";
import {ActivityColumn} from "../../common/dataGrid/Columns";
import {offerStatusOptions} from "../../form/fields/select/options";
import {OfferVersionStatus} from "../../props/apiRequests";
import {TopAction} from "../../common/dataGrid/TopActions";
import {Frame, FrameHeading} from "../../common/Frame";
import {Grid} from "@mui/material";
import {FORM_SPACING} from "../../form/props";
import TextField from "../../form/fields/TextField";
import {
    getContextAsList,
    getMassPropertyAsElement,
    showMoreFormatter
} from "../../common/dataGrid/formatter/ShowMoreFormatter";

export type OfferVersionsGridRow = {
    code: string;
    year: number;
    version: string;
    customer: string;
    contactPerson: string;
    status: string;
    createdAt: string;
    item: OfferVersionGridResponse;
    previewItem: ReactNode;
    previewItems: ReactNode[];
};

interface Props extends GridChooserProps<OfferVersionsGridRow> {}
const OfferVersionsGrid = (
    {
        topActions,
        actionsColumn,
        loading,
        onRowDoubleClick,
        disableQueryParams
    }: Props
) => {
    const {run} = useOfferVersionsManipulator(true);

    const columns = useGridChooserColumns<OfferVersionsGridRow>([
        {
            key: "code",
            name: "Číslo nabídky",
            width: "5%",
            minWidth: 110,
            headerRenderer: props => <Filter headerProps={props} name="code" element={TextFieldFilter()} />
        },
        {
            key: "version",
            name: "Verze",
            width: "5%",
            minWidth: 50,
            headerRenderer: props => <Filter headerProps={props} name="version" element={TextFieldFilter()} />
        },
        {
            key: "year",
            name: "Rok",
            width: "5%",
            minWidth: 50,
            headerRenderer: props => <Filter headerProps={props} name="year" element={TextFieldFilter()} />
        },
        {
            key: "customer",
            name: "Zákazník",
            headerRenderer: props => <Filter headerProps={props} name="customer" element={TextFieldFilter()} />
        },
        {
            key: "contactPerson",
            name: "Kontaktní osoba",
            headerRenderer: props => <Filter headerProps={props} name="contactPerson" element={TextFieldFilter()} />
        },
        {
            key: "previewItem",
            name: "Nabízené položky",
            formatter: ({row})=>showMoreFormatter(row.previewItem, row.previewItems.length, getContextAsList(row.previewItems)),
            sortable: false
        },
        ActivityColumn({options: offerStatusOptions}, {width: "10%", minWidth: 100}),
        {
            key: "createdAt",
            name: "Vytvořeno",
            width: "10%",
            minWidth: 100
        }
    ], actionsColumn);

    const request = useCallback((requestParams: GridRP)=>{
        return run(requestParams);
    }, []);

    const mapper = useCallback((response: GridResponse<OfferVersionGridResponse>): OfferVersionsGridRow[] => {
        return response.data.map((item, i)=>{
            let [previewItem, previewItems] = getMassPropertyAsElement<string>(
                item.previewItems,
                e=>e
            );

            return {
                code: item.code.toString().padStart(3, "0"),
                year: item.year,
                version: item.version.toString().padStart(2, "0"),
                customer: item.company,
                contactPerson: item.contactPerson,
                status: item.status,
                createdAt: formatDate(item.createdAt, "without time"),
                previewItem,
                previewItems,
                item
            };
        });
    }, []);

    const gridContextMenu = useGridContextMenu<OfferVersionsGridRow>(useMemo(()=>({
        main: {
            key: "main",
            component: props => <BaseMenu key="main" {...props} getPlainText={data => {
                if (data.column?.key==="createdAt") return formatDate(data.row.createdAt);
                return getPlainText(data);
            }} />
        }
    }), []));

    const dataGrid = useDataGrid(columns, request, mapper, useMemo(()=>({
        sorting: {init: {columnKey: "createdAt", direction: "DESC"}},
        filter: {
            schema: {
                code: withDefault(StringParam, ""),
                year: withDefault(StringParam, ""),
                customer: withDefault(StringParam, ""),
                version: withDefault(StringParam, ""),
                status: withDefault(ArrayParam, Object.values(OfferVersionStatus)),
                itemName: withDefault(StringParam, ""),
                itemCode: withDefault(StringParam, ""),
                contactPerson: withDefault(StringParam, "")
            }
        },
        disableQueryParams
    }),[disableQueryParams]));

    const extendedTopActions: TopAction[] = useMemo(()=>([
        ...(topActions ?? []),
        {
            element: (
                <Frame key="itemFilter" defaultColor>
                    <FrameHeading>Filtr položek</FrameHeading>
                    <Grid container spacing={FORM_SPACING}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Název"
                                name="itemName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Obj. číslo"
                                name="itemCode"
                            />
                        </Grid>
                    </Grid>
                </Frame>
            ),
            rightSide: true
        }
    ]), [topActions]);

    return (
        <FilterDataGrid
            dataGrid={dataGrid}
            filter={dataGrid.filter}
            DataGridProps={{
                topActionsAlignment: "flex-end",
                topActions: extendedTopActions,
                contextMenu: gridContextMenu.contextMenu,
                loading,
                onRowDoubleClick
            }}
        />
    );
};

export default OfferVersionsGrid;
