import Keycloak from 'keycloak-js';


export class KeycloakService {
    private static instance: KeycloakService | null = null;
    private static KEYCLOAK_CONFIG_ADDRESS: string = "/api/gui-config";
    private static MIN_TOKEN_VALIDITY: number = 30;
    private wasAuth: boolean = false;
    private keycloakObject: Keycloak | null = null;

    private constructor() {}

    public async auth(): Promise<Keycloak | null> {
        if (this.wasAuth) return this.keycloakObject;
        this.wasAuth = true;

        try {
            const response = await fetch(KeycloakService.KEYCLOAK_CONFIG_ADDRESS);
            const data = await response.json();
            const keycloak: Keycloak = new Keycloak(data);
            await keycloak.init({
                onLoad: "login-required"
            });
            this.keycloakObject = keycloak;
        } catch (e) {
            console.error(e, "Keycloak initialization failed");
        }

        return this.keycloakObject;
    }

    public async updateTokenIfExpired(): Promise<void> {
        if (this.keycloakObject?.isTokenExpired(KeycloakService.MIN_TOKEN_VALIDITY)) {
            await this.keycloakObject.updateToken(30);
        }
    }

    public async logOut(): Promise<boolean> {
        try {
            await this.keycloakObject?.logout();
            return true;
        } catch(e) {
            return false;
        }
    }

    public get keycloak() {
        return this.keycloakObject;
    }

    public static getInstance(): KeycloakService {
        if (this.instance===null) {
            this.instance = new KeycloakService();
        }

        return this.instance;
    }
}

const keycloakService: KeycloakService = KeycloakService.getInstance();
export default keycloakService;