import {FC, MouseEventHandler, ReactElement} from "react";
import SaveButton from "../button/SaveButton";
import Button from "../button/Button";
import {DialogActions} from "@mui/material";
import ClearButton from "../button/ClearButton";

type Props = {
    handleClear: MouseEventHandler;
    loading?: boolean;
    handleClose: ()=>void;
    disabled?: boolean;
};
const ClearAndCloseActions: FC<Props> = (
    {
        handleClear,
        loading,
        handleClose
    }
) => {
    return (
        <DialogActions>
            <ClearButton
                onClick={handleClear}
                loading={loading}
            />
            <Button
                onClick={handleClose}
                variant="outlined"
                loading={loading}
            >
                Zavřít
            </Button>
        </DialogActions>
    );
};
export default ClearAndCloseActions;