import {
    BusinessCaseStatus,
    CompanyStatus, ConfigurationDocumentTextType,
    Language,
    NotificationStatus,
    NotificationType,
    OfferVersionStatus, OpportunityStatus, PhoneType,
    ProductStatus, ShippingDestination, translateConfigurationDocumentTextType,
    translateLanguage, translateNotificationType, translateShippingDestination,
    translateState,
    translateUserRole,
    UserRole,
    UserStatus
} from "../../../props/apiRequests";
import {fromStringArrToSelectOption, SelectOption} from "./Select";


export const languageOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(Language), translateLanguage);

export const shippingDestinationOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(ShippingDestination), translateShippingDestination);

export const companyStatusOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(CompanyStatus), translateState);

export const userStatusOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(UserStatus), translateState);

export const userRoleOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(UserRole), translateUserRole);

export const offerStatusOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(OfferVersionStatus), translateState);

export const businessCasesOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(BusinessCaseStatus), translateState);

export const productStatusOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(ProductStatus), translateState);

export const opportunityStatusOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(OpportunityStatus), translateState);

export const notificationStatusOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(NotificationStatus), translateState);

function translateNotificationTypeForOptions(type: NotificationType): string {
    if (type===NotificationType.TIME_ONLY) return "Nepropojovat";
    return translateNotificationType(type);
}
export const notificationTypeOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(NotificationType), translateNotificationTypeForOptions);

export const configurationDocumentTextTypeOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(ConfigurationDocumentTextType), translateConfigurationDocumentTextType);

export const phoneTypeOptions: SelectOption[] = fromStringArrToSelectOption(Object.values(PhoneType));