import {FC} from "react";
import {useDocumentTextsSelect} from "../../services/setting/DocumentTextsService";
import {ConfigurationDocumentTextType, DocumentType, Language, paramsSerializer} from "../../props/apiRequests";
import {useFormikContext} from "formik";
import ChipGroup from "../../common/ChipGroup";
import {Box, css, styled} from "@mui/material";
import {Build, Edit} from "@mui/icons-material";
import {useHistory} from "../../props/helpers";
import {PAGE_KEY, passURL} from "../../mainUI/pages";
import Menu, {MenuItemProps, useMenu} from "../../common/Menu";

interface Props {
    documentType: DocumentType;
    documentTextType: ConfigurationDocumentTextType;
    language: Language;
    textFieldName: string;
    disabled?: boolean;
}
const DocumentTextTypeChooser: FC<Props> = (
    {
        documentType,
        documentTextType,
        language,
        textFieldName,
        disabled
    }
) => {
    const history = useHistory();
    const {setFieldValue} = useFormikContext<any>();
    const {items} = useDocumentTextsSelect(documentType, documentTextType, language);
    const menu = useMenu();

    if (!items) return null;

    return (
        <Wrapper>
            <ChipGroup>{
                [
                    ...items.slice(0, 4).map((item)=>({
                        label: item.title,
                        disabled,
                        onClick: ()=>setFieldValue(textFieldName, item.text)
                    })),
                    {
                        variant: "filled",
                        label: "Více...",
                        disabled,
                        onClick: menu.handleMenu
                    },
                    {
                        icon: <Build />,
                        variant: "filled",
                        disabled,
                        onClick: ()=>history.push(passURL(PAGE_KEY.DOCUMENTS_SETTINGS)+"?"+paramsSerializer({document: documentType}))
                    }
                ]
            }</ChipGroup>
            <Menu {...menu.props}>
                {
                    items.map((item): MenuItemProps => ({
                        key: item.id,
                        children: item.title,
                        onClick: ()=>{
                            setFieldValue(textFieldName, item.text);
                            menu.props.handleCloseMenu();
                        }
                    }))
                }
            </Menu>
        </Wrapper>
    );
};
export default DocumentTextTypeChooser;

const Wrapper = styled(Box)(()=>css`
    & .MuiChip-root:last-of-type .MuiChip-label {
      padding-left: 0;
    }
`);