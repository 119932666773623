import {FC, ReactNode, useCallback, useEffect} from "react";
import {FORM_SPACING, getPrefixedName} from "../props";
import {Box, css, Grid, styled} from "@mui/material";
import TextField from "../fields/TextField";
import ProductSearch from "../fields/ProductSearch";
import {CurrencyExchangeRateResponse, CurrencyResponse, ProductResponse} from "../../props/apiResponses";
import {getIn, useFormikContext} from "formik";
import {
    computePrice,
    convertProductSalePriceByCurrency,
    numberWithPercentages,
    priceWithCurrency
} from "../../pages/trade/props";
import NumberTextField, { convertFromPercentage } from "../fields/NumberTextField";
import DiscountField from "../fields/DiscountField";
import Switch from "../fields/Switch";
import {DeliveryNoteResponse} from "../../containers/trade/businessCase/schema/deliveryNote";
import DeliveryNoteSelect from "../fields/select/DeliveryNoteSelect";
import useGetConfiguration from "../../containers/configuration/services/useGetConfiguration";
import {CATEGORIES_ENABLED_KEY} from "../../containers/configuration/constants";
import ItemUnitSelect from "../fields/select/ItemUnitSelect";
import useAreCategoriesEnabled from "../../containers/configuration/utils/useAreCategoriesEnabled";

type Props = {
    children?: ReactNode;
    isOffer: boolean;
    name?: string;
    index?: number;
    currenciesRates: CurrencyExchangeRateResponse[];
    currentCurrency: CurrencyResponse | undefined;
    purchaseCurrency: CurrencyResponse | undefined;
    orderToSupplier: boolean;
    invoice: boolean;
    disabled?: boolean;
    ignoreVat?: boolean;
    deliveryNotes?: DeliveryNoteResponse[];
};
const TradeItemFormBase: FC<Props> = (
    {
        children,
        isOffer,
        name,
        index,
        currenciesRates,
        currentCurrency,
        orderToSupplier,
        purchaseCurrency,
        invoice,
        disabled,
        ignoreVat,
        deliveryNotes
    }
) => {
    const areCategoriesEnabled = useAreCategoriesEnabled();
    const { setFieldValue, values } = useFormikContext<any>();

    const prefixedName = (itemName: string)=>!name ? itemName : getPrefixedName(name, index, itemName);
    const getValue = (itemName: string)=>getIn(values, prefixedName(itemName));

    let amount = getValue("amount");
    let sellingPricePerUnit = getValue("sellingPricePerUnit");
    let purchasePricePerUnit = getValue("purchasePricePerUnit");
    let pricePerUnit = getValue("pricePerUnit");
    let discountValue = getValue("discountValue");
    let purchaseDiscountValue = getValue("purchaseDiscountValue");
    let priceBeforeDiscount = getValue("priceBeforeDiscount");
    let priceBeforePurchaseDiscount = getValue("priceBeforePurchaseDiscount");
    let vat = getValue("vat");


    const handleSelectProduct = useCallback((product: ProductResponse | null)=>{
        if (!product || !currentCurrency) return;

        setFieldValue(prefixedName("code"), product.catalogCode ?? product.catalogName);
        setFieldValue(prefixedName("name"), product.catalogName ?? product.catalogCode);
        setFieldValue(prefixedName("vat"), product.vatRate?.rate ? convertFromPercentage(product.vatRate?.rate) : 0);

        const salePrice = convertProductSalePriceByCurrency(product, currentCurrency, currenciesRates);

        if (isOffer) {
            setFieldValue(prefixedName("description"), product.description ?? values.description);
            setFieldValue(prefixedName("pricePerUnit"), salePrice);
        } else {
            setFieldValue(prefixedName("customerDescription"), product.description ?? values.customerDescription);
            setFieldValue(prefixedName("productDescription"), product.description ?? values.productDescription);
            setFieldValue(prefixedName("invoiceDescription"), product.description ?? values.invoiceDescription);
            setFieldValue(prefixedName("purchasePricePerUnit"), product.purchasePrice ?? values.purchasePricePerUnit);
            setFieldValue(prefixedName("sellingPricePerUnit"), salePrice);
        }

        setFieldValue(prefixedName("amount"), 1);
    }, [prefixedName, currentCurrency, currenciesRates, isOffer, purchaseCurrency]);

    const computeBusinessCasePrice = useCallback(()=>{
        setFieldValue(prefixedName("priceBeforeDiscount"), computePrice(amount, sellingPricePerUnit, 0));
        setFieldValue(prefixedName("priceBeforePurchaseDiscount"), computePrice(amount, purchasePricePerUnit, 0));
    }, [amount, sellingPricePerUnit, purchasePricePerUnit]);

    const computeOfferPrice = useCallback(()=>{
        setFieldValue(prefixedName("priceBeforeDiscount"), computePrice(amount, pricePerUnit, 0));
    }, [amount, pricePerUnit]);

    useEffect(()=>{
        if (isOffer) computeOfferPrice();
        else computeBusinessCasePrice();
    }, [computeBusinessCasePrice, isOffer, computeOfferPrice]);

    useEffect(()=>{
        const totalPrice = computePrice(1, priceBeforeDiscount, discountValue);
        const totalPriceWithVat = computePrice(1, priceBeforeDiscount, discountValue, vat);
        const vatValue = totalPriceWithVat - totalPrice;
        setFieldValue(prefixedName("priceTotal"), totalPrice);
        setFieldValue(prefixedName("priceTotalWithVat"), totalPriceWithVat);
        setFieldValue(prefixedName("vatValue"), vatValue);
        setFieldValue(prefixedName("purchasePriceTotal"), computePrice(1, priceBeforePurchaseDiscount, purchaseDiscountValue));
    }, [priceBeforeDiscount, discountValue, amount, priceBeforePurchaseDiscount, purchaseDiscountValue, vat]);

    useEffect(()=>{
        if (values.isAlternative && !name) {
            values.options?.forEach((option: any, index: number)=>setFieldValue(getPrefixedName("options", index, "isAlternative"), true))
        }
    }, [values.isAlternative]);


    const moneyLabel: string = `${currentCurrency?.code ?? ""}/ks`;
    const purchaseMoneyLabel: string = `${purchaseCurrency?.code ?? ""}/ks`;

    let descriptionName = "description";
    if (!isOffer) {
        if (invoice) descriptionName = "invoiceDescription";
        else if (orderToSupplier) descriptionName = "productDescription";
        else descriptionName = "customerDescription";
    }

    const getPricesElement = (discount: string, percentage: string, total: string, isPurchase: boolean) => (
        <PricesWrapper>
            <DiscountWrapper>
                {isPurchase ? "Nákupní" : "Prodejní"} sleva: {priceWithCurrency(getValue(discount) ?? 0, (isPurchase ? purchaseCurrency : currentCurrency)?.code)} ({numberWithPercentages(getValue(percentage))})
                {/* TODO use discountWithCurrency function */}
            </DiscountWrapper>
            <TotalPriceWrapper>
                <span>Celkem {!isPurchase && !ignoreVat && "bez DPH"} ({isPurchase ? "nákup" : "prodej"}):</span> {priceWithCurrency(getValue(total) ?? 0, (isPurchase ? purchaseCurrency : currentCurrency)?.code)}
            </TotalPriceWrapper>
            {
                (!isPurchase && !ignoreVat) &&
                    <>
                        <DiscountWrapper>
                            DPH: {priceWithCurrency(getValue("vatValue"), currentCurrency?.code)} ({numberWithPercentages(getValue("vat"))})
                        </DiscountWrapper>
                        <DiscountWrapper>
                            <strong>
                                Celkem s DPH: {priceWithCurrency(getValue("priceTotalWithVat"), currentCurrency?.code)}
                            </strong>
                        </DiscountWrapper>
                    </>
            }
        </PricesWrapper>
    );

    const customerTotal = getPricesElement("discountValue", "discountPercentage", "priceTotal", false);
    const supplierTotal = getPricesElement("purchaseDiscountValue", "purchaseDiscountPercentage", "purchasePriceTotal", true);


    return (
        <Grid container spacing={FORM_SPACING}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={FORM_SPACING}>
                    <Grid item xs={12} sm={areCategoriesEnabled ? 6 : 12}>
                        <ProductSearch
                            label="Produkt"
                            name={prefixedName("product")}
                            valueName={prefixedName("productValue")}
                            handleSelect={handleSelectProduct}
                            disabled={orderToSupplier || disabled}
                        />
                    </Grid>
                    {
                        areCategoriesEnabled && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Kategorie"
                                    name={prefixedName("category")}
                                    disabled={disabled || orderToSupplier}
                                />
                            </Grid>)
                    }
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Obj. číslo"
                            name={prefixedName("code")}
                            required
                            disabled={disabled || orderToSupplier}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Název"
                            name={prefixedName("name")}
                            required
                            disabled={disabled || orderToSupplier}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberTextField
                            label="Jednotková prodejní cena"
                            name={isOffer ? prefixedName("pricePerUnit") : prefixedName("sellingPricePerUnit")}
                            InputProps={{endAdornment: moneyLabel}}
                            required
                            disabled={disabled || orderToSupplier}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <NumberTextField
                            label="Množství"
                            name={prefixedName("amount")}
                            precision={0}
                            required
                            disabled={disabled || orderToSupplier}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ItemUnitSelect
                            label="Jednotka"
                            name={prefixedName("unit")}
                            disabled={disabled || orderToSupplier}
                        />
                    </Grid>
                    {
                        !ignoreVat &&
                            <Grid item xs={12} sm={6}>
                                <NumberTextField
                                    label="DPH"
                                    name={prefixedName("vat")}
                                    percentage
                                    disabled={disabled || orderToSupplier}
                                />
                            </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <DiscountField
                            label="Sleva"
                            disabled={orderToSupplier || disabled}
                            checkboxName={prefixedName("isDiscountInPercentage")}
                            percentageName={prefixedName("discountPercentage")}
                            valueName={prefixedName("discountValue")}
                            calculationPrice={priceBeforeDiscount}
                        />
                    </Grid>
                    {
                        !isOffer && deliveryNotes && (
                            <Grid item xs={12} sm={6}>
                                <DeliveryNoteSelect
                                    label="Dodací list"
                                    name={prefixedName("deliveryNoteId")}
                                    deliveryNotes={deliveryNotes}
                                    disabled={disabled || orderToSupplier}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" flexDirection="column">
                <TextField
                    label="Popis"
                    name={prefixedName(descriptionName)}
                    InputProps={{multiline: true}}
                    adjustableHeight
                    disabled={disabled}
                    minRows={3}
                    sx={{mb: orderToSupplier ? 1.5 : 0}}
                />
                {
                    orderToSupplier &&
                    <Grid container spacing={FORM_SPACING}>
                        <Grid item xs={12} sm={6}>
                            <NumberTextField
                                label="Nákupní cena"
                                name={prefixedName("purchasePricePerUnit")}
                                InputProps={{endAdornment: purchaseMoneyLabel}}
                                required
                                disabled={invoice || disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DiscountField
                                label="Nákupní sleva"
                                checkboxName={prefixedName("isPurchaseDiscountInPercentage")}
                                percentageName={prefixedName("purchaseDiscountPercentage")}
                                valueName={prefixedName("purchaseDiscountValue")}
                                calculationPrice={priceBeforePurchaseDiscount}
                                disabled={invoice || disabled}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>

            <Grid item xs={12} sm={6}>
                {customerTotal}
                <Line />
                <AlternativeWrapper>
                    <Switch
                        label="Alternativní položka"
                        name={prefixedName("isAlternative")}
                        SwitchProps={{disabled: orderToSupplier || disabled || (values.isAlternative && name)}}
                        smallFont
                    />
                </AlternativeWrapper>
            </Grid>
            {
                orderToSupplier &&
                    <Grid item xs={12} sm={6}>
                        {supplierTotal}
                        <Line />
                    </Grid>
            }

            {children}
        </Grid>
    );
};
export default TradeItemFormBase;

const PricesWrapper = styled(Box)(({theme})=>css`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-end;
  margin-top: ${theme.spacing(1)};
`);

const DiscountWrapper = styled(Box)(({theme})=>css`
  font-size: ${theme.typography.body2.fontSize};
`);

const TotalPriceWrapper = styled(Box)(({theme})=>css`
  font-weight: ${theme.typography.fontWeightBold};

  & > span {
    color: ${theme.palette.primary.main};
  }
`);

const Line = styled(Box)(({theme})=>css`
  border-bottom: 1px solid ${theme.palette.primary.main};
`);

const AlternativeWrapper = styled(Box)(({theme})=>css`
  display: flex;
  justify-content: flex-end;
  margin-right: -${theme.spacing(1.5)};
`);
