import { getKcContext } from "keycloakify/login";
export const { kcContext } = getKcContext({
    //mockPageId: "login-update-password.ftl",
    mockData: [
        {
            pageId: "login.ftl",
            locale: {
                currentLanguageTag: "cs"
            }
        },
        {
            pageId: "login-reset-password.ftl",
            locale: {
                currentLanguageTag: "cs"
            }
        },
        {
            pageId: "login-update-password.ftl",
            locale: {
                currentLanguageTag: "cs"
            }
        },
        {
            pageId: "info.ftl",
            locale: {
                currentLanguageTag: "cs"
            }
        }
    ]
});

export type KcContext = NonNullable<typeof kcContext>;
