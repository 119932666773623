import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {
    BusinessCaseItemResponse,
    DocumentResponse,
    SupplierOrderDocumentResponse,
    SupplierOrderResponse
} from "../../../../props/apiResponses";
import {Box, css, Grid, styled} from "@mui/material";
import Button from "../../../../common/button/Button";
import {FileOpen, NoteAdd} from "@mui/icons-material";
import {BusinessCaseContext, useBusinessCaseDocuments, useGeneratedDocument} from "../../props";
import TradeItemFormDialog, {useTradeItemFormDialog} from "../../../../form/trade/TradeItemFormDialog";
import {DocumentType} from "../../../../props/apiRequests";
import ExternalOneFile, {ExternalOneFileProps} from "../../../../common/ExternalOneFile";
import {FileFieldAccept} from "../../../../form/fields/FileField";
import SupplierOrderForm, {
    SupplierOrderFormValues,
    useSupplierOrderForm
} from "../../../../form/businessCase/SupplierOrderForm";
import {priceWithCurrency} from "../../../trade/props";
import {downloadDocument} from "../../../../services/DocumentService";
import SupplierBlock, {SupplierBlockProps} from "../SupplierBlock";
import {Frame} from "../../../../common/Frame";

interface Props extends SupplierBlockProps {}
const OrderToSupplierBlock: FC<Props> = (
    {
        supplier,
        items,
        supplierOrder
    }
) => {
    const context = useContext(BusinessCaseContext);

    const itemDialog = useTradeItemFormDialog({
        businessCaseId: context?.data?.id,
        isOffer: false
    });
    const {removeDocumentByType, uploadFile, getDocumentBlob, setDocuments, manipulator: documentManipulator} = useBusinessCaseDocuments(supplier);
    const [downloadGeneratedDocument, wasGeneratedOrder] = useGeneratedDocument(DocumentType.SUPPLIER_ORDER, supplierOrder?.documents, documentManipulator.get.run);

    const afterSave = useCallback((entity: SupplierOrderResponse)=>{
        if (!context?.setData) return;

        context.setData((oldData)=>{
            if (!oldData) return;

            const supplierOrders = oldData.supplierOrders
                .filter((supplierOrder)=>supplierOrder.supplierId!==supplier?.id);
            supplierOrders.push(entity);
            return {
                ...oldData,
                supplierOrders
            };
        });
    }, [context?.setData]);

    const [priceBeforeDiscount, setPriceBeforeDiscount] = useState<number>(0);

    const supplierOrderForm = useSupplierOrderForm({
        supplierId: supplier?.id,
        businessCaseId: context?.data?.id,
        afterSave,
        priceBeforeDiscount
    });

    const generateOrderToSupplier = useCallback(async ()=>{
        const id = context?.data?.id;
        if (!id || !supplier) return null;
        const result = await documentManipulator.getOrderToSupplier.run({
            businessCaseId: id,
            supplierId: supplier.id
        });
        if (result !== null) {
            const documents: DocumentResponse[] = supplierOrder.documents.filter((document)=>document.type!==DocumentType.SUPPLIER_ORDER);
            afterSave({...supplierOrder, documents: [...documents, result]});
            downloadDocument(result);
        }
    }, [context?.data?.id, documentManipulator.getOrderToSupplier, supplier, afterSave, supplierOrder]);

    const {startLoading, stopLoading} = documentManipulator.get;

    const [total, setTotal] = useState<number>(0);

    const computeTotal = useCallback((discountValue: number)=>{
        let result: number = 0;

        const addItemPrice = (item: BusinessCaseItemResponse) => {
            result += item.purchasePriceTotal;
        };
        items.forEach((item)=>{
            addItemPrice(item);
            item.options?.forEach(addItemPrice);
        });

        setPriceBeforeDiscount(result);
        setTotal(result-discountValue);
    }, [items]);

    const handleValuesChange = useCallback((values: SupplierOrderFormValues)=>{
        computeTotal(values.discountValue);
    }, [computeTotal]);

    useEffect(()=>{
        computeTotal(supplierOrder?.discountValue);
    }, [computeTotal, supplierOrder]);

    if (!context?.data) return null;

    const externalFileProps: Omit<ExternalOneFileProps, "documentType" | "match"> = {
        startLoading, stopLoading, getDocumentBlob, setDocuments, loading: documentManipulator.loading,
        handleRemove: removeDocumentByType,
        handleUpload: uploadFile,
        documents: context.data.documents,
        accept: FileFieldAccept.DOCUMENT
    };

    return (
        <SupplierBlock
            name={supplier?.name}
            handleDetailItem={(row)=>itemDialog.handleOpen(row.order, row.item)}
            items={items}
            purchaseCurrency={supplierOrder?.currency}
            disabled={context?.lockedOffer}
            filesColumn={
                <>
                    {
                        wasGeneratedOrder &&
                            <Grid item xs={12}>
                                <Button
                                    loading={documentManipulator.loading}
                                    startIcon={<FileOpen />}
                                    onClick={downloadGeneratedDocument}
                                    fullWidth
                                >
                                    Otevřít objednávku
                                </Button>
                            </Grid>
                    }
                    {
                        !context.lockedOffer &&
                            <Grid item xs={12}>
                                <Button
                                    loading={documentManipulator.loading}
                                    startIcon={<NoteAdd />}
                                    onClick={generateOrderToSupplier}
                                    fullWidth
                                >
                                    Vytvořit objednávku
                                </Button>
                            </Grid>
                    }
                    <Grid item xs={12}>
                        <ExternalOneFile
                            label="Potvrzení objednávky od dodavatele"
                            documentType={DocumentType.SUPPLIER_ORDER_CONFIRMATION}
                            disabled={context?.lockedOffer}
                            match={(document)=>
                                document.type===DocumentType.SUPPLIER_ORDER_CONFIRMATION
                                && (document as SupplierOrderDocumentResponse).supplierId===supplier?.id
                            }
                            {...externalFileProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ExternalOneFile
                            label="Nabídka od dodavatele"
                            documentType={DocumentType.SUPPLIER_OFFER}
                            disabled={context?.lockedOffer}
                            match={(document)=>
                                document.type===DocumentType.SUPPLIER_OFFER
                                && (document as SupplierOrderDocumentResponse).supplierId===supplier?.id
                            }
                            {...externalFileProps}
                        />
                    </Grid>
                </>
            }
        >
            <Grid item xs={12}>
                <TotalWrapper>
                    <span>Celkem:</span> {priceWithCurrency(total, supplierOrder?.currency?.code)}
                </TotalWrapper>
            </Grid>
            <Grid item xs={12}>
                <Frame defaultColor>
                    <SupplierOrderForm
                        {...supplierOrderForm.props}
                        supplierOrder={supplierOrder}
                        onValuesChange={handleValuesChange}
                        disabled={context?.lockedOffer}
                    />
                </Frame>
            </Grid>
            <TradeItemFormDialog
                {...itemDialog.props}
                currenciesRates={context.exchangeRates ?? []}
                currentCurrency={context.data.pricing?.currency}
                orderToSupplier={true}
                afterSave={context.afterSaveItem}
                purchaseCurrency={supplierOrder?.currency}
                disabled={context?.lockedOffer}
                deliveryNotes={context.data.deliveryNotes}
            />
        </SupplierBlock>
    );
};
export default OrderToSupplierBlock;

const TotalWrapper = styled(Box)(({theme})=>css`
  text-align: right;
  font-size: ${theme.typography.body1.fontSize};
  font-weight: bold;
  
  & > span {
    color: ${theme.palette.primary.main};
  }
`);
