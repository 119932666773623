import {MouseEventHandler, ReactElement} from "react";
import {Box, css, styled} from "@mui/material";
import IconButton, {IconButtonProps} from "../../button/IconButton";
import {
    AccessibilityNew,
    ArrowCircleRightOutlined,
    ArrowDownward,
    Delete, Edit,
    Notifications,
    Settings,
    Visibility
} from "@mui/icons-material";
import {Link} from "react-router-dom";

interface BasicAction {
    key: string;
    icon: ReactElement;
    label: string;
    color?: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
    onClick?: MouseEventHandler;
}

export enum PredefinedAction {
    REMOVE,
    DETAIL,
    NOTIFICATION,
    OPPORTUNITY,
    READONLY,
    SELECT,
    EDIT
}

type Predefined = [PredefinedAction, MouseEventHandler];

export type Action = BasicAction | Predefined;

export function ActionsFormatter(actions: Action[]): ReactElement {
    return (
        <Wrapper>
            {
                actions.map((action) => {
                    if (Array.isArray(action)) {
                        // predefined

                        const commonProps: IconButtonProps = {
                            gridVariant: true,
                            onClick: action[1]
                        };

                        switch (action[0]) {
                            case PredefinedAction.REMOVE:
                                return (
                                    <IconButton
                                        key="remove"
                                        IconButtonProps={{title: "Odstranit", color: "error"}}
                                        {...commonProps}
                                    >
                                        <Delete />
                                    </IconButton>
                                );

                            case PredefinedAction.DETAIL:
                                return (
                                    <IconButton
                                        key="detail"
                                        IconButtonProps={{title: "Detail"}}
                                        {...commonProps}
                                    >
                                        <ArrowCircleRightOutlined />
                                    </IconButton>
                                );

                            case PredefinedAction.EDIT:
                                return (
                                    <IconButton
                                        key="edit"
                                        IconButtonProps={{title: "Upravit"}}
                                        {...commonProps}
                                    >
                                        <Edit />
                                    </IconButton>
                                );

                            case PredefinedAction.NOTIFICATION:
                                return (
                                    <IconButton
                                        key="notification"
                                        IconButtonProps={{title: "Vytvořit upozornění", color: "default"}}
                                        {...commonProps}
                                    >
                                        <Notifications />
                                    </IconButton>
                                );

                            case PredefinedAction.OPPORTUNITY:
                                return (
                                    <IconButton
                                        key="opportunity"
                                        IconButtonProps={{title: "Vytvořit příležitost", color: "default"}}
                                        {...commonProps}
                                    >
                                        <AccessibilityNew />
                                    </IconButton>
                                );

                            case PredefinedAction.READONLY:
                                return (
                                    <IconButton
                                        key="readonly"
                                        IconButtonProps={{title: "Ukázat"}}
                                        {...commonProps}
                                    >
                                        <Visibility />
                                    </IconButton>
                                );

                            case PredefinedAction.SELECT:
                                return (
                                    <IconButton
                                        key="select"
                                        IconButtonProps={{title: "Vybrat"}}
                                        {...commonProps}
                                    >
                                        <ArrowDownward />
                                    </IconButton>
                                );
                        }

                    } else
                        return (
                            <IconButton
                                key={action.key}
                                IconButtonProps={{title: action.label, color: action.color ?? "primary"}}
                                onClick={action.onClick}
                                gridVariant
                            >
                                {action.icon}
                            </IconButton>
                        );
                })
            }
        </Wrapper>
    );
}

const spacing = "2px";
const Wrapper = styled(Box)(({theme})=>css`
  display: flex;
  height: 100%;
  align-items: center;
  
  margin-left: -${spacing};
  & > * {
    margin-left: ${spacing};
  }
`);