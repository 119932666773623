import {FC} from "react";
import {Skeleton, SkeletonProps} from "@mui/material";

type Props = {
    height?: number;
    disableFlexGrow?: boolean;
    sx?: SkeletonProps["sx"];
};
const RectangularSkeleton: FC<Props> = (
    {
        height,
        disableFlexGrow,
        sx
    }
) => {
    return <Skeleton
        sx={{flexGrow: disableFlexGrow ? undefined : 1, borderRadius: "5px", ...sx}}
        variant="rectangular"
        height={height}
    />;
};
export default RectangularSkeleton;