import React, {FC, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {
    BusinessCaseItemResponse,
    CompanyEmbeddedResponse,
    CurrencyResponse,
    SupplierOrderResponse
} from "../../../props/apiResponses";
import {css, Grid, styled, Typography} from "@mui/material";
import DataGrid from "../../../common/dataGrid/DataGrid";
import {Column} from "react-data-grid";
import {ActionsFormatter, PredefinedAction} from "../../../common/dataGrid/formatter/ActionsFormatter";
import {BusinessCaseGridRow} from "../props";
import {priceFormatter} from "../../../common/dataGrid/formatter/PriceFormatter";
import {
    getContextAsList,
    getMassPropertyAsElement,
    showMoreFormatter
} from "../../../common/dataGrid/formatter/ShowMoreFormatter";
import {getGridNote, getGridRow, pushGridRow} from "../../trade/props";
import useGridContextMenu from "../../../common/dataGrid/contextMenu/GridContextMenu";
import MainGridContextMenu from "../../trade/MainGridContextMenu";
import {BusinessCaseRow} from "../CustomerItemsDataGrid";
import {Frame} from "../../../common/Frame";
import {computeDynamicHeight} from "../../../common/dataGrid/props";
import {DeliveryNoteResponse} from "../../../containers/trade/businessCase/schema/deliveryNote";

export interface SupplierBlockProps {
    supplier?: CompanyEmbeddedResponse;
    supplierOrder: SupplierOrderResponse;
    items: BusinessCaseItemResponse[];
    disabled?: boolean;
    deliveryNote?: DeliveryNoteResponse;
}

interface Row extends BusinessCaseGridRow {
    purchasePricePerUnit: number;
    serialNumber: ReactNode;
    serialNumbers: ReactNode[];
}

type Props = {
    children?: ReactNode;
    name?: string;
    filesColumn?: ReactNode;
    handleDetailItem: (row: Row)=>void;
    items: BusinessCaseItemResponse[];
    purchaseCurrency: CurrencyResponse | undefined;
    isSerialNumbers?: boolean;
    disabled?: boolean;
    disableBorder?: boolean;
}
const SupplierBlock: FC<Props> = (
    {
        children,
        name,
        filesColumn,
        handleDetailItem,
        items,
        isSerialNumbers,
        purchaseCurrency,
        disabled,
        disableBorder
    }
) => {
    const [rows, setRows] = useState<Row[]>([]);

    const gridContextMenu = useGridContextMenu<Row>(useMemo(()=>({
        main: {
            key: "main",
            component: (props) =>
                <MainGridContextMenu
                    key="main"
                    {...props}
                    descriptionKey="productDescription"
                />
        }
    }), []));

    const formatPrice = useCallback((price: number)=>
        priceFormatter(price, purchaseCurrency?.code),
    [purchaseCurrency?.code]);

    const columns: Column<Row>[] = useMemo(()=>{
        let result: Column<Row>[] = [
            {
                key: "orderCaption",
                name: "Č.",
                width: "5%",
                minWidth: 50
            },
            {
                key: "code",
                name: "Obj. číslo",
                width: "20%",
                minWidth: 100
            },
            {
                key: "note",
                name: "Popis",
                minWidth: 100
            }
        ];

        if (!isSerialNumbers)
            result.push({
                key: "purchasePricePerUnit",
                name: "Nákupní cena",
                width: "10%",
                minWidth: 100,
                formatter: ({row}) => formatPrice(row.purchasePricePerUnit)
            });

        result.push({
            key: "amount",
            name: "Počet",
            width: "5%",
            minWidth: 50
        });

        if (!isSerialNumbers)
            result.push({
                key: "total",
                name: "Cena celkem",
                width: "15%",
                minWidth: 100,
                formatter: ({row}) => formatPrice(row.total)
            });

        if (isSerialNumbers) {
            result = result.concat([
                {
                    key: "serialNumber",
                    name: "Sériová čísla",
                    formatter: ({row})=>showMoreFormatter(
                        row.serialNumber, row.serialNumbers.length, getContextAsList(row.serialNumbers)
                    )
                }
            ]);
        }


        return (
            [
                ...result,
                {
                    key: "actions",
                    name: "Akce",
                    width: "5%",
                    minWidth: 50,
                    formatter: ({row}) => ActionsFormatter([
                        [disabled ? PredefinedAction.READONLY : PredefinedAction.EDIT, () => handleDetailItem(row)]
                    ])
                }
            ]
        );
    }, [formatPrice, isSerialNumbers, disabled]);

    useEffect(()=>{
        if (!items) return;

        const getRow = (item: BusinessCaseItemResponse, isSubItem: boolean, order: number, orderCaption?: string): Row => {
            let [serialNumber, serialNumbers] = getMassPropertyAsElement<string>(
                item.serialNumbers,
                e=>e
            );

            return {
                ...getGridRow(item, order, orderCaption),
                purchasePricePerUnit: item.purchasePricePerUnit ?? 0,
                note: getGridNote(item.name, item.productDescription),
                total: item.purchasePriceTotal ?? 0,
                serialNumber, serialNumbers,
                isSubItem
            };
        };

        setRows(pushGridRow(items, getRow));
    }, [items]);

    return (
        <Wrapper sx={{border: disableBorder ? "none" : undefined}}>
            <Typography variant="h6" color="primary">{name ?? "Bez dodavatele"}</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={1}>
                        {filesColumn}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9} display="flex" flexDirection="column">
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableMt
                        height={computeDynamicHeight(rows.length)}

                        rowClass={(row)=>row.isSubItem ? "subItem" : ""}

                        contextMenu={gridContextMenu.contextMenu}
                        onRowDoubleClick={handleDetailItem}
                    />
                </Grid>
                {children}
            </Grid>
        </Wrapper>
    );
};
export default SupplierBlock;

const Wrapper = styled(Frame)(({theme})=>css`
  color: inherit;
`);
