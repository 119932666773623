import {FC, MouseEventHandler, useCallback} from "react";
import {DialogActions} from "@mui/material";
import Button from "../button/Button";
import {Check, Clear} from "@mui/icons-material";
import {YesAndNoButtonsSettings} from "./props";


type Props = {
    handleYes: MouseEventHandler;
    handleNo: MouseEventHandler;
    loading?: boolean;
    primary?: "yes" | "no";
    buttons?: YesAndNoButtonsSettings;
    hideIcons?: boolean;
};
const YesAndNoActions: FC<Props> = (
    {
        handleNo,
        handleYes,
        loading,
        primary,
        buttons,
        hideIcons,
    }
) => {

    const buttonVariant = useCallback((isYesButton: boolean)=>{
        if (!!primary)
            if (isYesButton)
                return primary==="yes" ? "contained" : "outlined";
            else
                return primary==="no" ? "contained" : "outlined";
        return "contained";
    }, [primary]);

    return (
        <DialogActions>
            <Button
                onClick={handleYes}
                loading={loading}
                startIcon={hideIcons ? undefined : <Check /> }
                variant={buttonVariant(true)}
                ButtonProps={{disabled: buttons?.yes?.disabled}}
            >
                {buttons?.yes?.label ?? "Ano"}
            </Button>
            <Button
                onClick={handleNo}
                loading={loading}
                startIcon={hideIcons ? undefined : <Clear /> }
                variant={buttonVariant(false)}
                ButtonProps={{disabled: buttons?.no?.disabled}}
            >
                {buttons?.no?.label ?? "Ne"}
            </Button>
        </DialogActions>
    );
};
export default YesAndNoActions;