import {FC, useCallback, useEffect} from "react";
import {FieldProps} from "../props";
import {Box, css, styled} from "@mui/material";
import NumberTextField, {convertToPercentage} from "./NumberTextField";
import {getIn, useFormikContext} from "formik";
import {computeDiscount, computeDiscountValue} from "../../pages/trade/props";
import Radio from "./Radio";

interface Props extends Omit<FieldProps, "name"> {
    percentageName: string;
    valueName: string;
    checkboxName: string;
    calculationPrice: string | number;
    disabled?: boolean;
}
const DiscountField: FC<Props> = (
    {
        percentageName,
        valueName,
        checkboxName,
        required,
        calculationPrice,
        disabled,
        label
    }
) => {
    const {values, setFieldValue} = useFormikContext<any>();

    const getValue = (itemName: string)=>getIn(values, itemName);

    const percentageValue: string = getValue(percentageName);
    const value: string = getValue(valueName);
    const isInPercentage: boolean = !!getValue(checkboxName);

    const recalculateValue = useCallback(()=>{
        let numPercentage: number = Number(percentageValue);
        const numPrice: number = Number(calculationPrice);
        if (isNaN(numPercentage) || isNaN(numPrice)) return;

        numPercentage = convertToPercentage(numPercentage.toString());

        setFieldValue(valueName, computeDiscountValue(numPercentage, numPrice));
    }, [percentageValue, calculationPrice, valueName]);

    const recalculatePercentage = useCallback(()=>{
        setFieldValue(percentageName, computeDiscount(value, calculationPrice).toString());
    }, [calculationPrice, value, percentageName]);

    useEffect(()=>{
        if (isInPercentage)
            recalculateValue();
        else
            recalculatePercentage();
    }, [recalculateValue, isInPercentage, recalculatePercentage]);

    return (
        <Wrapper>
            {
                isInPercentage &&
                    <NumberTextField
                        label={label}
                        name={percentageName}
                        percentage
                        required={required}
                        InputProps={{disabled}}
                    />
            }
            {
                !isInPercentage &&
                    <NumberTextField
                        label={label}
                        name={valueName}

                        required={required}
                        InputProps={{disabled}}
                    />
            }
            <Radio
                name={checkboxName}
                smallFont
                options={[
                    {key: "true", label: "V procentech"},
                    {key: "false", label: "V částce"}
                ]}
                onChange={(e, value)=>setFieldValue(checkboxName, value==="true")}
                RadioProps={{disabled}}
            />
        </Wrapper>
    );
};
export default DiscountField;

const Wrapper = styled(Box)(()=>css`

`);