import Select, {SelectOption, SelectProps} from "./Select";
import {FC, useMemo} from "react";
import TradeItemUnit from "../../../containers/trade/schema/TradeItemUnit";

interface ItemUnitSelectProps extends SelectProps {
}

const ItemUnitSelect: FC<ItemUnitSelectProps> = (props) => {
    const options = useMemo<SelectOption[]>(() => ([
        {key: TradeItemUnit.PIECES, label: "Kusy"},
        {key: TradeItemUnit.HOURS, label: "Hodiny"}
    ]), [props.options]);

    return (
        <Select
            options={options}

            {...props}
        />
    );
};
export default ItemUnitSelect;
