import React, {FC, PropsWithChildren} from "react";
import {localeNumber, priceWithCurrency, TradeGridRow} from "./props";
import {printPercentage} from "../../props/helpers";
import BaseMenu, {getPlainText} from "../../common/dataGrid/contextMenu/menu/BaseMenu";
import {ContextMenuRef, GridContextMenuElementProps} from "../../common/dataGrid/contextMenu/GridContextMenu";

type Inheritable = {[key: string]: any};

interface Props<Row extends Inheritable> extends GridContextMenuElementProps<Row> {
    descriptionKey: string;
}

const MainGridContextMenu = <Row extends Inheritable, >(
    {
        descriptionKey,
        ...props
    }: PropsWithChildren<Props<Row>>
) => {
    return (
        <BaseMenu {...props} getPlainText={data => {
            if (data.column?.key==="note") return data.row.item[descriptionKey];
            if ([
                "pricePerUnit",
                "total",
                "discount",
                "purchasePricePerUnit",
                "sellingPricePerUnit"
            ].includes(data.column?.key ?? ""))
                return localeNumber(
                    //@ts-ignore
                    data.row[data.column.key]
                );

            return getPlainText(data);
        }} />
    );
};
export default MainGridContextMenu;