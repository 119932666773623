import {DeleteRP, GetRP, IdRP, PostRP, PutRP} from "../props/apiRequests";
import useRequest, {ErrorsDefinition, RequestOpt, RequestService} from "./RequestService";
import {AxiosResponse} from "axios";
import {BasicResponse} from "../props/apiResponses";

export interface CRUDRequestService {
    retrieve(rp: any): BasicResponse<any>;
    remove(rp: any): BasicResponse<"">;
    create(rp: any): BasicResponse<any>;
    update(rp: any): BasicResponse<any>;
}

export class CRUDRequestServiceImpl<Req, Res, CreateData = Req, UpdateData = Req> extends RequestService<Req, Res> implements CRUDRequestService {
    public retrieve = ({id, controller}: GetRP & IdRP) => this.get(this.addressWithId(id), controller);
    public remove = ({id, controller}: DeleteRP & IdRP) => this.delete(this.addressWithId(id), controller);
    public create = ({controller, data}: PostRP<CreateData>) => this.post(this.addressWithId(), data, controller);
    public update = ({id, controller, data}: PutRP<UpdateData>) => this.put(this.addressWithId(id), data, controller);
}

type CRUDErrors = {
    get?: ErrorsDefinition;
    remove?: ErrorsDefinition;
    create?: ErrorsDefinition;
    update?: ErrorsDefinition;
};
type RequestsOpt = {
    all?: RequestOpt;
    get?: RequestOpt;
    remove?: RequestOpt;
    create?: RequestOpt;
    update?: RequestOpt;
}
export function useCRUDRequest<Res, S extends CRUDRequestService>(service: S, errors?: CRUDErrors, opt?: RequestsOpt) {
    const get = useRequest<Res, Parameters<S["retrieve"]>[0]>(service.retrieve, errors?.get, opt?.get ?? opt?.all);
    const remove = useRequest<"", Parameters<S["remove"]>[0]>(service.remove, errors?.remove, opt?.remove ?? opt?.all);
    const create = useRequest<Res, Parameters<S["create"]>[0]>(service.create, errors?.create, opt?.create ?? opt?.all);
    const update = useRequest<Res, Parameters<S["update"]>[0]>(service.update, errors?.update, opt?.update ?? opt?.all);

    const loading = get.loading || remove.loading || create.loading || update.loading;

    return {get, remove, create, update, loading};
}