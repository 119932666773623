import {FC} from "react";
import {DialogBase, DialogReturnProps, useDialog} from "./props";
import Dialog from "./Dialog";
import CloseActions from "./CloseActions";

interface Props extends DialogBase {
    title?: string;
    message?: string;
}
const PropertyDialog: FC<Props> = (
    {
        DialogProps,
        handleClose,
        title,
        message
    }
) => {
    return (
        <Dialog
            DialogProps={DialogProps}
            actions={<CloseActions handleClose={handleClose} />}
            title={title ?? "Položka"}
        >
            {message}
        </Dialog>
    );
};
export default PropertyDialog;

interface ReturnProps extends DialogReturnProps {

}
export function usePropertyDialog(): ReturnProps {
    return useDialog(true);
}