import {GetRP} from "../../props/apiRequests";
import {ProfileResponse} from "../../props/apiResponses";
import useRequest, {RequestService} from "../RequestService";

const PROFILE_API = "/users/me";

class ProfileService extends RequestService<any, ProfileResponse> {
    public me = ({controller}: GetRP) =>
        this.get(this.addressWithId(), controller);
}
const profileService = new ProfileService(PROFILE_API);

export function useProfileManipulator() {
    const me = useRequest(profileService.me);

    return {me, loading: me.loading};
}
