import React, {FC, useCallback, useContext} from "react";
import {BusinessCaseContext, BusinessCaseTabProps} from "../../props";
import Button from "../../../../common/button/Button";
import {NoteAdd} from "@mui/icons-material";
import {Items} from "../../../../common/item/Items";
import DeliveryNoteItem from "./DeliveryNoteItem";
import {Stack} from "@mui/material";
import usePostDeliveryNote from "../../../../containers/trade/businessCase/services/usePostDeliveryNote";

interface Props extends BusinessCaseTabProps {

}
const DeliveryNote: FC<Props> = () => {
    const context = useContext(BusinessCaseContext);
    const postDeliveryNote = usePostDeliveryNote();

    const createDeliveryNote = useCallback(async ()=>{
        if (!context?.data || !context?.setData) return;

        let lastDeliveryNoteNumber: number = 1;
        for (let deliveryNote of context.data.deliveryNotes) {
            if (deliveryNote.deliveryNoteNumber>lastDeliveryNoteNumber)
                lastDeliveryNoteNumber = deliveryNote.deliveryNoteNumber;
        }

        try {
            const response = await postDeliveryNote.mutateAsync({
                data: {
                    businessCaseId: context.data.id.toString(),
                    deliveryNoteNumber: lastDeliveryNoteNumber+1,
                    deliveryDate: new Date()
                }
            });
            context.setData({
                ...context.data,
                deliveryNotes: [...context.data.deliveryNotes, response],
                invoices: [...context.data.invoices, response.invoice]
            });
        } catch (_) {}
    }, [context]);

    return (
        <>
            {
                !context?.finished && (
                    <Items sx={{mb: 5}}>
                        <Button
                            startIcon={<NoteAdd />}
                            loading={postDeliveryNote.isLoading}
                            onClick={createDeliveryNote}
                        >
                            Přidat dodací list
                        </Button>
                    </Items>
                )
            }
            <Stack spacing={5}>
                {
                    context?.data?.deliveryNotes.map((deliveryNote)=>(
                        <DeliveryNoteItem
                            key={deliveryNote.id}
                            data={deliveryNote}
                        />
                    ))
                }
            </Stack>
        </>
    );
};
export default DeliveryNote;

