import {useCallback, useContext} from "react";
import {BusinessCaseItemResponse as OldBusinessCaseItemResponse} from "../../../../props/apiResponses";
import {BusinessCaseContext} from "../../props";

export default function useAfterSaveSerialNumbers() {
    const context = useContext(BusinessCaseContext);
    return useCallback((entity: OldBusinessCaseItemResponse)=>{
        if (!context?.afterSaveItem) return;
        context.afterSaveItem(entity);
    }, [context]);
}
