import {FC} from "react";
import {BusinessCaseTabProps} from "../../props";
import OrderToSupplierBlock from "./OrderToSupplierBlock";
import Suppliers from "../Suppliers";

interface Props extends BusinessCaseTabProps {

}
const OrderToSupplier: FC<Props> = () => {
    return (
        <Suppliers component={OrderToSupplierBlock} />
    );
};
export default OrderToSupplier;