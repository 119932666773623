import {FC, SyntheticEvent, useCallback, useState} from "react";
import {SearchFieldProps} from "../props";
import {useField} from "formik";
import {ProductResponse} from "../../props/apiResponses";
import {useFastChangingRequest, useInitialRequest} from "../../services/RequestService";
import {useProductsManipulator} from "../../services/product/ProductService";
import ComboBox from "./ComboBox";
import {getProductName} from "../../pages/product/props";

interface Props extends SearchFieldProps {
    handleSelect?: (product: ProductResponse | null)=>void;
    disabled?: boolean;
}
const ProductSearch: FC<Props> = (
    {
        name,
        required,
        label,
        valueName,
        handleSelect,
        disabled
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");
    const [options, setOptions] = useState<ProductResponse[]>([]);
    const {run, loading} = useProductsManipulator(false);
    const execution = useFastChangingRequest();

    const request = useCallback(async (value: string = "")=>{
        await execution(async (controller) => {
            const data = await run({controller, params: {
                pageSize: 50,
                pageNum: 1,
                search: value
            }});

            if (data) setOptions(data.data);
        });
    }, []);
    useInitialRequest(useCallback(()=>request(), []));

    const handleChange = useCallback(async (event: SyntheticEvent, value: string, reason: string)=>{
        if (reason!=="input") return;

        await request(value);

    }, []);

    const handleSelectProxy = useCallback((product: ProductResponse | null)=>{
        helpers.setValue(product);

        if (handleSelect) handleSelect(product);
    }, []);

    return (
        <ComboBox
            label={label}
            name={valueName}

            options={options}
            getOptionLabel={getProductName}
            getOptionKey={option => option.id}
            isOptionEqualToValue={(option, value) => option.id===value.id}
            value={field.value as ProductResponse}

            onInputChange={handleChange}
            handleSelect={handleSelectProxy}

            clearable
            disableFreeSolo
            loading={loading}
            required={required}
            disabled={disabled}
        />
    );
};
export default ProductSearch;