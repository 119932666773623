import {FC, useCallback, useMemo} from "react";
import {Asserts, object, string} from "yup";
import {Formik, FormikValues} from "formik";
import {FORM_SPACING, FormOpt, FormProps, FormReturnProps, OnSubmit, useForm} from "../props";
import {Grid} from "@mui/material";
import RichTextField, {RichTextFieldProps} from "../fields/RichTextField";
import SaveGridItem from "../SaveGridItem";
import Form from "../Form";
import {ConfigurationDocumentTextType, DocumentType, Language} from "../../props/apiRequests";
import DocumentTextTypeChooser from "../fields/DocumentTextTypeChooser";

const validationSchema = object({
    terms: string()
});

export interface TermsFormValues extends FormikValues, Asserts<typeof validationSchema> {}

interface Props extends FormProps<TermsFormValues> {
    terms?: string;
    termsLabel: string;
    disabled?: boolean;
    RichTextFieldProps?: RichTextFieldProps;
}
const TermsForm: FC<Props> = (
    {
        onSubmit,
        loading,
        terms,
        termsLabel,
        disabled,
        RichTextFieldProps
    }
) => {

    const initValues = useCallback((): TermsFormValues=>({
        terms: terms ?? ""
    }), [terms]);

    return (
        <Formik
            initialValues={initValues()}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <Form>
                <Grid container spacing={FORM_SPACING}>
                    <Grid item xs={12}>
                        <RichTextField
                            label={termsLabel}
                            name="terms"
                            height={200}
                            disabled={disabled}
                            {...RichTextFieldProps}
                        />
                    </Grid>
                    {
                        !disabled && <SaveGridItem loading={loading} ButtonProps={{disabled}} />
                    }
                </Grid>
            </Form>
        </Formik>
    );
};
export default TermsForm;

export interface TermsFormReturnProps extends FormReturnProps<TermsFormValues> {
    props: Props;
}
interface Opt extends Omit<FormOpt<TermsFormValues, any>, "afterSave"> {
    update: (values: TermsFormValues)=>Promise<boolean>;
    loading: boolean;
    termsLabel: string;
    terms?: string;
}
export function useTermsForm({update, loading, termsLabel, terms}: Opt): TermsFormReturnProps {

    const onSubmit: OnSubmit<TermsFormValues> = useCallback( async (values, formikHelpers) => {
        if (await update(values)) formikHelpers.resetForm({values});
    }, [update]);

    const {props} = useForm(onSubmit, loading);

    return useMemo(()=>({
        props: {...props, termsLabel, terms}
    }), [props, termsLabel, terms]);
}
