import React, {FC, useCallback, useContext, useMemo} from "react";
import SupplierBlock, {SupplierBlockProps} from "../SupplierBlock";
import ExternalOneFile from "../../../../common/ExternalOneFile";
import {DocumentType} from "../../../../props/apiRequests";
import {Grid} from "@mui/material";
import {BusinessCaseContext, useBusinessCaseDocuments} from "../../props";
import {
    BusinessCaseItemResponse as OldBusinessCaseItemResponse,
    SupplierOrderDocumentResponse
} from "../../../../props/apiResponses";
import {FileFieldAccept} from "../../../../form/fields/FileField";
import DeliveryNoteItemFormModal, {
    useDeliveryNoteItemFormModal
} from "../../../../containers/trade/businessCase/modals/DeliveryNoteItemFormModal";
import {BusinessCaseItemResponse} from "../../../../containers/trade/businessCase/schema/businessCaseItem";
import useAfterSaveSerialNumbers from "./useAfterSaveSerialNumbers";

interface Props extends SupplierBlockProps {}

const DeliveryNoteBlock: FC<Props> = (
    {
        supplier,
        items: rawItems,
        supplierOrder,
        disabled,
        deliveryNote
    }
) => {
    const context = useContext(BusinessCaseContext);
    const deliveryNoteItemModal = useDeliveryNoteItemFormModal();

    const afterSaveSerialNumbers = useAfterSaveSerialNumbers();

    const documents = useBusinessCaseDocuments(supplier, deliveryNote);

    const items = useMemo<OldBusinessCaseItemResponse[]>(()=>rawItems.filter((i)=>i.deliveryNoteId===deliveryNote?.id),
        [rawItems, deliveryNote]);

    if (!context?.data) return null;

    return (
        <SupplierBlock
            items={items}
            handleDetailItem={(row)=>deliveryNoteItemModal.openModal({
                response: row.item as unknown as BusinessCaseItemResponse,
                isSubItem: row.isSubItem
            })}
            name={supplier?.name}
            purchaseCurrency={supplierOrder?.currency}
            disabled={disabled}
            isSerialNumbers
            disableBorder
            filesColumn={
                <>
                    <Grid item xs={12}>
                        <ExternalOneFile
                            label="Dodací list"
                            documentType={DocumentType.SUPPLIER_DELIVERY_NOTE}
                            accept={FileFieldAccept.DOCUMENT}
                            documents={context.data.documents}
                            getDocumentBlob={documents.getDocumentBlob}
                            handleRemove={documents.removeDocumentByType}
                            handleUpload={documents.uploadFile}
                            setDocuments={documents.setDocuments}
                            startLoading={documents.manipulator.get.startLoading}
                            stopLoading={documents.manipulator.get.stopLoading}
                            disabled={context?.finished}
                            match={
                                (document)=>
                                    document.type===DocumentType.SUPPLIER_DELIVERY_NOTE
                                    && (document as SupplierOrderDocumentResponse).supplierId===supplier?.id
                                    && document.deliveryNoteId===deliveryNote?.id
                            }
                        />
                    </Grid>
                </>
            }
        >
            <DeliveryNoteItemFormModal {...deliveryNoteItemModal} callback={({businessCaseItem})=>afterSaveSerialNumbers(businessCaseItem as any)} disabled={disabled} />
        </SupplierBlock>
    );
};
export default DeliveryNoteBlock;
