import {array, Asserts, boolean, date, number, object, ref, setLocale, string} from "yup";
import qs from "qs";
import {Messages, yupLocalization} from "./messages";
import {ActivityStatus} from "../common/ActivityChip";
import {CompanyEmbeddedResponse} from "./apiResponses";
import {DeliveryNoteRequest} from "../containers/trade/businessCase/schema/deliveryNote";
import {InvoiceRequest} from "../containers/trade/businessCase/schema/invoice";

setLocale(yupLocalization);

export function paramsSerializer(params: any) {
    return qs.stringify(params, {arrayFormat: "comma", skipNulls: true});
}

export function paramsDeserializer<T = any>(search: string): T {
    return qs.parse(search) as any;
}

export type ID = number | string;

export interface BaseRP {
    controller?: AbortController;
}

export interface DataRP<T> {
    data: T;
}

export interface IdRP {
    id: ID;
}

export interface QueryRP {
    params?: any;
}

export interface FileRP {
    file: File;
    metadata: DocumentRequest;
}

export interface GetRP extends BaseRP {}
export interface DeleteRP extends GetRP {}
export interface PostRP<T> extends BaseRP, DataRP<T> {}
export interface PutRP<T> extends PostRP<T>, IdRP {}


export interface Request {

}

export interface OptionalIdRequest {
    id?: number;
}

export interface IdRequest extends OptionalIdRequest {
    id: number;
}

export enum UserStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export enum UserRole {
    ADMIN = "ROLE_ADMIN",
    EMPLOYEE = "ROLE_EMPLOYEE"
}

export function translateUserRole(value: UserRole | string): string {
    switch (value) {
        case UserRole.ADMIN: return "Admin";
        case UserRole.EMPLOYEE: return "Zaměstnanec";
        case "DEVELOPER": return "Vývojář";
    }

    return "";
}

export enum Language {
    CZECH="CS",
    ENGLISH="EN"
}

export function translateLanguage(value: Language) {
    switch (value) {
        case Language.CZECH: return "Čeština";
        case Language.ENGLISH: return "Angličtina";
    }
}

export function translateState(value?: ActivityStatus): string {
    switch (value) {
        case "ACTIVE": return "Aktivní";
        case "INACTIVE": return "Neaktivní";
        case "COMPLETED": return "Dokončeno";
        case "INVOICE_GENERATED": return "Vytvořená faktura";
        case "INITIAL_PHASE": return "Probíhá";
        case "LOCKED": return "Uzamčeno";
        case "CANCELED": return "Zrušeno";
        case "READ": return "Dokončeno";
        case "UNREAD": return "Nedokončeno";
        case "IN_PROGRESS": return "V procesu";
    }

    return value ?? "";
}

export const idSchema = {
    id: number().required()
}

export const idSchemaObject = object(idSchema);

export const idSchemaNotRequired = {
    id: number()
}

export const idSchemaNotRequiredObject = object(idSchemaNotRequired);


export enum DocumentType {
    CUSTOMER_OFFER="CUSTOMER_OFFER",
    CUSTOMER_ORDER="CUSTOMER_ORDER",
    CUSTOMER_ORDER_CONFIRMATION="CUSTOMER_ORDER_CONFIRMATION",
    CUSTOMER_DELIVERY_NOTE="CUSTOMER_DELIVERY_NOTE",
    CUSTOMER_INVOICE="CUSTOMER_INVOICE",
    SUPPLIER_ORDER="SUPPLIER_ORDER",
    SUPPLIER_ORDER_CONFIRMATION="SUPPLIER_ORDER_CONFIRMATION",
    SUPPLIER_OFFER="SUPPLIER_OFFER",
    SUPPLIER_DELIVERY_NOTE="SUPPLIER_DELIVERY_NOTE",
    OTHER="OTHER"
}

export function translateDocumentType(value: DocumentType): string {
    switch (value) {
        case DocumentType.CUSTOMER_ORDER: return "Objednávka zákazníka";
        case DocumentType.CUSTOMER_ORDER_CONFIRMATION: return "Potvrzení objednávky zákazníka";
        case DocumentType.CUSTOMER_DELIVERY_NOTE: return "Dodací list pro zákazníka";
        case DocumentType.SUPPLIER_ORDER: return "Objednávka dodavateli";
        case DocumentType.SUPPLIER_ORDER_CONFIRMATION: return "Potvrzení objednávky dodavatelem";
        case DocumentType.SUPPLIER_OFFER: return "Nabídka dodavatele";
        case DocumentType.SUPPLIER_DELIVERY_NOTE: return "Dodací list dodavatele";
        case DocumentType.CUSTOMER_INVOICE: return "Faktura";
        case DocumentType.CUSTOMER_OFFER: return "Nabídka zákazníka";
    }

    return "Ostatní";
}

export const documentSchema = {
    type: string().oneOf(Object.values(DocumentType)).required(),
    title: string().required(),
    mimeType: string().required()
};
export const documentSchemaObject = object(documentSchema);
export interface DocumentRequest extends Asserts<typeof documentSchemaObject>, Request {
    businessCase?: IdRequest;
    deliveryNoteId?: ID;
    invoiceId?: ID;
    type: DocumentType;
}


/**
 * USER
 */

export const loginRequestSchema = object({
    username: string().email().required(),
    password: string().required()
});
export interface LoginRequest extends Asserts<typeof loginRequestSchema> {}

export const passwordSchema = string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/, Messages.INVALID_PASSWORD);
export const passwordCheckSchema = (passwordName: string)=>string().oneOf([ref(passwordName), null], Messages.IDENTICAL_PASSWORDS);

export const userSchema = {
    firstname: string().required(),
    lastname: string().required(),
    email: string().email().required(),
    status: string().oneOf(Object.values(UserStatus)).required(),
    role: array(string().oneOf(Object.values(UserRole))).min(1).required()
}
export const userSchemaObject = object(userSchema);
export interface UserRequest extends Asserts<typeof userSchemaObject>, Request {
    status: UserStatus;
    role: UserRole[];
}

export const userCreationSchema = {
    ...userSchema,
    username: string().required(),
    password: passwordSchema.required(),
    passwordCheck: passwordCheckSchema("password").required()
}
export const userCreationSchemaObject = object(userCreationSchema);
export interface UserCreationRequest extends Omit<Asserts<typeof userCreationSchemaObject>, "passwordCheck">, Request {
    status: UserStatus;
    role: UserRole[];
}

export const passwordChangeSchema = {
    password: passwordSchema.required(),
    passwordCheck: passwordCheckSchema("password").required()
}
export const passwordChangeSchemaObject = object(passwordChangeSchema);
export interface PasswordChangeRequest extends Omit<Asserts<typeof passwordChangeSchemaObject>, "passwordCheck">, Request {}


/**
 * COMPANY
 */

export enum CompanyStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export const countrySchema = {
    title: string(),
    titleEn: string()
}
export const countrySchemaObject = object(countrySchema);
export interface CountryRequest extends Asserts<typeof countrySchemaObject>, Request {}


export const addressSchemaNotRequired = {
    street: string(),
    city: string(),
    postcode: string(),
    type: string(),
    country: idSchemaNotRequiredObject
};
export const addressSchema = {
    ...addressSchemaNotRequired,
    street: string().required(),
    city: string().required(),
    country: idSchemaObject.required()
};
export const addressSchemaObjectNotRequired = object(addressSchemaNotRequired);
export const addressSchemaObject = object(addressSchema);
export interface AddressRequest extends Asserts<typeof addressSchemaObject>, Request {}


export const marketSegmentSchema = {
    code: string().required(),
    title: string().required()
}
export const marketSegmentSchemaObject = object(marketSegmentSchema);
export interface MarketSegmentRequest extends Asserts<typeof marketSegmentSchemaObject>, Request {}

export enum PhoneType {
    CELL_PHONE = "Mobil",
    LANDLINE = "Pevná linka"
}

export const phoneSchema = {
    id: number(),
    type: string().oneOf(Object.values(PhoneType)),
    countryCode: string().required(),
    number: string().required()
}
export const phoneSchemaObject = object(phoneSchema);
export interface PhoneRequest extends Asserts<typeof phoneSchemaObject>, Request {}


export const emailSchema = {
    id: number(),
    email: string().email().required(),
    massMailing: boolean()
}
export const emailSchemaObject = object(emailSchema);
export interface EmailRequest extends Asserts<typeof emailSchemaObject>, Request {}

export enum ContactPersonStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export const contactPersonSchema = {
    firstname: string().required(),
    lastname: string().required(),
    emails: array(emailSchemaObject).required(),
    title: string(),
    salutation: string(),
    position: string(),
    note: string(),
    status: string().oneOf(Object.values(ContactPersonStatus)).required(),
    isSupplier: boolean(),
    marketSegment: idSchemaNotRequiredObject.nullable(),
    phones: array(phoneSchemaObject).required(),
    officeId: number()
}
export const contactPersonSchemaObject = object(contactPersonSchema);
export interface ContactPersonRequest extends Asserts<typeof contactPersonSchemaObject>, Request {
    phones: PhoneRequest[];
    emails: EmailRequest[];
    company: IdRequest;
}




export enum OfficeStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export const officeSchema = {
    status: string().oneOf(Object.values(OfficeStatus)).required(),
    address: addressSchemaObject.required(),
    isOwnName: boolean(),
    name: string().when("isOwnName", {
        is: true,
        then: string().required()
    })
}
export const officeSchemaObject = object(officeSchema);
export interface OfficeRequest extends Omit<Asserts<typeof officeSchemaObject>, "isOwnName">, Request {
    address: AddressRequest;
    companyId: number;
}


export const customerSchema = {
    name: string().when("physicalPerson", {
        is: "false",
        then: string().required(),
    }),
    status: string().oneOf(Object.values(CompanyStatus)).required(),
    ico: string().when("physicalPerson", {
        is: "false",
        then: string().required()
    }),
    dic: string(),
    note: string(),
    address: addressSchemaObject.required(),
    physicalPerson: string().required(),
    person: contactPersonSchemaObject.nullable().when("physicalPerson", {
        is: "true",
        then: contactPersonSchemaObject.required()
    }),
    vatPayer: boolean()
}
export const customerSchemaObject = object(customerSchema);

export const supplierSchema = {
    ...customerSchema,
    ico: string()
}
export const supplierSchemaObject = object(supplierSchema);

export interface CompanyRequest extends Omit<Asserts<typeof supplierSchemaObject>, "physicalPerson">, Request {
    address: AddressRequest;
    supplier: boolean;
    status: CompanyStatus;
    physicalPerson: boolean;
}

export interface CreatePhysicalPersonCompanyRequest extends Request {
    company: CompanyRequest;
    contactPerson: Omit<ContactPersonRequest, "company">;
}




/**
 * OPPORTUNITIES
 */

export enum OpportunityStatus {
    IN_PROGRESS= "IN_PROGRESS",
    COMPLETED="COMPLETED",
    CANCELED="CANCELED"
}

export const opportunitySchema = {
    name: string().required(),
    note: string(),
    contactPerson: idSchemaObject.required(),
    status: string().oneOf(Object.values(OpportunityStatus)).required()
};
export const opportunitySchemaObject = object(opportunitySchema);
export interface OpportunityRequest extends Asserts<typeof opportunitySchemaObject>, Request {
    contactPerson: IdRequest;
    company: CompanyEmbeddedResponse;
    user?: UserRequest;
    status: OpportunityStatus;
}


export const meetingSchema = {
    meetingDate: date().required(),
    contactPersons: array(string()).required().min(1),
    users: array(string()).required().min(1),
    note: string()
};
export const meetingSchemaObject = object(meetingSchema);
export interface MeetingRequest extends Omit<Asserts<typeof meetingSchemaObject>, "contactPersons" | "users" | "companies">, Request {
    contactPersons: IdRequest[];
    users: IdRequest[];
    tradeId?: number;
}





/**
 * PRODUCTS
 */

export const currencySchema = {
    code: string().required(),
    name: string().required()
};
export const currencySchemaObject = object(currencySchema);
export interface CurrencyRequest extends Asserts<typeof currencySchemaObject>, Request {}


export const currencyExchangeRateSchema = {
    rate: number().required(),
    currencyFrom: idSchemaObject.required(),
    currencyTo: idSchemaObject.required()
}
export const currencyExchangeRateSchemaObject = object(currencyExchangeRateSchema);
export interface CurrencyExchangeRateRequest extends Asserts<typeof currencyExchangeRateSchemaObject>, Request {}



export const discountTypeSchema = {
    name: string().required(),
    importColumnName: string().required()
};
export const discountTypeSchemaObject = object(discountTypeSchema);
export interface DiscountTypeRequest extends Asserts<typeof discountTypeSchemaObject>, Request {}


export const discountSchema = {
    discount: number().required(),
    discountType: idSchemaObject.required()
};
export const discountSchemaObject = object(discountSchema);
export interface DiscountRequest extends Asserts<typeof discountSchemaObject>, Request {
    discountType: IdRequest;
}


export enum ProductStatus {
    ACTIVE="ACTIVE",
    INACTIVE="INACTIVE"
}

export const productSchema = {
    catalogCode: string(),
    catalogName: string().required(),
    description: string(),
    salePrice: number().required(),
    purchasePrice: number().required(),
    standardDiscount: number().required(),
    status: string().oneOf(Object.values(ProductStatus)).required(),
    supplier: idSchemaObject.required(),
    purchaseCurrency: idSchemaObject.required(),
    discounts: array(discountSchemaObject).required(),
    vatRate: idSchemaNotRequiredObject
}
export const productSchemaObject = object(productSchema);
export interface ProductRequest extends Omit<Asserts<typeof productSchemaObject>, "vatRate">, Request {
    status: ProductStatus;
    supplier: IdRequest;
    purchaseCurrency: IdRequest;
    discounts: DiscountRequest[];
    vatRate?: OptionalIdRequest;
    productType: "PRODUCT"
}


/**
 * TRADE FACE
 */

export enum ItemType {
    STANDARD = "STANDARD",
    TEXTUAL = "TEXTUAL"
}

export const discountField = number().min(0).nullable();

export interface VatSummary {
    base: number,
    rate: number,
    vatValue: number,
    totalPrice: number
}
export interface VatSummaryMap {
    [key: string]: VatSummary;
}

export const pricingSchema = {
    discountPercentage: discountField,
    discountValue: discountField.required(),
    price: number().required(),
    priceRaw: number().nullable(),
    priceVat: number().nullable(),
    currency: idSchemaObject.required(),
    ignoreVat: boolean()
};
export const pricingSchemaObject = object(pricingSchema);
export interface PricingRequest extends Asserts<typeof pricingSchemaObject>, Request {
    currency: IdRequest;
    vatSummary: VatSummaryMap;
}

export const bankAccountSchema = {
    name: string().required(),
    bankName: string().required(),
    accountNumber: string().required(),
    bankNumber: string().required(),
    iban: string(),
    bic: string(),
    currency: idSchemaObject.required(),
    defaultAccount: boolean()
};
export const bankAccountSchemaObject = object(bankAccountSchema);
export interface BankAccountRequest extends Asserts<typeof bankAccountSchemaObject>, Request {
    currency: IdRequest;
}

export const tradeItemSchema = {
    type: string().oneOf(Object.values(ItemType)).required(),
    amount: number().integer().min(0).required(),
    code: string().required(),
    name: string().required(),
    discountPercentage: discountField,
    discountValue: discountField,
    priceTotal: number(),
    product: idSchemaObject.nullable(),
    vat: number().min(0).nullable(),
    priceTotalWithVat: number(),
    vatValue: number(),
    category: string().nullable(),
    unit: string()
};
export const tradeItemSchemaObject = object(tradeItemSchema);
export interface TradeItemRequest extends Asserts<typeof tradeItemSchemaObject>, Request {
    order: number;
    parentItemId?: number;
    product: IdRequest | null;
    options?: any[];
}


/**
 * OFFER
 */

export enum OfferStatus {
    IN_PROGRESS= "IN_PROGRESS",
    COMPLETED="COMPLETED",
    CANCELED="CANCELED"
}

export interface OfferCreationRequest extends Request {
    tradeId: number;
    userId: number;
    note?: string;
    versionCurrency: IdRequest;
    versionLanguage: Language;
    versionNote?: string;
    versionVat?: number;
}

export interface OfferVersionCreationRequest extends Request {
    tradeId: number;
    offerVersionId: number;
}

let offerItemSchema: {[key: string]: any} = {
    ...tradeItemSchema,
    pricePerUnit: number().min(0).required(),
    description: string()
}
offerItemSchema["options"] = array(object(offerItemSchema));

export const offerItemSchemaObject = object(offerItemSchema);
export interface OfferItemRequest extends Asserts<typeof offerItemSchemaObject>, TradeItemRequest, Request {
    options?: OfferItemRequest[];
}

export enum OfferVersionStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    COMPLETED = "COMPLETED"
}

export const offerVersionSchema = {
    language: string().oneOf(Object.values(Language)).required(),
    pricing: pricingSchemaObject.required(),
    status: string().oneOf(Object.values(OfferVersionStatus)).required(),
    note: string(),
    appendix: string()
}
export const offerVersionSchemaObject = object(offerVersionSchema);
export interface OfferVersionRequest extends Asserts<typeof offerVersionSchemaObject>, Request {
    items?: OfferItemRequest[];
    status: OfferVersionStatus;
    language: Language;
    pricing: PricingRequest;
}

export const offerSchema = {
    user: idSchemaObject.required(),
    note: string()
}
export const offerSchemaObject = object(offerSchema);
export interface OfferRequest extends Asserts<typeof offerSchemaObject>, Request {
    contactPerson: IdRequest;
    user: IdRequest;
    trade: IdRequest;
    company: IdRequest;
    code: number;
    year: number;
}


/**
 * BUSINESS CASE
 */

let businessCaseItemSchema: {[key: string]: any} = {
    ...tradeItemSchema,
    purchasePricePerUnit: number().min(0),
    sellingPricePerUnit: number().min(0).required(),
    customerDescription: string(),
    invoiceDescription: string(),
    productDescription: string(),
    serialNumbers: array(string()),
    purchaseDiscountValue: discountField,
    purchaseDiscountPercentage: discountField,
    purchasePriceTotal: number()
};
businessCaseItemSchema["options"] = array(object(businessCaseItemSchema));

export const businessCaseItemSchemaObject = object(businessCaseItemSchema);
export interface BusinessCaseItemRequest extends Asserts<typeof businessCaseItemSchemaObject>, TradeItemRequest, Request {
    options?: BusinessCaseItemRequest[];
    deliveryNoteId?: ID;
}

export interface BusinessCaseCreationRequest extends Request {
    tradeId: number;
    offerVersionId: number;
}

export enum BusinessCaseStatus {
    INITIAL_PHASE = "INITIAL_PHASE",
    LOCKED = "LOCKED",
    INVOICE_GENERATED = "INVOICE_GENERATED",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED"
}

export enum ShippingDestination {
    CUSTOMER="CUSTOMER",
    MY_COMPANY="MY_COMPANY",
    CUSTOM="CUSTOM"
}

export function translateShippingDestination(value: ShippingDestination) {
    switch (value) {
        case ShippingDestination.MY_COMPANY: return "Doručit do mé firmy";
        case ShippingDestination.CUSTOMER: return "Doručit zákazníkovi";
        case ShippingDestination.CUSTOM: return "Doručit na jinou adresu";
    }
}

export interface BusinessCaseRequest extends Request {
    code: number;
    year: number;
    contactPerson: IdRequest;
    user: IdRequest;
    status: BusinessCaseStatus;
    trade: IdRequest;
    company?: IdRequest;
    language: Language;
    note?: string;
    paymentTermsDeliveryNote?: string;
    paymentTermsOrderConf?: string;
    pricing?: PricingRequest;
    exchangeRateCzk?: number;
    items?: BusinessCaseItemRequest[];
    documents: DocumentRequest[];
    supplierOrders: SupplierOrderRequest[];
    customerOrderCode?: string;
    deliveryNotes: DeliveryNoteRequest[];
    invoices: InvoiceRequest[];
}

export const supplierOrderSchema = {
    supplierOrderNumber: number(),
    supplierOrderCode: string(),
    discountPercentage: discountField,
    discountValue: discountField.required(),
    shippingDestination: string().oneOf(Object.values(ShippingDestination)).required(),
    shippingAddress: addressSchemaObjectNotRequired.nullable().when("shippingDestination", {
        is: ShippingDestination.CUSTOM,
        then: addressSchemaObject.required()
    }),
    shippingCompanyName: string().when("shippingDestination", {
        is: ShippingDestination.CUSTOM,
        then: string().required()
    }),
    shippingContact: string()
        .when("shippingDestination", {
            is: ShippingDestination.MY_COMPANY,
            then: string().required()
        })
        .when("shippingDestination", {
            is: ShippingDestination.CUSTOM,
            then: string().required()
        }),
    shippingPhone: string(),
    shippingEmail: string().email(),
    paymentTerms: string(),
    language: string().oneOf(Object.values(Language)),
    currency: idSchemaObject,
    orderReference: string()
};
export const supplierOrderSchemaObject = object(supplierOrderSchema);
export interface SupplierOrderRequest extends Asserts<typeof supplierOrderSchemaObject>, Request {
    businessCaseId: number,
    supplierId: number
}


/**
 * CONFIGURATION
 */


export enum ConfigurationKey {
    OFFER_DEFAULT_VAT="offerVersion:vat:default",
    FOOTER_1ST_COLUMN_EN="pdfCommon:footer1stColumn:en",
    FOOTER_1ST_COLUMN_CS="pdfCommon:footer1stColumn:cs",
    FOOTER_2ND_COLUMN_EN="pdfCommon:footer2ndColumn:en",
    FOOTER_2ND_COLUMN_CS="pdfCommon:footer2ndColumn:cs",
    FOOTER_3RD_COLUMN_EN="pdfCommon:footer3rdColumn:en",
    FOOTER_3RD_COLUMN_CS="pdfCommon:footer3rdColumn:cs",
    MAIN_PAGE_CONTACT_EN="pdfCommon:mainPageContact:en",
    MAIN_PAGE_CONTACT_CS="pdfCommon:mainPageContact:cs",

    COMPANY_NAME="common:companyName:default",
    COMPANY_PHONE="common:companyPhone:default",
    COMPANY_EMAIL="common:companyEmail:default",
    COMPANY_ADDRESS_STREET="common:companyAddressStreet:default",
    COMPANY_ADDRESS_CITY="common:companyAddressCity:default",
    COMPANY_ADDRESS_POSTCODE="common:companyAddressPostcode:default",
    COMPANY_ADDRESS_COUNTRY="common:companyAddressCountry:default",
    COMPANY_ICO="common:companyICO:default",
    COMPANY_DIC="common:companyDIC:default",

    OPPORTUNITY_CLOSING_STAGE="common:opportunityClosingStage:default"
}

export const configurationSchema = {
    value: string()
};
export const configurationSchemaObject = object(configurationSchema);
export interface ConfigurationRequest extends Asserts<typeof configurationSchemaObject>, Request {
    key: ConfigurationKey;
}

export enum ConfigurationDocumentTextType {
    PRE_TABLE="PRE_TABLE",
    POST_TABLE="POST_TABLE",
    APPENDIX="APPENDIX"
}

export function translateConfigurationDocumentTextType(value: ConfigurationDocumentTextType): string {
    switch (value) {
        case ConfigurationDocumentTextType.PRE_TABLE: return "Před tabulkou";
        case ConfigurationDocumentTextType.POST_TABLE: return "Po tabulce";
        case ConfigurationDocumentTextType.APPENDIX: return "Dodatek";
    }

    return "";
}

export const configurationDocumentTextSchema = {
    id: number(),
    documentType: string().oneOf(Object.values(DocumentType)).required(),
    documentTextType: string().oneOf(Object.values(ConfigurationDocumentTextType)).required(),
    language: string().oneOf(Object.values(Language)).required(),
    title: string().required(),
    text: string().required()
};
export const configurationDocumentTextSchemaObject = object(configurationDocumentTextSchema);
export interface ConfigurationDocumentTextRequest extends Asserts<typeof configurationDocumentTextSchemaObject>, Request {

}



/**
 * NOTIFICATIONS
 */
export enum NotificationType {
    COMPANY = "COMPANY",
    CONTACT_PERSON = "CONTACT_PERSON",
    MEETING = "MEETING",
    OPPORTUNITY = "OPPORTUNITY",
    OFFER = "OFFER",
    OFFER_VERSION = "OFFER_VERSION",
    BUSINESS_CASE = "BUSINESS_CASE",
    TIME_ONLY = "TIME_ONLY"
}

export function translateNotificationType(type: NotificationType): string {
    switch (type) {
        case NotificationType.BUSINESS_CASE: return "Obchodní případ";
        case NotificationType.COMPANY: return "Společnost";
        case NotificationType.CONTACT_PERSON: return "Kontaktní osoba";
        case NotificationType.MEETING: return "Schůzka";
        case NotificationType.OFFER: return "Nabídka";
        case NotificationType.OPPORTUNITY: return "Příležitost";
        case NotificationType.OFFER_VERSION: return "Verze nabídky";
    }

    return "";
}

export enum NotificationStatus {
    READ="READ",
    UNREAD="UNREAD"
}

export const notificationSchema = {
    title: string().required(),
    note: string(),
    notificationDate: date().required(),
    recipients: array(number()).min(1).required(),
    notificationType: object({
        entity: string().oneOf(Object.values(NotificationType)).required()
    }),
    entity: idSchemaNotRequiredObject.nullable().when("notificationType.entity", {
        is: (val: NotificationType)=>NotificationType.TIME_ONLY!==val,
        then: idSchemaObject.nullable().required()
    })
}
export const notificationSchemaObject = object(notificationSchema);

export interface NotificationRequest extends Omit<Asserts<typeof notificationSchemaObject>, "recipients">, Request {
    title: string;
    note: string;
    status: NotificationStatus;
    notificationDate: Date;
    recipients: IdRequest[];
    notificationType: {
        entity: NotificationType;
    },

    entityId: number | null;
    entity: null | IdRequest;
}
