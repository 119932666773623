import {FormValues} from "@data-session/forms";
import {Select, SelectOption, SelectProps} from "@data-session/mui";
import React, {useMemo} from "react";
import {DeliveryNoteResponse} from "../../schema/deliveryNote";
import useTranslation from "../../../../../utils/translations/useTranslation";

export interface DeliveryNoteSelectProps<TFormValues extends FormValues> extends Omit<SelectProps<TFormValues>, "options"> {
    options: DeliveryNoteResponse[];
}

const DeliveryNoteSelect = <TFormValues extends FormValues = any>(props: DeliveryNoteSelectProps<TFormValues>) => {
    const {t} = useTranslation();

    const options = useMemo<SelectOption[]>(() => {
        return props.options.map((deliveryNoteResponse)=>({
            key: deliveryNoteResponse.id,
            label: `${t("Delivery_note")} ${deliveryNoteResponse.deliveryNoteNumber}`
        }));
    }, [props.options]);

    return (
        <Select {...props} options={options} />
    );
};

export default DeliveryNoteSelect;
