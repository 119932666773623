import {DialogProps} from "@mui/material";
import {ReactNode, useCallback, useMemo, useState} from "react";

/*
 * Component props
 */

export interface DialogBase {
    DialogProps: DialogProps;
    handleClose: ()=>void;
}

type Button = {
    label?: string;
    disabled?: boolean;
}

export type YesAndNoButtonsSettings = {
    yes?: Button;
    no?: Button;
}

export interface ConfirmationDialogBase extends DialogBase {
    title?: string;
    message?: ReactNode;
    handleConfirm: ()=>void;
    loading?: boolean;
    primary?: "yes" | "no";
    buttons?: YesAndNoButtonsSettings;
    hideIcons?: boolean;
}


/*
 * Hook return props
 */

export interface DialogReturnProps {
    props: DialogBase;
    handleOpen: ()=>void;
    handleClose: ()=>void;
}

export interface ConfirmationDialogReturnProps extends DialogReturnProps {
    props: ConfirmationDialogBase;
}



/*
 * Hooks
 */

export function useDialog(includeOnClose?: boolean): DialogReturnProps {
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = useCallback(()=> setOpen(false), []);
    const handleOpen = useCallback(()=>setOpen(true), []);

    return useMemo(()=>({
        props: {
            DialogProps: {
                open,
                onClose: includeOnClose ? handleClose : undefined
            },
            handleClose
        },
        handleOpen, handleClose
    }), [open, includeOnClose, handleClose, handleOpen]);
}

export function useConfirmationDialog(
    handleConfirm: ()=>void
): ConfirmationDialogReturnProps {
    const props = useDialog(true);

    return useMemo(()=>({
        ...props,
        props: {
            ...props.props,
            handleConfirm
        }
    }), [handleConfirm, props]);
}



