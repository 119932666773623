import {FC, MouseEventHandler, ReactElement, ReactNode} from "react";
import Button from "../button/Button";
import {Save} from "@mui/icons-material";
import {DialogActions} from "@mui/material";
import SaveButton from "../button/SaveButton";

type Props = {
    handleSave: MouseEventHandler;
    loading?: boolean;
    handleClose: ()=>void;
    saveLabel?: string;
    saveIcon?: ReactElement;
    disabled?: boolean;
    children?: ReactNode;
};
const SaveAndCloseActions: FC<Props> = (
    {
        handleSave,
        loading,
        handleClose,
        saveLabel,
        saveIcon,
        disabled,
        children
    }
) => {
    return (
        <DialogActions>
            {
                !disabled &&
                    <>
                        <SaveButton
                            onClick={handleSave}
                            loading={loading}
                            startIcon={saveIcon}
                            ButtonProps={{disabled}}
                        >
                            {saveLabel}
                        </SaveButton>
                        {children}
                    </>
            }
            <Button
                onClick={handleClose}
                variant="outlined"
                loading={loading}
            >
                Zavřít
            </Button>
        </DialogActions>
    );
};
export default SaveAndCloseActions;