import {createTheme, css, ThemeOptions} from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';
import {grey} from "@mui/material/colors";
import {csCZ} from "@mui/material/locale";
import type {} from '@mui/x-date-pickers/themeAugmentation';

const MAIN_COLORS = {
    primary: "#014F9D",
    secondary: '#e65100',
    error: '#cf2738',
    success: '#27cf6b'
}

export const PAGE_PADDING: number = 3;

export const THEME_OPTIONS: ThemeOptions = {
    palette: {
        primary: {
            main: MAIN_COLORS.primary,
        },
        secondary: {
            main: MAIN_COLORS.secondary
        },
        error: {
            main: MAIN_COLORS.error
        },
        success: {
            main: MAIN_COLORS.success
        }
    },
    shadows: Array(25).fill("none") as Shadows,
    components: {
        MuiDialogTitle: {
            defaultProps: {
                color: "primary"
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: css`
                border: 1px solid ${MAIN_COLORS.primary};
            `,
                root: css`
                & .MuiMenuItem-root {
                    color: ${MAIN_COLORS.primary};
                }
            `
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: css`
                  background: ${grey[100]};
                  margin-top: 10px;
                `
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
                size: "small",
                fullWidth: true,
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: "standard",
                size: "small",
                fullWidth: true
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
                size: "small",
                fullWidth: true
            }
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true
            }
        },
        MuiCheckbox: {
            defaultProps: {
                size: "small"
            }
        },
        MuiCssBaseline: {
            styleOverrides: (theme) => css`
              .SnackbarContainer-root {
                & .SnackbarItem-variantSuccess {
                  background-color: ${theme.palette.success.main} !important;
                }

                & .SnackbarItem-variantError {
                  background-color: ${theme.palette.error.main} !important;
                }

                & .SnackbarItem-variantInfo {
                  background-color: ${theme.palette.info.main} !important;
                }

                & .SnackbarItem-variantWarning {
                  background-color: ${theme.palette.warning.main} !important;
                }
              }
            `
        }
    }
};

export const theme = createTheme(THEME_OPTIONS, csCZ);