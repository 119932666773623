import React, {FC} from "react";

type Props = {
    when: boolean;
};
const Prompt: FC<Props> = (
    {
        when
    }
) => {

    // TODO

    return (
        <></>
    );
};
export default Prompt;

/*
<ConfirmationDialog
                        handleClose={()=>onCancel(true)}
                        handleConfirm={()=>onConfirm(true)}
                        DialogProps={{open: isActive}}
                        title="Máte neuložené změny"
                        message="Doopravdy si přejete odejít? Přijdete o neuložené změny."
                        primary="no"
                    />
 */