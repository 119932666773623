import {CRUDEntityServiceBase} from '@data-session/services';
import {DeliveryNoteRequest, DeliveryNoteResponse} from "../schema/deliveryNote";


export class DeliveryNoteService extends
    CRUDEntityServiceBase<DeliveryNoteRequest, DeliveryNoteRequest, DeliveryNoteRequest, DeliveryNoteResponse> {
}

const deliveryNoteService = new DeliveryNoteService("/delivery-notes");
export default deliveryNoteService;
