import React, {FC, useRef} from "react";
import {FieldProps} from "../props";
import {alpha, Box, CircularProgress, css, FormControl, FormHelperText, styled} from "@mui/material";
import {grey} from "@mui/material/colors";
import {Delete, Download, UploadFile} from "@mui/icons-material";
import {useField} from "formik";
import mime from "mime";


interface CommonProps extends FieldProps {
    loading?: boolean;
}

export enum FileFieldAccept {
    DOCUMENT=".pdf,.doc,.docx,.eml,.msg,.xls,.xlsx",
    EXCEL=".xls,.xlsx"
}

export enum FileFieldActions {
    DELETE, DOWNLOAD
}

interface InputProps extends CommonProps {
    multiple?: boolean;
    value?: File[];
    accept?: FileFieldAccept;
    onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLLabelElement>, files: File[])=>void;
    actions?: [FileFieldActions, (index: number)=>void][];
    disabled?: boolean;
    error?: string;
}

interface FormikFieldProps extends CommonProps {
    InputProps?: InputProps;
}
const FileField: FC<FormikFieldProps> = (
    {
        name,
        label,
        InputProps
    }
) => {
    const [field, meta, helpers] = useField(name ?? "");

    return <FileFieldInput
        label={label}

        {...field}

        onChange={(_, files)=>{
            helpers.setValue(files, true);
        }}

        error={meta.touched && !!meta.error ? meta.error : undefined}

        {...InputProps}
    />;
};
export default FileField;


export const FileFieldInput: FC<InputProps> = (
    {
        label,
        name,
        multiple,
        value,
        accept,
        onChange,
        actions,
        loading,
        disabled,
        error
    }
) => {
    const labelRef = useRef<HTMLLabelElement>(null);

    const handleAction = (event: React.MouseEvent<SVGSVGElement>, callback: (index: number)=>void, index: number) => {
        event.preventDefault();
        callback(index);
    };

    const addFiles = (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLLabelElement>, files: File[]) => {
        if (onChange) onChange(e, multiple && !!value ? value.concat(files) : files);
    };

    const setDragOverClass = () => {
        if (!labelRef.current) return;
        labelRef.current.className = "dragOver";
    };

    const removeDragOverClass = () => {
        if (!labelRef.current) return;
        labelRef.current.className = "";
    };

    const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        setDragOverClass();
    };

    const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        removeDragOverClass();
    };

    const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();

        const {files} = e.dataTransfer;
        if (accept) {
            const mimeRegExp = new RegExp( accept.replace( /\*/g, '.\*' ).replace( /\,/g, '|' ) );
            for (let file of files) {
                if (!mimeRegExp.test("."+(mime.getExtension(file.type) ?? ""))) {
                    return;
                }
            }
        }

        addFiles(e, Array.from(files));
        removeDragOverClass();
    };

    disabled = disabled || loading;

    return (
        <Wrapper fullWidth disabled={disabled} error={!!error}>
            <label
                ref={labelRef}

                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <span className="label">{label}</span>
                <span className="content">
                    {
                        !disabled &&
                            <span className="uploadIcon" style={{visibility: disabled ? "hidden" : undefined}}>
                                <UploadFile/>
                            </span>
                    }
                    {
                        !!value && value.map(
                            (file, fileIndex)=>
                                <span className="files" key={fileIndex}>
                                    <span className="fileName">{file.name}</span>
                                    <span className="actions">
                                        {
                                            actions?.map((action, i) => {
                                                switch (action[0]) {
                                                    case FileFieldActions.DELETE:
                                                        if (disabled) return null;
                                                        return <Delete key={i} className="delete" color="error" onClick={
                                                            (e)=>handleAction(e, action[1], fileIndex)
                                                        } />;
                                                    case FileFieldActions.DOWNLOAD:
                                                        return <Download key={i} cursor="pointer" sx={(theme)=>({fill: theme.palette.text.primary})} onClick={
                                                            (e)=>handleAction(e, action[1], fileIndex)
                                                        } />;
                                                }

                                                return null;
                                            })
                                        }
                                    </span>
                                </span>
                        )
                    }
                </span>
                <input
                    name={name}
                    type="file"
                    multiple={multiple}
                    accept={accept}
                    disabled={disabled}
                    onChange={(e)=>{
                        addFiles(e, Array.from(e.target.files ?? []));
                    }}
                />
            </label>
            {
                loading &&
                <Loading>
                    <CircularProgress size={20} sx={{color: grey[400]}} />
                </Loading>
            }
            {
                !!error &&
                    <FormHelperText>{error}</FormHelperText>
            }
        </Wrapper>
    );
};


const Wrapper = styled(FormControl)(({theme, error, disabled})=>{

    const color = disabled ? alpha(theme.palette.text.primary, 0.38) : alpha(theme.palette.text.primary, 0.6);
    const fileColor = disabled ? color : theme.palette.text.primary;

    return css`
      & input {
        display: none;
      }
      
      & label {
        border: 1px dashed ${error ? theme.palette.error.main : color};
        border-radius: 5px;
        color: ${error ? `${theme.palette.error.main} !important` : fileColor};
        padding: ${theme.spacing(0.3)} ${theme.spacing(0.5)};
        cursor: ${!disabled ? "pointer" : "default"};
        display: flex;
        flex-direction: column;
        align-items: stretch;
        transition: border-color 300ms ease, border-style 300ms ease;
        
        &:hover {
          border-color: ${theme.palette.text.primary};

          & > span.label {
            color: ${theme.palette.text.primary}
          }
        }
        
        &.dragOver {
          border-color: ${theme.palette.primary.main};
          border-style: solid;

          & > span.label {
            color: ${theme.palette.primary.main}
          }
        }
        
        & > span.label {
          font-size: ${theme.typography.overline.fontSize};
          transition: color 300ms ease;
        }
    
        & > span.content {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          
          & > span.uploadIcon {
            display: flex;
            justify-content: center;
            margin-bottom: ${theme.spacing(1)};
            
            & .MuiSvgIcon-root {
              width: 1.2em;
              height: 1.2em;
            }
          }
          
          & > span.files {
            display: flex;
            justify-content: space-between;
            font-size: ${theme.typography.body2.fontSize};
            align-items: center;
            color: ${theme.palette.text.primary};
            
            & .fileName {
              white-space: nowrap;
              overflow: hidden;
              Meetingverflow: ellipsis;
              padding-right: ${theme.spacing(0.5)};
            }
            
            & .actions {
              display: flex;
              align-items: center;
              
              & .MuiSvgIcon-root {
                width: 0.8em;
                height: 0.8em;
              }
            }
          }
          
          & svg:not(.delete) {
            color: ${!!error ? theme.palette.error.main : color};
          }
        }
      }
`;
});

const Loading = styled(Box)(({theme})=>(css`
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    display: grid;
    place-items: center;
`));