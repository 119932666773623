import SupplierBlock from "../SupplierBlock";
import React, {useContext, useMemo} from "react";
import {DeliveryNoteResponse} from "../../../../containers/trade/businessCase/schema/deliveryNote";
import DeliveryNoteItemFormModal, {
    useDeliveryNoteItemFormModal
} from "../../../../containers/trade/businessCase/modals/DeliveryNoteItemFormModal";
import useAfterSaveSerialNumbers from "./useAfterSaveSerialNumbers";
import {BusinessCaseContext} from "../../props";
import {BusinessCaseItemResponse} from "../../../../containers/trade/businessCase/schema/businessCaseItem";

export interface DeliveryNoteBlockWithoutSupplierProps {
    data: DeliveryNoteResponse;
}

const DeliveryNoteBlockWithoutSupplier = (
    {
        data
    }: DeliveryNoteBlockWithoutSupplierProps
) => {
    const context = useContext(BusinessCaseContext);
    const deliveryNoteItemModal = useDeliveryNoteItemFormModal();
    const afterSaveSerialNumbers = useAfterSaveSerialNumbers();

    const items = useMemo(
        ()=>context?.data?.items?.filter(i=>!i.product?.supplier && i.deliveryNoteId===data.id),
        [context, data]
    );

    if (!items || items.length===0) return null;

    return (
        <SupplierBlock
            disableBorder
            handleDetailItem={(row)=>deliveryNoteItemModal.openModal({
                response: row.item as unknown as BusinessCaseItemResponse,
                isSubItem: row.isSubItem
            })}
            items={items ?? []}
            purchaseCurrency={data.invoice.pricing.currency}
        >
            <DeliveryNoteItemFormModal {...deliveryNoteItemModal} callback={({businessCaseItem})=>afterSaveSerialNumbers(businessCaseItem as any)} disabled={context?.finished} />
        </SupplierBlock>
    );
};

export default DeliveryNoteBlockWithoutSupplier;
