import {
    GetServiceBasicArgs,
    ID,
    putRequestBase,
    PutService,
    PutServiceBasicArgs,
    ServiceBase
} from "@data-session/services";
import {BusinessCaseItemRequest, BusinessCaseItemResponse} from "../schema/businessCaseItem";

export type BusinessCaseItemServicePutArgs = PutServiceBasicArgs<BusinessCaseItemRequest> & {businessCaseId: ID};

class BusinessCaseItemService extends ServiceBase implements PutService<BusinessCaseItemServicePutArgs, BusinessCaseItemResponse> {
    public addressWithBusinessCase = ({businessCaseId, itemId}: {businessCaseId?: ID, itemId?: ID}) => "/business-cases" + (businessCaseId ? `/${businessCaseId}` : "") + this.address(itemId);

    public put(args: BusinessCaseItemServicePutArgs): Promise<BusinessCaseItemResponse> {
        return putRequestBase.make<BusinessCaseItemResponse, BusinessCaseItemRequest>({
            url: this.addressWithBusinessCase({itemId: args.id, businessCaseId: args.businessCaseId}),
            controller: args.controller,
            body: args.data
        });
    }
}

const businessCaseItemService = new BusinessCaseItemService("/items");
export default businessCaseItemService;

