import {CountryResponse} from "../../props/apiResponses";
import {useSelectRequest} from "../../form/fields/select/Select";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {CountryRequest} from "../../props/apiRequests";
import {useCallback} from "react";
import {useCRUDRequest} from "../CRUDRequestService";

const COUNTRIES_API = "/countries";

class CountryService extends GridCRUDServiceImpl<CountryRequest, CountryResponse> {}
const countryService = new CountryService(COUNTRIES_API);

export function useCountryManipulator() {
    return useCRUDRequest<CountryResponse, CountryService>(countryService);
}

export function useCountriesManipulator(globalLoading: boolean) {
    return useGridRequest<CountryResponse>(countryService, globalLoading);
}

export function useCountrySelect() {
    const {run, loading} = useCountriesManipulator(false);

    const countries = useSelectRequest(
        useCallback((controller) => run({controller}), []),
        useCallback(data => data.data.map(({id, title})=>(
            {key: id, label: title ?? ""}
        )), [])
    );

    return {countries, loading};
}