import React, {FC} from "react";
import {BusinessCaseGridResponse} from "../../props/apiResponses";
import GridChooserDialog, {
    ChooserDialogBase,
    GridChooserProps,
    GridChooserReturnProps,
    useGridChooserDialog
} from "../../common/dialog/GridChooserDialog";
import BusinessCasesGrid, {BusinessCasesGridRow} from "./BusinessCasesGrid";


const BusinessCaseChooserDialog: FC<ChooserDialogBase<BusinessCaseGridResponse>> = (props) => {

    return (
        <GridChooserDialog<BusinessCaseGridResponse, BusinessCasesGridRow, FC<GridChooserProps<BusinessCasesGridRow>>>

            {...props}

            entityName="obchodních případů"
            component={BusinessCasesGrid}
        />
    );
};
export default BusinessCaseChooserDialog;

export function useBusinessCaseChooserDialog(handleSelection: (businessCase: BusinessCaseGridResponse)=>void): GridChooserReturnProps<BusinessCaseGridResponse> {
    return useGridChooserDialog(handleSelection);
}