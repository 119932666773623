import {FC, ReactElement, ReactNode} from "react";
import Button from "../common/button/Button";
import Form from "./Form";

type Props = {
    children: ReactNode;
    loading: boolean | undefined;
    disabled?: boolean;
    disablePrompt?: boolean;
};
const FormWithoutButton: FC<Props> = (
    {
        children,
        loading,
        disabled,
        disablePrompt
    }
) => {
    return (
        <Form disablePrompt={disablePrompt}>
            {children}
            <Button hidden loading={loading} ButtonProps={{disabled}} />
        </Form>
    );
};
export default FormWithoutButton;