import React, {FC} from "react";
import GridChooserDialog, {
    ChooserDialogBase,
    GridChooserProps,
    GridChooserReturnProps,
    useGridChooserDialog
} from "../../common/dialog/GridChooserDialog";
import OpportunitiesGrid, {OpportunitiesGridRow} from "./OpportunitiesGrid";
import {OpportunityGridResponse} from "../../props/apiResponses";

const OpportunityChooserDialog: FC<ChooserDialogBase<OpportunityGridResponse>> = (props) => {

    return (
        <GridChooserDialog<OpportunityGridResponse, OpportunitiesGridRow, FC<GridChooserProps<OpportunitiesGridRow>>>

            {...props}

            entityName="příležitosti"
            component={OpportunitiesGrid}
        />
    );
};
export default OpportunityChooserDialog;

export function useOpportunityChooserDialog(handleSelection: (opportunity: OpportunityGridResponse)=>void): GridChooserReturnProps<OpportunityGridResponse> {
    return useGridChooserDialog(handleSelection);
}