import React, {lazy, Suspense} from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {SnackbarProvider} from 'notistack';
import {createRoot} from "react-dom/client";
import {ThemeProvider} from "@mui/material";
import {theme} from "./props/theme";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from "@mui/x-date-pickers";
import csLocale from "date-fns/locale/cs";
import NotificationProvider from "./form/notification/NotificationProvider";
import {BrowserRouter} from "react-router-dom";
import {ReactRouter6Adapter} from "use-query-params/adapters/react-router-6";
import {QueryParamProvider} from "use-query-params";
import "./utils/translations/i18n";
import { kcContext } from './containers/keycloak-theme/kcContext';

const App = lazy(()=>import("./App"));
const KcApp = lazy(()=>import("./containers/keycloak-theme/KcApp"));

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale} localeText={{
                cancelButtonLabel: "Zrušit"
            }}>
                <SnackbarProvider
                    maxSnack={3}
                    preventDuplicate
                >
                    <Suspense>
                        {
                            kcContext===undefined
                                ? (
                                    <BrowserRouter>
                                        <QueryParamProvider
                                            adapter={ReactRouter6Adapter}
                                        >
                                            <NotificationProvider>
                                                <App />
                                            </NotificationProvider>
                                        </QueryParamProvider>
                                    </BrowserRouter>
                                )
                                : <KcApp kcContext={kcContext} />
                        }
                    </Suspense>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
