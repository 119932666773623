import {Formik, FormikValues} from "formik";
import {MeetingRequest, meetingSchemaObject} from "../../props/apiRequests";
import {
    FORM_SPACING,
    FormOpt,
    OnSubmit,
    OuterSubmitFormProps,
    OuterSubmitFormReturnProps,
    saveIdEntity,
    useOuterSubmitForm
} from "../props";
import {CompanyEmbeddedResponse, MeetingResponse} from "../../props/apiResponses";
import {FC, useCallback, useContext, useMemo} from "react";
import FormWithoutButton from "../FormWithoutButton";
import {Grid} from "@mui/material";
import TextField from "../fields/TextField";
import {useMeetingManipulator} from "../../services/meeting/MeetingService";
import DateField from "../fields/DateField";
import CompanySearch from "../fields/CompanySearch";
import {ContactPersonSearch} from "../fields/select/ContactPersonSelect";
import {AuthContext} from "../../services/auth/AuthService";
import UserSelect from "../fields/select/UserSelect";
import RichTextField from "../fields/RichTextField";

export interface MeetingFormValues extends FormikValues, Omit<MeetingRequest, "tradeId" | "contactPersons" | "users" | "companies"> {
    companyValue: string;
    contactPersons: number[];
    users: number[];
}

interface Props extends OuterSubmitFormProps<MeetingFormValues> {
    meeting?: MeetingResponse;
    tradeId?: number;
    company?: CompanyEmbeddedResponse;
}
const MeetingForm: FC<Props> = (
    {
        meeting,
        formikRef,
        onSubmit,
        loading,
        tradeId,
        company
    }
) => {
    const auth = useContext(AuthContext);

    const initValues = useCallback((): MeetingFormValues=>({
        meetingDate: meeting?.meetingDate ?? (null as unknown as Date),
        contactPersons: meeting?.contactPersons.map(c => c.id) ?? [],
        note: meeting?.note ?? "",
        company: meeting?.companies?.[0] ?? company ?? (null as unknown as CompanyEmbeddedResponse),
        companyValue: meeting?.companies?.[0].name ?? company?.name ?? "",
        users: meeting?.users.map(c => c.id) ?? [auth.user?.id as number],
        tradeId: meeting?.tradeId
    }), [meeting, auth, tradeId]);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initValues()}
            validationSchema={meetingSchemaObject}
            onSubmit={onSubmit}
        >
            {
                ({values, dirty})=>
                    <FormWithoutButton loading={loading}>
                        <Grid container spacing={FORM_SPACING}>
                            <Grid item xs={12} sm={6}>
                                <DateField
                                    label="Datum"
                                    name="meetingDate"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UserSelect
                                    label="Uživatel"
                                    name="users"
                                    SelectProps={{multiple: true}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CompanySearch
                                    label="Společnost"
                                    name="company"
                                    valueName="companyValue"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ContactPersonSearch
                                    label="Kontaktní osoba"
                                    name="contactPersons"
                                    companyEmbedded={values.company}
                                    dirty={dirty}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RichTextField
                                    label="Poznámka"
                                    name="note"
                                    height={300}
                                />
                            </Grid>
                        </Grid>
                    </FormWithoutButton>
            }
        </Formik>
    );
};

export default MeetingForm;

interface ReturnProps extends OuterSubmitFormReturnProps<MeetingFormValues> {
    props: Props;
}
interface Opt extends FormOpt<MeetingFormValues, MeetingResponse> {
    meeting?: MeetingResponse;
    tradeId?: number;
}

export function useMeetingForm({afterSave, meeting, tradeId}: Opt): ReturnProps {
    const manipulator = useMeetingManipulator()
    const onSubmit: OnSubmit<MeetingFormValues> = useCallback(async (values, formikHelpers) => {
        const data: MeetingRequest = {
            ...values,
            contactPersons: values.contactPersons.map((id) => ({id})),
            users: values.users.map((id) => ({id}))
        };

        if (tradeId!==undefined) data["tradeId"] = tradeId;

        if (await saveIdEntity(
            (id) => manipulator.update.run({id, data}),
            () => manipulator.create.run({data}),
            manipulator.update.messageHandler.success,
            meeting?.id, afterSave
        )) formikHelpers.resetForm({values});
    }, [meeting, tradeId]);

    const {props} = useOuterSubmitForm(onSubmit,manipulator.loading);

    return useMemo(() => ({
        props: {
            ...props,
            meeting,
            tradeId
        },
        loading: manipulator.loading
    }), [props, manipulator.loading, meeting, tradeId]);
}