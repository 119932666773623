import useRequest, {RequestService} from "../RequestService";
import {
    BaseRP,
    BusinessCaseCreationRequest,
    BusinessCaseRequest,
    BusinessCaseStatus,
    DeleteRP,
    GetRP,
    ID,
    IdRP,
    PostRP,
    PutRP,
    SupplierOrderRequest
} from "../../props/apiRequests";
import {BusinessCaseGridResponse, BusinessCaseResponse, SupplierOrderResponse} from "../../props/apiResponses";
import {Messages} from "../../props/messages";
import {setOrderToItems} from "../trade/TradeService";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {useCRUDRequest} from "../CRUDRequestService";
import {DocumentService} from "../DocumentService";

export interface BusinessCaseRP {
    businessCaseId: number;
}

export const BUSINESS_CASES_API = "/business-cases";

interface PatchBusinessCaseRP extends IdRP, BaseRP {
    status: BusinessCaseStatus;
}


export class BusinessCaseService extends GridCRUDServiceImpl<BusinessCaseRequest, BusinessCaseGridResponse, BusinessCaseCreationRequest> {
    public update = ({id, controller, data}: PutRP<BusinessCaseRequest>) => this.put<BusinessCaseRequest, BusinessCaseGridResponse>(this.addressWithId(id), setOrderToItems(data), controller);
    public create = ({controller, data}: PostRP<BusinessCaseCreationRequest>) => this.post(this.addressWithId(), data, controller);
    public status = ({id, controller, status}: PatchBusinessCaseRP) => this.patch(this.addressWithId(id)+"/status", {status}, controller);
}

const businessCaseService = new BusinessCaseService(BUSINESS_CASES_API);
export default businessCaseService;


/*
HOOKS
 */

export function useBusinessCaseManipulator() {
    const {get, remove, update, create, loading: crudLoading} = useCRUDRequest<BusinessCaseResponse, BusinessCaseService>(businessCaseService);
    const patch = useRequest(businessCaseService.status);

    const loading = crudLoading|| patch.loading;

    return {get, remove, create, update, loading, patch};
}

export function useBusinessCasesManipulator(globalLoading: boolean) {
    return useGridRequest<BusinessCaseGridResponse>(businessCaseService, globalLoading);
}


/**
 * DOCUMENT
 */

export interface GetDocumentBusinessCase extends GetRP, IdRP {}
class BusinessCaseDocumentService extends DocumentService {
    public address = ({businessCaseId, documentId}: {businessCaseId?: ID, documentId?: ID}) =>
        this.addressWithId(businessCaseId) + "/documents" + (documentId ? `/${documentId}` : "");

    public remove = ({id, controller}: DeleteRP & IdRP) =>
        this.delete(`/documents/${id}`, controller); // TODO document-only service
    public retrieve = ({controller, id}: GetDocumentBusinessCase) =>
        this.getFile(`/documents/${id}/download`, controller); // TODO document-only service
    public getInvoice = ({businessCaseId, controller, invoiceId}: GetRP & BusinessCaseRP & {invoiceId: number}) =>
        this.get(this.addressWithId(businessCaseId)+"/invoices"+`/${invoiceId}`, controller);
    public getDelivery = ({businessCaseId, controller, deliveryNoteId}: GetRP & BusinessCaseRP & {deliveryNoteId: number}) =>
        this.get(this.addressWithId(businessCaseId)+"/delivery-notes"+`/${deliveryNoteId}`, controller);
    public getConfirmation = ({businessCaseId, controller}: GetRP & BusinessCaseRP) =>
        this.get(this.addressWithId(businessCaseId)+"/order-confirmation", controller);
    public getSupplierOrder = ({businessCaseId, supplierId, controller}: GetRP & BusinessCaseRP & {supplierId: number}) =>
        this.get(this.addressWithId(businessCaseId)+"/supplier-order", controller, {
            params: {supplierId}
        });
}
const businessCaseDocumentService = new BusinessCaseDocumentService(BUSINESS_CASES_API);

const invoiceErrors = {
    400: {
        default: Messages.BANK_ACCOUNT_NOT_SET
    }
};
export function useBusinessCaseDocumentManipulator() {
    const upload = useRequest(businessCaseDocumentService.uploadDocument, undefined, {disableAutoLoading: true});
    const remove = useRequest(businessCaseDocumentService.remove, undefined, {disableAutoLoading: true});
    const get = useRequest(businessCaseDocumentService.retrieve);
    const getOrderToSupplier = useRequest(businessCaseDocumentService.getSupplierOrder);
    const getConfirmation = useRequest(businessCaseDocumentService.getConfirmation);
    const getDeliveryNote = useRequest(businessCaseDocumentService.getDelivery);
    const getInvoice = useRequest(businessCaseDocumentService.getInvoice, invoiceErrors);

    const loading = upload.loading || remove.loading || get.loading || getOrderToSupplier.loading
        || getConfirmation.loading || getDeliveryNote.loading || getInvoice.loading;

    return {upload, remove, loading, get, getOrderToSupplier, getConfirmation, getDeliveryNote, getInvoice};
}



/**
 * SUPPLIER ORDER
 */

class SupplierOrderService extends RequestService<SupplierOrderRequest, SupplierOrderResponse> {
    public update = ({businessCaseId, id, controller, data}: PutRP<SupplierOrderRequest> & BusinessCaseRP) =>
        this.put(this.addressWithId()+`/${businessCaseId}/${id}`, data, controller);
}
const supplierOrderService = new SupplierOrderService("/supplier-orders");

export function useSupplierOrderManipulator() {
    const update = useRequest(supplierOrderService.update);
    return {update, loading: update.loading};
}

