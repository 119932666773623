import {PropsWithChildren, SyntheticEvent, useState} from "react";
import {Autocomplete, CircularProgress, TextFieldProps as MuiTextFieldProps} from "@mui/material";
import {TextFieldInput} from "./TextField";
import {useField} from "formik";
import {FieldProps} from "../props";

interface Props<T> extends FieldProps {
    loading?: boolean;
    options: readonly T[];
    isOptionEqualToValue?: (option: T, value: T)=>boolean;
    getOptionLabel: (option: T)=>string;
    TextFieldProps?: MuiTextFieldProps;
    onInputChange?: (event: SyntheticEvent, value: string, reason: "input" | "reset" | "clear")=>void;
    getOptionKey?: (option: T)=>string | number;
    handleSelect?: (option: T)=>void;
    disableFreeSolo?: boolean;
    groupBy?: (option: T)=>string;
    clearable?: boolean;
    value?: T;
    disabled?: boolean;
    error?: string;
}
const ComboBox = <T, >(
    {
        loading,
        options,
        isOptionEqualToValue,
        getOptionLabel,
        TextFieldProps,
        label,
        name,
        required,
        onInputChange,
        handleSelect,
        disableFreeSolo,
        getOptionKey,
        groupBy,
        clearable,
        value,
        disabled,
        error
    }: PropsWithChildren<Props<T>>
) => {
    const [field, meta, helpers] = useField(name ?? "");
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}

            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel as (option: T | string)=>string}
            options={options}

            freeSolo={!disableFreeSolo}
            loading={loading}
            disableClearable={!clearable}
            inputValue={!disableFreeSolo ? field.value : undefined}
            value={value}

            groupBy={groupBy}

            onInputChange={onInputChange}
            onChange={(event, value, reason) => {
                if (reason==="selectOption" || reason==="clear") {
                    helpers.setValue(getOptionLabel ? getOptionLabel(value as T) : value);
                    if (handleSelect) handleSelect(value as T);
                }
            }}

            renderOption={(params, option) => {
                return (
                    <li {...params} key={getOptionKey ? getOptionKey(option) : getOptionLabel(option)}>
                        {getOptionLabel ? getOptionLabel(option) : ""}
                    </li>
                );
            }}

            renderInput={(params) => (
                <TextFieldInput
                    {...params}
                    label={label}
                    size="small"
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}

                    {...field}
                    {...TextFieldProps}

                    onBlur={(e)=>{
                        field.onBlur(e);
                        if (TextFieldProps?.onBlur) TextFieldProps.onBlur(e);
                    }}

                    required={required ?? TextFieldProps?.required}

                    error={!!error || (meta.touched && !!meta.error)}
                    helperText={!!error ? error : (meta.touched && meta.error)}
                />
            )}

            disabled={disabled}
        />
    );
};

export default ComboBox;