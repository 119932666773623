import React, { ReactNode } from "react";
import Grid from "../Grid";
import { FORM_SPACING } from "./constants";

interface FormGridProps {
    children?: ReactNode;
    className?: string;
}

const FormGrid = ({
    children,
    className
}: FormGridProps) => {
    return (
        <Grid container spacing={FORM_SPACING} className={className}>
            {children}
        </Grid>
    );
};

export default FormGrid;