enum ConfigurationKey {
    CS = "cs",
    EN = "en",
    DEFAULT = "default",

    COMMON = "common",
    OFFER_VERSION = "offerVersion",
    PDF_COMMON = "pdfCommon",

    COMPANY_NAME="companyName",
    COMPANY_PHONE="companyPhone",
    COMPANY_EMAIL="companyEmail",
    COMPANY_ADDRESS_STREET="companyAddressStreet",
    COMPANY_ADDRESS_CITY="companyAddressCity",
    COMPANY_ADDRESS_POSTCODE="companyAddressPostcode",
    COMPANY_ADDRESS_COUNTRY="companyAddressCountry",
    COMPANY_ICO="companyICO",
    COMPANY_DIC="companyDIC",
    COMPANY_LOGO="companyLogo",

    FOOTER_1ST_COLUMN="footer1stColumn",
    FOOTER_2ND_COLUMN="footer2ndColumn",
    FOOTER_3RD_COLUMN="footer3rdColumn",
    MAIN_PAGE_CONTACT="mainPageContact",

    OFFER_DEFAULT_VAT="vat",

    PRODUCT_IMPORT_COLUMN_MAPPING="productImportColumnMapping",

    OPPORTUNITY_CLOSING_STAGE="opportunityClosingStage",

    CATEGORIES_ITEMS_ENABLED="itemCategoriesEnabled"
}

export default ConfigurationKey;
