import {useCRUDRequest} from "../CRUDRequestService";
import {
    ConfigurationDocumentTextRequest,
    ConfigurationDocumentTextType,
    DocumentType,
    Language
} from "../../props/apiRequests";
import {ConfigurationDocumentTextResponse} from "../../props/apiResponses";
import {CONFIGURATIONS_API} from "./ConfigurationService";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {SelectOption, useSelectRequest} from "../../form/fields/select/Select";
import {useCallback, useRef, useState} from "react";
import {useCurrenciesManipulator} from "../currency/CurrencyService";
import {useInitialRequest} from "../RequestService";


class DocumentTextsService extends GridCRUDServiceImpl<ConfigurationDocumentTextRequest, ConfigurationDocumentTextResponse> {}
const documentTextsService = new DocumentTextsService(CONFIGURATIONS_API + "/document-texts");

export function useDocumentTextManipulator() {
    return useCRUDRequest<ConfigurationDocumentTextResponse, DocumentTextsService>(documentTextsService);
}

export function useDocumentTextsManipulator(globalLoading: boolean) {
    return useGridRequest<ConfigurationDocumentTextResponse>(documentTextsService, globalLoading);
}

export function useDocumentTextsSelect(
    documentType: DocumentType,
    documentTextType: ConfigurationDocumentTextType,
    language: Language
) {
    const manipulator = useDocumentTextsManipulator(false);
    const [items, setItems] = useState<ConfigurationDocumentTextResponse[]>();

    useInitialRequest(useCallback(async (controller?: AbortController)=>{
        const data = await manipulator.run({controller, params: {
            fetchAll: true, pageSize: 1, pageNum: 1,
            documentType, documentTextType, language
        }});
        if (data) setItems(data.data);
    }, [documentTextType, documentType, language]));

    return {items, loading: manipulator.loading};
}