import {useSelectRequest} from "../../form/fields/select/Select";
import {useCallback} from "react";
import {GridCRUDServiceImpl, useGridRequest} from "../GridService";
import {useCRUDRequest} from "../CRUDRequestService";
import {BankAccountRequest} from "../../props/apiRequests";
import {BankAccountResponse} from "../../props/apiResponses";

const ACCOUNTS_API = "/bank-accounts";

class BankAccountService extends GridCRUDServiceImpl<BankAccountRequest, BankAccountResponse> {}
const bankAccountService = new BankAccountService(ACCOUNTS_API);

export function useBankAccountManipulator() {
    return useCRUDRequest<BankAccountResponse, BankAccountService>(bankAccountService);
}

export function useBankAccountsManipulator(globalLoading: boolean) {
    return useGridRequest<BankAccountResponse>(bankAccountService, globalLoading);
}

export function useBankAccountSelect() {
    const {run, loading} = useBankAccountsManipulator(false);

    const accounts = useSelectRequest(
        useCallback((controller) => run({controller, params: {fetchAll: true, pageSize: 1, pageNum: 1}}), []),
        useCallback(data => data.data.map(({id, name, bankNumber, accountNumber})=>(
            {key: id, label: `${name} (${accountNumber}/${bankNumber})`}
        )), [])
    );

    return {accounts, loading};
}