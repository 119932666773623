import {FC, PropsWithChildren} from "react";
import {Menu} from "@mui/material";
import {GridContextMenuControls} from "../GridContextMenu";

type Props<Row> = {
    controls: GridContextMenuControls<Row>;
    visible: boolean;
};
const GridContextMenuWrapper = <Row, >(
    {
        controls: {contextMenu, handleClose},
        visible,
        children
    }: PropsWithChildren<Props<Row>>
) => {
    return (
        <Menu
            open={visible && contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            {children}
        </Menu>
    );
};
export default GridContextMenuWrapper;