import {
    BusinessCaseEmbeddedResponse,
    BusinessCaseResponse,
    CurrencyExchangeRateResponse,
    CurrencyResponse,
    OfferGridResponse,
    OfferListItemResponse,
    OfferResponse,
    OfferVersionResponse,
    ProductResponse,
    TradeItemResponse
} from "../../props/apiResponses";
import {round} from "../../props/helpers";
import React, {ReactNode} from "react";
import {ItemType} from "../../props/apiRequests";
import {convertToPercentage} from "../../form/fields/NumberTextField";


export interface TradeGridRow {
    order: number;
    orderCaption?: string;
    code: string;
    note: ReactNode;
    amount: number;
    total: number;
    item: TradeItemResponse;
    vat: number;
    category?: string;
}

export const getGridRow = (item: TradeItemResponse, order: number, orderCaption?: string): TradeGridRow => {
    return ({
        order: order,
        orderCaption: item.type===ItemType.TEXTUAL ? undefined : orderCaption,
        code: item.code ?? "",
        amount: item.amount ?? 0,
        total: item.priceTotal ?? 0,
        note: "",
        vat: item.vat ?? 0,
        category: item.category ?? "",
        item
    })
};

export const getGridNote = (name: string, description: string) => <><strong>{name}</strong> {description}</>;

export function pushGridRow<Row>(
    items: TradeItemResponse[],
    getRow: (item: TradeItemResponse, isSubItem: boolean, order: number, orderCaption: string)=>Row,
    disableOptions?: boolean
) {
    let realOrder: number = 0;
    let basicOrder: number = 0;
    const tmpRows: Row[] = [];

    items.forEach((item)=>{
        basicOrder++;
        if (item.type!==ItemType.TEXTUAL)
            realOrder++;

        tmpRows.push(getRow(item, false, basicOrder, realOrder.toString()));
        if (!disableOptions && item.options) {
            let realOptionsOrder: number = 0;
            let basicOptionsOrder: number = 0;

            item.options.forEach(
                (subItem, i) => {
                    basicOptionsOrder++;
                    if (subItem.type!==ItemType.TEXTUAL)
                        realOptionsOrder++;

                    tmpRows.push(getRow(subItem, true, basicOptionsOrder, `${realOrder}.${realOptionsOrder}`));
                }
            );
        }
    });

    return tmpRows;
}

export function setItemIn<R extends OfferVersionResponse | BusinessCaseResponse>(prev: R | undefined, entity: TradeItemResponse): R | undefined {
    if (!prev) return;

    const tryToSubstitute = (items: TradeItemResponse[], index: number) => {
        if (items[index].id === entity.id) {
            items[index] = entity;
            return true;
        }
        return false;
    };

    const items: TradeItemResponse[] = [...(prev.items ?? [])];
    let stored: boolean = false;
    for (let i=0; i<items.length; i++) {
        if ((stored = tryToSubstitute(items, i))) break;

        const options = items[i].options ?? [];
        for (let j=0; j<options.length; j++)
            if ((stored = tryToSubstitute(options, j))) break;

        if (stored) break;
    }
    if (!stored) items.push(entity);

    return {...prev, items};
}

export function removeItemIn<T extends TradeItemResponse>(removalId: number, oldItems?: T[]): T[] {
    const items = (oldItems ?? []).filter(item=>item.id!==removalId);
    for (let i=0; i<items.length; i++) {
        items[i].options = items[i].options?.filter(subItem=>subItem.id!==removalId);
    }

    return items;
}

export function computePrice(
    rawAmount: string | number,
    rawPrice: string | number,
    rawDiscount?: string | number,
    rawVat?: string | number
) {
    const numAmount = Number(rawAmount);
    const numPrice = Number(rawPrice);
    const numDiscount = Number(rawDiscount);
    const numVat = Number(rawVat);
    let totalPrice: number = 0;

    if (!isNaN(numAmount) && !isNaN(numPrice))
        totalPrice = numAmount*numPrice;

    if (!isNaN(numDiscount))
        totalPrice -= numDiscount;

    if (!isNaN(numVat)) {
        totalPrice *= 1+convertToPercentage(numVat);
    }

    return round(totalPrice);
}

export function computeDiscount(
    discountValue: string | number,
    priceBeforeDiscount: string | number
): number {
    const numValue: number = Number(discountValue);
    const numPrice: number = Number(priceBeforeDiscount);
    if (isNaN(numValue) || isNaN(numPrice)) return 0;
    if (numPrice===0) return 0;

    return round((numValue/numPrice)*100);
}

export function computeDiscountValue(
    percentage: number,
    priceBeforeDiscount: number
) {
    return round(percentage*priceBeforeDiscount);
}


export function convertPriceByCurrency(
    price: number,
    originCurrency: number,
    newCurrency: number,
    availableRates: CurrencyExchangeRateResponse[]
) {
    for (let rate of availableRates) {
        if (rate.currencyFrom.id===originCurrency && rate.currencyTo.id===newCurrency) {
            return round(rate.rate*price);
        }
    }
    return round(price);
}

export function convertProductSalePriceByCurrency(
    product: ProductResponse,
    newCurrency: CurrencyResponse,
    availableRates: CurrencyExchangeRateResponse[]
): number {
    return convertPriceByCurrency(
        product.salePrice,
        product.purchaseCurrency.id,
        newCurrency.id,
        availableRates
    );
}

export function convertProductPurchasePriceByCurrency(
    product: ProductResponse,
    newCurrency: CurrencyResponse,
    availableRates: CurrencyExchangeRateResponse[]
): number {
    return convertPriceByCurrency(
        product.purchasePrice,
        product.purchaseCurrency.id,
        newCurrency.id,
        availableRates
    );
}

export function createIndivisibleGaps(num: string) {
    return num.replaceAll(" ", String.fromCharCode(160));
}

export function localeNumber(num?: number | null, options?: Intl.NumberFormatOptions): string {
    if (num===undefined || num===null) return "0";

    return createIndivisibleGaps(num.toLocaleString("cs", {
        maximumFractionDigits: 2, minimumFractionDigits: 2,
        ...options
    }));
}

export function priceWithCurrency(price: number | string, code?: string, opt?: Intl.NumberFormatOptions): string {
    price = Number(price);
    code = code ?? "";

    try {
        return localeNumber(price, {style: "currency", currency: code, ...opt});
    } catch (e) {
        return `${localeNumber(price, opt)} ${code}`;
    }
}

export function discountWithCurrency(
    priceBeforeDiscount: number,
    discountValue: number | string,
    currencyCode?: string,
    discountPercentage?: number | string | null,
    exchangeRate?: number
): ReactNode {
    const value = Number(discountValue);
    if (isNaN(value)) return <></>;

    let percentage: number = 0;
    if (discountPercentage!==undefined && discountPercentage!==null) {
        const numPercentage = Number(discountPercentage);
        if (!isNaN(numPercentage)) percentage = numPercentage;
    }
    if (percentage===0) percentage = computeDiscount(value, priceBeforeDiscount);

    return <>{priceWithCurrency((value ?? 0)*(exchangeRate ?? 1), currencyCode)} ({numberWithPercentages(percentage)})</>;
}

export function numberWithPercentages(raw?: number | string): string {
    let result: string = "0 %";
    const num: number = Number(raw);
    if (isNaN(num)) return result;

    if (!!num) result = createIndivisibleGaps(localeNumber(Number(num), {
        minimumFractionDigits: 0
    })+" %");
    return result;
}

export function tradeEntityName(entity: OfferListItemResponse | BusinessCaseEmbeddedResponse | OfferResponse | OfferGridResponse): string {
    return `${entity.code}/${entity.year.toString().substr(2)}`;
}
