import {FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {CalculatedColumn, Column} from "react-data-grid";
import useGridContextMenu from "../../common/dataGrid/contextMenu/GridContextMenu";
import DataGrid, {DataGridProps} from "../../common/dataGrid/DataGrid";
import {percentageFormatter} from "../../common/dataGrid/formatter/PercentageFormatter";
import {priceFormatter} from "../../common/dataGrid/formatter/PriceFormatter";
import {computeDynamicHeight} from "../../common/dataGrid/props";
import {RowRenderer} from "../../common/dataGrid/row/props";
import {TopAction} from "../../common/dataGrid/TopActions";
import {BusinessCaseItemResponse} from "../../props/apiResponses";
import MainGridContextMenu from "../trade/MainGridContextMenu";
import {getGridNote, getGridRow, pushGridRow} from "../trade/props";
import {BusinessCaseContext, BusinessCaseGridRow} from "./props";

export interface BusinessCaseRow extends BusinessCaseGridRow {
    sellingPricePerUnit: number;
    purchasePricePerUnit: number;
    discount: number;
}

interface Props {
    rows: BusinessCaseRow[];
    columns: Column<BusinessCaseRow>[];
    topActions?: TopAction[];
    descriptionKey: string;
    rowRenderer?: RowRenderer<BusinessCaseRow>;
    onRowDoubleClick?: (row: BusinessCaseRow, column: CalculatedColumn<BusinessCaseRow>)=>void;
    DataGridProps?: Partial<DataGridProps<BusinessCaseRow>>;
}
const CustomerItemsDataGrid: FC<Props> = (
    {
        rows,
        columns,
        topActions,
        descriptionKey,
        rowRenderer,
        onRowDoubleClick,
        DataGridProps
    }
) => {
    const gridContextMenu = useGridContextMenu<BusinessCaseRow>(useMemo(()=>({
        main: {
            key: "main",
            component: (props) =>
                <MainGridContextMenu
                    key="main"
                    {...props}
                    descriptionKey={descriptionKey}
                />
        }
    }), []));

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            rowRenderer={rowRenderer}
            height={computeDynamicHeight(rows.length)}

            topActions={topActions}
            contextMenu={gridContextMenu.contextMenu}

            onRowDoubleClick={onRowDoubleClick}

            rowClass={(row)=>row.isSubItem ? "subItem" : ""}

            {...DataGridProps}
        />
    );
};
export default CustomerItemsDataGrid;


interface ReturnProps {
    props: Props;
}

export function useCustomerItemsGrid(
    gridActionsFormatter: (row: BusinessCaseRow)=>ReactElement,
    descriptionKey: "customerDescription" | "invoiceDescription",
    items?: BusinessCaseItemResponse[]
): ReturnProps {
    const context = useContext(BusinessCaseContext);
    const [rows, setRows] = useState<BusinessCaseRow[]>([]);

    const formatPrice = useCallback((price: number, currencyCode?: string)=>
        priceFormatter(price, currencyCode ?? context?.data?.pricing?.currency.code),
    [context?.data?.pricing?.currency.code]);

    const columns: Column<BusinessCaseRow>[] = useMemo(()=>{
        const result: Column<BusinessCaseRow>[] = [
            {
                key: "orderCaption",
                name: "Č.",
                minWidth: 50,
                width: "5%"
            },
            {
                key: "code",
                name: "Obj. číslo",
                width: "20.5%",
                minWidth: 100
            },
            {
                key: "note",
                name: "Popis",
                width: "25%",
                minWidth: 200
            },
            {
                key: "purchasePricePerUnit",
                name: "Nákupní cena",
                width: "10%",
                minWidth: 100,
                formatter: ({row})=>formatPrice(row.purchasePricePerUnit, row.item.product?.purchaseCurrency.code)
            },
            {
                key: "sellingPricePerUnit",
                name: "Prodejní cena",
                width: "10%",
                minWidth: 100,
                formatter: ({row})=>formatPrice(row.sellingPricePerUnit)
            },
            {
                key: "amount",
                name: "Počet",
                width: "5%",
                minWidth: 50,
            },
            {
                key: "discount",
                name: "Sleva",
                width: "10%",
                minWidth: 100,
                formatter: ({row})=>percentageFormatter(row.discount)
            },
            {
                key: "total",
                name: "Cena celkem",
                width: "10%",
                minWidth: 100,
                formatter: ({row})=>formatPrice(row.total)
            },
        ];

        if (!context?.data?.pricing.ignoreVat)
            result.push({
                key: "vat",
                name: "DPH",
                width: "7%",
                minWidth: 50,
                formatter: ({row})=>percentageFormatter(row.vat)
            });

        return result.concat([
            {
                key: "actions",
                name: "Akce",
                formatter: ({row})=>gridActionsFormatter(row),
                minWidth: 50,
                width: "5%"
            }
        ]);
    }, [formatPrice, gridActionsFormatter, context?.data?.pricing.ignoreVat]);

    useEffect(()=>{
        if (!items && !context?.data?.items) return;

        const getRow = (item: BusinessCaseItemResponse, isSubItem: boolean, order: number, orderCaption?: string): BusinessCaseRow => ({
            ...getGridRow(item, order, orderCaption),
            sellingPricePerUnit: item.sellingPricePerUnit ?? 0,
            purchasePricePerUnit: item.purchasePricePerUnit ?? 0,
            discount: item.discountPercentage ?? 0,
            note: getGridNote(item.name, item[descriptionKey]),
            isSubItem
        });

        setRows(pushGridRow(items ?? context?.data?.items ?? [], getRow));
    }, [context?.data, items]);

    return {props: {rows, columns, descriptionKey}};
}
