import React, {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AddressResponse, NotificationResponse} from "./apiResponses";
import {AddressRequest} from "./apiRequests";

const dateFormat = require("dateformat");


/**
 * COMMON
 */

export function notEmpty(val: any): boolean {
    return val!=="" && val!==null && val!==undefined;
}

export function clearNumberInput(): number {
    return (("" as unknown) as number);
}

export function isActiveNotification(notification: NotificationResponse, withoutUNREADFlag?: boolean): boolean {
    const timeMatches: boolean = new Date(notification.notificationDate).getTime() < (new Date()).getTime();
    if (withoutUNREADFlag) return timeMatches;
    //@ts-ignore
    return timeMatches && notification.status === NotificationStatusKind.UNREAD;
}

export function makeDownload(url: string, filename: string) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
}

export function makeDownloadBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    makeDownload(url, filename);
}

export function openFileBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
}

export function createURLFromBase64(base64Data: string, mimeType: string) {
    return `data:${mimeType};base64,${base64Data}`;
}

export function inlineAddress(street?: string, city?: string, zip?: string): string {
    const exists = (value?: string): boolean => value!==undefined && value!=="" && value!==null;
    return (exists(street) ? street : "")+(exists(city) ? ((!exists(street) ? "" : ", ")+city+(exists(zip) ? " "+zip : "")) : "");
}

export function mergeAddress({street, city, postcode}: AddressResponse | AddressRequest): string {
    const exists = (value?: string): boolean => value!==undefined && value!=="" && value!==null;
    return (exists(street) ? street : "")+(exists(city) ? ((!exists(street) ? "" : ", ")+(exists(postcode) ? postcode+" " : "")+city) : "");
}

export function computeUnitTotalPrice(pricePerUnit: number, amount: number, discountValue: number): number {
    if (isNaN(pricePerUnit)) pricePerUnit = 0;
    if (isNaN(amount)) amount = 0;
    if (isNaN(discountValue)) discountValue = 0;
    return (pricePerUnit*amount)-discountValue;
}

export function priceWithCurrency(price: number, currencyLabel: string): string {
    const options = {maximumFractionDigits: 2, minimumFractionDigits: 2};

    let result: string = "";
    try {
        result = price.toLocaleString(undefined, {...options, style: "currency", currency: currencyLabel});
    } catch (e) {
        result = `${price.toLocaleString(undefined, options)} ${currencyLabel}`;
    }

    return result.replaceAll(" ", String.fromCharCode(160));
}

export function printPercentage(percentage: number): string {
    if (isNaN(percentage)) return `0${String.fromCharCode(160)}%`;
    return `${(percentage*100).toLocaleString(undefined, {maximumFractionDigits: 2})}${String.fromCharCode(160)}%`;
}

export function isValidDate(date?: Date): boolean {
    if (!date) return false;
    return !isNaN(date.valueOf());
}

export type PassParamsReplacements = {[key in string | number]: string | number};
export function passParams(pattern: string, replacements: PassParamsReplacements): string {
    Object.entries(replacements).forEach(([key, value]): void => {
        pattern = pattern.replace(`:${key}`, value.toString());
    });
    return pattern;
}

export function numberToLocaleString(value: number): string {
    return round2DecimalsPrecise(value).toLocaleString();
}

export function compareTwoArraysInorder(a: any[], b: any[]): boolean {
    if (a.length !== b.length) return false;
    const uniqueValues: Set<any> = new Set([...a, ...b]);
    for (const v of Array.from(uniqueValues.values())) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
}

export function useIntervalExecution(
    interval: number,
    firstImmediately?: boolean,
    progress?: (percentage: number)=>void
): {
    run: (callback: ()=>Promise<void>)=>Promise<void>;
    doAndReset: ()=>Promise<void>;
} {
    let timer = useRef<ReturnType<typeof window.requestAnimationFrame>>();
    const savedCallback = useRef<()=>Promise<void>>();
    const startTime = useRef<number>();

    const process = async (timestamp: number) => {
        if (!startTime.current) startTime.current = timestamp;
        const elapsed: number = timestamp - startTime.current;

        let currentProgress: number = (Math.round((elapsed/interval)*10)/10)*100;
        if (elapsed>=interval) {
            if (savedCallback.current) await savedCallback.current();
            startTime.current = undefined;
            currentProgress = 0
        }
        if (progress) progress(currentProgress);

        timer.current = requestAnimationFrame(process);
    };

    const run = async (callback: ()=>Promise<void>) => {
        savedCallback.current = callback;
        if (firstImmediately) await callback();
        timer.current = requestAnimationFrame(process);
    };

    const doAndReset = async () => {
        if (timer.current)
            cancelAnimationFrame(timer.current);
        startTime.current = undefined;
        if (progress) progress(0);

        if (savedCallback.current) await savedCallback.current();

        timer.current = requestAnimationFrame(process);
    };

    return {run, doAndReset};
}

export function useHistory() {
    const navigate = useNavigate();
    return useMemo(()=>({
        goBack: ()=>navigate(-1),
        push: (link: string | {search: string}, state?: any)=>navigate(link, {state}),
        replace: (link: string, state?: any)=>navigate(link, {state, replace: true})
    }), [navigate]);
}

export function usePrevPage(): {goBack: ()=>void; snapshot: ()=>{prevPage: string}; prevSnapshot: string | undefined} {
    const location = useLocation<{ prevPage?: string }>();
    const navigate = useNavigate();

    return {
        goBack: () => {
            if (location.state && location.state.prevPage) {
                navigate(location.state.prevPage);
            } else {
                navigate(-1);
            }
        },
        snapshot:() => ({prevPage: location.pathname + location.search}),
        prevSnapshot: location.state?.prevPage
    }
}

export function useUpdateChange(fce: ()=>void, deps: any[]) {
    const initRender = useRef<boolean>(true);

    useEffect(()=>{
        if (initRender.current) {
            initRender.current = false;
            return;
        }

        fce();
    }, deps);
}

export type RowSelectionType = {
    selected: (number|string)[],
    setSelected: Dispatch<SetStateAction<(string | number)[]>>,
    maxLength: number,
    setMaxLength: Dispatch<SetStateAction<number>>
};
export function useRowSelection() : RowSelectionType {
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [maxLength, setMaxLength] = useState<number>(0);

    return {
        selected,
        setSelected,
        maxLength,
        setMaxLength,
    }
}


/**
 * TRANSLATIONS
 */

export function translateState(value: "ACTIVE" | "INACTIVE" | "COMPLETED" | undefined): string {
    switch (value) {
        case "ACTIVE": return "Aktivní";
        case "INACTIVE": return "Neaktivní";
        case "COMPLETED": return "Dokončeno";
    }

    return value ?? "";
}

/**
 * DATES
 */
export function formatDate(date?: Date | string | null, format?: "without time" | string): string {
    if (format==="without time") format = "dd. mm. yyyy";
    if (date) {
        if (date instanceof Date && isNaN(date.valueOf()))
            return "";
        return dateFormat(date, format ?? 'dd. mm. yyyy HH:MM');
    }

    return "";
}

/**
 * MATH
 */
function round2DecimalsPrecise(num: number) { // most precise
    let m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

export function round(num: number, decimals?: number) {

    if (!decimals || decimals===2) return round2DecimalsPrecise(num);

    const dec: number = Math.pow(10, decimals);
    return Math.round(num * dec) / dec;
}

/**
 * NOTIFICATIONS
 */
export function fireNotification(title: string, body: string) {
    const options = {
        body: body,
        icon: '/logo512.png'
    }

    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        new Notification(title, options);
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                new Notification(title, options);
            }
        });
    }
}
