import {DeliveryNoteRequest, DeliveryNoteResponse} from "../../containers/trade/businessCase/schema/deliveryNote";
import {Asserts, date, number, object, string} from "yup";
import {Form, Formik, FormikValues} from "formik";
import {FORM_SPACING, FormProps, FormReturnProps, OnSubmit, useForm} from "../props";
import {ConfigurationDocumentTextType, DocumentType, Language} from "../../props/apiRequests";
import React, {useCallback, useContext, useMemo} from "react";
import {BusinessCaseContext} from "../../pages/businessCase/props"
import NumberTextField from "../fields/NumberTextField";
import {Grid} from "@mui/material";
import {useMessages} from "../../props/messagesHandler";
import {Messages} from "../../props/messages";
import DateField from "../fields/DateField";
import DocumentTextTypeChooser from "../fields/DocumentTextTypeChooser";
import RichTextField from "../fields/RichTextField";
import SaveGridItem from "../SaveGridItem";
import {Frame} from "../../common/Frame";
import usePutDeliveryNote from "../../containers/trade/businessCase/services/usePutDeliveryNote";

const schema = {
    warrantyMonths: number().integer().min(0).nullable().optional(),
    deliveryDate: date().nullable().optional(),
    deliveryNoteTerms: string().nullable().optional()
};
const schemaObject = object(schema);

interface DeliveryNoteFormValues extends Asserts<typeof schemaObject>, FormikValues {
}

export interface DeliveryNoteFormProps extends FormProps<DeliveryNoteFormValues> {
    deliveryNote: DeliveryNoteResponse;
    disabled?: boolean;
}

const DeliveryNoteForm = (
    {
        deliveryNote,
        onSubmit,
        loading,
        disabled
    }: DeliveryNoteFormProps
) => {
    const context = useContext(BusinessCaseContext);

    const initValues = useCallback((): DeliveryNoteFormValues=>({
        warrantyMonths: deliveryNote.warrantyMonths,
        deliveryDate: deliveryNote.deliveryDate ?? deliveryNote.createdAt ?? null,
        deliveryNoteTerms: deliveryNote.deliveryNoteTerms ?? ""
    }), [deliveryNote]);

    return (
        <Formik
            initialValues={initValues()}
            validationSchema={schemaObject}
            onSubmit={onSubmit}
        >
            <Form>
                <Grid container spacing={FORM_SPACING}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Frame defaultColor>
                            <Grid container spacing={FORM_SPACING}>
                                <Grid item xs={12}>
                                    <NumberTextField
                                        label="Délka záruky"
                                        name="warrantyMonths"
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DateField
                                        label="Datum dodání"
                                        name="deliveryDate"
                                        DatePickerProps={{disabled}}
                                    />
                                </Grid>
                            </Grid>
                        </Frame>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <Frame defaultColor>
                            <RichTextField
                                label="Podmínky (dodatek)"
                                name="deliveryNoteTerms"
                                disabled={disabled}
                                height={150}
                                controls={
                                    <DocumentTextTypeChooser
                                        documentType={DocumentType.CUSTOMER_DELIVERY_NOTE}
                                        documentTextType={ConfigurationDocumentTextType.APPENDIX}
                                        language={context?.data?.language ?? Language.CZECH}
                                        textFieldName="deliveryNoteTerms"
                                        disabled={disabled}
                                    />
                                }
                            />
                        </Frame>
                    </Grid>
                    {
                        !disabled && <SaveGridItem loading={loading} />
                    }
                </Grid>
            </Form>
        </Formik>
    );
};

export default DeliveryNoteForm;

export interface DeliveryNoteFormReturnProps extends FormReturnProps<DeliveryNoteFormValues> {
    props: DeliveryNoteFormProps;
}

export interface UseDeliveryNoteFormArgs {
    deliveryNote: DeliveryNoteResponse;
}

export function useDeliveryNoteForm({ deliveryNote }: UseDeliveryNoteFormArgs): DeliveryNoteFormReturnProps {
    const context = useContext(BusinessCaseContext);
    const {success} = useMessages();
    const putDeliveryNote = usePutDeliveryNote();

    const onSubmit: OnSubmit<DeliveryNoteFormValues> = useCallback( async (values, formikHelpers) => {
        if (!context?.data || !context?.setData) return;

        const data: DeliveryNoteRequest = {
            ...deliveryNote,
            ...values
        };

        try {
            const response = await putDeliveryNote.mutateAsync({
                id: deliveryNote.id,
                data
            });
            formikHelpers.resetForm({values});
            context.setData((oldData)=>!oldData ? undefined : ({
                ...oldData,
                deliveryNotes: oldData.deliveryNotes.map(d=>{
                    if (d.id===deliveryNote.id) return response;
                    return d;
                })
            }));
            success(Messages.SAVED);
        } catch (e) {
            //
        }
    }, [context, success]);

    const {props} = useForm(onSubmit, !!context?.saveCaseLoading);

    return useMemo(()=>({
        props: {
            ...props,
            deliveryNote,
            loading: putDeliveryNote.isLoading
        }
    }), [props, deliveryNote, putDeliveryNote.isLoading]);
}
