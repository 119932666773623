import { FC, useCallback, useContext, useMemo } from "react";
import { Action, ActionsFormatter, PredefinedAction } from "../../../common/dataGrid/formatter/ActionsFormatter";
import { DnDRendererProvider } from "../../../common/dataGrid/row/dnd/props";
import { PredefinedTopAction, TopAction } from "../../../common/dataGrid/TopActions";
import DeleteConfirmationDialog, { useDeleteConfirmationDialog } from "../../../common/dialog/DeleteConfirmationDialog";
import ExternalOneFile from "../../../common/ExternalOneFile";
import { Frame } from "../../../common/Frame";
import CustomerOrderForm, { useCustomerOrderForm } from "../../../form/businessCase/CustomerOrderForm";
import TradeItemFormDialog, { useTradeItemFormDialog } from "../../../form/trade/TradeItemFormDialog";
import { DocumentType } from "../../../props/apiRequests";
import { BusinessCaseItemResponse } from "../../../props/apiResponses";
import { Messages } from "../../../props/messages";
import { useBusinessCaseItemManipulator } from "../../../services/businessCase/BusinessCaseItemService";
import { useTradeItemsDnd } from "../../trade/DnDItemsReordering";
import { removeItemIn } from "../../trade/props";
import CustomerItemsDataGrid, { BusinessCaseRow, useCustomerItemsGrid } from "../CustomerItemsDataGrid";
import { BusinessCaseContext, BusinessCaseTabProps, useBusinessCaseDocuments } from "../props";


interface Props extends BusinessCaseTabProps {

}
const CustomerOrder: FC<Props> = () => {
    const context = useContext(BusinessCaseContext);
    const itemManipulator = useBusinessCaseItemManipulator();
    const {update: {messageHandler}} = itemManipulator;
    const itemDialog = useTradeItemFormDialog({
        businessCaseId: context?.data?.id,
        isOffer: false
    });
    const footerForm = useCustomerOrderForm();

    const handleDetail = useCallback((row: BusinessCaseRow)=>{
        itemDialog.handleOpen(row.order, row.item);
    }, [itemDialog]);

    const itemsGrid = useCustomerItemsGrid(useCallback((row)=>{
        const result: Action[] = [
            [
                context?.lockedOffer ? PredefinedAction.READONLY : PredefinedAction.EDIT,
                ()=>handleDetail(row)
            ],
        ];

        if (!context?.lockedOffer)
            result.push([PredefinedAction.REMOVE, ()=>deleteDialog.handleOpen(row.item.id)]);

        return ActionsFormatter(result);
    }, [context, handleDetail]), "customerDescription");

    const handleDeleteItem = useCallback((itemId: number)=>{
        if (!context?.setData) return;

        context.setData((businessCase)=>{
            if (!businessCase) return;
            return {...businessCase, items: removeItemIn(itemId, businessCase.items)};
        });
    }, [context?.setData]);

    const deleteDialog = useDeleteConfirmationDialog<number>(async ()=>{
        if (!context?.data) return;
        const id = deleteDialog.entityRef.current;
        if (!id) return;
        const result = await itemManipulator.remove.run({id, businessCaseId: context.data.id});
        if (result!==null) handleDeleteItem(id);
        deleteDialog.handleClose();
    });

    const handleAddItem = useCallback(()=>{
        if (!context?.data) return;

        itemDialog.handleOpen((context.data.items?.length ?? 0)+1);
    }, [context?.data]);

    const topActions: TopAction[] = useMemo(()=>([
        [PredefinedTopAction.ADD, handleAddItem, {label: "Přidat položku"}]
    ]), [handleAddItem]);

    const afterSaveProxy = useCallback((entity: BusinessCaseItemResponse)=>{
        if (context?.afterSaveItem) context.afterSaveItem(entity);
        else itemDialog.handleOpen(entity.order, entity);
    }, [context, itemDialog]);


    const gridDnD = useTradeItemsDnd(useCallback(async (items)=>{
        if (!context?.data || !context?.setData) return;
        if (await context.saveCase({...context.data, items})) messageHandler.success(Messages.SAVED);
    }, [context, messageHandler]), itemsGrid.props.rows, context?.data?.items);

    const documents = useBusinessCaseDocuments();

    if (!context?.data) return null;

    return (
        <>
            <DnDRendererProvider>
                <CustomerItemsDataGrid
                    {...itemsGrid.props}
                    rowRenderer={context.lockedOffer ? undefined : gridDnD.gridDnD.rowRenderer}
                    topActions={context.lockedOffer ? undefined : topActions}
                    onRowDoubleClick={handleDetail}
                />
            </DnDRendererProvider>
            {gridDnD.dialogElement}
            <TradeItemFormDialog
                {...itemDialog.props}
                currenciesRates={context.exchangeRates ?? []}
                currentCurrency={context.data.pricing?.currency}
                afterSave={afterSaveProxy}
                purchaseCurrency={undefined}
                disabled={context?.lockedOffer}
                deliveryNotes={context.data.deliveryNotes}
            />
            <DeleteConfirmationDialog
                {...deleteDialog.props}
                entityName="Položka"
            />

            <Frame defaultColor sx={{mt: 1}}>
                <CustomerOrderForm
                    {...footerForm.props}
                    disabled={context?.lockedOffer}

                    fileUploadComponent={
                        <ExternalOneFile
                            label="Objednávka zákazníka"
                            documents={context.data.documents}
                            handleRemove={documents.removeDocumentByType}
                            handleUpload={documents.uploadFile}
                            getDocumentBlob={documents.getDocumentBlob}
                            startLoading={documents.manipulator.get.startLoading}
                            stopLoading={documents.manipulator.get.stopLoading}
                            setDocuments={documents.setDocuments}
                            documentType={DocumentType.CUSTOMER_ORDER}
                            match={(document)=>document.type===DocumentType.CUSTOMER_ORDER}
                            disabled={context?.lockedOffer}
                        />
                    }
                />
            </Frame>
        </>
    );
};
export default CustomerOrder;
